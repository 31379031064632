import { AddressUtil } from '../utils/address-util';

export class ICep {
  cep: string | undefined;
  logradouro: string | undefined;
  bairro: string | undefined;
  cidade: string | undefined;
  estado: string | undefined;
  altopadrao: boolean | undefined;
  idConsultor: string | undefined;
  streetcode: string | undefined;

  constructor(args?: Partial<ICep>) {
    if (args) {
      Object.assign(this, args);
    }
  }

  public toLabel(): string {
    return AddressUtil.build(this);
  }
}

export interface IBuscaEnderecoOutput {
  conteudo: ICep[];
  mensagem: string;
  codigo: number;
}

export interface IBuscaComplemento {
  label: string;
  complemento: string;
  suplemento: string;
  apartamentos: string[];
}

export interface IBuscaEnderecoUnificado {
  cep: string;
  logradouro: string;
  bairro: string;
  cidade: string;
  numero: string;
  estado: string;
  streetcode: string;
}

export interface IBuscaComplementosOutput {
  conteudo: {
    codigoMsg: number;
    complementos: IBuscaComplemento[];
    enderecoUnificado: IBuscaEnderecoUnificado;
  };
}

export interface IBuscaEnderecoUnificadaInput {
  nome: string;
  telefone: string;
  dadosEndereco: {
    cep?: string;
    numero?: string;
    complemento?: string;
    suplemento?: string;
    apartamento?: string;
    logradouro?: string;
    bairro?: string;
    cidade?: string;
    estado?: string;
    idEndereco?: string;
  };
}

export interface IAddress {
  cep: string;
  logradouro: string;
  cidade: string;
  bairro: string;
  estado: string;
  numero?: string;
  apt?: string;
  bloco?: string;
  suplemento?: string;
  complemento?: string;
}

export interface IGooglePlacesInput {
  logradouro: string;
  sessionToken: string;
}

export interface IListaEnderecosConteudoOutput {
  enderecos: IBuscaEnderecoOutput[];
  labels: string[];
}

export interface IListaEnderecosOutput {
  conteudo: IListaEnderecosConteudoOutput;
}

export enum FlowTypeEnum {
  MutiraoHabitado = '01',
  IndividualizacaoCliente = '02',
  LigaReliga = '03',
  Ortec = '04',
}

export interface IVerificaInstalacaoInput {
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  suplemento: string;
  apartamento: string;
  cidade: string;
  estado: string;
  bairro: string;
  perfil: string;
  idLogradouro: string;
  nome: string;
  telefone: string;
}

export interface IVerificaInstalacaoOutput {
  mensagem: string;
  codigo: number;
  conteudo: IVerificaInstalacaoConteudoOutput;
}

export interface IVerificaInstalacaoConteudoOutput {
  dadosGeogas: {
    concessao: boolean;
    ramal: boolean;
    confirmacaoRamal: string;
    rede: boolean;
    latitude: number;
    longitude: number;
  };
}

export interface IEnderecoUnificadoTipoFluxoOutput {
  cep: string;
  logradouro: string;
  numero: string;
  apartamento: string;
  bairro: string;
  cidade: string;
  bloco: string;
  suplemento: string;
  estado: string;
}

export interface IBuscaEnderecoUnificadoTipoFluxoOutput {
  idInstalacao: string;
  endereco: IEnderecoUnificadoTipoFluxoOutput;
  nomeCondominio: string;
  protocolo: string;
  dataCadastro: string;
  status: string;
}

export interface IBuscaTipoFluxoOutput {
  conteudo: {
    nomeCompleto: string;
    assunto: string;
    codigoMsg: number;
    contrato: string;
    enderecoUnificado: IBuscaEnderecoUnificadoTipoFluxoOutput;
    extra: string;
    localConsumo: string;
    protocolo: string;
    tipo: string;
  };
}
