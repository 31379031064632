{
  "locale": "pt-BR",
  "businessName": "Comgás",
  "buttonWidth": "100",
  "buttonHeight": "100",
  "customColors": {
    "brandColor": "007ec1",
    "actionColor": "007ec1"
  },
  "customText": {
    "actionPaymentCompleted": "Pagamento Realizado",
    "actionPaymentError": "Um erro ocorreu durante o processamento do cartão. <br> Por favor tente novamente ou use um cartão diferente.",
    "actionPostbackError": "Um erro ocorreu durante o processamento da sua ação. Por favor tente novamente.",
    "clickToRetry": "Não foi possível entregar a mensagem. Clique aqui para tentar novamente.",
    "clickToRetryForm": "Não foi possível enviar o formulário. Clique em qualquer lugar do formulário para tentar novamente.",
    "connectNotificationText": "Sincronize esta conversa e continue a falar conosco a partir de seu aplicativo preferido.",
    "connectNotificationSingleText": "Seja notificado quando houver uma resposta.",
    "conversationListHeaderText": "Minhas conversas",
    "conversationListRelativeTimeJustNow": "Agora",
    "conversationListRelativeTimeMinute": "1 minuto atrás",
    "conversationListRelativeTimeMinutes": "{value} minutos atrás",
    "conversationListRelativeTimeHour": "1 hora atrás",
    "conversationListRelativeTimeHours": "{value} horas atrás",
    "conversationListRelativeTimeYesterday": "Ontem",
    "conversationListTimesta": "DD/MM/YYYY",
    "conversationListPreviewAnonymousText": "Alguém",
    "conversationListPreviewCarouselText": "{user} enviou uma mensagem",
    "conversationListPreviewFileText": "{user} enviou um arquivo",
    "conversationListPreviewFormText": "{user} enviou um formulário",
    "conversationListPreviewFormResponseText": "{user} preencheu um formulário",
    "conversationListPreviewImageText": "{user} enviou uma imagem",
    "conversationListPreviewLocationRequestText": "{user} enviou uma solicitação de localização",
    "conversationListPreviewUserText": "Você",
    "conversationTimestampHeaderFormat": "MMMM D YYYY, h:mm A",
    "couldNotConnect": "Desconectado. Você não receberá mensagens.",
    "couldNotConnectRetry": "Reconectando...",
    "couldNotConnectRetrySuccess": "Você está online novamente!",
    "couldNotLoadConversations": "Não foi possível carregar as conversas.",
    "emailChangeAddress": "Alterar meu e-mail",
    "emailDescription": "Para ser notificado via e-mail quando receber uma resposta, preencha seu endereço de e-mail.",
    "emailFieldLabel": "E-mail",
    "emailFieldPlaceholder": "Seu endereço de e-mail",
    "emailFormButton": "Enviar",
    "emailLinkingErrorMessage": "Por favor, preencha um endereço de e-mail válido.",
    "fetchHistory": "Carregar mais",
    "fetchingHistory": "Recuperando histórico...",
    "fileTooLargeError": "Tamanho máximo de arquivo excedido ({size})",
    "fileTypeError": "Tipo de arquivo não suportado.",
    "formErrorInvalidEmail": "E-mail é inválido",
    "formErrorNoLongerThan": "Não deve ter mais que ({characters}) caractéres",
    "formErrorNoShorterThan": "Deve ter pelo menos ({characters}) caractéres",
    "formErrorUnknown": "Isto não parece certo",
    "formFieldSelectPlaceholderFallback": "Escolha um...",
    "frontendEmailChannelDescription": "Para conversar com a gente via e-mail, basta enviar uma mensagem para nosso e-mail e nós responderemos em breve:",
    "headerText": "Como podemos ajudar?",
    "imageClickToReload": "Clique para recarregar a imagem.",
    "imageClickToView": "Clique para ver {size} a imagem.",
    "imagePreviewNotAvailable": "Pré-visualização não está disponível.",
    "inputPlaceholder": "Digite uma mensagem...",
    "inputPlaceholderBlocked": "Preencha o formulário acima...",
    "introAppText": "Envie uma mensagem aqui ou do seu aplicativo preferido.",
    "lineChannelDescription": "Para conversar com a gente via LINE, escanei este código QR utilizando o aplicativo LINE e nos envie uma mensagem.",
    "linkError": "Ocorreu um erro ao tentar gerar um link para este canal. Por favor, tente novamente.",
    "linkChannelPageHeader": "Sincronize sua conversa",
    "locationNotSupported": "Seu navegador não suporta os serviços de localização ou eles estão desabilitados. Por favor, digite sua localização.",
    "locationSecurityRestriction": "Esta página não pode acessar sua localização. Por favor, digite sua localização.",
    "locationSendingFailed": "Não foi possível enviar a localização",
    "locationServicesDenied": "Esta página não pode acessar sua localização. Permite o acesso nas suas configurações ou digite sua localização.",
    "messageIndicatorTitlePlural": "({count}) Novas mensagens",
    "messageIndicatorTitleSingular": "({count}) Nova mensagem",
    "messageRelativeTimeDay": "{value}d atrás",
    "messageRelativeTimeHour": "{value}h atrás",
    "messageRelativeTimeJustNow": "Agora",
    "messageRelativeTimeMinute": "{value}m atrás",
    "messageTimestampFormat": "h:mm A",
    "messageSending": "Enviando...",
    "messageDelivered": "Entregue",
    "messengerChannelDescription": "Conecte sua conta do Facebook Messenger para ser notificado quando receber uma resposta e continuar a conversa no Facebook Messenger.",
    "newConversationButtonText": "Nova Conversa",
    "notificationSettingsChannelsDescription": "Sincronize esta conversa conectando seu aplicativo de troca de mensagens favorito.",
    "notificationSettingsChannelsTitle": "Outros Canais",
    "notificationSettingsConnected": "Conectado",
    "notificationSettingsConnectedAs": "Conectado como {username}",
    "prechatCaptureGreetingText": "Olá 👋\nPara começar, gostaríamos de saber um pouco mais sobre você:",
    "prechatCaptureNameLabel": "Seu nome",
    "prechatCaptureNamePlaceholder": "Digite seu nome...",
    "prechatCaptureEmailLabel": "E-mail",
    "prechatCaptureEmailPlaceholder": "nome@empresa.com",
    "prechatCaptureConfirmationText": "Obrigado! Como podemos te ajudar?",
    "prechatCaptureMailgunLinkingConfirmation": "Você será notificado aqui e por e-mail no endereço {email} quando respondermos.",
    "sendButtonText": "Enviar",
    "settingsHeaderText": "Configurações",
    "shareLocation": "Localização",
    "smsBadRequestError": "Não foi possível se comunicar com este número. Tente novamente ou use um diferente.",
    "smsCancel": "Cancelar",
    "smsChangeNumber": "Alterar meu número",
    "smsChannelDescription": "Conecte seu número de celular para ser notificado via SMS quando houver uma resposta.",
    "smsChannelPendingDescription": "Verifique as mensagens do número {number} para realizar a confirmação.",
    "smsContinue": "Enviar",
    "smsInvalidNumberError": "Por favor, preencha um número de telefone válido.",
    "smsLinkCancelled": "Vínculo com {appUserNumber} foi cancelado.",
    "smsLinkPending": "Pendente",
    "smsPingChannelError": "Houve um erro ao enviar a mensagem para seu número.",
    "smsSendText": "Me envie um SMS",
    "smsStartTexting": "Conversar por SMS",
    "smsTooManyRequestsError": "Uma conexão com este número foi feita recentemente. Por favor tente novamente em {minutes} minutos.",
    "smsTooManyRequestsOneMinuteError": "Uma conexão com este número foi feita recentemente. Por favor tente novamente em 1 minuto.",
    "smsUnhandledError": "Algo deu errado. Por favor tente novamente.",
    "syncConversation": "Sincronizar a conversa",
    "tapToRetry": "Mensagem não entregue. Toque para reenviar.",
    "tapToRetryForm": "Formulário não enviado. Toque em qualquer lugar do formulário para reenviar.",
    "telegramChannelDescription": "Conecte sua conta do Telegram para ser notificado quando receber uma resposta e continuar a conversa no Facebook Messenger",
    "unsupportedMessageType": "Tipo de mensagem não suportado.",
    "unsupportedActionType": "Tipo de ação não suportado.",
    "uploadDocument": "Arquivo",
    "uploadInvalidError": "Arquivo inválido",
    "uploadPhoto": "Imagem",
    "uploadVirusError": "Um vírus foi encontrado no seu arquivo e ele foi rejeitado",
    "viberChannelDescription": "Connect your Viber account to be notified when you get a reply and continue the conversation on Viber. To get started, scan the QR code using the Viber app.",
    "viberChannelDescriptionMobile": "Connect your Viber account to be notified when you get a reply and continue the conversation on Viber. To get started, install the Viber app and tap Connect.",
    "viberQRCodeError": "An error occurred while fetching your Viber QR code. Please try again.",
    "wechatChannelDescription": "Connect your WeChat account to be notified when you get a reply and continue the conversation on WeChat. To get started, scan this QR code using the WeChat app.",
    "wechatChannelDescriptionMobile": "Connect your WeChat account to be notified when you get a reply and continue the conversation on WeChat. To get started, save this QR code image and upload it in the <a href='weixin://dl/scan'>QR code scanner</a>.",
    "wechatQRCodeError": "An error occurred while fetching your WeChat QR code. Please try again.",
    "whatsappChannelDescriptionDesktop": "Sincronize sua conta do WhatsApp escaneando o código QR ou clicando no link abaixo.\nEntão, envie a mensagem pré-definida para validar a sincronização. (Seu código: {{code}}).",
    "whatsappChannelDescriptionMobile": "Sincronize sua conta do WhatsApp clicando no link abaixo.\nEntão, envie a mensagem pré-definida para validar a sincronização. (Seu código: {{code}}).",
    "whatsappLinkingError": "Ocorreu um erro ao tentar vincular sua conta do WhatsApp. Por favor, tente novamente."
  }
}
