{
  "menuItems": [
    {
      "id": 1,
      "label": "@HAMBURGUER_MENU_1",
      "subItems": [
        {
          "id": 1,
          "label": "@HAMBURGUER_MENU_1_SUBITEM_1",
          "url": "/residencias"
        },
        {
          "id": 3,
          "label": "@HAMBURGUER_MENU_1_SUBITEM_2",
          "url": "/negocios"
        },
        {
          "id": 2,
          "label": "@HAMBURGUER_MENU_1_SUBITEM_3",
          "url": "/predios"
        },
        {
          "id": 5,
          "label": "@HAMBURGUER_MENU_1_SUBITEM_4",
          "url": "/industrias"
        },
        {
          "id": 4,
          "label": "@HAMBURGUER_MENU_1_SUBITEM_5",
          "url": "/veiculos"
        },
        {
          "id": 6,
          "label": "@HAMBURGUER_MENU_1_SUBITEM_6",
          "url": "/blog"
        }
      ]
    },
    {
      "id": 2,
      "label": "@HAMBURGUER_MENU_2",
      "subItems": [
        {
          "id": 6,
          "label": "@HAMBURGUER_MENU_2_SUBITEM_1",
          "url": "@ComgasVirtual/?utm_source=qc&utm_medium=referral&utm_campaign=segunda-via&utm_content=botao"
        },
        {
          "id": 7,
          "label": "@HAMBURGUER_MENU_2_SUBITEM_2",
          "url": "@ComgasVirtual/trocaTitularidade/?utm_source=qc&utm_medium=referral&utm_campaign=troca-titularidade&utm_content=botao"
        },
        {
          "id": 8,
          "label": "@HAMBURGUER_MENU_2_SUBITEM_3",
          "url": "@ComgasVirtual/liga-religa/triagem/?utm_source=qc&utm_medium=referral&utm_campaign=religa&utm_content=botao"
        },
        {
          "id": 9,
          "label": "@HAMBURGUER_MENU_2_SUBITEM_4",
          "url": "@ComgasSolucoes/?utm_source=qc&utm_medium=referral&utm_campaign=converter-fogao-hamb&utm_content=botao"
        },
        {
          "id": 10,
          "label": "@HAMBURGUER_MENU_2_SUBITEM_5",
          "url": "@ComgasSolucoes/?utm_source=qc&utm_medium=referral&utm_campaign=manut-aquec-hamb&utm_content=botao"
        }
      ]
    }
  ],
  "banners": [
    {
      "id": 1,
      "title": "@HAMBURGUER_MENU_BANNER_TITLE",
      "disclaimer": "@HAMBURGUER_MENU_BANNER_DISCLAIMER",
      "image": {
        "alt": "@HAMBURGUER_MENU_BANNER_IMG_ALT",
        "title": "@HAMBURGUER_MENU_BANNER_IMG_TITLE",
        "mobile": {
          "png": "assets/img/hamburger-menu/atendimento-online-comgas.png",
          "webp": "assets/img/hamburger-menu/atendimento-online-comgas.webp"
        }
      },
      "contrastImage": {
        "alt": "@HAMBURGUER_MENU_BANNER_IMG_ALT",
        "title": "@HAMBURGUER_MENU_BANNER_IMG_TITLE",
        "mobile": {
          "png": "assets/img/hamburger-menu/atendimento-online-comgas-contraste.png",
          "webp": "assets/img/hamburger-menu/atendimento-online-comgas-contraste.webp"
        }
      },
      "svgs": [
        "assets/img/hamburger-menu/svg/sem-a-visita-da-comgas-na-sua-casa.svg",
        "assets/img/hamburger-menu/svg/logo-icon.svg"
      ]
    }
  ]
}
