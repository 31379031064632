<div class="container">
  <div class="container__close">
    <button class="btn-reset" (click)="closeModal()">
      <i
        modal-close
        class="icon-sinal_multiplicar_x_fechar"
        aria-hidden="true"
      ></i>
    </button>
  </div>
  <div class="d-flex flex-column align-items-lg-center">
    <div>
      <p
        class="container__title"
        [innerHTML]="'@HEADER-BUSCA-PAGINAS-CONTEUDO' | translate"
      ></p>
    </div>
    <div class="container__button w-100">
      <qsc-button
        class="w-100"
        variant="secondary"
        [buttonText]="'@HEADER-BUSCA-RESIDENCIAS' | translate"
        iconClass="icon-casa_home"
        [iconBefore]="true"
        (buttonClick)="goToResidences()"
      ></qsc-button>
      <qsc-button
        class="w-100"
        variant="secondary"
        [buttonText]="'@HEADER-BUSCA-PREDIOS' | translate"
        iconClass="icon-predios"
        [iconBefore]="true"
        (buttonClick)="goToBuildings()"
      ></qsc-button>
      <qsc-button
        class="w-100"
        variant="secondary"
        [buttonText]="'@HEADER-BUSCA-NEGOCIOS' | translate"
        iconClass="icon-loja"
        [iconBefore]="true"
        (buttonClick)="goToCommerce()"
      ></qsc-button>
      <qsc-button
        class="w-100"
        variant="secondary"
        [buttonText]="'@HEADER-BUSCA-INDUSTRIAS' | translate"
        iconClass="icon-industria"
        [iconBefore]="true"
        (buttonClick)="goToIndustry()"
      ></qsc-button>
      <qsc-button
        class="w-100"
        variant="secondary"
        [buttonText]="'@HEADER-BUSCA-VEICULOS' | translate"
        iconClass="icon-carro_direita"
        [iconBefore]="true"
        (buttonClick)="goToGnv()"
      ></qsc-button>
      <div class="line">
        <span [innerHTML]="'@HEADER-BUSCA-OU' | translate"> </span>
      </div>
      <div class="login">
        <button (click)="goToVirtual()" type="button">
          <div class="d-flex justify-content-center align-items-center">
            <picture>
              <img
                [src]="
                  'assets/home/img/svg/icon-perfil-login-mobile.svg' | fileHash
                "
                alt="Login"
              />
            </picture>
            <span
              class="text-nowrap"
              [innerHTML]="'@HEADER-BUSCA-LOGIN' | translate"
            ></span>
          </div>
        </button>
      </div>
      <div class="hire-solutions">
        <div class="d-flex gap-2">
          <i aria-hidden="true" class="icon-carrinho"></i>
          <div>
            <button
              class="btn-reset"
              (click)="goToSolutions()"
              [innerHTML]="'@HEADER-BUSCA-CONTRATAR-SERVICOS' | translate"
            ></button>
            <div class="underline"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
