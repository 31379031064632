import { Inject, Injectable } from '@angular/core';
import { sleep } from '@shared/utils/sleep';
import { DOCUMENT } from '@angular/common';
import { PlatformCheckerService } from '../platform-checker/platform-checker.service';

@Injectable({
  providedIn: 'root',
})
export class IframeStylerService {
  constructor(
    private readonly platformChecker: PlatformCheckerService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  async applyStyle(
    selector: string,
    styleFileDir: string,
    attempts = 15
  ): Promise<void> {
    if (this.platformChecker.isBrowser()) {
      let iframeEl = this.getIframe(selector);
      while (!iframeEl && attempts > 0) {
        await sleep(100);
        iframeEl = this.getIframe(selector);
        attempts--;
      }

      if (!iframeEl) {
        return;
      }

      iframeEl.addEventListener('load', async () => {
        await this.injectStyleEarly(iframeEl, styleFileDir);
      });

      if (iframeEl.contentDocument?.readyState === 'complete') {
        await this.injectStyleEarly(iframeEl, styleFileDir);
      }
    }
  }

  private async injectStyleEarly(
    iframeEl: HTMLIFrameElement,
    styleFileDir: string
  ): Promise<void> {
    const iframeDoc =
      iframeEl.contentDocument || iframeEl.contentWindow?.document;
    if (iframeDoc) {
      const isLinkInIframe = this.isLinkInIframe(iframeDoc.head, styleFileDir);
      if (!isLinkInIframe) {
        this.addLinkInIframe(iframeDoc.head, styleFileDir);
      }
    }
  }

  private addLinkInIframe(
    iframeHead: HTMLHeadElement,
    styleFileDir: string
  ): void {
    const linkEl = this.document.createElement('link');
    linkEl.rel = 'stylesheet';
    linkEl.type = 'text/css';
    linkEl.href = styleFileDir;
    iframeHead.appendChild(linkEl);
  }

  private isLinkInIframe(
    iframeHead: HTMLHeadElement,
    styleFileDir: string
  ): boolean {
    const linksInIframe = Array.from(iframeHead.getElementsByTagName('link'));
    return linksInIframe.some((link) => link.href.includes(styleFileDir));
  }

  private getIframe(selector: string): HTMLIFrameElement | undefined {
    return this.document.querySelector(selector) as HTMLIFrameElement;
  }
}
