import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { UiBreakpoints } from './breakpoints';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.initBreakpointObserver();
  }

  private _skipGA = false;
  get skipGA() {
    return this._skipGA;
  }
  set skipGA(skipGA: boolean) {
    this._skipGA = skipGA;
  }

  private _analyticsKeys: any;
  get analyticsKeys() {
    return this._analyticsKeys;
  }
  set analyticsKeys(analyticsKeys: any) {
    this._analyticsKeys = analyticsKeys;
  }

  private _isMobile = false;
  get isMobile() {
    return this._isMobile;
  }
  set isMobile(isMobile: boolean) {
    this._isMobile = isMobile;
  }

  private _isLaptop = false;
  get isLaptop() {
    return this._isLaptop;
  }
  set isLaptop(isLaptop: boolean) {
    this._isLaptop = isLaptop;
  }

  private _isDesktopXl = false;
  get isDesktopXl() {
    return this._isDesktopXl;
  }
  set isDesktopXl(isDesktopXl: boolean) {
    this._isDesktopXl = isDesktopXl;
  }

  private initBreakpointObserver(): void {
    this.breakpointObserver
      .observe([
        UiBreakpoints.Mobile,
        UiBreakpoints.Laptop,
        UiBreakpoints.DesktopXl,
      ])
      .subscribe((result) => {
        this._isMobile = result.breakpoints[UiBreakpoints.Mobile];
        this._isLaptop = result.breakpoints[UiBreakpoints.Laptop];
        this._isDesktopXl = result.breakpoints[UiBreakpoints.DesktopXl];
      });
  }
}
