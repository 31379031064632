import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

@Component({
  selector: 'qsc-flow-title',
  standalone: true,
  imports: [CommonModule, FileHashPipe],
  templateUrl: './flow-title.component.html',
  styleUrls: ['./flow-title.component.scss'],
})
export class FlowTitleComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() iconClass = '';
  @Input() iconImg = '';
  @Input() iconColor: 'blue' | 'green' | 'red' = 'green';
  @Input() fireBackground = true;
}
