import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { ChatService, FlowService } from '@shared/services';
import { FlowsAnalytics } from '@models/analytics';
import { IconId, SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'qsc-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, ButtonComponent, TranslateModule],
})
export class ChatModalComponent {
  openChat = false;
  title?: string;
  subtitle?: string;
  icon?: IconId;
  sendEvent?: boolean;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly chatService: ChatService,
    private readonly flowService: FlowService
  ) {}

  onOpenChat(): void {
    this.openChat = true;
    if (this.sendEvent) {
      this.sendGtmEventOnOpenChat();
    }
    this.bsModalRef.hide();
    this.chatService.openChat();
  }

  sendGtmEventOnOpenChat(): void {
    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.erroFrontend,
      FlowsAnalytics.action.erroGenerico,
      FlowsAnalytics.label.iniciarChat
    );
  }
}
