<div
  class="benefit-card"
  [ngClass]="{
    'benefit-card--box': variant === 'box'
  }"
>
  <div class="benefit-card__title-container">
    <div class="benefit-card__icon">
      <i
        class="benefit-card__icon-class"
        *ngIf="iconClass"
        [class]="iconClass"
      ></i>
      <img
        class="benefit-card__icon-img"
        *ngIf="iconSvg"
        [src]="iconSvg | fileHash"
        [alt]="title"
      />
    </div>

    <h3 class="benefit-card__title" [innerHTML]="title"></h3>
  </div>
  <p class="benefit-card__description" [innerHTML]="description"></p>
</div>
