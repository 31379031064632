<div class="container">
  <div class="d-flex flex-column align-items-lg-center">
    <div class="container__icon">
      <i aria-hidden="true" [class]="iconClass"></i>
    </div>
    <div>
      <div>
        <p class="container__title" [innerHTML]="title | translate"></p>
      </div>
      <div *ngIf="subtitle">
        <p class="container__subtitle" [innerHTML]="subtitle | translate"></p>
      </div>
    </div>
    <div class="container__button w-100">
      <qsc-button
        *ngFor="let option of options"
        class="w-100"
        [variant]="option.variant"
        [buttonText]="option.text | translate"
        (buttonClick)="onOptionClick(option.action)"
      ></qsc-button>
    </div>
    <div *ngIf="backButtonClick" class="container__link">
      <a
        href="javascript:void(0)"
        aria-label="Link"
        (click)="onBackButtonClick()"
        [innerHTML]="'@QUESTION-BACK-BUTTON-TEXT' | translate"
      ></a>
    </div>
  </div>
</div>
