import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';

@Directive({
  selector: '[qscEventTrackerCategory]',
  standalone: true,
})
export class EventTrackerDirective implements AfterViewInit {
  @Input('qscEventTrackerCategory') category = '';
  @Input('qscEventTrackerAction') action = '';
  @Input('qscEventTrackerLabel') label = '';
  @Input('qscEventTrackerFlow') flow?: string;
  @Input('qscEventTrackerIsToggle') isToggle?: boolean = false;

  private clickCount = 0;

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly elementRef: ElementRef
  ) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.addEventListener('click', () =>
      this.onClick()
    );
  }

  onClick(): void {
    if (this.isToggle) {
      this.clickCount++;
      if (this.clickCount % 2 !== 0) {
        this.googleTagManager.sendCustomEvent(
          this.category,
          this.action,
          this.label,
          this.flow
        );
      }
    } else {
      this.googleTagManager.sendCustomEvent(
        this.category,
        this.action,
        this.label,
        this.flow
      );
    }
  }
}
