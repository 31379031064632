import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgIf, NgFor, AsyncPipe, NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { default as gasStationMapJson } from '@assets/vehicles-content/data/mapa-postos.json';

@Component({
  selector: 'qsc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgIf,
    NgClass,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatOptionModule,
    NgFor,
    MatIconModule,
    AsyncPipe,
    TranslateModule,
    ScrollingModule,
  ],
})
export class AutocompleteComponent implements OnInit, OnChanges {
  @Input() control = new FormControl('');
  @Input() options: string[] = [];
  @Input() outsideLabel = '';
  @Input() insideLabel = '';
  @Input() id = '';
  @Input() name = '';
  @Input() required = true;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() format = '';
  @Input() iconClass = '';
  @Input() success = false;
  @Input() searching = false;
  @Input() noData = false;
  @Input() errorMatcher: ErrorStateMatcher = new ErrorStateMatcher();
  @Input() dataTestId = '';
  @Input() prefixIcon = '';
  @Input() useVirtualScroll = false;

  @Output() selectionChangeEvent = new EventEmitter();
  @Output() inputEvent = new EventEmitter();
  @Output() clearButtonEvent = new EventEmitter();

  @ViewChild('input')
  public input: ElementRef | undefined;

  @ViewChild(MatAutocompleteTrigger)
  autoCompleteTrigger: MatAutocompleteTrigger | undefined;

  public filteredOptions?: Observable<string[]>;

  gasStationMapData = gasStationMapJson;

  isOpened = false;

  ngOnInit() {
    setTimeout(() => {
      this.filteredOptions = this.control.valueChanges.pipe(
        startWith(''),
        map((value) => {
          return value ? this._filter(value) : this.options.slice();
        })
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      this.disabled ? this.control.disable() : this.control.enable();
    }
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  public onSelectionChange(option: any, closePanel = false): void {
    this.selectionChangeEvent.emit(option);

    if (closePanel) {
      this.autoCompleteTrigger?.closePanel();
    }
  }

  public onInput(): void {
    this.inputEvent.emit();
  }

  getViewportHeight(options: string[] | null): number {
    const maxItemsToShow = 5;
    const itemHeight = 48;

    if (options && options.length < maxItemsToShow) {
      return options.length * itemHeight;
    } else {
      return maxItemsToShow * itemHeight;
    }
  }

  onOpenedChange(): void {
    this.isOpened = !this.isOpened;
  }

  getPrefixIconColor(item: string, prefixIcon: string): string {
    if (prefixIcon === 'icon-localizacao_gps') {
      const gasStationData = this.gasStationMapData.find(
        ({ name }) => name === item
      );
      return gasStationData?.highFlow
        ? 'prefix-color-green'
        : 'prefix-color-red';
    }

    if (prefixIcon === 'icon-local-gas-station') {
      const gasStationData = this.gasStationMapData.find(
        ({ name }) => name === item
      );
      return gasStationData?.highFlow
        ? 'prefix-background-yellow'
        : 'prefix-background-green';
    }

    return '';
  }
}
