<div class="input input--select" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [attr.for]="id" class="content-bold">{{
    required && outsideLabel
      ? (outsideLabel | translate) + " *"
      : (outsideLabel | translate)
  }}</label>
  <mat-form-field appearance="fill" [class.is-valid]="isValid">
    <mat-label *ngIf="insideLabel">{{ insideLabel }}</mat-label>
    <mat-icon *ngIf="prefixIcon && !this.selectInput?.empty"
      ><i
        aria-hidden="true"
        [class]="prefixIcon"
        [ngClass]="getPrefixIconColor(this.selectInput?.value, prefixIcon)"
        matTextPrefix
      ></i
    ></mat-icon>
    <mat-select
      class="label-search-semibold"
      [id]="id"
      disableRipple
      [formControl]="control"
      [required]="required"
      [disabled]="disabled"
      [attr.data-testid]="dataTestId"
      panelClass="custom-select-panel"
      [disableOptionCentering]="true"
      (selectionChange)="onSelectionChange($event)"
      (openedChange)="onOpenedChange()"
      [placeholder]="
        (placeholder | translate) || '@SELECT-DEFAULT-PLACEHOLDER-LABEL'
          | translate
      "
      (opened)="onOpened()"
      #selectInput
    >
      <div class="options-container">
        <mat-option
          class="option"
          *ngFor="let item of items"
          [value]="item | translate"
        >
          <mat-icon *ngIf="prefixIcon"
            ><i
              aria-hidden="true"
              [class]="prefixIcon"
              [ngClass]="getPrefixIconColor(item, prefixIcon)"
              matPrefix
            ></i></mat-icon
          >{{ toLabel(item) | translate }}
        </mat-option>
        <mat-option
          *ngIf="lastFixedOption"
          class="last-option"
          [value]="lastOptionLabel"
          (click)="onLastOptionClick()"
        >
          {{ lastOptionLabel }}
        </mat-option>
      </div>
    </mat-select>
    <mat-icon [ngClass]="{ 'rotate-y-180': isOpened }" matSuffix
      ><i
        aria-hidden="true"
        class="icon-seta_baixo"
        [ngClass]="{ 'icon-seta_baixo--disabled': disabled }"
      ></i
    ></mat-icon>
    <mat-error *ngIf="control.hasError('required')">
      {{ "@SELECT-DEFAULT-REQUIRED-LABEL" | translate }}
    </mat-error>
  </mat-form-field>
</div>
