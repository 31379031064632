import { ISelectedEquipment } from '@modules/equipment-selection/models/equipment';
export type EventFlowGtmByProfileId =
  | 'residencias'
  | 'predios'
  | 'negocios'
  | 'industrias';

export type EventFlowGtmByCategoryId =
  | 'casa construida'
  | 'casa em obra'
  | 'predios morador'
  | 'predios sindico'
  | 'predios construtora'
  | 'negocios'
  | 'industrias';

export interface EquipmentListGtm {
  codigo: string;
  quantidade: number;
}

export const equipmentsMapperForGtm = (
  arr: ISelectedEquipment[]
): EquipmentListGtm[] =>
  arr
    .filter(({ qtd }) => qtd > 0)
    .map(({ value, qtd }) => ({
      codigo: value,
      quantidade: qtd,
    }));

export const quantityOfUnitsForGtm = (n: number) => n;
