[
    {
        "installation": 588740,
        "name": "Paulista De Americana",
        "city": "Americana",
        "postalCode": "13478-580",
        "address": "Av. Paulista, 499",
        "area": "Vila Nossa Sra. de Fatima",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.737652,
        "longitude": -47.3203167,
        "localName": "Ale",
        "link": "https://maps.app.goo.gl/Suy1SSgfBhgC4AnR7"
    },
    {
        "installation": 4002216682,
        "name": "Auto Posto E Restaurante Aparecida Ltda",
        "city": "Aparecida",
        "postalCode": "12570-000",
        "address": "Rodovia Presidente Dutra 10883-10967",
        "area": "Aparecida",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.8485732,
        "longitude": -45.2268989,
        "localName": "Posto Aparecida",
        "link": "https://maps.app.goo.gl/3MCP5ZYEUA9QLkFH7"
    },
    {
        "installation": 728167,
        "name": "Lavajato Aquarius Ltda",
        "city": "Araras",
        "postalCode": "13601-200",
        "address": "Av. Padre Alarico Zacarias, 70",
        "area": "tr",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.3609347,
        "longitude": -47.3882155,
        "localName": "Lavajato Aquárius",
        "link": "https://maps.app.goo.gl/vUKwXPcCJBzZC2Hq7"
    },
    {
        "installation": 982813,
        "name": "Posto De Serviços Duque Alphaville Ltda.",
        "city": "Barueri",
        "postalCode": "06454-000",
        "address": "Al. Rio Negro, 600",
        "area": "Alphaville",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5007513,
        "longitude": -46.8479215,
        "localName": "Posto BR + GNV | Rede Duque - Alphaville",
        "link": "https://maps.app.goo.gl/x6nkDtNegDGQG7Hu5"
    },
    {
        "installation": 4000502306,
        "name": "Auto Posto Duque Barueri Ltda.",
        "city": "Barueri",
        "postalCode": "06420-390",
        "address": "Rua Campos Sales, 934",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5040344,
        "longitude": -46.8773436,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/r1bAXAgAzzEZKicQA"
    },
    {
        "installation": 911182,
        "name": "Rede De Postos Sete Estrelas Ltda Tigrão - Posto 52",
        "city": "Cacapava",
        "postalCode": "12287-260",
        "address": "GNV - 238 Possui, Av. Henry Nestlé, 190",
        "area": "Vila Antonio Augusto Luiz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1132223,
        "longitude": -45.7102647,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/8LuY481aBHnd2sUz9"
    },
    {
        "installation": 620938,
        "name": "Sete Estrelas Caçapava - Filial 19",
        "city": "Cacapava",
        "postalCode": "12287-260",
        "address": "GNV - 238 Possui, Av. Henry Nestlé, 190",
        "area": "Vila Antônio Augusto Luiz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1132005,
        "longitude": -45.7103033,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/g5gVzkwThhidw61U9"
    },
    {
        "installation": 925589,
        "name": "Flor Do Vera",
        "city": "Caieiras",
        "postalCode": "07700-000",
        "address": "Rod. Pres. Tancredo de Almeida Neves, 4100",
        "area": "Jardim Monte Alegre",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.33209,
        "longitude": -46.7482525,
        "localName": "Auto Posto Flor do Vera",
        "link": "https://maps.app.goo.gl/8z6N9ngF9DBfhSwY9"
    },
    {
        "installation": 4002222425,
        "name": "Auto Posto Colinas Verde Ltda",
        "city": "Cajamar",
        "postalCode": "07760-000",
        "address": "Av. Jordano Mendes, 246",
        "area": "Jardim Nova Jordanésia",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.3337431,
        "longitude": -46.8379738,
        "localName": "AUTO POSTO COLINAS VERDE LTDA",
        "link": "https://maps.app.goo.gl/SfGTkN91T9hq9NJQ6"
    },
    {
        "installation": 737948,
        "name": "Auto Posto Luma De Cajamar Ltda.",
        "city": "Cajamar",
        "postalCode": "07760-000",
        "address": "Av. Arnaldo Rojek, 569",
        "area": "Jordanesia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.3347539,
        "longitude": -46.8361687,
        "localName": "Posto GNV",
        "link": "https://maps.app.goo.gl/DNEhNDhFsZv3a1nn9"
    },
    {
        "installation": 816527,
        "name": "Bg Gnv Do Brasil Ltda -Posto Futuro",
        "city": "Campinas",
        "postalCode": "13084-008",
        "address": "Av. Albino J. B. de Oliveira, 360",
        "area": "Barão Geraldo",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.8353097,
        "longitude": -47.0798357,
        "localName": "Auto Posto Futuro GNV Shell",
        "link": "https://maps.app.goo.gl/eFqPjCqwdWvPMPti6"
    },
    {
        "installation": 855045,
        "name": "Auto Posto Barao De Itapura Campinas Ltda",
        "city": "Campinas",
        "postalCode": "13020-431",
        "address": "Av. Barão de Itapura, 641",
        "area": "Botafogo",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8978498,
        "longitude": -47.0687977,
        "localName": "Posto Barão de Itapura + GNV",
        "link": "https://maps.app.goo.gl/qk4EZ4PrrdBVe47y9"
    },
    {
        "installation": 855047,
        "name": "Posto Andorinhas Ltda",
        "city": "Campinas",
        "postalCode": "13025-320",
        "address": "Av. José de Sousa Campos, 2101",
        "area": "Cambuí",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8877982,
        "longitude": -47.0447437,
        "localName": "Posto Shell Andorinhas",
        "link": "https://maps.app.goo.gl/AmFgAqybcZD3qZrp8"
    },
    {
        "installation": 841184,
        "name": "Rodocamp Auto Service",
        "city": "Campinas",
        "postalCode": "13054-750",
        "address": "AV MERCEDES BENZ, 01 Q1 QT 8907",
        "area": "Distrito Industrial",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0025037,
        "longitude": -47.1129526,
        "localName": "Posto Ipiranga GNV - Rodocamp Auto Service",
        "link": "https://maps.app.goo.gl/jfQHi8S6Q5i6XNA37"
    },
    {
        "installation": 911183,
        "name": "Posto Garcia De Campinas Ltda",
        "city": "Campinas",
        "postalCode": "13063-370",
        "address": "Rod. Anhanguera, Km 99, S/n",
        "area": "Jardim Eulina",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.8882005,
        "longitude": -47.112089,
        "localName": "Posto Garcia de Campinas",
        "link": "https://maps.app.goo.gl/n4gqbZcvXxW6PKqB6"
    },
    {
        "installation": 860897,
        "name": "Nn Amoreiras",
        "city": "Campinas",
        "postalCode": "13050-575",
        "address": "Av. das Amoreiras",
        "area": "Jardim Paraiso de Viracopos",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9336266,
        "longitude": -47.0915596,
        "localName": "Posto Amoreiras",
        "link": "https://maps.app.goo.gl/2qiuMw3xDgAkoqFg8"
    },
    {
        "installation": 884496,
        "name": "Auto Posto 2002",
        "city": "Campinas",
        "postalCode": "13100-040",
        "address": "Av. Princesa D'Oeste, 2002",
        "area": "Jardim Paraíso",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.906031,
        "longitude": -47.0460063,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/3jXUN4fC5w51fyWJA"
    },
    {
        "installation": 649162,
        "name": "Auto Posto Global",
        "city": "Campinas",
        "postalCode": "13080-230",
        "address": "R Marques De Valenca 60 20 Jd",
        "area": "Jardim Santa Genebra",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8445844,
        "longitude": -47.069327,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/bYM9xEYuiQ5QRh5x7"
    },
    {
        "installation": 737563,
        "name": "Auto Posto Jardim Do Trevo Ltda.",
        "city": "Campinas",
        "postalCode": "13024-500",
        "address": "Av. Benedito de Campos, 193",
        "area": "Jardim do Trevo",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9298529,
        "longitude": -47.0707778,
        "localName": "Posto Jardim do Trevo",
        "link": "https://maps.app.goo.gl/4CxXAHGMvEXpvA8Q6"
    },
    {
        "installation": 562851,
        "name": "Flamingo",
        "city": "Campinas",
        "postalCode": "13033-780",
        "address": "Av. John Boyd Dunlop, Chac, 460",
        "area": "Republica",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9103389,
        "longitude": -47.0967401,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/hmomr5Yaues1sgZx5"
    },
    {
        "installation": 4002109503,
        "name": "Rodoposto Turmalina Ltda",
        "city": "Cordeiropolis",
        "postalCode": "13490-000",
        "address": "Rod. Anhanguera, Km 158 S/n",
        "area": "Bosque",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.4733714,
        "longitude": -47.5520863,
        "localName": "Graal Turmalina (Norte)",
        "link": "https://maps.app.goo.gl/zjcVobFoWWeXKSFW7"
    },
    {
        "installation": 688301,
        "name": "Sete Estrelas Cruzeiro - Posto 39",
        "city": "Cruzeiro",
        "postalCode": "12702-010",
        "address": "Av. Rotary, 768",
        "area": "Jardim America",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.6011988,
        "longitude": -45.0580117,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/36GbkTkhVFfiQdeM8"
    },
    {
        "installation": 546484,
        "name": "Posto Vila Rica São Cristovão",
        "city": "Cruzeiro",
        "postalCode": "12712-010",
        "address": "Av. Minas Gerais, 1200 - II",
        "area": "Retiro da Mantiqueira",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.5654349,
        "longitude": -44.9516081,
        "localName": "Auto Posto Vila Rica",
        "link": "https://maps.app.goo.gl/9ATwtVxPAC71PXsa9"
    },
    {
        "installation": 597183,
        "name": "Ecoposto  Via Brasil Ecovias",
        "city": "Diadema",
        "postalCode": "09920-579",
        "address": "Av. Fábio Eduardo Ramos Esquivel, 2105",
        "area": "Jardim Concordia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6836838,
        "longitude": -46.605452,
        "localName": "Rede 7 - Ecoposto",
        "link": "https://maps.app.goo.gl/TCT5qBLuBJJzoEM6A"
    },
    {
        "installation": 475019,
        "name": "Posto E Restaurante Três Garças",
        "city": "Guaratingueta",
        "postalCode": "12522-640",
        "address": "Rod. Pres. Dutra, Km 59",
        "area": "Piedade",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.7815012,
        "longitude": -45.1525577,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/CXPvJMENRC2rCmTu8"
    },
    {
        "installation": 507249,
        "name": "Clube Dos 500",
        "city": "Guaratingueta",
        "postalCode": "12522-640",
        "address": "Clube dos Quinhentos",
        "area": "Vila Sao Jose",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7884476,
        "longitude": -45.156596,
        "localName": "Posto Rodogás ",
        "link": "https://maps.app.goo.gl/e5DxKQkPbE1hxdPH7"
    },
    {
        "installation": 4001367478,
        "name": "Rede Duque - Aeroporto De Gru",
        "city": "Guarulhos",
        "postalCode": "07190-100",
        "address": "Aeroporto",
        "area": "Aeroporto",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4332052,
        "longitude": -46.4929168,
        "localName": "Ipiranga",
        "link": "https://maps.app.goo.gl/smsTuKJXgFyvn4By7"
    },
    {
        "installation": 665825,
        "name": "Auto Posto J.K.",
        "city": "Guarulhos",
        "postalCode": "07252-000",
        "address": "Estr. Pres. Juscelino K. de Oliveira, 554",
        "area": "Bairro Do Pimentas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4704406,
        "longitude": -46.4263332,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/cQezPWz2cwizou41A"
    },
    {
        "installation": 763595,
        "name": "Centro Automotivo Rsb Ltda",
        "city": "Guarulhos",
        "postalCode": "07252-000",
        "address": "Estr. Pres. Juscelino K. de Oliveira, 554",
        "area": "Bairro Do Pimentas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4688651,
        "longitude": -46.4229199,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/US7imaAxPmywvo717"
    },
    {
        "installation": 520239,
        "name": "Nikigas Comercial  Padre Celestino",
        "city": "Guarulhos",
        "postalCode": "07013-100",
        "address": "R. Padre Celestino, 426",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4731348,
        "longitude": -46.525788,
        "localName": "Nikigas Comercial ltda",
        "link": "https://maps.app.goo.gl/qsceYhFBjtMi4cP28"
    },
    {
        "installation": 4002087266,
        "name": "Autoposto Lisboa",
        "city": "Guarulhos",
        "postalCode": "07151-440",
        "address": "Av. Osíres, 21",
        "area": "Cidade Serodio",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4142587,
        "longitude": -46.4615506,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/m9tApiQZo7pDU8Rz9"
    },
    {
        "installation": 677579,
        "name": "Nikigas Comercial  Guarulhos- Matriz",
        "city": "Guarulhos",
        "postalCode": "07022-020",
        "address": "Av. Guarulhos, 4389",
        "area": "Gopouva",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5038897,
        "longitude": -46.5520205,
        "localName": "Branca",
        "link": "https://maps.app.goo.gl/mBkeYBF6LT1Xq17BA"
    },
    {
        "installation": 636460,
        "name": "Novo Anel-Centro Automotivo Canaiolo Ltda",
        "city": "Guarulhos",
        "postalCode": "07033-179",
        "address": "Av. Aricanduva, Pista Norte, 789",
        "area": "Jardim Munhoz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5052078,
        "longitude": -46.5586898,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/nBN8hFrpSpZbWdfP6"
    },
    {
        "installation": 4000203757,
        "name": "Centro Automotivo Asas De Cumbica Ltda.",
        "city": "Guarulhos",
        "postalCode": "07140-040",
        "address": "Av. Marginal Baquirivu, 450",
        "area": "Jardim Sao Geraldo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4478743,
        "longitude": -46.4948183,
        "localName": "Centro Automotivo Asas Cumbica",
        "link": "https://maps.app.goo.gl/87y34Qu8W2TyTZ5o6"
    },
    {
        "installation": 500795,
        "name": "Carreteiro Revendedor De Petr. E Der.",
        "city": "Guarulhos",
        "postalCode": "07034-000",
        "address": "Rod. Presidente Dutra, Via Lateral-Norte, Km 221",
        "area": "Porto da Igreja",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4697088,
        "longitude": -46.5117093,
        "localName": "Posto Petrobrás",
        "link": "https://maps.app.goo.gl/JNBGA1WVfBcW1NnR8"
    },
    {
        "installation": 487834,
        "name": "Posto De Serviços Gas Dutra  - (Antigo Platanos) Atual Posto Puglia",
        "city": "Guarulhos",
        "postalCode": "07034-010",
        "address": "Via Lateral-Norte, 775-1215",
        "area": "Porto da Igreja",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4871611,
        "longitude": -46.5421277,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/5NKZ4h7zhJAyVHLx9"
    },
    {
        "installation": 4002057002,
        "name": "Posto De Serviços Automotivos E Comercio De Combustivel Madri Ltda",
        "city": "Guarulhos",
        "postalCode": "07030-010",
        "address": "Rua Anton Philips, 119",
        "area": "Pte. Grande",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4902835,
        "longitude": -46.5466191,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/pJR2wYGfGfTKuMHS8"
    },
    {
        "installation": 869959,
        "name": "Cecap Guarulhos Auto Posto Ltda",
        "city": "Guarulhos",
        "postalCode": "07190-020",
        "address": "R. Asea, 160",
        "area": "São Roque",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4635316,
        "longitude": -46.499434,
        "localName": "Cecap Guarulhos Auto Posto LTDA",
        "link": "https://maps.app.goo.gl/utmbwajXiHEntj379"
    },
    {
        "installation": 499926,
        "name": "Argetax Guarulhos",
        "city": "Guarulhos",
        "postalCode": "07043-010",
        "address": "Av. Carlos Ferreira Endres, 875 A",
        "area": "Vila Itapegica",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4853931,
        "longitude": -46.587321,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/hd2sRWZUBnV5U3rH9"
    },
    {
        "installation": 647019,
        "name": "Centro Automotivo Guarulhos (Auto Posto Mfp Polli)",
        "city": "Guarulhos",
        "postalCode": "07042-000",
        "address": "Av. Rotary, 710",
        "area": "Vila Izinha",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4862625,
        "longitude": -46.5512592,
        "localName": "Rede Duque",
        "link": "https://maps.app.goo.gl/HXPzZsMDmmVUB4ig7"
    },
    {
        "installation": 953138,
        "name": "Centro Automotivo Trevo De Bonsucesso Ltda",
        "city": "Guarulhos",
        "postalCode": "07175-090",
        "address": "Av. Paschoal Thomeu, 10",
        "area": "Vila Nova Bonsucesso",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4257142,
        "longitude": -46.4089841,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/hAhgR8GeKtbWwHFB6"
    },
    {
        "installation": 637982,
        "name": "Nova Trabalhadores",
        "city": "Guarulhos",
        "postalCode": "07220-020",
        "address": "Av. Antônio Bardella, 71",
        "area": "Zona Industrial",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4787528,
        "longitude": -46.4672318,
        "localName": "Posto Nova Trabalhadores",
        "link": "https://maps.app.goo.gl/kQUgdJmpeYz4349K6"
    },
    {
        "installation": 589409,
        "name": "Remanso",
        "city": "Hortolandia",
        "postalCode": "13184-420",
        "address": "R. Luís Camilo de Camargo, 840",
        "area": "Jardim Santana",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8647478,
        "longitude": -47.2128283,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/TA1pJJZu23F1wC8DA"
    },
    {
        "installation": 967216,
        "name": "Centro Comercial De Comb.Hortolândia  Posto Madri",
        "city": "Hortolandia",
        "postalCode": "13183-140",
        "address": "Av. Anhanguera, 150",
        "area": "Vila Real",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8580143,
        "longitude": -47.2160163,
        "localName": "Auto Posto Madri",
        "link": "https://maps.app.goo.gl/28zEPqfehrSmHiss6"
    },
    {
        "installation": 598173,
        "name": "Cidade Do Sol",
        "city": "Indaiatuba",
        "postalCode": "13344-610",
        "address": "Av. Francisco de Paula Leite, 2.393",
        "area": "Parque das Nacoes",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1121738,
        "longitude": -47.2204696,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/1BymU5qQYQoUbF8L8"
    },
    {
        "installation": 4000207199,
        "name": "Posto Renascença",
        "city": "Itapevi",
        "postalCode": "06694-000",
        "address": "Av. Pres. Vargas, 833",
        "area": "Jardim Nova Itapevi",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5382368,
        "longitude": -46.9389573,
        "localName": "Renascença Serviços Automotivo",
        "link": "https://maps.app.goo.gl/3fxegGZUEQrg3DQY6"
    },
    {
        "installation": 687538,
        "name": "Itaquagas",
        "city": "Itaquaquecetuba",
        "postalCode": "08582-545",
        "address": "Av. Itaquaquecetuba, 928-950",
        "area": "Vila Monte Belo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4851634,
        "longitude": -46.3735406,
        "localName": "Auto posto Ceprano Gnv",
        "link": "https://maps.app.goo.gl/gjLDvwdhvgatgsQH7"
    },
    {
        "installation": 656604,
        "name": "Posto Trevo De Itatiba",
        "city": "Itatiba",
        "postalCode": "13255-350",
        "address": "Av. Mal. Castelo Branco, 845",
        "area": "Bairro do Engenho",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0230151,
        "longitude": -46.8414938,
        "localName": "Posto 7 - Itatiba",
        "link": "https://maps.app.goo.gl/XCWtka6ZGAA5Ke9x5"
    },
    {
        "installation": 500377,
        "name": "J. Gava Auto Posto",
        "city": "Itatiba",
        "postalCode": "13253-200",
        "address": "R. Santo Antônio, 717",
        "area": "Jardim Sao Joao",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0022145,
        "longitude": -46.8267674,
        "localName": "Posto Platina",
        "link": "https://maps.app.goo.gl/foZBomDpwAtRM6wPA"
    },
    {
        "installation": 917396,
        "name": "Frango Assado Suleste Jacareí",
        "city": "Jacarei",
        "postalCode": "12300-000",
        "address": "Rodoviária Presidente Dutra s/n KM 162 + 500 MTS",
        "area": "Condominio Lagoinha",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.3009254,
        "longitude": -46.0173901,
        "localName": "Posto Shell - JACAREÍ",
        "link": "https://maps.app.goo.gl/bJx1spKabNmqFskh8"
    },
    {
        "installation": 599178,
        "name": "Sete Estrelas Jacareí - Posto 42",
        "city": "Jacarei",
        "postalCode": "12300-000",
        "address": "Av. Presidente Getúlio Vargas, 2300",
        "area": "Jardim California",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2697159,
        "longitude": -45.9504249,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/ewcQx6FQwEL4iZKY7"
    },
    {
        "installation": 485141,
        "name": "Retão Da Dutra (Antigo Rodrigão)",
        "city": "Jacarei",
        "postalCode": "12307-000",
        "address": "Av. Siqueira Campos, 641",
        "area": "Jardim Esper",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2967975,
        "longitude": -45.9591272,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/ptiD9wxA5TK3rZiK6"
    },
    {
        "installation": 671628,
        "name": "Auto Posto Ppr",
        "city": "Jaguariuna",
        "postalCode": "13915-290",
        "address": "KM 138,5, SP-340",
        "area": "Chácara Santo Antônio",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.6347097,
        "longitude": -47.003378,
        "localName": "PPR AUTO POSTO GNV",
        "link": "https://maps.app.goo.gl/YK4aqc6U7qtqCepR6"
    },
    {
        "installation": 4002109437,
        "name": "Rodoposto Mirante ",
        "city": "Jarinu",
        "postalCode": "13240-000",
        "address": "Rod. Dom Pedro I",
        "area": "Pte. Alta",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.0365988,
        "longitude": -46.6894262,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/mvTgoN9rym78oGDi8"
    },
    {
        "installation": 650121,
        "name": "Centro Automotivo Ceaj",
        "city": "Jundiai",
        "postalCode": "13214-525",
        "address": "Av. Armando Giassetti, 333 - Area 1",
        "area": "Area 1",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1677468,
        "longitude": -46.9172212,
        "localName": "Posto Ipiranga + GNV",
        "link": "https://maps.app.goo.gl/W1LwWQ4qZG3dwpUS6"
    },
    {
        "installation": 494797,
        "name": "Auto Posto Caminho Dos Passaros Ltda",
        "city": "Jundiai",
        "postalCode": "13215-485",
        "address": "Av. Antonio Frederico Ozanan, 3400",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1868727,
        "longitude": -46.8761988,
        "localName": "Rede 7 - Caminho",
        "link": "https://maps.app.goo.gl/v4gketekrxweciMH8"
    },
    {
        "installation": 535507,
        "name": "Figueira Cia Ipiranga",
        "city": "Jundiai",
        "postalCode": "13201-750",
        "address": "Rua Lacerda Franca, 180 ",
        "area": "Vila Arens Ii",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1963606,
        "longitude": -46.8726047,
        "localName": "Posto Ipiranga - Gnv - Vila Arens",
        "link": "https://maps.app.goo.gl/d4AZ1GBkyogfCtn47"
    },
    {
        "installation": 498454,
        "name": "Wf Jundiaí ",
        "city": "Jundiai",
        "postalCode": "13202-284",
        "address": "R. Brasil, 2272",
        "area": "Vila Isabel Eber",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2175585,
        "longitude": -46.8721854,
        "localName": "WF Jundiaí",
        "link": "https://maps.app.goo.gl/v36QcDC9jX6WL3kA9"
    },
    {
        "installation": 489577,
        "name": "Gás Jundiaí ",
        "city": "Jundiai",
        "postalCode": "13206-105",
        "address": "Av. Quatorze de Dezembro, 3200",
        "area": "Vila Mafalda",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2255018,
        "longitude": -46.8738583,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/rZVf9vZDcpjAZhVZ7"
    },
    {
        "installation": 547340,
        "name": "Posto Oasis De Limeira",
        "city": "Limeira",
        "postalCode": "13486-190",
        "address": "Av. Maj. José Levi Sobrinho, 1516",
        "area": "Boa Vista",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.5577092,
        "longitude": -47.3846905,
        "localName": "Auto Posto Novo Oasis (Ipiranga) (GNV)",
        "link": "https://maps.app.goo.gl/Wqc5HWgiDQHWKxGN9"
    },
    {
        "installation": 501347,
        "name": "Modelo De Limeira",
        "city": "Limeira",
        "postalCode": "13480-180",
        "address": "R. Boa Morte, 33",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.5685977,
        "longitude": -47.3990534,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/skfobFsS4Fb3wxbV8"
    },
    {
        "installation": 4000281613,
        "name": "Auto Posto Brasil Gás Sp  Rj Ltda",
        "city": "Lorena",
        "postalCode": "12605-720",
        "address": "Estrada Mun., 117",
        "area": "Jardim Novo Horizonte",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7456087,
        "longitude": -45.0970977,
        "localName": "Posto GNV Brasil",
        "link": "https://maps.app.goo.gl/ChwuQHGU1PfhWuwA9"
    },
    {
        "installation": 685291,
        "name": "Frango Assado Louveira",
        "city": "Louveira",
        "postalCode": "13290-000",
        "address": "Rod. Anhanguera, S/N - KM 72",
        "area": "Santo Antônio",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.0865755,
        "longitude": -46.9750356,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/XTjstsYh9PZ6GNQEA"
    },
    {
        "installation": 620193,
        "name": "Auto Posto Oitenta",
        "city": "Maua",
        "postalCode": "09360-120",
        "address": "Av. Cap. João, 614",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6719642,
        "longitude": -46.4589234,
        "localName": "Shell",
        "link": "https://maps.app.goo.gl/kanW1kbKHo3YkApS7"
    },
    {
        "installation": 489574,
        "name": "Kll Posto De Abastecimento E Serviços",
        "city": "Maua",
        "postalCode": "09370-800",
        "address": "Avenida Papa João XXIII, Av. Sertãozinho, 2009",
        "area": "Vila Noemia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.684249,
        "longitude": -46.4695787,
        "localName": "posto ipiranga",
        "link": "https://maps.app.goo.gl/1sdi2k8H667pT6pz5"
    },
    {
        "installation": 919504,
        "name": "M Super Combustiveis E Lubrificantes Ltda  Me",
        "city": "Mogi Das Cruzes",
        "postalCode": "08750-560",
        "address": "Av. Lourenço de Souza Franco, 959",
        "area": "Jundiapeba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5447357,
        "longitude": -46.2527198,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/CBpPfHHpZhpaMN51A"
    },
    {
        "installation": 516421,
        "name": "Posto Equipe Quality",
        "city": "Mogi Das Cruzes",
        "postalCode": "08780-840",
        "address": "Av. Manoel Bezerra Lima Filho, 260 - Loja 01",
        "area": "Socorro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5150984,
        "longitude": -46.1815787,
        "localName": "Posto Shell - Posto Equipe Quality",
        "link": "https://maps.app.goo.gl/9zdzp9HyZKZy6Wq18"
    },
    {
        "installation": 499932,
        "name": "Auto Posto Titans (Antigo Vipam Ii )",
        "city": "Mogi Das Cruzes",
        "postalCode": "08735-620",
        "address": "Rua Dr. Frederico Renê de Jaegher, 27",
        "area": "Vila Bernadotti",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5352973,
        "longitude": -46.2192551,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/qyKmhSHK1usWoc8o6"
    },
    {
        "installation": 621366,
        "name": "Itamarathy Auto Posto E Acessórios",
        "city": "Mogi Das Cruzes",
        "postalCode": "08773-380",
        "address": "Av. Francisco Rodrigues Filho, 695",
        "area": "Vila Mogilar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5167683,
        "longitude": -46.1899467,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/hJaD4dpuobxNFPrs7"
    },
    {
        "installation": 489225,
        "name": "João Xxiii Automobile",
        "city": "Mogi Das Cruzes",
        "postalCode": "08773-120",
        "address": "Av. José Meloni, 1280",
        "area": "Vila Mogilar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5097828,
        "longitude": -46.1957172,
        "localName": "Auto Posto Automobile",
        "link": "https://maps.app.goo.gl/ZJ3xBgi3bhakMc2D6"
    },
    {
        "installation": 871140,
        "name": "Rio Guaçu",
        "city": "Mogi-Guacu",
        "postalCode": "13844-450",
        "address": "Av. Waldemar Armani, 300",
        "area": "Jd Guacu Mirim Ii",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.3762616,
        "longitude": -46.9713127,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/FrDRB2GMbL7FrFdw9"
    },
    {
        "installation": 729833,
        "name": "Auto Posto Parque Cidade Nova Ltda.",
        "city": "Mogi-Guacu",
        "postalCode": "13845-440",
        "address": "Av. Bandeirantes, 1135",
        "area": "Lot. Parque Cidade Nova",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.3489635,
        "longitude": -46.9470213,
        "localName": "Auto Posto Parque Cidade Nova GNV",
        "link": "https://maps.app.goo.gl/hUU6NqomzFifTe677"
    },
    {
        "installation": 920077,
        "name": "Auto Posto Rvm Maxi Ltda.",
        "city": "Mogi-Mirim",
        "postalCode": "13803-590",
        "address": "Rodovia SP 340, Km 159",
        "area": "Jardim Maria Beatriz",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.4526993,
        "longitude": -46.9821446,
        "localName": "Postos RVM petronac Mogi Mirim",
        "link": "https://maps.app.goo.gl/Upju6sSZb3uVm4XH6"
    },
    {
        "installation": 679741,
        "name": "Posto Maxxi Gnv",
        "city": "Mogi-Mirim",
        "postalCode": "13806-310",
        "address": "Jardim California, Mogi Mirim - SP",
        "area": "Parque Da Imprensa",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.4228524,
        "longitude": -46.9806673,
        "localName": "Posto B",
        "link": "https://maps.app.goo.gl/Dp6EQqw3MY6697jq8"
    },
    {
        "installation": 476399,
        "name": "Bussocaba",
        "city": "Osasco",
        "postalCode": "06216-000",
        "address": "Av. Fuad Auada, 236",
        "area": "Bonfim",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5253453,
        "longitude": -46.7738104,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/CK5QVoYMV3uKn3q57"
    },
    {
        "installation": 763593,
        "name": "Auto Posto Sena Ltda.",
        "city": "Osasco",
        "postalCode": "06298-190",
        "address": "Av. Pres. Kennedy, 3984",
        "area": "Piratininga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5213878,
        "longitude": -46.7830412,
        "localName": "Auto posto Sena LT",
        "link": "https://maps.app.goo.gl/z2R3Svs5Rery44Ht9"
    },
    {
        "installation": 4000046118,
        "name": "Petrosasco",
        "city": "Osasco",
        "postalCode": "06233-020",
        "address": "Av. Getúlio Vargas, 1485",
        "area": "Piratininga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5057297,
        "longitude": -46.7884806,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/4zB4niJtKh8FUpY1A"
    },
    {
        "installation": 815596,
        "name": "Auto Posto Chic Ltda",
        "city": "Osasco",
        "postalCode": "06216-340",
        "address": "R. Maria da Conceição Mattias Santos",
        "area": "Pres. Altino",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5281828,
        "longitude": -46.7732237,
        "localName": "Posto AUTO POSTO CHIC + GNV",
        "link": "https://maps.app.goo.gl/NzVGJ4DgviyKwrhF8"
    },
    {
        "installation": 516190,
        "name": "Alternativa",
        "city": "Osasco",
        "postalCode": "06018-090",
        "address": "Rua Narciso Sturlini, 248",
        "area": "Vila Bussocaba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5411179,
        "longitude": -46.7741917,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/nVuZxSjUzAEZfvX7A"
    },
    {
        "installation": 729265,
        "name": "C. A. Ponte Metalica (Antigo Servacar Com. E Serv. Ltda. Ss Osasco)",
        "city": "Osasco",
        "postalCode": "06020-015",
        "address": "Av. dos Autonomistas, 1987",
        "area": "Vila Yara",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5396138,
        "longitude": -46.7721039,
        "localName": "Loja Shell Select - POSTO SHELL - CENTRO AUT PONTE METALICA DE OSASCO",
        "link": "https://maps.app.goo.gl/uGquUZZNRBsa1CM39"
    },
    {
        "installation": 970241,
        "name": "Centro De Serviços Paz Gts Ltda - Antigo Promissão",
        "city": "Osasco",
        "postalCode": "06298-190",
        "address": "Av. Pres. Kenedy, 5031",
        "area": "Vila dos Remedios",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5209416,
        "longitude": -46.7484497,
        "localName": "Jet Oil - Serviços Automotivos | Ipiranga",
        "link": "https://maps.app.goo.gl/HXeBKRNwb6CVPwUJA"
    },
    {
        "installation": 790684,
        "name": "Posto Jardim Okinawa Ltda",
        "city": "Paulinia",
        "postalCode": "13141-010",
        "address": "Av. José Álvaro Delmonde, 53",
        "area": "Jardim Okinawa",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7999309,
        "longitude": -47.1320467,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/GA45RyPiVJWyepZy6"
    },
    {
        "installation": 784777,
        "name": "Auto Posto Cidade Nova Pinda",
        "city": "Pindamonhangaba",
        "postalCode": "12414-020",
        "address": "Av. Gastão Vidigal Neto, 1200",
        "area": "Cidade Nova",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9399841,
        "longitude": -45.4066067,
        "localName": "Posto BRASIL + GNV | Cidade Nova Pinda",
        "link": "https://maps.app.goo.gl/Yd9zvoPRA7JyCYT67"
    },
    {
        "installation": 4001525378,
        "name": "Sete Estrelas Com De Derivados ",
        "city": "Pindamonhangaba",
        "postalCode": "12421-010",
        "address": "SP-062, 2150",
        "area": "Mombaça",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9486594,
        "longitude": -45.4786395,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/NxVSXZtnp7zYygWk7"
    },
    {
        "installation": 687540,
        "name": "Brasil Gas Pinda",
        "city": "Pindamonhangaba",
        "postalCode": "12441-010",
        "address": "Av. Dr. José Monteiro Machado César, 1260",
        "area": "Moreira Césara",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9271166,
        "longitude": -45.3560736,
        "localName": "GNV Brasil",
        "link": "https://maps.app.goo.gl/WteH4uCcg9b1mxZy8"
    },
    {
        "installation": 542887,
        "name": "Tiger Auto Posto",
        "city": "Piracicaba",
        "postalCode": "13414-018",
        "address": "Av. Limeira, 950",
        "area": "Areião",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.702892,
        "longitude": -47.6485093,
        "localName": "Posto GT Shopping",
        "link": "https://maps.app.goo.gl/ArMpEKhwSLGWcjkaA"
    },
    {
        "installation": 4000771786,
        "name": "Auto Posto Diamante De Piracicaba Ltda",
        "city": "Piracicaba",
        "postalCode": "13417-370",
        "address": "Av. Comendador Luciano Guidotti, 420",
        "area": "Higienópolis",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7412019,
        "longitude": -47.6414574,
        "localName": "Posto Amaral (GNV)",
        "link": "https://maps.app.goo.gl/BcdMTAK2bzs5X3c77"
    },
    {
        "installation": 495646,
        "name": "Costa Prado",
        "city": "Piracicaba",
        "postalCode": "13417-670",
        "address": "Av. Prof.Alberto Valter Sachs,2600",
        "area": "Nova America",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.72608,
        "longitude": -47.626408,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/6bKCYttnv3ChNRLB7"
    },
    {
        "installation": 656330,
        "name": "Portal De Poá Auto Posto",
        "city": "Poa",
        "postalCode": "08559-400",
        "address": "Av. Fernando Rossi, 314",
        "area": "Vila Bandeirantes",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5185314,
        "longitude": -46.332157,
        "localName": "Posto Portal de Poá",
        "link": "https://maps.app.goo.gl/H5pMPQXq1T3qPjzo7"
    },
    {
        "installation": 4001685227,
        "name": "Rede Boxter Poá",
        "city": "Poa",
        "postalCode": "08557-700",
        "address": "Rod. Henrique Eroles, 24",
        "area": "Vila Lucia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5086699,
        "longitude": -46.341706,
        "localName": "Boxter Poá",
        "link": "https://maps.app.goo.gl/rMdMvoBqFZWRhgnq8"
    },
    {
        "installation": 659123,
        "name": "Caturama  Antigo Rfm ",
        "city": "Rio Claro",
        "postalCode": "13503-183",
        "address": "Av. Pres. Tancredo de Almeida Neves, 581 - 1",
        "area": "Jardim Claret",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.4163897,
        "longitude": -47.5735453,
        "localName": "Posto Shell GNV RIO CLARO",
        "link": "https://maps.app.goo.gl/z5B8wuTJjgeZjAYu7"
    },
    {
        "installation": 502232,
        "name": "Rio Clarense Via Brasil",
        "city": "Rio Claro",
        "postalCode": "13503-098",
        "address": "R. 22 Jsp, 801",
        "area": "Jardim Mirassol",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.4186712,
        "longitude": -47.5767511,
        "localName": "Rede 7 - Rio Clarense",
        "link": "https://maps.app.goo.gl/6RELa8SbS1ZuoxWj9"
    },
    {
        "installation": 4001928706,
        "name": "Auto Posto Brasil Gas Luiz De",
        "city": "Santa Barbara Doeste",
        "postalCode": "13457-056",
        "address": "Parque Res. Santa Ines",
        "area": "Jardim Paraiso",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7657702,
        "longitude": -47.3979068,
        "localName": "Posto Brasil Gas",
        "link": "https://maps.app.goo.gl/p5jYjnQfcn68i1ft6"
    },
    {
        "installation": 966767,
        "name": "Tenda Gnv Com De Comb E Gas Natural Veicular Ltda",
        "city": "Santa Barbara Doeste",
        "postalCode": "13454-000",
        "address": "R. da Agricultura, 874",
        "area": "Jardim Sao Fernando",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7463363,
        "longitude": -47.3645831,
        "localName": "Posto Tenda GNV",
        "link": "https://maps.app.goo.gl/VvdLh9Bh8WVDbRSr9"
    },
    {
        "installation": 647761,
        "name": " Posto Gp Gnv Ltda",
        "city": "Santo Andre",
        "postalCode": "09111-340",
        "address": "Rua Giovanni Battista Pirelli",
        "area": "Capuava",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6645261,
        "longitude": -46.4905167,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/dwiAtDpWZCQw9CBh6"
    },
    {
        "installation": 490226,
        "name": "Cia Bras. De Distibuicao Loja 2301  Extra",
        "city": "Santo Andre",
        "postalCode": "09210-570",
        "address": "R. Visc. de Taunay, 216 - Box 2",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6520269,
        "longitude": -46.5262024,
        "localName": "Assaí Atacadista",
        "link": "https://maps.app.goo.gl/3ng9jyARvfKRQYPQ8"
    },
    {
        "installation": 4001609798,
        "name": "O Estadão De Santo André Antigo Cabeça Branca ",
        "city": "Santo Andre",
        "postalCode": "09290-520",
        "address": "Av. dos Estados, 6700",
        "area": "Tamanduateí 8",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6523003,
        "longitude": -46.5142438,
        "localName": "Rede 7 - Estadão de Santo Andre",
        "link": "https://maps.app.goo.gl/h7FMseK6mL9NKJqk6"
    },
    {
        "installation": 472422,
        "name": "Catequese Auto Posto",
        "city": "Santo Andre",
        "postalCode": "09090-401",
        "address": "R. Catequese, 1263",
        "area": "Vila Guiomar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6590592,
        "longitude": -46.5403832,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/1zkHw2e8RR6ocLYe9"
    },
    {
        "installation": 547358,
        "name": "Lívia Auto Posto",
        "city": "Santo Andre",
        "postalCode": "09090-390",
        "address": "Av. José Antônio de Almeida Amazonas, 551",
        "area": "Vila Guiomar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.659573,
        "longitude": -46.5399381,
        "localName": "Livia Auto Posto Ltda",
        "link": "https://maps.app.goo.gl/HAVFDhiJTLnW3KXD6"
    },
    {
        "installation": 480148,
        "name": "Posto De Serviços Trilegal (Antigo Nn Santo André)",
        "city": "Santo André",
        "postalCode": "09290-000",
        "address": "Av. André Ramalho, 74",
        "area": "Parque João Ramalho",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6518297,
        "longitude": -46.5108595,
        "localName": "Ale",
        "link": "https://maps.app.goo.gl/ejLXM8mzkeeBjFx29"
    },
    {
        "installation": 982809,
        "name": "Auto Posto Arrastão Ltda.",
        "city": "Santos",
        "postalCode": "11010-200",
        "address": "R.Visconde De Sao Leopoldo, 350",
        "area": "Centro",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.9314405,
        "longitude": -46.3374973,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/pVPNT7ufLV8szqYX7"
    },
    {
        "installation": 4001470873,
        "name": "Formula Indy Comercio E Serviços Automotivos Ltda",
        "city": "Santos",
        "postalCode": "11085-202",
        "address": "Av. Nossa Senhora de Fátima, 302",
        "area": "Chico de Paula",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.9351435,
        "longitude": -46.3624311,
        "localName": "Formula Indy Com. e Serviços Automotivos (RodOil)",
        "link": "https://maps.app.goo.gl/JmXeUnfpK7xqdrcS8"
    },
    {
        "installation": 564510,
        "name": "Zangão Serviços Automotivos",
        "city": "Sao Caetano Do Sul",
        "postalCode": "09550-051",
        "address": "Av. Goiás, 3144",
        "area": "Barcelona",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6224159,
        "longitude": -46.5489867,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/95mhQ31wvLAchSfA9"
    },
    {
        "installation": 472670,
        "name": "Auto Posto Eco Gas (Antigo Eco Gás  Simi)",
        "city": "Sao Caetano Do Sul",
        "postalCode": "09550-700",
        "address": "Av. Prosperidade,367",
        "area": "Prosperidade",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6109829,
        "longitude": -46.5515058,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/Akfibk3EY9HtJPaD8"
    },
    {
        "installation": 4000207230,
        "name": "Br  Posto 800 Milhas",
        "city": "Sao Vicente",
        "postalCode": "11320-001",
        "address": "Av. Presidente Wilson, 759",
        "area": "Itararé",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.9685763,
        "longitude": -46.3758966,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/PzkTmHVEySHfiRr99"
    },
    {
        "installation": 713867,
        "name": "Posto Brasil Gás Sumaré",
        "city": "Sumare",
        "postalCode": "13177-432",
        "address": "R. Adolfo Berto de Oliveira",
        "area": "Jardim Santa Maria (Nova Veneza)",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7981069,
        "longitude": -47.2238215,
        "localName": "Auto Posto GNV Brasil Anhanguera",
        "link": "https://maps.app.goo.gl/UjukqUg8XUbSCnCA9"
    },
    {
        "installation": 4001953781,
        "name": "Itamarathy Auto Posto E Acessórios Ltda – Itamarathy  V ",
        "city": "Suzano",
        "postalCode": "08694-000",
        "address": "Av. Francisco Marengo, 49",
        "area": "Jardim Revista",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.518745,
        "longitude": -46.2991616,
        "localName": "POSTOS ITAMARATHY É do seu jeito",
        "link": "https://maps.app.goo.gl/2axzu2dUATV5h3FT7"
    },
    {
        "installation": 800248,
        "name": "Posto Quality Casablanca Ltda",
        "city": "Suzano",
        "postalCode": "08673-000",
        "address": "Av. Armando Salles de Oliveira, 2185",
        "area": "Parque Santa Rosa",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5523419,
        "longitude": -46.3195517,
        "localName": "Posto Shell - Posto Quality Casablanca",
        "link": "https://maps.app.goo.gl/beL6gVqi5tTdRf9W6"
    },
    {
        "installation": 684433,
        "name": "Suzan Petro",
        "city": "Suzano",
        "postalCode": "08683-000",
        "address": "Av. Ver. João Batista Fitipaldi, 300",
        "area": "Vila Maluf",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5322078,
        "longitude": -46.306959,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/s7oFAf7CdKUdWZRy9"
    },
    {
        "installation": 4001926330,
        "name": "Posto Abu-Dhabi",
        "city": "Suzano",
        "postalCode": "08613-900",
        "address": "R. Prudente de Morais, 4135",
        "area": "Vila Santana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5409811,
        "longitude": -46.2732063,
        "localName": "Posto Abu-Dhabi - Ipiranga",
        "link": "https://maps.app.goo.gl/42FdXwvEAUxtZomb8"
    },
    {
        "installation": 546262,
        "name": "Portal De São Bernardo Serviços",
        "city": "São Bernardo Do Campo",
        "postalCode": "09600-004",
        "address": "Av. Senador Vergueiro, 2930",
        "area": "Anchieta",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6700249,
        "longitude": -46.5596264,
        "localName": "Auto Posto Ipiranga - Portal de São Bernardo",
        "link": "https://maps.app.goo.gl/fUvkMihvX36XLj427"
    },
    {
        "installation": 493143,
        "name": "Vip Auto Posto Ltda",
        "city": "São Bernardo Do Campo",
        "postalCode": "09750-000",
        "address": "Av. Senador Vergueiro,1032",
        "area": "Jardim Do Mar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6856908,
        "longitude": -46.5542337,
        "localName": "Posto Com GNV",
        "link": "https://maps.app.goo.gl/FNJkYDb7bprdfPZ16"
    },
    {
        "installation": 547343,
        "name": "Queops Auto Posto Ltda",
        "city": "São Bernardo Do Campo",
        "postalCode": "09770-340",
        "address": "Av. Pery Ronchetti, 212",
        "area": "Nova Petrópolis",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.7023341,
        "longitude": -46.5419487,
        "localName": "Queop S Auto Posto Limitada",
        "link": "https://maps.app.goo.gl/aSjx6XNkNcmFAA1S7"
    },
    {
        "installation": 518120,
        "name": "Auto Posto Vergueirão",
        "city": "São Bernardo Do Campo",
        "postalCode": "09740-001",
        "address": "Av. Senador Vergueiro, 2861",
        "area": "Rudge Ramos",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6705272,
        "longitude": -46.560137,
        "localName": "Auto Posto Vergueirão",
        "link": "https://maps.app.goo.gl/Uuev9oqsHgPMMK976"
    },
    {
        "installation": 480135,
        "name": "Muffatto Sbc",
        "city": "São Bernardo Do Campo",
        "postalCode": "09695-100",
        "address": "Rua Frei Damião, 355",
        "area": "Rudge Ramos",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6727146,
        "longitude": -46.5712715,
        "localName": "Posto gasolina (“Grupo Muffato”),",
        "link": "https://maps.app.goo.gl/QySkqfMUQ5sUWpPn6"
    },
    {
        "installation": 547381,
        "name": "Térios Auto Posto",
        "city": "São Bernardo Do Campo",
        "postalCode": "09721-210",
        "address": "R. Alferes Bonilha, 531",
        "area": "Vila Magnolia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.7181224,
        "longitude": -46.5503123,
        "localName": "Terios Auto Posto",
        "link": "https://maps.app.goo.gl/Mq4h8nLpobRrntSM6"
    },
    {
        "installation": 677580,
        "name": "Auto Posto Master Vale Antigo Gran Vale",
        "city": "São José Dos Campos",
        "postalCode": "12231-000",
        "address": "Av. Cidade Jardim, 764",
        "area": "Floradas de São José",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2204317,
        "longitude": -45.8851454,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/L9YkF5VBvSt5A5hC6"
    },
    {
        "installation": 819499,
        "name": "Jcn Vale",
        "city": "São José Dos Campos",
        "postalCode": "12216-550",
        "address": "R. Jordânia, 152",
        "area": "Jardim Oswaldo Cruz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2020631,
        "longitude": -45.8818743,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/XNxxfqXVm3aouDpJA"
    },
    {
        "installation": 541246,
        "name": "Posto De Serviços Bolla Branca",
        "city": "São José Dos Campos",
        "postalCode": "12216-550",
        "address": "R. Jordânia, 152",
        "area": "Jardim Oswaldo Cruz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2020358,
        "longitude": -45.8819411,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/j1eHVjs1X12Pv2Q18"
    },
    {
        "installation": 520236,
        "name": "Rede De Postos Sete Estrelas  Penedo - Posto 04",
        "city": "São José Dos Campos",
        "postalCode": "12237-640",
        "address": "R. Penedo, 1051",
        "area": "Jardim Petropolis",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2406644,
        "longitude": -45.9169866,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/7STA8SDA7MZdocJn7"
    },
    {
        "installation": 830546,
        "name": "Bandeirante",
        "city": "São José Dos Campos",
        "postalCode": "12245-031",
        "address": "Av. Dr. Nélson d'Ávila, 1650",
        "area": "Jardim Sao Dimas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2008491,
        "longitude": -45.8860211,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/EwypsdG9FkbNcYEb8"
    },
    {
        "installation": 476400,
        "name": "Rede De Postos Sete Estrelas  Tejo - Posto 31",
        "city": "São José Dos Campos",
        "postalCode": "12245-021",
        "address": "Rua Paraibuna,1340",
        "area": "Jardim Sao Dimas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2036376,
        "longitude": -45.8849727,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/EPK6ZcHJrm29KhkC6"
    },
    {
        "installation": 536431,
        "name": "Auto Posto Gascem Ltda.",
        "city": "São José Dos Campos",
        "postalCode": "12231-000",
        "address": "Av. Cidade Jardim, 920",
        "area": "Jd Satelite",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2209893,
        "longitude": -45.8847438,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/dQqEqCKwNSZpEb82A"
    },
    {
        "installation": 796095,
        "name": "Rede De Postos Sete Estrelas Tropical - Posto 47",
        "city": "São José Dos Campos",
        "postalCode": "12237-280",
        "address": "Av. Dr. Sebastião Henrique da Cunha Pontes, 5830",
        "area": "Palmeiras de São José",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2341426,
        "longitude": -45.9147957,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/rZKT8RjYFVBEYraB9"
    },
    {
        "installation": 516092,
        "name": "Mulffatto Sjc",
        "city": "São José Dos Campos",
        "postalCode": "12220-120",
        "address": "R. Aldo José de Souza, 711-733",
        "area": "Res. Tatetuba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1854364,
        "longitude": -45.8547261,
        "localName": "Auto posto Max",
        "link": "https://maps.app.goo.gl/Mqs5LcCEVDM9kqAe6"
    },
    {
        "installation": 793685,
        "name": "Auto Posto Master Cidade (Mineirinho Sjc)",
        "city": "São José Dos Campos",
        "postalCode": "12211-005",
        "address": "Av. Rui Barbosa, 837",
        "area": "Santana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1698752,
        "longitude": -45.8912689,
        "localName": "Chaveiro G. Gil (Meu Atacarejo)",
        "link": "https://maps.app.goo.gl/TGAUExXX6hk4zSo96"
    },
    {
        "installation": 713869,
        "name": "Rede De Postos Sete Estrelas Crylor - Posto 49",
        "city": "São José Dos Campos",
        "postalCode": "12209-750",
        "address": "Rua Benedito Juliao Machado, 49",
        "area": "Vila Guarani",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.174752,
        "longitude": -45.8770145,
        "localName": "Auto Posto Torrão",
        "link": "https://maps.app.goo.gl/byF5W7FhYNTLoXTc8"
    },
    {
        "installation": 520237,
        "name": "Vitrine",
        "city": "São José Dos Campos",
        "postalCode": "12245-031",
        "address": "Av. Dr. Nélson d'Ávila, 1.810",
        "area": "Vila Sanches",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2021562,
        "longitude": -45.8852712,
        "localName": "Auto Posto Vitrine | Jardim São Dimas",
        "link": "https://maps.app.goo.gl/9gLinDe3Jm2vqdzNA"
    },
    {
        "installation": 518101,
        "name": "Auto Posto Brasil Gas Dutra",
        "city": "São José Dos Campos",
        "postalCode": "12220-140",
        "address": "R. Patativa, 631",
        "area": "Vila Tatetuba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1851931,
        "longitude": -45.8533868,
        "localName": "Auto Posto GNV Brasil Dutra",
        "link": "https://maps.app.goo.gl/79HZdkeHt5j4rD8U6"
    },
    {
        "installation": 4000545020,
        "name": "Serviços Automotivos Pedrodavi Ltda.",
        "city": "São Paulo",
        "postalCode": "03022-000",
        "address": "Rua Paulo Andrighetti, 1484",
        "area": "Alto do Pari",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.523909,
        "longitude": -46.6064881,
        "localName": "Posto Singular (GNV)",
        "link": "https://maps.app.goo.gl/21NUExv65Dh3JSfdA"
    },
    {
        "installation": 518095,
        "name": "Playgas",
        "city": "São Paulo",
        "postalCode": "01139-003",
        "address": "Av. Marquês de São Vicente, 999",
        "area": "Barra Funda",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5205679,
        "longitude": -46.6707099,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/kK6h4BLA9ZCBNUAk9"
    },
    {
        "installation": 499316,
        "name": "Jardim Da Saúde",
        "city": "São Paulo",
        "postalCode": "04132-001",
        "address": "Av. do Cursino, 2154",
        "area": "Bosque da Saúde",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6205446,
        "longitude": -46.6210496,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/SctdYT395Rfb2V529"
    },
    {
        "installation": 906524,
        "name": "Duque Bandeirantes",
        "city": "São Paulo",
        "postalCode": "04558-040",
        "address": "R. Conceição de Monte Alegre, 1680",
        "area": "Brooklin",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6017178,
        "longitude": -46.6830095,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/h1p337axyWE3a4Mi6"
    },
    {
        "installation": 190108,
        "name": "Auto Posto Império",
        "city": "São Paulo",
        "postalCode": "03019-000",
        "address": "R. Joaquim Carlos, 1380",
        "area": "Brás",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.5244444,
        "longitude": -46.6080556,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/h17SpCVn5UWjrHmY9"
    },
    {
        "installation": 535510,
        "name": "Brazatlas",
        "city": "São Paulo",
        "postalCode": "03004-000",
        "address": "Rua do Gasômetro, 570",
        "area": "Brás",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5440542,
        "longitude": -46.6219071,
        "localName": "BR Mania Conveniência",
        "link": "https://maps.app.goo.gl/bYAucP2bnNrmW4KD7"
    },
    {
        "installation": 518103,
        "name": "Tropical Gasolina Serviços Automotivos Ltda",
        "city": "São Paulo",
        "postalCode": "03102-000",
        "address": "Av. Alcântara Machado, 360",
        "area": "Brás",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5526075,
        "longitude": -46.621635,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/rKcLur5ka1SdJFUf8"
    },
    {
        "installation": 675045,
        "name": "Paraná",
        "city": "São Paulo",
        "postalCode": "05509-001",
        "address": "Rua Alvarenga, 1071 Ou 1041",
        "area": "Butanta",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5728084,
        "longitude": -46.711574,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/jYA2Wn6drpg5cZA86"
    },
    {
        "installation": 374687,
        "name": "Santa Helena (Antigo Caluana)",
        "city": "São Paulo",
        "postalCode": "05582-000",
        "address": "Rua Corifeu De Azevedo Marques 44 X Av. Vital Brasil",
        "area": "Butanta",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5692,
        "longitude": -46.715746,
        "localName": "CENTRO AUTOMOTIVO SANTA HELENA LTDA",
        "link": "https://maps.app.goo.gl/HsVUz13XQhMkbxXK6"
    },
    {
        "installation": 623190,
        "name": "Argetax Posto Dallas",
        "city": "São Paulo",
        "postalCode": "05533-000",
        "address": "Av. Eliseu de Almeida, 2481",
        "area": "Butantã",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5874958,
        "longitude": -46.7319941,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/MsxmPYUVwnjvTXNf8"
    },
    {
        "installation": 737543,
        "name": "Batalha",
        "city": "São Paulo",
        "postalCode": "05593-090",
        "address": "R. Azem Abdalla Azem, 2600",
        "area": "Butantã",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5811086,
        "longitude": -46.7305635,
        "localName": "Posto Shell + GNV | Batalha",
        "link": "https://maps.app.goo.gl/UZTjQnVcPoJtdUBg9"
    },
    {
        "installation": 682690,
        "name": "Nova Cachoeirinha",
        "city": "São Paulo",
        "postalCode": "02717-000",
        "address": "Av. Inajar de Souza, 3535",
        "area": "Cachoeirinha",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4783305,
        "longitude": -46.673332,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/KCTyYriLFdKE4kBP9"
    },
    {
        "installation": 541782,
        "name": "Ana Nery Posto Xaranga",
        "city": "São Paulo",
        "postalCode": "01522-000",
        "address": "R. Dona Ana Neri, 1156",
        "area": "Cambuci",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5640949,
        "longitude": -46.6179266,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/2nDukRBmJFzYWQfA8"
    },
    {
        "installation": 643741,
        "name": "Cambuci Auto Posto",
        "city": "São Paulo",
        "postalCode": "01523-010",
        "address": "Largo Do Cambuci 172",
        "area": "Cambuci",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5636493,
        "longitude": -46.6207276,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/8tcqZ7zZV2F5aNra6"
    },
    {
        "installation": 625641,
        "name": "Ouro 22",
        "city": "São Paulo",
        "postalCode": "04615-001",
        "address": "R. Dr. Jesuíno Maciel, 789",
        "area": "Campo Belo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6184641,
        "longitude": -46.6729025,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/VzZZEfXzNFE6THHG6"
    },
    {
        "installation": 665826,
        "name": "Papa 5 Braz Leme",
        "city": "São Paulo",
        "postalCode": "02511-000",
        "address": "Av. Braz Leme, 164",
        "area": "Casa Verde",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5135984,
        "longitude": -46.6535094,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/LTjrhBP5rL5MuMCB9"
    },
    {
        "installation": 4001614257,
        "name": "Auto Posto Angra Dos Reis Ltda",
        "city": "São Paulo",
        "postalCode": "03021-000",
        "address": "R. Catumbi, 1400",
        "area": "Catumbi",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5257579,
        "longitude": -46.6005727,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/oJwYZFXdaraAPD7q8"
    },
    {
        "installation": 720570,
        "name": "Auto Posto 2000",
        "city": "São Paulo",
        "postalCode": "05517-040",
        "address": "Av. Prof. Francisco Morato, 2300",
        "area": "Caxingui",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5851831,
        "longitude": -46.7208119,
        "localName": "Auto Posto Rede 1000",
        "link": "https://maps.app.goo.gl/dL2xDGgNixtQwTUA7"
    },
    {
        "installation": 516186,
        "name": "Posto De Serviço Iramaia Morumbi Ltda",
        "city": "São Paulo",
        "postalCode": "04707-000",
        "address": "Av. Roque Petroni Júnior, 984",
        "area": "Cidade Monções",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6233998,
        "longitude": -46.6962399,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/wicKkfygJJEUkT6K8"
    },
    {
        "installation": 488525,
        "name": "Auto Posto Rio Coa",
        "city": "São Paulo",
        "postalCode": "03813-110",
        "address": "R. Rodeiro, 102",
        "area": "Ermelino Matarazzo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4882284,
        "longitude": -46.4652177,
        "localName": "Auto Posto Rio Coa",
        "link": "https://maps.app.goo.gl/HExQrNfprC7DB19Z7"
    },
    {
        "installation": 4001293768,
        "name": "Posto De Serviços Jurucê Ltda.",
        "city": "São Paulo",
        "postalCode": "04074-020",
        "address": "Av. Moreira Guimarães, 718",
        "area": "Indianópolis",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6127977,
        "longitude": -46.6579624,
        "localName": "Shell",
        "link": "https://maps.app.goo.gl/7XSL6yyzR2bHsHrH7"
    },
    {
        "installation": 799515,
        "name": "Interlaken",
        "city": "São Paulo",
        "postalCode": "04661-000",
        "address": "Av. Interlagos, 2981",
        "area": "Interlagos",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6777986,
        "longitude": -46.6829973,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/6W7bTMBcKmgCyjgL8"
    },
    {
        "installation": 376459,
        "name": "Posto Phenix Com. Var. De Combustiveis (Antigo Nautilus-Raizen)",
        "city": "São Paulo",
        "postalCode": "04208-000",
        "address": "R. Silva Bueno, 754",
        "area": "Ipiranga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5850565,
        "longitude": -46.6019233,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/gHZ5o8JBnapfCbRf8"
    },
    {
        "installation": 480804,
        "name": "Nipo Brasileiro",
        "city": "São Paulo",
        "postalCode": "08115-100",
        "address": "Avenida Marechal Tito, 3501",
        "area": "Itaim Paulista",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4948221,
        "longitude": -46.4109953,
        "localName": "Auto Posto Nipo Brasileiro C",
        "link": "https://maps.app.goo.gl/iBbKhD9h1FxMszmx5"
    },
    {
        "installation": 937354,
        "name": "Pêssego Com. E Serviços Combustíveis E Lubrif. Ltda",
        "city": "São Paulo",
        "postalCode": "08260-001",
        "address": "Estr. do Pêssego, 2101",
        "area": "Itaquera",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.577677,
        "longitude": -46.4431008,
        "localName": "Pêssego GNV",
        "link": "https://maps.app.goo.gl/B8XEBGA3SQx1oZGe9"
    },
    {
        "installation": 487831,
        "name": "Orion Auto Posto Ltda",
        "city": "São Paulo",
        "postalCode": "05346-000",
        "address": "Av. Jaguaré, 740",
        "area": "Jaguaré",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5538293,
        "longitude": -46.7429415,
        "localName": "Arinella Orion auto posto ltda",
        "link": "https://maps.app.goo.gl/KqtAsezikZaADB177"
    },
    {
        "installation": 617858,
        "name": "Posto De Combustiveis Conveniência Zatec Tietê",
        "city": "São Paulo",
        "postalCode": "03707-015",
        "address": "Av. Condessa Elizabeth de Robiano, 2200",
        "area": "Jardim America da Penha",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5080086,
        "longitude": -46.5510889,
        "localName": "Posto GULF - Posto Zatec Marginal Tietê",
        "link": "https://maps.app.goo.gl/WHDmmB62uGazVsqFA"
    },
    {
        "installation": 530846,
        "name": "Posto Fábio Mota Marques Ltda (Antigo Chaparral Via Veneto )",
        "city": "São Paulo",
        "postalCode": "03704-000",
        "address": "Av. Condessa Elizabeth de Robiano, 2222",
        "area": "Jardim America da Penha",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5207342,
        "longitude": -46.5553051,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/WyYzW7n6eh47NnF49"
    },
    {
        "installation": 707775,
        "name": "Tex Auto Posto Ltda",
        "city": "São Paulo",
        "postalCode": "03090-000",
        "address": "Av. Airton Pretini, 539",
        "area": "Jardim America da Penha",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5254439,
        "longitude": -46.5568203,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/f3coJvSmBnpazZKF7"
    },
    {
        "installation": 806838,
        "name": "Alicemar",
        "city": "São Paulo",
        "postalCode": "08115-000",
        "address": "Avenida Marechal Tito, 6034",
        "area": "Jardim Miragaia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4925986,
        "longitude": -46.3883184,
        "localName": "Posto Petrobras Av Marechal",
        "link": "https://maps.app.goo.gl/4N5Uf7kGUyTZgQN98"
    },
    {
        "installation": 707497,
        "name": "Posto Bg Gnv-Posto Bela Vista",
        "city": "São Paulo",
        "postalCode": "04723-002",
        "address": "Av. João Dias, 1951",
        "area": "Jardim Mirante",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6447327,
        "longitude": -46.7195914,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/QkSE2gqqzCyaw1Ac9"
    },
    {
        "installation": 756900,
        "name": "Posto De Serviço Santa Rita Do Monte Azul",
        "city": "São Paulo",
        "postalCode": "05835-002",
        "address": "Estr. de Itapecerica, 1422",
        "area": "Jardim Monte Azul",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6474278,
        "longitude": -46.7479432,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/8RvPseXte7Wnd66Z7"
    },
    {
        "installation": 833265,
        "name": "Auto Posto Relix Ltda",
        "city": "São Paulo",
        "postalCode": "05723-400",
        "address": "Av. João Dias, 3107",
        "area": "Jardim Santo Antonio",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6435433,
        "longitude": -46.7332146,
        "localName": "Rede 28 - Auto Posto Relix Ltda.",
        "link": "https://maps.app.goo.gl/ZbCM3uJA4ZmSnRnj8"
    },
    {
        "installation": 487827,
        "name": "Posto Vm Rodrigues  (Antigo Roney Peres De Brito )",
        "city": "São Paulo",
        "postalCode": "08120-590",
        "address": "R. Estevão Ribeiro Garcia, 30",
        "area": "Jardim Virginia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4962019,
        "longitude": -46.3917185,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/DuB9e7q6HzTNknoD7"
    },
    {
        "installation": 4001897378,
        "name": "Via Brasil Ltda-Portal Da Raposo",
        "city": "São Paulo",
        "postalCode": "05550-070",
        "address": "R. dos Piemonteses, 26",
        "area": "Jardim do Lago",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.5831624,
        "longitude": -46.7831143,
        "localName": "Auto Posto Portal da Raposo",
        "link": "https://maps.app.goo.gl/LnEdkRwe44szzEYj6"
    },
    {
        "installation": 550803,
        "name": "Auto Posto Duque Zagottis",
        "city": "São Paulo",
        "postalCode": "04676-000",
        "address": "Av. Eng. Alberto de Zagottis, 567",
        "area": "Jurubatuba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6689649,
        "longitude": -46.6959137,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/v9M9bRQEDrWjcN5f6"
    },
    {
        "installation": 500386,
        "name": "Ms2 Auto Posto Ltda",
        "city": "São Paulo",
        "postalCode": "04696-310",
        "address": "R. Cristalino Rolim de Freitas, 96",
        "area": "Jurubatuba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6640542,
        "longitude": -46.7071265,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/pbeqCfhPYi8AUKvr6"
    },
    {
        "installation": 4002152893,
        "name": "Auto Posto Engenheiro 1200 Ltda",
        "city": "São Paulo",
        "postalCode": "02521-080",
        "address": "R. Tangerinas, 353",
        "area": "Limão",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5039339,
        "longitude": -46.6676608,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/GtxA8hTRYNJaQRCs7"
    },
    {
        "installation": 518097,
        "name": "Auto Posto Estado De São Paulo (Antigo Converrede)",
        "city": "São Paulo",
        "postalCode": "02546-000",
        "address": "Bandeira Branca - Av. Eng. Caetano Álvares, 370",
        "area": "Limão",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.5103107,
        "longitude": -46.6884171,
        "localName": "Auto Posto Estado de São Paulo",
        "link": "https://maps.app.goo.gl/LnBzLRjj9bHk74ps6"
    },
    {
        "installation": 814653,
        "name": "Lider Santos Dumont",
        "city": "São Paulo",
        "postalCode": "01107-000",
        "address": "Av. do Estado, 1036",
        "area": "Mercado",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.524301,
        "longitude": -46.6315888,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/fGXnCvp2TFCcHnRRA"
    },
    {
        "installation": 552188,
        "name": "Auto Posto Pétala Azul",
        "city": "São Paulo",
        "postalCode": "03164-110",
        "address": "R. Conselheiro Lafaiette, 255",
        "area": "Mooca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5511178,
        "longitude": -46.6084996,
        "localName": "Posto Boxter",
        "link": "https://maps.app.goo.gl/vpFt2rrjEysc2soq8"
    },
    {
        "installation": 472424,
        "name": "Qualitá",
        "city": "São Paulo",
        "postalCode": "03123-010",
        "address": "R. Sarapuí, 277",
        "area": "Mooca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5657503,
        "longitude": -46.6010107,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/cpvDA71bzqeyzdKj8"
    },
    {
        "installation": 516189,
        "name": "Posto Da Ponte",
        "city": "São Paulo",
        "postalCode": "02715-000",
        "address": "Av. Nossa Senhora do Ó, 1693",
        "area": "N S Do O",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5053538,
        "longitude": -46.6893545,
        "localName": "Posto Shell - Rede Papa GNV",
        "link": "https://maps.app.goo.gl/WrEKPNxNx3y51p1t7"
    },
    {
        "installation": 617299,
        "name": "Centro De Abastecimento De Gás Milena Ltda  Niagara",
        "city": "São Paulo",
        "postalCode": "02901-000",
        "address": "Av. Otaviano Alves de Lima, 3926",
        "area": "Nossa Sra. do O",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5067954,
        "longitude": -46.69622,
        "localName": "Shell Select",
        "link": "https://maps.app.goo.gl/X5VVLP7wxaF23R369"
    },
    {
        "installation": 4001470849,
        "name": "Auto Posto De Serv Oliveira Ltda",
        "city": "São Paulo",
        "postalCode": "03022-030",
        "address": "R. Ferreira de Oliveira, 187",
        "area": "Pari",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5241204,
        "longitude": -46.6037908,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/MBric4c5aHAuMz9F8"
    },
    {
        "installation": 480138,
        "name": "Posto De Serviços Sagitário",
        "city": "São Paulo",
        "postalCode": "03026-001",
        "address": "R. Silva Teles, 1819",
        "area": "Pari",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5241629,
        "longitude": -46.6046542,
        "localName": "Posto de serviço Sagitario",
        "link": "https://maps.app.goo.gl/zhyiFtPd5D1y6SSh6"
    },
    {
        "installation": 4001699312,
        "name": "Silguekron",
        "city": "São Paulo",
        "postalCode": "04902-015",
        "address": "Av. Guarapiranga, 1791",
        "area": "Parque Alves de Lima",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6676172,
        "longitude": -46.7296791,
        "localName": "Ipiranga",
        "link": "https://maps.app.goo.gl/S7nd22GBegZeBxjB9"
    },
    {
        "installation": 4001621062,
        "name": "Auto Posto Pacific I",
        "city": "São Paulo",
        "postalCode": "02180-040",
        "address": "R. Sd. Francisco Franco, 24",
        "area": "Parque Novo Mundo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.523046,
        "longitude": -46.5658596,
        "localName": "BR PETROBRAS",
        "link": "https://maps.app.goo.gl/b2fTseoc8TNXqDrm7"
    },
    {
        "installation": 4002195128,
        "name": "Autoposto Nsa I ",
        "city": "São Paulo",
        "postalCode": "02187-110",
        "address": "Av. Educador Paulo Freire",
        "area": "Parque Novo Mundo",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.519191,
        "longitude": -46.5610061,
        "localName": "Posto Singular",
        "link": "https://maps.app.goo.gl/2ygyg9z3aLufnAA36"
    },
    {
        "installation": 4002268127,
        "name": "Autoposto Tropical",
        "city": "São Paulo",
        "postalCode": "02187-210",
        "address": "Avenida Educador Paulo Freire sn",
        "area": "Parque Novo Mundo",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.5183,
        "longitude": -46.56087,
        "localName": "Sem marcador cadastrado",
        "link": "https://maps.app.goo.gl/cBEAooTJR1vYc1er5"
    },
    {
        "installation": 665827,
        "name": "Mundo Novo",
        "city": "São Paulo",
        "postalCode": "02170-000",
        "address": "Av. Morvan Dias de Figueiredo, 6779",
        "area": "Parque Novo Mundo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5336011,
        "longitude": -46.5890368,
        "localName": "Posto Br GNV",
        "link": "https://maps.app.goo.gl/wZMPtHm3ch9w2vcw5"
    },
    {
        "installation": 778234,
        "name": "Auto Posto Nossa Senhora Da Penha",
        "city": "São Paulo",
        "postalCode": "03637-010",
        "address": "Av. Amador Bueno da Veiga, 20",
        "area": "Penha de França",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.523339,
        "longitude": -46.5445105,
        "localName": "Auto Posto Nossa Senhora da Penha - Posto GNV",
        "link": "https://maps.app.goo.gl/JShq6caEhwUQZZtL9"
    },
    {
        "installation": 790683,
        "name": "Finlândia (Antigo Band Iii)",
        "city": "São Paulo",
        "postalCode": "04553-003",
        "address": "Av. dos Bandeirantes, 3686",
        "area": "Planalto Paulista",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6164382,
        "longitude": -46.6642986,
        "localName": "Posto Ipiranga Suecia",
        "link": "https://maps.app.goo.gl/wP1KiPWvomzPD2fB6"
    },
    {
        "installation": 561917,
        "name": "Auto Lider Bandeiras Ltda",
        "city": "São Paulo",
        "postalCode": "01142-300",
        "address": "Av. Pres. Castelo Branco, 3345",
        "area": "Pte. Pequena",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5199108,
        "longitude": -46.6279967,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/AzPACG9nBmtKtbQu8"
    },
    {
        "installation": 592076,
        "name": "Auto Posto Luz Da Radial",
        "city": "São Paulo",
        "postalCode": "03331-000",
        "address": "Av. Alvaro Ramos, 707",
        "area": "Quarta Parada",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5436291,
        "longitude": -46.5873564,
        "localName": "Av. Álvaro Ramos, 707 - Quarta Parada, São Paulo - SP, 03331-000",
        "link": "https://maps.app.goo.gl/jRRt321x5MWjrgm77"
    },
    {
        "installation": 713742,
        "name": "Auto Posto Br Sul",
        "city": "São Paulo",
        "postalCode": "04801-010",
        "address": "Av. Sen. Teotônio Vilela, 2631",
        "area": "Rio Bonito",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.7238973,
        "longitude": -46.698878,
        "localName": "Posto Orion com Gnv",
        "link": "https://maps.app.goo.gl/i2vS7QqK4dhuZ7QG6"
    },
    {
        "installation": 707488,
        "name": "Wgás Ii Rsb",
        "city": "São Paulo",
        "postalCode": "04801-000",
        "address": "Av. Sen. Teotônio Vilela, 2004",
        "area": "Rio Bonito",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.7188597,
        "longitude": -46.6991023,
        "localName": "W Gás RB Auto Ltda.",
        "link": "https://maps.app.goo.gl/HCMCnxQvdZ9i9KyB8"
    },
    {
        "installation": 552986,
        "name": "Santa Cecília Cia Ipiranga",
        "city": "São Paulo",
        "postalCode": "01225-030",
        "address": "R. Dr. Frederico Steidel, 58",
        "area": "Santa Cecilia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.54116,
        "longitude": -46.6472684,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/dzRoFVQkhDg8dPMR8"
    },
    {
        "installation": 480137,
        "name": "Auto Posto Aeromarte",
        "city": "São Paulo",
        "postalCode": "02012-090",
        "address": "R. Faustino Pereira Matias, Matias, 86",
        "area": "Santana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.509223,
        "longitude": -46.627508,
        "localName": "Rede 7 - Aero Marte",
        "link": "https://maps.app.goo.gl/HmoMW7WftKbRLfuw5"
    },
    {
        "installation": 759034,
        "name": "Arco Posto De Serviços Ltda",
        "city": "São Paulo",
        "postalCode": "04661-000",
        "address": "Av. Interlagos, 635",
        "area": "Santo Amaro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.659944,
        "longitude": -46.6794488,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/UgxWNfwuKEVKLscVA"
    },
    {
        "installation": 520241,
        "name": "Auto Posto Panamby",
        "city": "São Paulo",
        "postalCode": "05802-140",
        "address": "Av. Guido Caloi, 661",
        "area": "Santo Amaro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.650043,
        "longitude": -46.7266116,
        "localName": "Shell",
        "link": "https://maps.app.goo.gl/zh7DZTCfTMR3qSHm6"
    },
    {
        "installation": 488914,
        "name": "Glaussea",
        "city": "São Paulo",
        "postalCode": "04255-140",
        "address": "Rua Tamuatá, 20",
        "area": "São João Climaco",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6205418,
        "longitude": -46.5922525,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/T87q9WzQu1FFzYAM7"
    },
    {
        "installation": 500378,
        "name": "Auto Posto Cristais Do Tatuapé Ltda  ",
        "city": "São Paulo",
        "postalCode": "03065-000",
        "address": "R. Filipe Camarão, 461",
        "area": "Tatuapé",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5392538,
        "longitude": -46.5783883,
        "localName": "Auto Posto Cristais do Tatuapé (Shell)",
        "link": "https://maps.app.goo.gl/CoY9xwRxp6q9QGgV8"
    },
    {
        "installation": 778849,
        "name": "Auto Posto Ewamaro Ltda",
        "city": "São Paulo",
        "postalCode": "03087-010",
        "address": "R. Antônio Macedo, 660",
        "area": "Tatuapé",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5270406,
        "longitude": -46.5644393,
        "localName": "Auto Posto Ewamaro",
        "link": "https://maps.app.goo.gl/hsZt9uzAHSsAY8XaA"
    },
    {
        "installation": 4002202957,
        "name": "Auto Posto Parque Piqueri Ltda",
        "city": "São Paulo",
        "postalCode": "03077-005",
        "address": "Av. Condessa Elizabeth de Robiano",
        "area": "Tatuapé",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5269693,
        "longitude": -46.5765555,
        "localName": "Singular Postos de Gasolina",
        "link": "https://maps.app.goo.gl/w6gv3faNBdiDtrg97"
    },
    {
        "installation": 500846,
        "name": "Gold Star",
        "city": "São Paulo",
        "postalCode": "03080-000",
        "address": "R. Jacirendi, 850",
        "area": "Tatuapé",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5280884,
        "longitude": -46.5808801,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/xR4Grp18mwGVLN9a9"
    },
    {
        "installation": 743089,
        "name": "Piu Piu Auto Center Ltda.",
        "city": "São Paulo",
        "postalCode": "03401-001",
        "address": "R. Antônio de Barros, 2181",
        "area": "Tatuapé",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5490898,
        "longitude": -46.5548707,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/d5ux1GVBXW59fiySA"
    },
    {
        "installation": 1039643,
        "name": "Posto De Serviços Florida Ltda",
        "city": "São Paulo",
        "postalCode": "02305-003",
        "address": "Av. Nova Cantareira, 2315",
        "area": "Tucuruvi",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4772816,
        "longitude": -46.6107913,
        "localName": "Posto Serviço Flórida",
        "link": "https://maps.app.goo.gl/CcnMXKuMXdQTeLFA9"
    },
    {
        "installation": 694230,
        "name": "Auto Posto Arco Do Triunfo",
        "city": "São Paulo",
        "postalCode": "01139-040",
        "address": "Rua Doutor Moisés Kahan, 501",
        "area": "Varzea Da Barra Funda",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5193207,
        "longitude": -46.6738725,
        "localName": "R. Dr. Moisés Kahan, 501 - Barra Funda, São Paulo - SP, 01139-040",
        "link": "https://maps.app.goo.gl/7vbXJAGFpRduxv858"
    },
    {
        "installation": 649161,
        "name": "Walbrax 1 Auto Center",
        "city": "São Paulo",
        "postalCode": "08020-350",
        "address": "Av. Pires do Rio, 458",
        "area": "Vila Americana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5005435,
        "longitude": -46.4424151,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/aLaTfbHavcwqz6cH8"
    },
    {
        "installation": 640286,
        "name": "Auto Posto Rio Verde",
        "city": "São Paulo",
        "postalCode": "02968-000",
        "address": "Av. Fuad Lutfalla, 2062-2112",
        "area": "Vila Arcadia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.489531,
        "longitude": -46.7101592,
        "localName": "Auto Posto Rio Verde LTDA",
        "link": "https://maps.app.goo.gl/rWTRzbfWmWprc5m29"
    },
    {
        "installation": 887757,
        "name": "Auto Posto Bertioga Ltda",
        "city": "São Paulo",
        "postalCode": "03181-100",
        "address": "Rua Do Acre, 54",
        "area": "Vila Bertioga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5607603,
        "longitude": -46.5848153,
        "localName": "AUTO POSTO BERTIOGA LTDA - Posto Petrobras - MOOCA",
        "link": "https://maps.app.goo.gl/6JSpmL9Mqz9QSrKc8"
    },
    {
        "installation": 574457,
        "name": "Start Auto Posto",
        "city": "São Paulo",
        "postalCode": "03216-040",
        "address": "Av. do Estado, 2125",
        "area": "Vila California",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.606454,
        "longitude": -46.5514646,
        "localName": "Shell",
        "link": "https://maps.app.goo.gl/4bHdznUCuyzFtRBz7"
    },
    {
        "installation": 518100,
        "name": "Coimbra",
        "city": "São Paulo",
        "postalCode": "03640-010",
        "address": "Av. Dr. Orêncio Vidigal, 340",
        "area": "Vila Carlos de Campos",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5313586,
        "longitude": -46.5404045,
        "localName": "Posto Shell",
        "link": "https://maps.app.goo.gl/DVvMWRrvAMHyQ1ZA9"
    },
    {
        "installation": 656603,
        "name": "Super Posto Flor Do Carrão",
        "city": "São Paulo",
        "postalCode": "03403-002",
        "address": "Av. Conselheiro Carrão, 2673",
        "area": "Vila Carrão",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5493696,
        "longitude": -46.535702,
        "localName": "Posto Ale + GNV",
        "link": "https://maps.app.goo.gl/ynDmYyMjgaScXFb4A"
    },
    {
        "installation": 636458,
        "name": "Auto Posto Edu Ltda",
        "city": "São Paulo",
        "postalCode": "02260-000",
        "address": "Av. Luís Stamatis, 240",
        "area": "Vila Constança",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4679719,
        "longitude": -46.5831201,
        "localName": "Ipiranga",
        "link": "https://maps.app.goo.gl/rSMXeNjzQwoWRJb48"
    },
    {
        "installation": 671663,
        "name": "Com. Varejista De Combustíveis Veloz - (Antigo  Servacar)",
        "city": "São Paulo",
        "postalCode": "03295-100",
        "address": "Av. Professor Luiz Ignácio Anhaia Mello, 6143",
        "area": "Vila Ema",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5915288,
        "longitude": -46.5417983,
        "localName": "Rede 7 - Veloz",
        "link": "https://maps.app.goo.gl/2QoarogeYnKWSyik8"
    },
    {
        "installation": 774750,
        "name": "Chow Gas Auto Posto Ltda.",
        "city": "São Paulo",
        "postalCode": "04474-180",
        "address": "R. Prof. Cardozo de Melo Neto, 1516",
        "area": "Vila Guacuri",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6971387,
        "longitude": -46.6330677,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/WVVe2U7sEN674zcv7"
    },
    {
        "installation": 536432,
        "name": "Apolo 8 ",
        "city": "São Paulo",
        "postalCode": "02063-000",
        "address": "Av. Morvan Dias de Figueiredo, 2605",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5223041,
        "longitude": -46.6093567,
        "localName": "Posto Serviços Apolo 8",
        "link": "https://maps.app.goo.gl/yFax6dUGDtwWDwam8"
    },
    {
        "installation": 4000157687,
        "name": "Argetax Chico Pontes",
        "city": "São Paulo",
        "postalCode": "02067-002",
        "address": "R. Chico Pontes, 1292",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5089785,
        "longitude": -46.6002919,
        "localName": "Agtax",
        "link": "https://maps.app.goo.gl/Cc4N9MYheKcEPdtr6"
    },
    {
        "installation": 4002110654,
        "name": "Auto Posto J.E Ltda",
        "city": "São Paulo",
        "postalCode": "02065-000",
        "address": "Av. Joaquina Ramalho, 151",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.515259,
        "longitude": -46.6081319,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/i6uUa2wdMYygxQT68"
    },
    {
        "installation": 630926,
        "name": "Rodovias",
        "city": "São Paulo",
        "postalCode": "02063-000",
        "address": "Av. Morvan Dias de Figueiredo, 2605",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.522127,
        "longitude": -46.6090158,
        "localName": "Posto Serviços Apolo 8",
        "link": "https://maps.app.goo.gl/aBFSBzHyLnpxD82T6"
    },
    {
        "installation": 491864,
        "name": "Jurema  Sm Espaço Automotive",
        "city": "São Paulo",
        "postalCode": "03827-001",
        "address": "Av. Dr. Assis Ribeiro, 8900",
        "area": "Vila Jacuí",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4846845,
        "longitude": -46.4768095,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/LqxwVneHa4MaaswK8"
    },
    {
        "installation": 491958,
        "name": "Auto Posto São Francisco Ltda      ",
        "city": "São Paulo",
        "postalCode": "05340-002",
        "address": "Av. Corifeu de Azevedo Marques, 5842",
        "area": "Vila Lageado",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5528518,
        "longitude": -46.7565656,
        "localName": "AUTO POSTO SÃO FRANCISCO",
        "link": "https://maps.app.goo.gl/iDMCgsn94pJSDyM59"
    },
    {
        "installation": 4002110651,
        "name": "Posto Serviços Automotivos Galapagos Eireli",
        "city": "São Paulo",
        "postalCode": "05319-075",
        "address": "Vila Leopoldina",
        "area": "Vila Leopoldina",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5411257,
        "longitude": -46.7301233,
        "localName": "Posto Galapagos",
        "link": "https://maps.app.goo.gl/vs2ZeU74ENTmJdjB6"
    },
    {
        "installation": 547361,
        "name": "Confiança Cia Ipiranga",
        "city": "São Paulo",
        "postalCode": "02170-000",
        "address": "Av. Morvan Dias de Figueiredo, 7035",
        "area": "Vila Maria",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5233611,
        "longitude": -46.5666077,
        "localName": "Posto Confiança",
        "link": "https://maps.app.goo.gl/U4JjVVahe8AV6Adm9"
    },
    {
        "installation": 4000178043,
        "name": "Posto Sena Park",
        "city": "São Paulo",
        "postalCode": "04019-000",
        "address": "Av. Sena Madureira, 1.490",
        "area": "Vila Mariana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5924086,
        "longitude": -46.6479811,
        "localName": "Posto Shell + GNV | Sena Park ( 24 Horas )",
        "link": "https://maps.app.goo.gl/F7kemJfQedymPuDy5"
    },
    {
        "installation": 382451,
        "name": "Tutóia",
        "city": "São Paulo",
        "postalCode": "04012-080",
        "address": "Rua Doutor Amâncio de Carvalho, 26 PARAISO",
        "area": "Vila Mariana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.582001,
        "longitude": -46.6490015,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/JKd1jhftK9T8cZSg6"
    },
    {
        "installation": 4001805950,
        "name": "Auto Posto Start Aricanduva",
        "city": "São Paulo",
        "postalCode": "03444-000",
        "address": "R. Olímpio Brás de Sousa, 180",
        "area": "Vila Matilde",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5442692,
        "longitude": -46.529871,
        "localName": "Branca",
        "link": "https://maps.app.goo.gl/3hHEfA2MyfMGsmhA9"
    },
    {
        "installation": 481392,
        "name": "Argetax Anchieta",
        "city": "São Paulo",
        "postalCode": "04246-001",
        "address": "Rod. Anchieta, 1368",
        "area": "Vila Moinho Velho",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6139048,
        "longitude": -46.6011487,
        "localName": "ARGETAX ANCHIETA",
        "link": "https://maps.app.goo.gl/SgfQvLeBCrRjDjoM7"
    },
    {
        "installation": 500388,
        "name": "Auto Posto 710",
        "city": "São Paulo",
        "postalCode": "04537-001",
        "address": "R. Clodomiro Amazonas, 710",
        "area": "Vila Olímpia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5903941,
        "longitude": -46.6784002,
        "localName": "Posto Ipiranga GNV",
        "link": "https://maps.app.goo.gl/zrXkBWDXJPFz3vDX8"
    },
    {
        "installation": 518133,
        "name": "Funchal",
        "city": "São Paulo",
        "postalCode": "04551-060",
        "address": "R. Funchal, 554",
        "area": "Vila Olímpia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5954904,
        "longitude": -46.6907558,
        "localName": "Funchal Auto Posto",
        "link": "https://maps.app.goo.gl/9VXzN9XfoZoCwt1E6"
    },
    {
        "installation": 516191,
        "name": "Posto Wellcome",
        "city": "São Paulo",
        "postalCode": "05731-250",
        "address": "Av. Das Belezas,513",
        "area": "Vila Prel",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6419444,
        "longitude": -46.7425,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/gKcGHqBfu8kDwYW68"
    },
    {
        "installation": 546261,
        "name": "Portal Do Ipiranga-Alesat Combustíveis - Duque Estadão ",
        "city": "São Paulo",
        "postalCode": "04207-040",
        "address": "R. Barão de Resende, 66",
        "area": "Vila Prudente",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5833069,
        "longitude": -46.59922,
        "localName": "Posto BR GNV Rede Duque",
        "link": "https://maps.app.goo.gl/RztkhPcw4AnC7r2c8"
    },
    {
        "installation": 644587,
        "name": "Auto Posto Imbo",
        "city": "São Paulo",
        "postalCode": "03342-000",
        "address": "Av. Reg. Feijó, 752",
        "area": "Vila Regente Feijo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5580154,
        "longitude": -46.5712838,
        "localName": "Posto ALE + GNV | IMBO",
        "link": "https://maps.app.goo.gl/sDNRHjgZXjZxW3CK7"
    },
    {
        "installation": 813818,
        "name": "Posto De Servicos Advantgarde Ltda",
        "city": "São Paulo",
        "postalCode": "02413-000",
        "address": "Av. Eng. Caetano Álvares, 4605",
        "area": "Vila Romero",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.486666,
        "longitude": -46.6440874,
        "localName": "Posto Shell rede papa",
        "link": "https://maps.app.goo.gl/BazKHfeHiZQ7nagm7"
    },
    {
        "installation": 503086,
        "name": "Posto De Serviços Robson Eirelli (Antigo Posto Falke  Guaximim) ",
        "city": "São Paulo",
        "postalCode": "08041-000",
        "address": "Av. Pires do Rio, 1784",
        "area": "Vila Vessoni",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5064121,
        "longitude": -46.4431046,
        "localName": "Posto Serviços Falke",
        "link": "https://maps.app.goo.gl/KXrUNrngPC6ySigq6"
    },
    {
        "installation": 493176,
        "name": "Lagos Andinos Cia Ipiranga",
        "city": "São Paulo",
        "postalCode": "04301-002",
        "address": "04301-002, Av. Miguel Estefano, 2315-2349",
        "area": "Vila da Saúde",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6333276,
        "longitude": -46.6274074,
        "localName": "Posto GNV",
        "link": "https://maps.app.goo.gl/gUNDYyfc8kjmVS298"
    },
    {
        "installation": 479466,
        "name": "Ml20 Imigrantes - Antigo Gavião Da Imigrantes",
        "city": "São Paulo",
        "postalCode": "04301-001",
        "address": "Av. Miguel Estefano, 1663",
        "area": "Vila da Saúde",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6248519,
        "longitude": -46.6291164,
        "localName": "Posto Ipiranga",
        "link": "https://maps.app.goo.gl/9UCBwPJKpFdTbS1B7"
    },
    {
        "installation": 487835,
        "name": "Posto De Serviços Popular",
        "city": "São Paulo",
        "postalCode": "04046-300",
        "address": "Av. Jabaquara, 1966",
        "area": "Vila da Saúde",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6215704,
        "longitude": -46.6402782,
        "localName": "Posto Petrobras",
        "link": "https://maps.app.goo.gl/rt8vD9Fu2Ws5AuhT7"
    },
    {
        "installation": 719414,
        "name": "Posto de Serviço Maracanã",
        "city": "São Paulo",
        "postalCode": "05835-003",
        "address": "Estr. de Itapecerica, 1397",
        "area": "Vila das Belezas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6475566,
        "longitude": -46.7471361,
        "localName": "Posto de Serviço Maracanã",
        "link": "https://maps.app.goo.gl/4p8UBDURSKR3BUQu8"
    },
    {
        "installation": 546485,
        "name": "West Park",
        "city": "São Paulo",
        "postalCode": "05037-000",
        "address": "Rua Francisco Luiz de Souza Júnior, 145",
        "area": "Água Branca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5151794,
        "longitude": -46.6886744,
        "localName": "Westpark Auto Posto",
        "link": "https://maps.app.goo.gl/yLgK1rC23oQpbZN99"
    },
    {
        "installation": 481390,
        "name": "Auto Posto Oiti",
        "city": "São Paulo",
        "postalCode": "03342-000",
        "address": "Av. Reg. Feijó, 1188",
        "area": "Água Rasa",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5596218,
        "longitude": -46.5674901,
        "localName": "Auto Posto Oiti",
        "link": "https://maps.app.goo.gl/iGaCzntnjG1YZ2e96"
    },
    {
        "installation": 707771,
        "name": "Auto Posto Tubarão - Antigo Rota 116 ",
        "city": "Taboao Da Serra",
        "postalCode": "06750-000",
        "address": "16, Rod. Régis Bittencourt, 1530 - 5",
        "area": "Jardim Tres Marias",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6078051,
        "longitude": -46.7679429,
        "localName": "BR GNV - AUTO POSTO ROTA 116",
        "link": "https://maps.app.goo.gl/f6bZmDWXgLm228Uz9"
    },
    {
        "installation": 696581,
        "name": "Auto Posto Dutra Sul Ltda",
        "city": "Taubate",
        "postalCode": "12030-810",
        "address": "R. Mal. Artur da Costa e Silva, 2253",
        "area": "Centro",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.0421455,
        "longitude": -45.5747669,
        "localName": "Posto RodOil - Taubaté",
        "link": "https://maps.app.goo.gl/v6rkbrwH78fLKdjX8"
    },
    {
        "installation": 546480,
        "name": "Sete Estrelas Com. E Der. De Petr.  Bandeirantes - Filial 10",
        "city": "Taubate",
        "postalCode": "12061-590",
        "address": "Av. Bandeirantes, 5236",
        "area": "Independência",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.043661,
        "longitude": -45.5816887,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/8gbvMy3xubxzDXUQ8"
    },
    {
        "installation": 553779,
        "name": "Rede De Postos Sete Estrelas  Taubaté - Filail 07",
        "city": "Taubate",
        "postalCode": "12090-600",
        "address": "Av. Charles Schnneider, 1775",
        "area": "Parque Sr. do Bonfim",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0253115,
        "longitude": -45.5831264,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/MMTG8jGgcXDWzu4a7"
    },
    {
        "installation": 545502,
        "name": "Centro Automotivo Sete Estrelas Ltda – Posto 67 ( Posto De Gás Natural Taubaté)",
        "city": "Taubate",
        "postalCode": "12091-000",
        "address": "Av. Dom Pedro I, 5391",
        "area": "São Gonçalo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0447079,
        "longitude": -45.5814977,
        "localName": "Sete Estrelas",
        "link": "https://maps.app.goo.gl/LCEfoysMHVio5s6U9"
    },
    {
        "installation": 647018,
        "name": "Super Tof",
        "city": "Valinhos",
        "postalCode": "13270-210",
        "address": "Av. dos Esportes, 1092",
        "area": "Jardim Primavera",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9769558,
        "longitude": -47.0002563,
        "localName": "Branca",
        "link": "https://maps.app.goo.gl/V1FE2BsN8dtCBpAu9"
    },
    {
        "installation": 808051,
        "name": "Auto Posto Saci Ltda.",
        "city": "Valinhos",
        "postalCode": "13273-609",
        "address": "LOC, SP-330, SN - KM 84",
        "area": "Macuco",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.9973389,
        "longitude": -47.0342136,
        "localName": "Saci",
        "link": "https://maps.app.goo.gl/zjtQgf7DT6JZ1DkRA"
    },
    {
        "installation": 630919,
        "name": "Ferraz E Ferraz",
        "city": "Valinhos",
        "postalCode": "13277-200",
        "address": "R. Dr. Marino Costa Terra, 2451",
        "area": "Vila Pagano",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9903972,
        "longitude": -47.0038491,
        "localName": "Posto Ferraz & Ferraz",
        "link": "https://maps.app.goo.gl/XHhJiuu45dAeQs4D6"
    }
]