import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { HeaderAnalytics } from '@models/analytics';
import { ContentPagesModalService } from '@shared/services';

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  Observable,
  startWith,
  tap,
} from 'rxjs';
import {
  ISearchService,
  SearchService,
} from '@shared/services/search/search.service';

@Component({
  selector: 'qsc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    TranslateModule,
    MatOptionModule,
    AsyncPipe,
    MatAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
})
export class SearchComponent implements OnInit, AfterViewInit {
  @Input() control = new FormControl('');
  @Input() options: ISearchService[] = [];
  @Input() id = '';
  @Input() name = '';
  @Input() placeholder = '';

  @Output() selectionChangeEvent = new EventEmitter();

  @ViewChild('input')
  public input: ElementRef | undefined;

  @ViewChild(MatAutocompleteTrigger)
  autoCompleteTrigger: MatAutocompleteTrigger | undefined;

  public filteredOptions?: Observable<ISearchService[]>;
  notFound = false;
  bsModalContentPages?: BsModalRef;

  constructor(
    private readonly contentPagesModalService: ContentPagesModalService,
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly searchService: SearchService
  ) {}

  ngAfterViewInit() {
    const enter = 13;
    const backspace = 8;

    fromEvent(this.input?.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        distinctUntilChanged(),
        tap(() => {
          this.onInput();
        })
      )
      .subscribe();

    fromEvent(this.input?.nativeElement, 'keyup')
      .pipe(
        filter(
          (e: any) =>
            e.keyCode !== enter &&
            e.keyCode !== backspace &&
            this.input?.nativeElement.value.length > 3
        ),
        debounceTime(2500),
        distinctUntilChanged(),
        tap(() => {
          this.onInputMoreThanThreeCharacters();
        })
      )
      .subscribe();

    fromEvent(this.input?.nativeElement, 'keyup')
      .pipe(
        filter((e: any) => e.keyCode === enter),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.onEnterPressed();
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.searchService.initializeFuse(this.options);
    this.initialize();
  }

  initialize(): void {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value ?? ''))
    );
  }

  onInput(): void {
    const value = this.input?.nativeElement.value;
    if (!value || value.length <= 0) {
      this.notFound = false;
      this.autoCompleteTrigger?.closePanel();
    }
    if (typeof value === 'string' && value.length > 0) {
      const contains = this.searchService.search(value);
      if (contains.length > 0) {
        this.notFound = false;
        return;
      }
      this.notFound = true;
    }
  }

  onEnterPressed(): void {
    if (!this.notFound) return;
    this.sendGtmEventOnNotFound(this.input?.nativeElement.value);
  }

  onInputMoreThanThreeCharacters(): void {
    const element = this.input?.nativeElement;
    const value = element.value;

    if (typeof value === 'string') {
      const contains = this.searchService.search(value);
      if (contains.length > 0) {
        return;
      }
      this.sendGtmEventOnNotFound(value);
    }
  }

  private _filter(value: string): ISearchService[] {
    if (!value) return [];
    const results = this.searchService.search(value);
    return results.map((result) => result.item);
  }

  onSelectionChange(option: ISearchService): void {
    this.selectionChangeEvent.emit(option);
    this.control.setValue(null);
    this.initialize();
    this.sendGtmEventOnSelect(option.names[0]);
  }

  onNavigate(): void {
    this.autoCompleteTrigger?.closePanel();
    this.control.setValue(null);
    this.notFound = false;
    this.sendGtmEventOnNavigate();
    this.contentPagesModalService.openContentPagesModal();
  }

  highlightMatchingText(result: string): string {
    const searchTerm = this.input?.nativeElement.value;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return result.replace(regex, '<b>$1</b>');
  }

  sendGtmEventOnSelect(selectedOption: string): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.busca,
      HeaderAnalytics.action.buscaValida,
      selectedOption
    );
  }

  sendGtmEventOnNotFound(searchTerm: string): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.busca,
      HeaderAnalytics.action.buscaInvalida,
      searchTerm
    );
  }

  sendGtmEventOnNavigate(): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.busca,
      HeaderAnalytics.action.modalBusca,
      HeaderAnalytics.label.abrir
    );
  }
}
