export const removeBlankAttributes = (object: object): object => {
  const removeEmptyOrNull = (obj: object) => {
    Object.keys(obj).forEach(
      (k) =>
        (obj[k] && typeof obj[k] === 'object' && removeEmptyOrNull(obj[k])) ||
        (!obj[k] && obj[k] !== undefined && delete obj[k])
    );
    return obj;
  };
  object = removeEmptyOrNull(object);
  return object;
};
