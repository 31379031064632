import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, map, shareReplay } from 'rxjs';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { getUrlWithoutParams } from '@shared/utils';

export interface IBreadcrumbItem {
  label: string;
  url: string;
  status?: 'previous' | 'current' | 'next';
}

interface IConfig {
  storageKey: string;
  urlsAsTriggerToReset?: string[];
  initialBreadcrumbs: IBreadcrumbItem[];
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbSwiperService {
  private readonly displayedBreadcrumbsSubject = new BehaviorSubject<
    IBreadcrumbItem[]
  >([]);

  private storageKey = 'flow_breadcrumbs';
  private urlsAsTriggerToReset?: string[];
  private initialBreadcrumbs: IBreadcrumbItem[] = [];
  private breadcrumbs: IBreadcrumbItem[] = [];

  public readonly displayedBreadcrumbs$ = this.displayedBreadcrumbsSubject
    .asObservable()
    .pipe(shareReplay(1));

  constructor(
    private readonly router: Router,
    private readonly sessionStorageService: SessionStorageService
  ) {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects)
      )
      .subscribe((url) => this.updateDisplayedBreadcrumbs(url));
  }

  setConfig(config: IConfig): void {
    this.storageKey = config.storageKey;
    this.urlsAsTriggerToReset = config.urlsAsTriggerToReset;
    this.initialBreadcrumbs = config.initialBreadcrumbs;
    this.breadcrumbs = config.initialBreadcrumbs;
  }

  navigateByUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  updateBreadcrumbs(breadcrumbs: IBreadcrumbItem[]): void {
    this.breadcrumbs = breadcrumbs;
    this.updateDisplayedBreadcrumbs(this.router.url);
  }

  getCurrentBreadcrumbItem(): { index: number; item: IBreadcrumbItem } {
    const index = this.breadcrumbs.findIndex((b) => b.url === this.router.url);
    return {
      index,
      item: this.breadcrumbs[index],
    };
  }

  private updateDisplayedBreadcrumbs(url: string): void {
    const urlWhithoutParams = getUrlWithoutParams(url);

    if (this.urlsAsTriggerToReset?.includes(urlWhithoutParams)) {
      this.breadcrumbs = [...this.initialBreadcrumbs];
      this.saveBreadcrumbsInSession(this.breadcrumbs);
    } else {
      const storedBreadcrumbs = this.getBreadcrumbsFromSession();
      if (storedBreadcrumbs.length > 0) {
        this.breadcrumbs = [...storedBreadcrumbs, ...this.breadcrumbs];
      }
    }

    const currentIndex = this.breadcrumbs.findIndex(
      (breadcrumb) => getUrlWithoutParams(breadcrumb.url) === urlWhithoutParams
    );

    this.breadcrumbs = this.breadcrumbs.map((breadcrumb, index) => {
      if (index < currentIndex) {
        breadcrumb.status = 'previous';
      } else if (index === currentIndex) {
        breadcrumb.status = 'current';
      } else {
        breadcrumb.status = 'next';
      }
      return breadcrumb;
    });

    this.breadcrumbs = this.breadcrumbs.filter(
      (breadcrumb, index, self) =>
        index ===
        self.findIndex(
          (b) =>
            getUrlWithoutParams(b.url) === getUrlWithoutParams(breadcrumb.url)
        )
    );

    const urlParts = url.split('?');
    this.breadcrumbs = this.breadcrumbs.map((breadcrumb) => {
      if (urlParts.length > 1) {
        const urlParams = new URLSearchParams(urlParts[1]);
        breadcrumb.url = `${
          breadcrumb.url.split('?')[0]
        }?${urlParams.toString()}`;
      } else {
        breadcrumb.url = breadcrumb.url.split('?')[0];
      }
      return breadcrumb;
    });

    const lastIndex = this.breadcrumbs.length - 1;
    const shouldReset =
      this.breadcrumbs.length > this.initialBreadcrumbs.length &&
      currentIndex === lastIndex;

    if (currentIndex === -1 || shouldReset) {
      this.displayedBreadcrumbsSubject.next([]);
    } else {
      this.displayedBreadcrumbsSubject.next(this.breadcrumbs);
    }

    this.saveBreadcrumbsInSession(this.breadcrumbs);
  }

  private getBreadcrumbsFromSession(): IBreadcrumbItem[] {
    return (
      this.sessionStorageService.getData<IBreadcrumbItem[]>(this.storageKey) ||
      []
    );
  }

  private saveBreadcrumbsInSession(breadcrumbs: IBreadcrumbItem[]): void {
    this.sessionStorageService.saveData<IBreadcrumbItem[]>(
      this.storageKey,
      breadcrumbs
    );
  }
}
