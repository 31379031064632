import { createAction, props } from '@ngrx/store';
import { IUser } from './user.reducer';
import { ScheduleFeedbackStatusEnum } from '@modules/scheduling/models/schedule-feedback';
import { ManageScheduleStatusEnum } from '@modules/scheduling/models/manage-schedule';
import { ScheduleFlowEnum } from '@modules/scheduling/models/schedule';
import { ScheduleContactConfirmStatusEnum } from '@modules/scheduling/models/schedule-contact-confirm';

// User Actions

export const addUser = createAction(
  '[User] Add User',
  props<{ user: IUser }>()
);

export const updateUser = createAction(
  '[User] Update User',
  props<{ user: IUser }>()
);

export const deleteUser = createAction('[User] Delete User');

export const userError = createAction(
  '[User] User Error',
  props<{ error: any }>()
);

export const setProtocolRecoverySuccess = createAction(
  '[User] Set Protocol Recovery Success',
  props<{
    status?: boolean;
    email?: string;
  }>()
);

// Scheduling Actions

export const updateScheduling = createAction(
  '[User] Update Scheduling',
  props<{ scheduling: Partial<IUser['scheduling']> }>()
);

export const resetScheduling = createAction('[User] Reset Scheduling');

export const setSchedulingDateOrTime = createAction(
  '[User] Set Scheduling Date or Time',
  props<{
    key: 'selectedDate' | 'formattedDate' | 'selectedTime' | 'formattedTime';
    value: string | null;
  }>()
);

export const setSchedulingFlowStatus = createAction(
  '[User] Set Scheduling Flow Status',
  props<{ isInScheduleFlow: boolean }>()
);

export const setInPersonScheduleStatus = createAction(
  '[User] Set In-Person Schedule Status',
  props<{ hasInPerson: boolean }>()
);

export const setOnlineScheduleStatus = createAction(
  '[User] Set Online Schedule Status',
  props<{ hasOnline: boolean }>()
);

export const setCanScheduleFlowStatus = createAction(
  '[User] Set Can Schedule Flow',
  props<{ canSchedule: { status: ScheduleFlowEnum; error?: any } }>()
);

export const setCanManageScheduleStatus = createAction(
  '[User] Set Can Manage Schedule Status',
  props<{
    canManageSchedule: {
      status: ManageScheduleStatusEnum;
      error?: any;
    };
  }>()
);

export const setCanScheduleContactConfirm = createAction(
  '[User] Set Can Schedule Contact Confirm Status',
  props<{
    canScheduleContactConfirm: {
      status: ScheduleContactConfirmStatusEnum;
      error?: any;
    };
  }>()
);

export const setCanRescheduleStatus = createAction(
  '[User] Set Can Reschedule Status',
  props<{ canReschedule: boolean }>()
);

export const setCanScheduleFeedbackStatus = createAction(
  '[User] Set Can Schedule Feedback Status',
  props<{
    canScheduleFeedback: { status: ScheduleFeedbackStatusEnum; error?: any };
  }>()
);

export const setSkipScheduling = createAction(
  '[User] Set Skip Scheduling',
  props<{ skipScheduling?: boolean }>()
);

export const setReuseProtocol = createAction(
  '[User] Set Reuse Protocol',
  props<{ reuseProtocol?: boolean }>()
);

export const setUseOtherComplement = createAction(
  '[User] Set Use Other Complement',
  props<{ useOtherComplement?: boolean }>()
);
