<button
  [disabled]="disabled"
  [type]="buttonType"
  (click)="click($event)"
  [ngClass]="['button', getVariant()]"
>
  <div class="button__content">
    <div class="button__icon">
      <qsc-svg-icon
        class="left m-1 align-middle"
        *ngIf="icon && iconBefore"
        width="1.8rem"
        height="1.8rem"
        [icon]="icon"
      ></qsc-svg-icon>
    </div>
    <div class="button__icon" *ngIf="iconClass.length && iconBefore">
      <i aria-hidden="true" [class]="'left ' + getIconClass('before')"></i>
    </div>
    <div class="button__text">
      <span [innerHTML]="buttonText | translate"> </span>
    </div>
    <div class="button__icon" *ngIf="iconClass.length && iconAfter">
      <i aria-hidden="true" [class]="'right ' + getIconClass('after')"></i>
    </div>
  </div>
</button>
