import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { sleep } from '@shared/utils/sleep';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor(
    private readonly windowRef: WindowRefService,
    private cookieService: CookieService
  ) {}

  async applyConfig(): Promise<void> {
    let attempts = 10;
    const selector = '#onetrust-consent-sdk';
    let containerEl = this.getContainer(selector);
    while (!containerEl && attempts > 0) {
      await sleep(100);
      attempts--;
      containerEl = this.getContainer(selector);
    }

    if (!containerEl) {
      return;
    }

    const cookieBtn = containerEl.querySelector('#ot-sdk-btn-floating');
    if (cookieBtn) {
      cookieBtn.classList.add('d-none');
    }

    const banner = containerEl.querySelector('#onetrust-banner-sdk');
    if (banner) {
      banner.classList.add('cookies');
    }
  }

  private getContainer(selector: string): HTMLElement | undefined {
    return this.windowRef.nativeWindow.document.querySelector(
      selector
    ) as HTMLElement;
  }

  public openConfiguration(): void {
    const containerEl = this.getContainer('#onetrust-consent-sdk');
    const configurationBtn = containerEl?.querySelector(
      '.ot-floating-button__open'
    ) as HTMLElement;

    if (configurationBtn) {
      configurationBtn.click();
    }
  }

  public getCookie(name: string): any {
    if (!this.checkIfCookieExists(name)) return null;
    return this.cookieService.get(name);
  }

  private checkIfCookieExists(name: string): boolean {
    return this.cookieService.check(name);
  }
}
