import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterEventsService {
  constructor(private router: Router) {}

  getEvents(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter((evt: any) => evt instanceof NavigationEnd)
    );
  }
}
