<svg
  [style.width]="size ? size + 'px' : width || '1.5rem'"
  [style.height]="size ? size + 'px' : height || '1.5rem'"
  [class]="class"
>
  <title [id]="title.toLowerCase()">{{ title }}</title>
  <use
    [attr.xlink:href]="svgSpritePath | fileHash"
    [attr.href]="svgSpritePath | fileHash"
  ></use>
</svg>
