/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorHandler, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private readonly errorSubject = new BehaviorSubject<Error | null>(null);
  public error$: Observable<Error | null> = this.errorSubject.asObservable();

  handleError(error: any): void {
    console.error(error);
  }

  setError(error: Error): void {
    this.errorSubject.next(error);
  }

  clearError(): void {
    this.errorSubject.next(null);
  }
}
