<div class="comparative-table">
  <div class="table-responsive">
    <table aria-describedby="comparative-table" class="table align-middle">
      <thead>
        <tr>
          <th scope="col"></th>
          @for (column of columns; track $index) {
          <th
            scope="col"
            class="col-title text-center"
            [class.th-highlight]="$index === 0"
          >
            {{ column.label | translate }}
          </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (row of rows; track $index) {
        <tr>
          <th scope="row" class="row-title">{{ row.title | translate }}</th>

          @for (td of row.comparative; track $index) {
          <td class="text-center" [class.td-highlight]="$index === 0">
            @if(td.checked) {
            <i
              aria-hidden="true"
              class="icon-check_bola_preenchido cell-icon"
            ></i>
            } @else {
            <i aria-hidden="true" class="icon-erro_fechar cell-icon"></i>
            }
          </td>
          }
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>
