import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Fuse, { FuseResult, IFuseOptions } from 'fuse.js';
import { LoadingService } from '../loading/loading.service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { environment } from '@env';

export enum SearchServiceEnum {
  QueroComgas = 'Quero Comgás',
  Virtual = 'Virtual',
  Solucoes = 'Solucoes',
  RedesSociais = 'Redes sociais',
}

export interface ISearchService {
  names: string[];
  url: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private fuse: Fuse<ISearchService> = new Fuse([]);
  private defaultFuseOptions: IFuseOptions<ISearchService> = {
    keys: ['names'],
    includeScore: true,
    threshold: 0.3,
  };
  private options: ISearchService[] = [];

  constructor(
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly windowRef: WindowRefService
  ) {}

  initializeFuse(
    options: ISearchService[],
    fuseOptions: IFuseOptions<ISearchService> = this.defaultFuseOptions
  ): void {
    this.fuse = new Fuse(options, fuseOptions);
    this.options = options;
  }

  search(term: string): FuseResult<ISearchService>[] {
    const normalizedTerm = this.normalizeText(term.toLowerCase());

    const words = normalizedTerm
      .split(' ')
      .filter(
        (word) =>
          word &&
          !['gas', 'como', 'para', 'meu', 'que', 'com'].includes(word) &&
          word.length > 2
      );

    const fuseResults = this.fuse.search(normalizedTerm);

    const fuseItems = new Set(fuseResults.map((result) => result.item));

    const containsResults = this.options
      .filter((item) => {
        const names = item.names.map((name) =>
          this.normalizeText(name.toLowerCase())
        );

        return words.some(
          (word) =>
            names.some((name) => name.includes(word)) && !fuseItems.has(item)
        );
      })
      .map((item) => ({ item } as FuseResult<ISearchService>));

    return [...fuseResults, ...containsResults];
  }

  private normalizeText(text: string): string {
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9\s]/g, '');
  }

  navigate(service: ISearchService): void {
    switch (service.type) {
      case SearchServiceEnum.QueroComgas:
        this.loadingService.showLoading('@LOADING-TEXT-PLEASE-WAIT');
        setTimeout(() => {
          const finalUrl = this.constructUrl(service.url);
          this.router
            .navigateByUrl(finalUrl, { skipLocationChange: true })
            .then(() => {
              this.loadingService.hideLoading();
            });
        }, 500);
        break;
      case SearchServiceEnum.Virtual:
        this.windowRef.nativeWindow.open(
          environment.endpoints.comgasVirtual + service.url,
          '_blank'
        );
        break;
      case SearchServiceEnum.Solucoes:
        this.windowRef.nativeWindow.open(
          environment.endpoints.solucoes + service.url,
          '_blank'
        );
        break;
      default:
        this.windowRef.nativeWindow.open(service.url, '_blank');
    }
  }

  private constructUrl(serviceUrl: string): string {
    let baseUrl = serviceUrl.split('?')[0];
    const urlParams = serviceUrl.split('?')[1];
    const currentRouteBaseUrl = this.router.url.split('?')[0];
    if (!baseUrl) {
      baseUrl = currentRouteBaseUrl;
    }
    if (baseUrl === currentRouteBaseUrl && urlParams) {
      const queryParamsString = '?' + urlParams;
      return baseUrl + queryParamsString;
    }
    return baseUrl;
  }
}
