<div class="audio-reader">
  <p class="audio-reader__title">{{ "@READING-TIME-LABEL" | translate }}</p>

  <button
    class="audio-reader__button"
    *ngIf="!isPlaying"
    [qscEventTrackerCategory]="gaEventCategory ?? ''"
    [qscEventTrackerAction]="blogAnalytics.action.acessibilidade"
    [qscEventTrackerLabel]="blogAnalytics.label.audioDinamico"
    (click)="readContent()"
    title="Iniciar leitura"
  >
    <div class="audio-reader__play">
      <img
        [src]="'assets/img/svg/icon-play.svg' | fileHash"
        alt="play"
        title="Iniciar leitura"
        loading="lazy"
      />
      <span>
        {{ "@READING-START" | translate }}
      </span>
    </div>
  </button>

  <div class="d-flex gap-2 m-0">
    <button
      class="audio-reader__button"
      *ngIf="isPlaying && !isPaused"
      (click)="pauseReading()"
      title="Pausar leitura"
    >
      <div class="audio-reader__pause">
        <img
          [src]="'assets/img/svg/icon-pause.svg' | fileHash"
          alt="pause"
          title="Pausar leitura"
          loading="lazy"
        />
        <span>
          {{ "@READING-PAUSE" | translate }}
        </span>
      </div>
    </button>

    <button
      class="audio-reader__button"
      *ngIf="isPaused"
      (click)="resumeReading()"
      title="Retomar leitura"
    >
      <div class="audio-reader__resume">
        <img
          [src]="'assets/img/svg/icon-play.svg' | fileHash"
          alt="resume"
          title="Retomar leitura"
          loading="lazy"
        />
        <span>
          {{ "@READING-RESUME" | translate }}
        </span>
      </div>
    </button>

    <button
      class="audio-reader__button"
      *ngIf="isPlaying"
      (click)="stopReading()"
      title="Parar leitura"
    >
      <div class="audio-reader__stop">
        <img
          [src]="'assets/img/svg/icon-stop.svg' | fileHash"
          alt="stop"
          title="Parar leitura"
          loading="lazy"
        />
        <span>
          {{ "@READING-STOP" | translate }}
        </span>
      </div>
    </button>
  </div>
</div>
