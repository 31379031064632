<footer id="footer">
  <section class="footer-desktop d-none d-lg-block">
    <div class="footer-desktop__info">
      <div class="footer-desktop__info-container container container-fhd px-3">
        <div class="logo">
          <a
            routerLink="/"
            [qscEventTrackerCategory]="footerAnalytics.category.rodape"
            [qscEventTrackerAction]="footerAnalytics.action.logoComgas"
            [qscEventTrackerLabel]="footerAnalytics.label.clique"
            [qscEventTrackerFlow]="getGtmFlow()"
            class="text-decoration-none"
          >
            <img
              class="d-lg-none d-xl-inline"
              [src]="'assets/img/svg/logo/logo-white.svg' | fileHash"
              [title]="'@HOMEPAGE' | translate"
              [alt]="'@HOMEPAGE' | translate"
              loading="lazy"
            />
            <i class="icon-logo_comgas d-none d-lg-inline d-xl-none"></i>
          </a>
        </div>
        <div class="links d-flex flex-column gap-3">
          <ul class="w-100">
            <li>
              <button
                #comgasExpandButton
                class="btn-reset expand-button"
                [ngClass]="{
                  'expand-button--active': expandListButton === 'comgas'
                }"
                (click)="expandList('comgas', comgasExpandButton)"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                [qscEventTrackerFlow]="getGtmFlow()"
                [qscEventTrackerIsToggle]="true"
              >
                {{ "@FOOTER-COMGAS" | translate }}
                <qsc-svg-icon
                  icon="arrow-down"
                  [class.rotate-180]="expandListButton === 'comgas'"
                  [width]="'1.25rem'"
                  [height]="'1.25rem'"
                ></qsc-svg-icon>
              </button>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.comgas.com.br/a-comgas/?utm_source=qc&utm_medium=referral&utm_campaign=conheca-comgas-rodape&utm_content=botao"
                rel="noopener"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@COMGAS-FOOTER-MENU-CONHECER-COMGAS' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                >{{ "@COMGAS-FOOTER-MENU-CONHECER-COMGAS" | translate }}</a
              >
            </li>
            <li>
              <button
                #servicesExpandButton
                class="btn-reset expand-button"
                [ngClass]="{
                  'expand-button--active': expandListButton === 'services'
                }"
                (click)="expandList('services', servicesExpandButton)"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                [qscEventTrackerFlow]="getGtmFlow()"
                [qscEventTrackerIsToggle]="true"
              >
                {{ "@FOOTER-SERVICOS" | translate }}
                <qsc-svg-icon
                  icon="arrow-down"
                  [class.rotate-180]="expandListButton === 'services'"
                  [width]="'1.25rem'"
                  [height]="'1.25rem'"
                ></qsc-svg-icon>
              </button>
            </li>
            <li>
              <button
                class="btn-reset expand-button"
                [ngClass]="{
                  'expand-button--active':
                    expandListButton === 'supportChannels'
                }"
                (click)="expandList('supportChannels')"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@FOOTER-CANAIS-DE-CONTATO' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                [qscEventTrackerIsToggle]="true"
              >
                {{ "@FOOTER-CANAIS-DE-CONTATO" | translate }}
                <qsc-svg-icon
                  icon="arrow-down"
                  [class.rotate-180]="expandListButton === 'supportChannels'"
                  class="toggle-contact-list"
                  [width]="'1.25rem'"
                  [height]="'1.25rem'"
                ></qsc-svg-icon>
              </button>
            </li>
            <li>
              <a
                [href]="openLogin('clienteComgas')"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@COMGAS-FOOTER-MENU-CLIENTE' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                >{{ "@COMGAS-FOOTER-MENU-CLIENTE" | translate }}</a
              >
            </li>
          </ul>
          <div
            class="expanded-list"
            [class.expanded-list--active]="expandListButton === 'comgas'"
            [ngStyle]="{ 'margin-left.px': expandedListMarginLeft }"
          >
            <ul>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  href="https://www.comgas.com.br/"
                  target="_blank"
                  rel="noopener"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                  [qscEventTrackerLabel]="'@FOOTER-A-COMGAS' | translate"
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-A-COMGAS' | translate"
                ></a>
              </li>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  routerLink="/duvidas-frequentes"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                  [qscEventTrackerLabel]="'@FOOTER-FAQ' | translate"
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-FAQ' | translate"
                ></a>
              </li>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  routerLink="/selecionar-segmento"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                  [qscEventTrackerLabel]="
                    '@FOOTER-SOLICITAR-GAS-ENCANADO' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-SOLICITAR-GAS-ENCANADO' | translate"
                ></a>
              </li>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  routerLink="/selecionar-segmento"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                  [qscEventTrackerLabel]="
                    '@FOOTER-VALIDAR-ENDERECO' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-VALIDAR-ENDERECO' | translate"
                ></a>
              </li>
            </ul>
          </div>
          <div
            class="expanded-list expanded-list--row"
            [class.expanded-list--active]="
              expandListButton === 'supportChannels'
            "
          >
            <ul>
              <li class="expanded-list-item">
                <button
                  class="expanded-list-item__text d-flex align-items-center gap-2 cursor-pointer btn-reset pt-0"
                  (click)="openChat()"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="
                    '@FOOTER-CANAIS-DE-CONTATO' | translate
                  "
                  [qscEventTrackerLabel]="
                    '@FOOTER-WHATSAPP-FALE-CRIS' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                >
                  <i aria-hidden="true" class="icon-baloes_chat"></i
                  >{{ "@FOOTER-WHATSAPP-FALE-CRIS" | translate }}
                </button>
              </li>
              <li class="expanded-list-item">
                <p
                  class="expanded-list-item__text mb-1"
                  [innerHTML]="'@FOOTER-AUTOATENDIMENTO' | translate"
                ></p>
                <a
                  class="expanded-list-item__sub-text"
                  href="https://virtual.comgas.com.br/"
                  target="_blank"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="
                    '@FOOTER-CANAIS-DE-CONTATO' | translate
                  "
                  [qscEventTrackerLabel]="'@FOOTER-COMGAS-VIRTUAL' | translate"
                  [qscEventTrackerFlow]="getGtmFlow()"
                  rel="noopener"
                  [innerHTML]="'@FOOTER-COMGAS-VIRTUAL' | translate"
                ></a>
              </li>
              <li class="expanded-list-item whatsapp">
                <p
                  class="expanded-list-item__text mb-1"
                  [innerHTML]="'@FOOTER-WHATSAPP-DA-CRIS' | translate"
                ></p>
                <a
                  class="expanded-list-item__sub-text"
                  [href]="whatsappUrl"
                  target="_blank"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="
                    '@FOOTER-CANAIS-DE-CONTATO' | translate
                  "
                  [qscEventTrackerLabel]="
                    '@FOOTER-WHATSAPP-DA-CRIS' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-WHATSAPP-DA-CRIS-NUMERO' | translate"
                ></a>
              </li>
              <li class="expanded-list-item">
                <p
                  class="expanded-list-item__text mb-1"
                  [innerHTML]="
                    '@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS' | translate
                  "
                ></p>
                <a
                  class="expanded-list-item__sub-text"
                  [href]="
                    'tel:' +
                      ('@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS-NUMERO'
                        | translate) | removeSpaces
                  "
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="
                    '@FOOTER-CANAIS-DE-CONTATO' | translate
                  "
                  [qscEventTrackerLabel]="
                    '@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="
                    '@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS-NUMERO' | translate
                  "
                ></a>
                <p
                  class="expanded-list-item__caption mt-3 mb-0"
                  [innerHTML]="
                    '@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS-OBS' | translate
                  "
                ></p>
              </li>
              <li class="expanded-list-item">
                <p
                  class="expanded-list-item__text mb-1"
                  [innerHTML]="'@FOOTER-DEFICIENTES-AUDITIVOS' | translate"
                ></p>
                <a
                  class="expanded-list-item__sub-text d-block mb-1"
                  [href]="
                    'tel:' +
                      ('@FOOTER-DEFICIENTES-AUDITIVOS-NUMERO-1' | translate)
                      | removeSpaces
                  "
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="
                    '@FOOTER-CANAIS-DE-CONTATO' | translate
                  "
                  [qscEventTrackerLabel]="
                    '@FOOTER-DEFICIENTES-AUDITIVOS' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="
                    '@FOOTER-DEFICIENTES-AUDITIVOS-NUMERO-1' | translate
                  "
                ></a>
                <a
                  class="expanded-list-item__sub-text"
                  [href]="
                    'tel:' +
                      ('@FOOTER-DEFICIENTES-AUDITIVOS-NUMERO-2' | translate)
                      | removeSpaces
                  "
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="
                    '@FOOTER-CANAIS-DE-CONTATO' | translate
                  "
                  [qscEventTrackerLabel]="
                    '@FOOTER-DEFICIENTES-AUDITIVOS' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="
                    '@FOOTER-DEFICIENTES-AUDITIVOS-NUMERO-2' | translate
                  "
                ></a>
              </li>
            </ul>
          </div>
          <div
            class="expanded-list"
            [class.expanded-list--active]="expandListButton === 'services'"
            [ngStyle]="{ 'margin-left.px': expandedListMarginLeft }"
          >
            <ul>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  routerLink="/residencias"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                  [qscEventTrackerLabel]="'@FOOTER-CASAS' | translate"
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-CASAS' | translate"
                ></a>
              </li>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  routerLink="/predios"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                  [qscEventTrackerLabel]="'@FOOTER-PREDIOS' | translate"
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-PREDIOS' | translate"
                ></a>
              </li>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  routerLink="/negocios"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                  [qscEventTrackerLabel]="'@FOOTER-NEGOCIOS' | translate"
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-NEGOCIOS' | translate"
                ></a>
              </li>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  routerLink="/selecionar-segmento"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                  [qscEventTrackerLabel]="
                    '@FOOTER-SOLICITAR-GAS-ENCANADO' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-SOLICITAR-GAS-ENCANADO' | translate"
                ></a>
              </li>
              <li class="expanded-list-item">
                <a
                  class="expanded-list-item__text"
                  routerLink="/selecionar-segmento"
                  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                  [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                  [qscEventTrackerLabel]="
                    '@FOOTER-VALIDAR-ENDERECO' | translate
                  "
                  [qscEventTrackerFlow]="getGtmFlow()"
                  [innerHTML]="'@FOOTER-VALIDAR-ENDERECO' | translate"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-desktop__social">
      <div
        class="w-100 d-flex flex-column-reverse align-items-center gap-3 flex-xl-row justify-content-xl-between container container-fhd px-3"
      >
        <ul>
          <li>
            <a
              [qscAppLink]="{ url: 'linkedin://company?id=20877' }"
              href="https://www.linkedin.com/company/20877/"
              target="_blank"
              [qscEventTrackerCategory]="footerAnalytics.category.rodape"
              [qscEventTrackerAction]="footerAnalytics.action.linkedin"
              [qscEventTrackerLabel]="footerAnalytics.label.clique"
              [qscEventTrackerFlow]="getGtmFlow()"
              rel="noopener"
              ><img
                [src]="'assets/img/svg/icon-cg-linkedin.svg' | fileHash"
                alt="linkedin"
                loading="lazy"
            /></a>
          </li>
          <li>
            <a
              [qscAppLink]="{ url: 'twitter://user?screen_name=comgasbr' }"
              href="https://twitter.com/comgasbr"
              target="_blank"
              [qscEventTrackerCategory]="footerAnalytics.category.rodape"
              [qscEventTrackerAction]="footerAnalytics.action.twitter"
              [qscEventTrackerLabel]="footerAnalytics.label.clique"
              [qscEventTrackerFlow]="getGtmFlow()"
              rel="noopener"
              ><img
                [src]="'assets/img/svg/icon-cg-twitter.svg' | fileHash"
                alt="twitter"
                loading="lazy"
            /></a>
          </li>
          <li>
            <a
              [qscAppLink]="{
                url: 'fb://page/1066236296722739',
                urlIos: 'fb://profile/1066236296722739'
              }"
              href="https://www.facebook.com/comgasoficial/"
              target="_blank"
              [qscEventTrackerCategory]="footerAnalytics.category.rodape"
              [qscEventTrackerAction]="footerAnalytics.action.facebook"
              [qscEventTrackerLabel]="footerAnalytics.label.clique"
              [qscEventTrackerFlow]="getGtmFlow()"
              rel="noopener"
              ><img
                [src]="'assets/img/svg/icon-cg-facebook.svg' | fileHash"
                alt="facebook"
                loading="lazy"
            /></a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCTK3MMJSoilA--M3ss_QD8w"
              target="_blank"
              [qscEventTrackerCategory]="footerAnalytics.category.rodape"
              [qscEventTrackerAction]="footerAnalytics.action.youtube"
              [qscEventTrackerLabel]="footerAnalytics.label.clique"
              [qscEventTrackerFlow]="getGtmFlow()"
              rel="noopener"
              ><img
                [src]="'assets/img/svg/icon-cg-youtube.svg' | fileHash"
                alt="youtube"
                loading="lazy"
            /></a>
          </li>
        </ul>

        <div class="links">
          <ul class="d-flex justify-content-between gap-4 justify-content-xl-center align-items-center">
            <li>
              <a
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  footerAnalytics.action.politicaDePrivacidade
                "
                [qscEventTrackerLabel]="footerAnalytics.label.clique"
                [qscEventTrackerFlow]="getGtmFlow()"
                target="_blank"
                href="https://www.comgas.com.br/politica-de-privacidade/"
                rel="noopener"
                >{{
                  "@COMGAS-FOOTER-MENU-POLITICA-DE-PRIVACIDADE" | translate
                }}</a
              >
            </li>
            <li>
              <a
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  footerAnalytics.action.politicaDeCookies
                "
                [qscEventTrackerLabel]="footerAnalytics.label.clique"
                [qscEventTrackerFlow]="getGtmFlow()"
                target="_blank"
                href="https://www.comgas.com.br/termo-de-cookies/"
                rel="noopener"
                >{{ "@COMGAS-FOOTER-MENU-POLITICA-DE-COOKIES" | translate }}</a
              >
            </li>
            <li>
              <button
                class="btn-reset"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  footerAnalytics.action.definicoesDeCookies
                "
                [qscEventTrackerLabel]="footerAnalytics.label.clique"
                [qscEventTrackerFlow]="getGtmFlow()"
                (click)="openCookieConfiguration()"
              >
                {{ "@FOOTER-COOKIES" | translate }}
              </button>
            </li>
            <li class="d-none d-lg-inline-block d-xl-none">
              <ng-container
                *ngTemplateOutlet="copyrightTemplate"
              ></ng-container>
            </li>
          </ul>
        </div>

        <div class="d-none d-xl-inline-block">
          <ng-container *ngTemplateOutlet="copyrightTemplate"></ng-container>
        </div>
      </div>
    </div>
  </section>

  <section
    class="footer-mobile d-lg-none"
    [ngClass]="{ 'pb-floating-wrapper': hasFloatingButton }"
  >
  <div class="footer-mobile__container container px-3">
    <a
    routerLink="/"
    [qscEventTrackerCategory]="footerAnalytics.category.rodape"
    [qscEventTrackerAction]="footerAnalytics.action.logoComgas"
    [qscEventTrackerLabel]="footerAnalytics.label.clique"
    [qscEventTrackerFlow]="getGtmFlow()"
    class="footer-mobile__info-title"
  >
    <img
      [src]="'assets/img/svg/logo/logo-white.svg' | fileHash"
      [alt]="'@HOMEPAGE' | translate"
      loading="lazy"
    />
  </a>
  <div class="footer-mobile__info">
    <ul>
      <li>
        <qsc-expandable-list
          [title]="'@FOOTER-COMGAS' | translate"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
          [qscEventTrackerFlow]="getGtmFlow()"
          [qscEventTrackerIsToggle]="true"
        >
          <ul>
            <li class="expanded-list-item">
              <a
                href="https://www.comgas.com.br/"
                target="_blank"
                rel="noopener"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                [qscEventTrackerLabel]="'@FOOTER-A-COMGAS' | translate"
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-A-COMGAS' | translate"
              ></a>
            </li>
            <li class="expanded-list-item">
              <a
                routerLink="/duvidas-frequentes"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                [qscEventTrackerLabel]="'@FOOTER-FAQ' | translate"
                [qscEventTrackerFlow]="getGtmFlow()"
                >{{ "@FOOTER-FAQ" | translate }}</a
              >
            </li>
            <li class="expanded-list-item">
              <a
                routerLink="/selecionar-segmento"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                [qscEventTrackerLabel]="
                  '@FOOTER-SOLICITAR-GAS-ENCANADO' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-SOLICITAR-GAS-ENCANADO' | translate"
              ></a>
            </li>
            <li class="expanded-list-item">
              <a
                routerLink="/selecionar-segmento"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-COMGAS' | translate"
                [qscEventTrackerLabel]="
                  '@FOOTER-VALIDAR-ENDERECO' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-VALIDAR-ENDERECO' | translate"
              ></a>
            </li>
          </ul>
        </qsc-expandable-list>
      </li>
      <li>
        <a
          target="_blank"
          href="https://www.comgas.com.br/a-comgas/?utm_source=qc&utm_medium=referral&utm_campaign=conheca-comgas-rodape&utm_content=botao"
          rel="noopener"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="
            '@COMGAS-FOOTER-MENU-CONHECER-COMGAS' | translate
          "
          [qscEventTrackerFlow]="getGtmFlow()"
          >{{ "@COMGAS-FOOTER-MENU-CONHECER-COMGAS" | translate }}</a
        >
      </li>
      <li>
        <qsc-expandable-list
          [title]="'@FOOTER-SERVICOS' | translate"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
          [qscEventTrackerFlow]="getGtmFlow()"
          [qscEventTrackerIsToggle]="true"
        >
          <ul>
            <li class="expanded-list-item">
              <a
                routerLink="/residencias"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                [qscEventTrackerLabel]="'@FOOTER-CASAS' | translate"
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-CASAS' | translate"
              ></a>
            </li>
            <li class="expanded-list-item">
              <a
                routerLink="/predios"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                [qscEventTrackerLabel]="'@FOOTER-PREDIOS' | translate"
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-PREDIOS' | translate"
              ></a>
            </li>
            <li class="expanded-list-item">
              <a
                routerLink="/negocios"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                [qscEventTrackerLabel]="'@FOOTER-NEGOCIOS' | translate"
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-NEGOCIOS' | translate"
              ></a>
            </li>
            <li class="expanded-list-item">
              <a
                routerLink="/selecionar-segmento"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                [qscEventTrackerLabel]="
                  '@FOOTER-SOLICITAR-GAS-ENCANADO' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-SOLICITAR-GAS-ENCANADO' | translate"
              ></a>
            </li>
            <li class="expanded-list-item">
              <a
                routerLink="/selecionar-segmento"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@FOOTER-SERVICOS' | translate"
                [qscEventTrackerLabel]="
                  '@FOOTER-VALIDAR-ENDERECO' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-VALIDAR-ENDERECO' | translate"
              ></a>
            </li>
          </ul>
        </qsc-expandable-list>
      </li>
      <li>
        <qsc-expandable-list
          [title]="'@FOOTER-CANAIS-DE-CONTATO' | translate"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="'@FOOTER-CANAIS-DE-CONTATO' | translate"
          [qscEventTrackerFlow]="getGtmFlow()"
          [qscEventTrackerIsToggle]="true"
        >
          <ul>
            <li>
              <button
                class="d-flex align-items-center gap-2 cursor-pointer btn-reset fw-bold"
                (click)="openChat()"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@FOOTER-CANAIS-DE-CONTATO' | translate
                "
                [qscEventTrackerLabel]="
                  '@FOOTER-WHATSAPP-FALE-CRIS' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
              >
                <i aria-hidden="true" class="icon-baloes_chat"></i
                >{{ "@FOOTER-WHATSAPP-FALE-CRIS" | translate }}
              </button>
            </li>
            <li class="whatsapp">
              <p
                [innerHTML]="'@FOOTER-WHATSAPP-DA-CRIS-MOBILE' | translate"
              ></p>
              <a
                [href]="whatsappUrl"
                target="_blank"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@FOOTER-CANAIS-DE-CONTATO' | translate
                "
                [qscEventTrackerLabel]="
                  '@FOOTER-WHATSAPP-DA-CRIS-MOBILE' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                rel="noopener"
                [innerHTML]="'@FOOTER-WHATSAPP-DA-CRIS-NUMERO' | translate"
              ></a>
            </li>
            <li>
              <p
                [innerHTML]="'@FOOTER-AUTOATENDIMENTO-MOBILE' | translate"
              ></p>
              <a
                href="https://virtual.comgas.com.br/"
                target="_blank"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@FOOTER-CANAIS-DE-CONTATO' | translate
                "
                [qscEventTrackerLabel]="
                  '@FOOTER-AUTOATENDIMENTO-MOBILE' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                rel="noopener"
                [innerHTML]="'@FOOTER-COMGAS-VIRTUAL' | translate"
              ></a>
            </li>
            <li>
              <p
                [innerHTML]="
                  '@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS-MOBILE' | translate
                "
              ></p>
              <a
                [href]="
                  'tel:' +
                    ('@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS-NUMERO'
                      | translate) | removeSpaces
                "
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@FOOTER-CANAIS-DE-CONTATO' | translate
                "
                [qscEventTrackerLabel]="
                  '@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS-MOBILE' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="
                  '@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS-NUMERO' | translate
                "
              ></a>
              <span
                [innerHTML]="
                  '@FOOTER-CENTRAL-DE-ATENDIMENTO-24HRS-OBS' | translate
                "
              ></span>
            </li>
            <li>
              <p
                [innerHTML]="
                  '@FOOTER-DEFICIENTES-AUDITIVOS-MOBILE' | translate
                "
              ></p>
              <a
                [href]="
                  'tel:' +
                    ('@FOOTER-DEFICIENTES-AUDITIVOS-NUMERO-1' | translate)
                    | removeSpaces
                "
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@FOOTER-CANAIS-DE-CONTATO' | translate
                "
                [qscEventTrackerLabel]="
                  '@FOOTER-DEFICIENTES-AUDITIVOS-MOBILE' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="
                  '@FOOTER-DEFICIENTES-AUDITIVOS-NUMERO-1' | translate
                "
              ></a>
            </li>
            <li>
              <p [innerHTML]="'@FOOTER-CANAL-ETICA-MOBILE' | translate"></p>
              <a
                [href]="
                  'tel:' + ('@FOOTER-CANAL-ETICA-NUMERO' | translate)
                    | removeSpaces
                "
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="
                  '@FOOTER-CANAIS-DE-CONTATO' | translate
                "
                [qscEventTrackerLabel]="
                  '@FOOTER-CANAL-ETICA-MOBILE' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                [innerHTML]="'@FOOTER-CANAL-ETICA-NUMERO' | translate"
              ></a>
            </li>
          </ul>
        </qsc-expandable-list>
      </li>
      <li>
        <a
          [href]="openLogin('clienteComgas')"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="'@COMGAS-FOOTER-MENU-CLIENTE' | translate"
          [qscEventTrackerFlow]="getGtmFlow()"
          >{{ "@COMGAS-FOOTER-MENU-CLIENTE" | translate }}</a
        >
      </li>
      <li>
        <qsc-expandable-list
          [title]="'@OTHERS' | translate"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="'@OTHERS' | translate"
          [qscEventTrackerFlow]="getGtmFlow()"
          [qscEventTrackerIsToggle]="true"
        >
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.comgas.com.br/politica-de-privacidade/"
                rel="noopener"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@OTHERS' | translate"
                [qscEventTrackerLabel]="
                  '@COMGAS-FOOTER-MENU-POLITICA-DE-PRIVACIDADE' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                >{{
                  "@COMGAS-FOOTER-MENU-POLITICA-DE-PRIVACIDADE" | translate
                }}</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.comgas.com.br/termo-de-cookies/"
                rel="noopener"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@OTHERS' | translate"
                [qscEventTrackerLabel]="
                  '@COMGAS-FOOTER-MENU-POLITICA-DE-COOKIES' | translate
                "
                [qscEventTrackerFlow]="getGtmFlow()"
                >{{
                  "@COMGAS-FOOTER-MENU-POLITICA-DE-COOKIES" | translate
                }}</a
              >
            </li>
            <li>
              <button
                (click)="openCookieConfiguration()"
                [qscEventTrackerCategory]="footerAnalytics.category.rodape"
                [qscEventTrackerAction]="'@OTHERS' | translate"
                [qscEventTrackerLabel]="'@FOOTER-COOKIES' | translate"
                [qscEventTrackerFlow]="getGtmFlow()"
              >
                {{ "@FOOTER-COOKIES" | translate }}
              </button>
            </li>
          </ul>
        </qsc-expandable-list>
      </li>
    </ul>
  </div>
  <div class="footer-mobile__social">
    <ul>
      <li>
        <a
          [qscAppLink]="{ url: 'linkedin://company?id=20877' }"
          href="https://www.linkedin.com/company/20877/"
          target="_blank"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="footerAnalytics.action.linkedin"
          [qscEventTrackerLabel]="footerAnalytics.label.clique"
          [qscEventTrackerFlow]="getGtmFlow()"
          rel="noopener"
          ><img
            [src]="'assets/img/svg/icon-cg-linkedin.svg' | fileHash"
            alt="linkedin"
            loading="lazy"
        /></a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/comgasbr/"
          target="_blank"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="footerAnalytics.action.instagram"
          [qscEventTrackerLabel]="footerAnalytics.label.clique"
          [qscEventTrackerFlow]="getGtmFlow()"
          rel="noopener"
        >
          <img
            [src]="'assets/img/svg/icon-cg-instagram.svg' | fileHash"
            alt="instagram"
            loading="lazy"
        /></a>
      </li>
      <li>
        <a
          [qscAppLink]="{
            url: 'fb://page/1066236296722739',
            urlIos: 'fb://profile/1066236296722739'
          }"
          href="https://www.facebook.com/comgasoficial/"
          target="_blank"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="footerAnalytics.action.facebook"
          [qscEventTrackerLabel]="footerAnalytics.label.clique"
          [qscEventTrackerFlow]="getGtmFlow()"
          rel="noopener"
          ><img
            [src]="'assets/img/svg/icon-cg-facebook.svg' | fileHash"
            alt="facebook"
            loading="lazy"
        /></a>
      </li>
      <li>
        <a
          [qscAppLink]="{ url: 'twitter://user?screen_name=comgasbr' }"
          href="https://twitter.com/comgasbr"
          target="_blank"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="footerAnalytics.action.twitter"
          [qscEventTrackerLabel]="footerAnalytics.label.clique"
          [qscEventTrackerFlow]="getGtmFlow()"
          rel="noopener"
          ><img
            [src]="'assets/img/svg/icon-cg-twitter.svg' | fileHash"
            alt="twitter"
            loading="lazy"
        /></a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UCTK3MMJSoilA--M3ss_QD8w"
          target="_blank"
          [qscEventTrackerCategory]="footerAnalytics.category.rodape"
          [qscEventTrackerAction]="footerAnalytics.action.youtube"
          [qscEventTrackerLabel]="footerAnalytics.label.clique"
          [qscEventTrackerFlow]="getGtmFlow()"
          rel="noopener"
          ><img
            [src]="'assets/img/svg/icon-cg-youtube.svg' | fileHash"
            alt="youtube"
            loading="lazy"
        /></a>
      </li>
    </ul>
  </div>
  </div>

  </section>
</footer>

<ng-template #copyrightTemplate>
  <span
    class="copyright"
    [innerHTML]="'@FOOTER-COPYRIGHT' | translate : { year: getCurrentYear() }"
  ></span>
</ng-template>
