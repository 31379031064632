<qsc-json-ld [schema]="data?.schema"></qsc-json-ld>
<section id="short-faq" class="short-faq">
  <div class="container px-3 container-fhd">
    <h2
      class="short-faq__title"
      [innerHTML]="'@SHORT-FAQ-TITULO' | translate"
    ></h2>
    <cdk-accordion class="short-faq__accordion">
      <cdk-accordion-item
        *ngFor="let item of data?.items; let i = index; trackBy: faqItemTrackBy"
        #accordionItem="cdkAccordionItem"
        class="short-faq__accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + i"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + i"
        (opened)="openQuestion(item)"
        (closed)="closeQuestion(item)"
        (click)="accordionItem.toggle()"
      >
        <div class="short-faq__accordion-item-header">
          <span
            class="short-faq__accordion-item-icon"
            [ngClass]="{
              'short-faq__accordion-item-icon--expanded': accordionItem.expanded
            }"
            ><i aria-hidden="true" class="icon-sinal_somar"></i
          ></span>
          <h3 [innerHTML]="item.question | translate"></h3>
        </div>
        <div
          [@contentExpansion]="
            accordionItem.expanded ? 'expanded' : 'collapsed'
          "
          [innerHTML]="item.answer.html | translate"
          class="short-faq__accordion-item-body"
          [ngClass]="{
            'short-faq__accordion-item-body--expanded': accordionItem.expanded
          }"
          role="region"
          [attr.id]="'accordion-body-' + i"
          [attr.aria-labelledby]="'accordion-header-' + i"
        ></div>
      </cdk-accordion-item>
      <div class="short-faq__link">
        <a (click)="sendEventGA()" routerLink="/duvidas-frequentes">
          {{ "@SHORT-FAQ-LINK-MAIS-PERGUNTAS" | translate }}
          <span><i aria-hidden="true" class="icon-seta_direita"></i></span>
        </a>
      </div>
    </cdk-accordion>
  </div>
</section>
