export const analytics = {
  ortec: [
    'selecionePerfil',
    'dadosContatoEndereco',
    'instalacaoInternaVistoria',
    'instalacaoInternaVistoria/equipamentos',
    'protocolo',
  ],
  verificaRede: [
    'selecionePerfil',
    'dadosContatoEndereco',
    'instalacaoInternaVistoria/equipamentos',
    'protocolo',
  ],
  queroComgas: ['buscaEndereco'],
};
