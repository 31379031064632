import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackToTopService {
  private showBackToTop = new BehaviorSubject<boolean>(false);

  getBackToTopStatus(): Observable<boolean> {
    return this.showBackToTop.asObservable();
  }

  updateBackToTopStatus(status: boolean): void {
    this.showBackToTop.next(status);
  }

  resetBackToTopStatus(): void {
    this.showBackToTop.next(false);
  }
}
