import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import { take } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  BackToTopService,
  FloatingWrapperService,
  ChatService,
  LoginService,
  FlowService,
  ThemeService,
} from '@shared/services';
import { GoogleTagManagerService } from '@core/index';
import { FlowsAnalytics, HeaderAnalytics } from '@models/analytics';
import { environment } from '@env';
import { EventTrackerDirective } from '@shared/directives';
import menuDataJson from 'src/assets/data/menu.json';
import { Router, RouterLink } from '@angular/router';
import { PictureComponent } from '../picture/picture.component';
import { ExpandableListComponent } from '../expandable-list/expandable-list.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

export interface IMenuItem {
  id: number;
  label: string;
  subItems: IMenuSubItem[];
}

export interface IMenuSubItem {
  id: number;
  label: string;
  url: string;
}

@UntilDestroy()
@Component({
  selector: 'qsc-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    FileHashPipe,
    EventTrackerDirective,
    RouterLink,
    PictureComponent,
    ExpandableListComponent,
    TranslateModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class HamburgerMenuComponent implements OnInit, OnDestroy {
  @Output() activeChange = new EventEmitter();
  isActive = false;
  menuItems: IMenuItem[] = menuDataJson.menuItems;
  menuBanner = menuDataJson.banners[0];
  currentSubMenu: IMenuSubItem[] = [];
  currentMenuId = 0;
  imageBanner = menuDataJson.banners[0].image;
  private wasChatOpenWhenMenuOpened: boolean | null = null;
  private wasBackToTopVisibleWhenMenuOpened: boolean | null = null;
  private wasFloatingWrapperVisibleWhenMenuOpened: boolean | null = null;
  window: any;

  constructor(
    private readonly chatService: ChatService,
    private readonly backToTopService: BackToTopService,
    private readonly floatingWrapperService: FloatingWrapperService,
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly loginService: LoginService,
    private readonly flowService: FlowService,
    private readonly router: Router,
    private readonly themeService: ThemeService,
    private readonly translateService: TranslateService,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  ngOnInit(): void {
    this.themeService.theme$.pipe(untilDestroyed(this)).subscribe((theme) => {
      this.imageBanner =
        theme === 'contrast'
          ? this.menuBanner.contrastImage
          : this.menuBanner.image;
    });
  }

  ngOnDestroy(): void {
    this.closeMenu();
  }

  get headerAnalytics() {
    return HeaderAnalytics;
  }

  onSegmentClick(id: number): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.menuPrincipal,
      this.translateService.instant(
        this.menuItems
          .find((f) => f.id === this.currentMenuId)
          ?.subItems.find((f) => f.id === id)?.label ?? ''
      ),
      HeaderAnalytics.label.clique
    );
    this.setActiveMenu(false);
  }

  showMenu(): void {
    if (!this.isActive) {
      this.chatService
        .getChatMessageStatus()
        .pipe(take(1))
        .subscribe((value) => {
          this.wasChatOpenWhenMenuOpened = value;
        });
      this.backToTopService
        .getBackToTopStatus()
        .pipe(take(1))
        .subscribe((value) => {
          this.wasBackToTopVisibleWhenMenuOpened = value;
        });
      this.floatingWrapperService
        .getFloatingWrapperStatus()
        .pipe(take(1))
        .subscribe((value) => {
          this.wasFloatingWrapperVisibleWhenMenuOpened = value;
        });
    }
    if (this.wasChatOpenWhenMenuOpened) {
      this.chatService.hideChat();
    }
    if (this.wasBackToTopVisibleWhenMenuOpened) {
      this.backToTopService.updateBackToTopStatus(false);
    }
    if (this.wasFloatingWrapperVisibleWhenMenuOpened) {
      this.floatingWrapperService.updateFloatingWrapperStatus(false);
    }
    this.setActiveMenu(true);
  }

  closeMenu(): void {
    if (this.wasChatOpenWhenMenuOpened) {
      this.chatService.showChat();
    }
    if (this.wasBackToTopVisibleWhenMenuOpened) {
      this.backToTopService.updateBackToTopStatus(true);
    }
    if (this.wasFloatingWrapperVisibleWhenMenuOpened) {
      this.floatingWrapperService.updateFloatingWrapperStatus(true);
    }
    this.resetStatesWhenMenuOpened();
    this.setActiveMenu(false);
  }

  closeMenuOnEsc(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeMenu();
    }
  }

  private resetStatesWhenMenuOpened(): void {
    this.wasChatOpenWhenMenuOpened = null;
    this.wasBackToTopVisibleWhenMenuOpened = null;
    this.wasFloatingWrapperVisibleWhenMenuOpened = null;
  }

  private setActiveMenu(value: boolean): void {
    this.isActive = value;
    this.activeChange.emit(this.isActive);
    if (this.isActive) {
      this.renderer.addClass(this.document.body, 'menu-active');
    } else {
      this.renderer.removeClass(this.document.body, 'menu-active');
    }
  }

  openLogin(): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.menuPrincipal,
      HeaderAnalytics.action.login,
      HeaderAnalytics.label.clique
    );
    this.loginService.openModalLogin(
      'clienteComgas',
      'utm_source=qc&utm_medium=referral&utm_campaign=login-hamb&utm_content=botao'
    );
  }

  openEmergencies(): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.menuPrincipal,
      HeaderAnalytics.action.emergencia,
      HeaderAnalytics.label.clique
    );

    const url = environment.endpoints.comgasVirtual + '/emergencias';
    this.window.open(url, '_self');
  }

  getExternalLink(url: string): string {
    if (url.includes('@ComgasVirtual')) {
      return url.replace('@ComgasVirtual', environment.endpoints.comgasVirtual);
    }
    if (url.includes('@ComgasSolucoes')) {
      return url.replace('@ComgasSolucoes', environment.endpoints.solucoes);
    }
    return '';
  }

  showSubMenu(item: IMenuItem): void {
    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.menuPrincipal,
      this.translateService.instant(
        this.menuItems.find((f) => f.id === item.id)?.label ?? ''
      ),
      FlowsAnalytics.label.clique
    );
    this.currentSubMenu = item.subItems;
    this.currentMenuId = item.id;
  }

  checkCurrentPageByUrl(url: string): boolean {
    return this.router.url.includes(url);
  }

  clickBanner(bannerTitle: string): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.menuPrincipal,
      HeaderAnalytics.action.bannerHamburguer,
      this.translateService.instant(bannerTitle)
    );
    this.router.navigateByUrl('/selecionar-segmento');
  }
}
