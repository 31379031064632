import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddressConfirmationModalComponent } from '@modules/address-validation/components/address-confirmation-modal/address-confirmation-modal.component';
import { LoadingService } from '../loading/loading.service';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { fromUser } from '@core/store';
import { IUser } from '@core/store/user/user.reducer';

@Injectable({
  providedIn: 'root',
})
export class AddressConfirmationModalService {
  bsModalRefAddressConfirmation: any;
  userData$!: Observable<fromUser.IUser | null>;

  constructor(
    private readonly bsModalService: BsModalService,
    private readonly loadingService: LoadingService,
    private readonly store: Store
  ) {
    this.userData$ = this.store.select(fromUser.selectUserData);
  }

  openAddressConfirmationModal(): BsModalRef | null {
    this.loadingService.hideLoading();
    this.store
      .select(fromUser.selectUserData)
      .pipe(take(1))
      .subscribe((userData: IUser | null) => {
        const modalOptions: ModalOptions = {
          initialState: {
            street: userData?.address?.logradouro,
            neighborhood: userData?.address?.bairro,
            number: userData?.number,
            complement: userData?.complement?.label ?? '',
            apartment: userData?.apartment,
          },
          backdrop: 'static',
          keyboard: false,
          class: 'address-confirmation-modal modal-dialog-centered',
        };

        this.bsModalRefAddressConfirmation = this.bsModalService.show(
          AddressConfirmationModalComponent,
          modalOptions
        );
      });

    return this.bsModalRefAddressConfirmation;
  }
}
