<div class="solution-card" *ngIf="data">
  <div>
    <img
      class="solution-card__icon-img"
      *ngIf="data.iconSvg"
      [src]="data.iconSvg | fileHash"
      alt=""
    />
    <i
      *ngIf="data.iconClass"
      aria-hidden="true"
      class="solution-card__icon-class"
      [class]="data.iconClass"
    ></i>
  </div>
  <h3 class="solution-card__title" [innerHTML]="data.title | translate"></h3>
  <div
    class="solution-card__description"
    [innerHTML]="data.description ?? '' | translate"
  ></div>
</div>
