export const environment = {
  production: true,
  env: 'PRD',
  endpoints: {
    comgasVirtual: 'https://virtual.comgas.com.br/',
    solucoes: 'https://www.solucoes.comgas.com.br/',
    backEndPoint: '/api',
  },
  suncoId: '5f6ba540d40652000c9ff02a',
  privateStorageKey: 'private-key',
  googleApiKey: 'AIzaSyDAZtf6ywGSk4n1t1jv4DLsud870PNSdN4',
  webToCaseConfig: {
    url: 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
    swimmingPoolConfig: {
      orgid: '00D41000000VzgQ',
      retURL: 'https://www.querocomgas.com.br/protocolo?fluxo=piscinas',
      nome: '00N1K00000fgnMx',
      email: 'email',
      celular: '00N5d00000Ix3CD',
      cep: '00N1K00000fgnMu',
      numero: '00N5d00000Ix1cv',
      complemento: '00N5d00000Ix1cn',
      apartamento: '00N5d00000Ix3By',
      logradouro: '00N1K00000fgnMt',
      bairro: '00N5d00000Ix1cu',
      cidade: '00N1K00000fgnMv',
      estado: '00N5d00000Ix1cz',
      perfil: '00N5d00000Ix1cy',
      protocolo: '00N5d00000Ix1cw',
      optIn: '00N1K00000fgnMz',
      campanha: '00N5d00000Ix3Bz',
      source: '00N5d00000Ix3C4',
      medium: '00N5d00000Ix3C2',
      term: "00NU40000012p7d",
      gclid: '00N5d00000Ix3C1',
      fbclid: '00N5d00000Ix3C0',
      canal: '00N1K00000folyM',
    },
    gnvContactConfig: {
      orgid: '00D41000000VzgQ',
      retURL: 'https://www.querocomgas.com.br/veiculos',
      nome: '00N1K00000fgnMx',
      email: 'email',
      celular: 'phone',
      cidade: '00N1K00000fgnMv',
      tipoSolicitacao: '00N5d00000J2CpR',
      objetivo: '00N5d00000J2CpW',
      optIn: '00N1K00000fgnMz',
      campanha: '00N5d00000Ix3Bz',
      source: '00N5d00000Ix3C4',
      medium: '00N5d00000Ix3C2',
      term: "00NU40000012p7d",
      gclid: '00N5d00000Ix3C1',
      fbclid: '00N5d00000Ix3C0',
      canal: '00N1K00000folyM',
    },
  },
};
