import { ProfileService } from '@shared/services';

export enum ProfileEnum {
  Residencial = 'RESIDENCIAL',
  Condominio = 'CONDOMINIO',
  Comercial = 'COMERCIAL',
  Industrial = 'INDUSTRIAL',
}

export enum ProfileIdsEnum {
  Buildings = 1,
  Residences,
  Commerce,
  Industry,
}

export function getProfile(profileService: ProfileService): ProfileEnum {
  if (profileService.isResidences()) {
    return ProfileEnum.Residencial;
  }
  if (profileService.isBuildings()) {
    return ProfileEnum.Condominio;
  }
  if (profileService.isCommerce()) {
    return ProfileEnum.Comercial;
  }
  if (profileService.isIndustry()) {
    return ProfileEnum.Industrial;
  }
  return ProfileEnum.Residencial;
}
