<div id="category" class="category">
  <div class="category__description">
    <div
      *ngIf="
        [
          profileIdsEnum.Residences,
          profileIdsEnum.Commerce,
          profileIdsEnum.Industry
        ].includes(profileService.getProfileId()!)
      "
      [innerHTML]="'@CATEGORY-SELECTION-LABEL-1' | translate"
    ></div>
    <div
      *ngIf="profileService.getProfileId() === profileIdsEnum.Buildings"
      [innerHTML]="'@CATEGORY-SELECTION-LABEL-2' | translate"
    ></div>
    <ng-container [ngSwitch]="selectedCategoryId">
      <span
        *ngSwitchCase="categoryIdsEnum.HouseBuilt"
        [innerHTML]="'@CATEGORY-SELECTION-CASA-CONSTRUIDA-LABEL' | translate"
      ></span>
      <span
        *ngSwitchCase="categoryIdsEnum.NewHome"
        [innerHTML]="'@CATEGORY-SELECTION-NEW-HOME-LABEL' | translate"
      ></span>
      <span
        *ngSwitchCase="categoryIdsEnum.Resident"
        [innerHTML]="'@CATEGORY-SELECTION-MORADOR-LABEL' | translate"
      ></span>
      <span
        *ngSwitchCase="categoryIdsEnum.Inhabited"
        [innerHTML]="'@CATEGORY-SELECTION-SINDICO-LABEL' | translate"
      ></span>
      <span
        *ngSwitchCase="categoryIdsEnum.NewHousing"
        [innerHTML]="'@CATEGORY-SELECTION-CONSTRUTORA-LABEL' | translate"
      ></span>
      <span
        *ngSwitchCase="categoryIdsEnum.Commercial"
        [innerHTML]="'@CATEGORY-SELECTION-COMERCIO-LABEL' | translate"
      ></span>
      <span
        *ngSwitchCase="categoryIdsEnum.Industrial"
        [innerHTML]="'@CATEGORY-SELECTION-INDUSTRIA-LABEL' | translate"
      ></span>
    </ng-container>
  </div>
</div>
