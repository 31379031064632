import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwimmingPoolMapper } from '@shared/mappers';
import { WebToCaseService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class SwimmingPoolService {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly swimmingPoolMapper: SwimmingPoolMapper,
    private readonly webToCaseService: WebToCaseService
  ) {}

  contractNow(): void {
    this.router.navigate(['/selecionar-segmento'], {
      queryParams: { fluxo: 'piscinas' },
    });
  }

  isSwimmingPoolFlow(): boolean {
    return this.activatedRoute.snapshot.queryParams['fluxo'] === 'piscinas';
  }

  sendSwimmingPoolCase(): void {
    const input = this.swimmingPoolMapper.createSwimmingPoolInput();
    this.webToCaseService.createAndSubmitForm(input);
  }
}
