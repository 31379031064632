import { Injectable } from '@angular/core';
import {
  MapActionModalComponent,
  MapActionModalEnum,
  MapNotificationModalComponent,
  MapNotificationModalEnum,
} from '@shared/components';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsModalService {
  constructor(private readonly modalService: BsModalService) {}

  openNotificationModal(
    notificationModalType?: MapNotificationModalEnum | null
  ): void {
    if (notificationModalType == null) return;

    const modalOptions: ModalOptions = {
      initialState: {
        notificationModalType,
      },
      class: 'map-notification-modal modal-dialog-centered',
      backdrop: 'static',
    };

    this.modalService.show(MapNotificationModalComponent, modalOptions);
  }

  openActionModal(
    actionModalType: MapActionModalEnum,
    gasStation: string
  ): BsModalRef<any> {
    const modalOptions: ModalOptions = {
      initialState: {
        actionModalType,
        gasStation,
      },
      class: 'map-action-modal modal-dialog-centered',
      backdrop: 'static',
    };

    return this.modalService.show(MapActionModalComponent, modalOptions);
  }
}
