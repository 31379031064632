const i = {
    less: "less than a minute read",
    default: "min read"
  },
  o = {
    less: "moins d'une minute de lecture",
    default: "min de lecture"
  },
  l = {
    less: "menos de un minuto leyendo",
    default: "min de lectura"
  },
  d = {
    less: "小于一分钟",
    default: "分钟"
  },
  r = {
    less: "1分未満の読み取り",
    default: "最小読み取り"
  },
  c = {
    less: "weniger als eine Minute Lesezeit",
    default: "Min. Lesezeit"
  },
  m = {
    less: "menos de um minuto de leitura",
    default: "minutos de leitura"
  },
  f = {
    less: "bir dakikadan az okuma süresi",
    default: "dakika okuma süresi"
  },
  p = {
    less: "timp de citire mai puțin de un minut",
    default: "min. timp de lectură"
  },
  g = {
    less: "এক মিনিটের কম সময়ে পড়ুন",
    default: "সর্বনিম্ন সময়ে পড়ুন"
  },
  k = {
    less: "menej ako minúta čítania",
    default: "min čítania"
  },
  h = {
    less: "méně než minuta čtení",
    default: "min čtení"
  },
  b = {
    en: i,
    fr: o,
    es: l,
    cn: d,
    ja: r,
    de: c,
    "pt-br": m,
    tr: f,
    ro: p,
    bn: g,
    sk: k,
    cs: h
  },
  w = ["en", "fr", "es", "cn", "ja", "de", "pt-br", "tr", "ro", "bn", "sk", "cs"],
  L = e => (e.match(/[\w|\d|\s|,|.|\u00C0-\u024F|\u4E00-\u9FA5|\u3041-\u309F]+/giu) ?? []).flatMap(s => s.match(/[\u4E00-\u9FA5]/gu) ?? s),
  M = e => L(e).reduce((t, s) => t + (s.trim().length ? s.trim().split(/\s+/u).length : 0), 0),
  F = e => e < 1 + Number.EPSILON,
  j = (e, t = "en") => b[t][e ? "less" : "default"],
  z = (e, t = 300, s = "en") => {
    const u = M(e),
      n = Math.round(u / t),
      a = F(n);
    return {
      minutes: n,
      words: u,
      text: `${a ? "" : `${n} `}${j(a, s)}`
    };
  };
export { z as readingTime, w as supportedLanguages };