<div class="swiper-image__container" #swiperContainer>
  <img class="swiper-image__bg" #swiperImage [src]="imageSrc" alt="" />
  <ng-content></ng-content>
</div>

<div class="swiper-image__nav">
  @for (stop of stops; track $index) {
  <button
    class="btn-reset dot"
    [ngClass]="{ 'dot--active': $index + 1 === slideIndex }"
    (click)="scrollToSlide($index + 1)"
  >
  </button>
  }
</div>
