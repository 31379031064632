import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'qsc-ds-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ds-spinner.component.html',
  styleUrls: ['./ds-spinner.component.scss'],
})
export class DsSpinnerComponent {
  @Input() text = 'Carregando...';
}
