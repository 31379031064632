import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from '@env';
import { isPlatformBrowser } from '@angular/common';

interface IStorageData<T> {
  value: T;
  secure?: boolean;
}

interface Options {
  secure: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private readonly privateKey = environment.privateStorageKey;
  private readonly storageSub = new Subject<string | null>();

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {}

  watchStorage(): Observable<string | null> {
    return this.storageSub.asObservable();
  }

  saveData<T>(
    key: string,
    value: T,
    options: Options = { secure: false }
  ): void {
    if (!isPlatformBrowser(this.platformId)) return;
    const newValue = this.handleStringify(value, options.secure);
    window.sessionStorage.setItem(key, newValue);
    this.storageSub.next(newValue);
  }

  getData<T>(key: string): T | undefined {
    if (!isPlatformBrowser(this.platformId)) return;
    const value = window.sessionStorage.getItem(key);
    if (!value) return;
    this.storageSub.next(value);
    return this.handleParse(value);
  }

  removeData(key: string): void {
    if (!isPlatformBrowser(this.platformId)) return;
    window.sessionStorage.removeItem(key);
    this.storageSub.next(null);
  }

  clearData(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    window.sessionStorage.clear();
    this.storageSub.next(null);
  }

  private handleStringify(value: any, isSecure: boolean): string {
    const storageData: IStorageData<string> = { value };
    if (isSecure) {
      storageData.value = this.encrypt(value);
      storageData.secure = true;
    }
    return JSON.stringify(storageData);
  }

  private handleParse(data: string): any {
    let result: any = null;
    const parsedStorageData: IStorageData<any> = JSON.parse(data);
    if (parsedStorageData.secure) {
      result = JSON.parse(this.decrypt(parsedStorageData.value));
    } else {
      result = parsedStorageData.value;
    }
    return result;
  }

  private encrypt(data: any): string {
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      this.privateKey
    ).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.privateKey).toString(
      CryptoJS.enc.Utf8
    );
  }
}
