{
  "segments": [
    {
      "id": 1,
      "title": "@HOME-SEGMENTOS-CARD-MINHA-RESIDENCIA-TITULO",
      "description": "@HOME-SEGMENTOS-CARD-MINHA-RESIDENCIA-DESCRICAO",
      "imageUrl": {
        "home": {
          "alt": "@HOME-SEGMENTOS-CARD-MINHA-RESIDENCIA-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/residences/fogão-a-gás-encanado-na-residencia.png",
            "webp": "assets/home/img/segments/residences/fogão-a-gás-encanado-na-residencia.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/residences/fogão-a-gás-encanado-na-residencia.png",
            "webp": "assets/home/img/segments/residences/fogão-a-gás-encanado-na-residencia.webp"
          }
        },
        "default": {
          "alt": "@HOME-SEGMENTOS-CARD-MINHA-RESIDENCIA-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas.png",
            "webp": "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas-mobile.png",
            "webp": "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas-mobile.webp"
          }
        },
        "pools": {
          "alt": "@HOME-SEGMENTOS-CARD-PISCINA-MINHA-RESIDENCIA-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal.png",
            "webp": "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal-mobile.png",
            "webp": "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal-mobile.webp"
          }
        }
      },
      "buttonText": "@HOME-SEGMENTOS-CARD-BOTAO",
      "iconClass": "icon-casa_home",
      "link": "/validar-endereco",
      "linkContent": "/residencias"
    },
    {
      "id": 2,
      "title": "@HOME-SEGMENTOS-CARD-MEU-COMERCIO-TITULO",
      "description": "@HOME-SEGMENTOS-CARD-MEU-COMERCIO-DESCRICAO",
      "imageUrl": {
        "home": {
          "alt": "@HOME-SEGMENTOS-CARD-MEU-COMERCIO-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/commerce/gás-encanado-para-comercio.png",
            "webp": "assets/home/img/segments/commerce/gás-encanado-para-comercio.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/commerce/gás-encanado-para-comercio.png",
            "webp": "assets/home/img/segments/commerce/gás-encanado-para-comercio.webp"
          }
        },
        "default": {
          "alt": "@HOME-SEGMENTOS-CARD-MEU-COMERCIO-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas.png",
            "webp": "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas-mobile.png",
            "webp": "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas-mobile.webp"
          }
        },
        "pools": {
          "alt": "@HOME-SEGMENTOS-CARD-PISCINA-MEU-COMERCIO-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal.png",
            "webp": "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal-mobile.png",
            "webp": "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal-mobile.webp"
          }
        }
      },
      "buttonText": "@HOME-SEGMENTOS-CARD-BOTAO",
      "iconClass": "icon-loja",
      "link": "/validar-endereco",
      "linkContent": "/negocios"
    },
    {
      "id": 3,
      "title": "@HOME-SEGMENTOS-CARD-MINHA-INDUSTRIA-TITULO",
      "description": "@HOME-SEGMENTOS-CARD-MINHA-INDUSTRIA-DESCRICAO",
      "imageUrl": {
        "home": {
          "alt": "@HOME-SEGMENTOS-CARD-MINHA-INDUSTRIA-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/industries/gás-natural-para-industrias.png",
            "webp": "assets/home/img/segments/industries/gás-natural-para-industrias.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/industries/gás-natural-para-industrias.png",
            "webp": "assets/home/img/segments/industries/gás-natural-para-industrias.webp"
          }
        },
        "default": {
          "alt": "@HOME-SEGMENTOS-CARD-MINHA-INDUSTRIA-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas.png",
            "webp": "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas-mobile.png",
            "webp": "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas-mobile.webp"
          }
        }
      },
      "buttonText": "@HOME-SEGMENTOS-CARD-BOTAO",
      "iconClass": "icon-industria",
      "link": "/validar-endereco",
      "linkContent": "/industrias"
    },
    {
      "id": 4,
      "title": "@HOME-SEGMENTOS-CARD-MEU-VEICULO-TITULO",
      "description": "@HOME-SEGMENTOS-CARD-MEU-VEICULO-DESCRICAO",
      "imageUrl": {
        "home": {
          "alt": "@HOME-SEGMENTOS-CARD-MEU-VEICULO-IMAGE-ALT",
          "desktop": {
            "png": "assets/home/img/segments/vehicles/gnv-veiculos-comgas.png",
            "webp": "assets/home/img/segments/vehicles/gnv-veiculos-comgas.webp"
          },
          "mobile": {
            "png": "assets/home/img/segments/vehicles/gnv-veiculos-comgas.png",
            "webp": "assets/home/img/segments/vehicles/gnv-veiculos-comgas.webp"
          }
        }
      },
      "buttonText": "@HOME-SEGMENTOS-CARD-BOTAO-CONTATO",
      "buttonVariant": "secondary_0",
      "iconClass": "icon-carro_frente",
      "link": "/veiculos?secao=contato-gnv",
      "linkContent": "/veiculos"
    }
  ]
}
