import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, SvgIconComponent } from '@shared/components';

@Component({
  selector: 'qsc-human-support-modal',
  templateUrl: './human-support-modal.component.html',
  styleUrls: ['./human-support-modal.component.scss'],
  standalone: true,
  imports: [SvgIconComponent, ButtonComponent, TranslateModule],
})
export class HumanSupportModalComponent {
  confirm = false;

  constructor(private readonly bsModalRef: BsModalRef) {}

  onClose(): void {
    this.confirm = true;
    this.bsModalRef.hide();
  }
}
