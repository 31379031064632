<div class="segment-card">
  <div *ngIf="data" class="d-flex flex-column justify-content-between h-100">
    <div>
      <div
        class="segment-card__image-bg"
        [ngClass]="{
          'cropped-image': index === 0 && withCta && !isHome
        }"
      >
        <div class="segment-card__degrade"></div>
        <qsc-picture
          [webp]="{
            desktop: data.imageUrl[imageDataKey]?.desktop?.webp,
            mobile: data.imageUrl[imageDataKey]?.mobile?.webp
          }"
          [png]="{
            desktop: data.imageUrl[imageDataKey]?.desktop?.png,
            mobile: data.imageUrl[imageDataKey]?.mobile?.png
          }"
          [alt]="data.imageUrl[imageDataKey]?.alt ?? '' | translate"
        >
        </qsc-picture>
        <div
          class="segment-card__image-title"
          *ngIf="!isHome && !isSwimmingPoolFlow"
        >
          <span>
            <i aria-hidden="true" [class]="data.iconClass"></i>
          </span>
          <p [innerHTML]="data.title | translate"></p>
        </div>
      </div>
      <div class="segment-card__title" *ngIf="isHome">
        <span>
          <i aria-hidden="true" [class]="data.iconClass"></i>
        </span>
        <p [innerHTML]="data.title | translate"></p>
      </div>
      <p
        class="segment-card__description-title mx-3"
        *ngIf="isSwimmingPoolFlow"
      >
        {{ "@HOME-SEGMENTOS-CARD-DESCRIPTION-TITLE" | translate }}
      </p>
      <p class="segment-card__description mx-3">
        <span
          *ngIf="!isSwimmingPoolFlow"
          [innerHTML]="data.description | translate"
        ></span>
        <span
          *ngIf="isSwimmingPoolFlow"
          [innerHTML]="data.titleAlt || data.title | translate"
        ></span>
        <br *ngIf="isHome && isAddTagBr(data.description | translate)" />
        <a
          (click)="sendGtmEventOnKnowMore(data.id)"
          *ngIf="isHome"
          [routerLink]="data.linkContent"
          class="segment-card__know-more"
          [innerHTML]="'@KNOW-MORE' | translate"
        ></a>
      </p>
    </div>

    <div class="px-3" *ngIf="isHome || withCta">
      <qsc-button
        (buttonClick)="handleCta(data)"
        variant="green"
        [buttonText]="data.buttonText | translate"
      ></qsc-button>
    </div>
  </div>
</div>
