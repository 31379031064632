import { Injectable } from '@angular/core';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private readonly windowRef: WindowRefService) {}

  startNavigation(
    origin: string,
    destination: string,
    waypoints: google.maps.DirectionsWaypoint[]
  ): void {
    const waypointsStr = waypoints
      .map((wp) => {
        const lat = (wp.location as google.maps.LatLng).lat;
        const lng = (wp.location as google.maps.LatLng).lng;
        return `${lat},${lng}`;
      })
      .join('|');

    const googleMapsURL = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypointsStr}&travelmode=driving`;

    this.windowRef.nativeWindow.open(googleMapsURL, '_blank');
  }
}
