import { Injectable } from '@angular/core';
import { MapSettings } from './map-settings';

@Injectable({
  providedIn: 'root',
})
export class MapFactory {
  constructor(private readonly mapSettings: MapSettings) {}

  async createMapAsync(elementId: string): Promise<google.maps.Map> {
    const { Map: GoogleMap } = (await google.maps.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;
    return new GoogleMap(
      document.getElementById(elementId) as HTMLElement,
      this.mapSettings.defaultOptions()
    );
  }
}
