import { Injectable } from '@angular/core';
import {
  GoogleAnalyticsService,
  GoogleTagManagerService,
  SeoService,
} from '@core/index';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsAppFacade {
  constructor(
    public readonly googleAnalyticsService: GoogleAnalyticsService,
    public readonly googleTagManager: GoogleTagManagerService,
    public readonly seoService: SeoService
  ) {}
}
