import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import {
  ICriptografiaInput,
  ICriptografiaOutput,
} from '@models/cryptography/cryptography';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CryptographyService {
  constructor(private readonly http: HttpClient) {}

  backEndPoint: string = environment.endpoints.backEndPoint;

  encrypt(input: ICriptografiaInput): Observable<ICriptografiaOutput> {
    const url = this.backEndPoint.concat('/criptografia/criptografar');
    return this.http.post<ICriptografiaOutput>(url, input);
  }

  decrypt(input: ICriptografiaInput): Observable<ICriptografiaOutput> {
    const url = this.backEndPoint.concat('/criptografia/descriptografar');
    return this.http.post<ICriptografiaOutput>(url, input);
  }
}
