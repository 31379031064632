import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { IconId, SvgIconComponent } from '../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';

export interface ICustomEventGA {
  category: string;
  action: string;
  label: string;
}

export interface IButtonVariants {
  green: string;
  blue: string;
  lightGreen: string;
  lightBlue: string;
  primary: string;
  ghost: string;
  ghostPrimary: string;
  ghostLight: string;
  ghostAlert: string;
  secondary: string;
  secondary_0: string;
  alert: string;
  link: string;
}

@Component({
  selector: 'qsc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, SvgIconComponent, TranslateModule],
})
export class ButtonComponent {
  @Output() buttonClick = new EventEmitter<any>();
  @Input() buttonText = 'some button text';
  @Input() buttonType: 'button' | 'submit' | 'reset' = 'button';
  @Input() disabled = false;
  @Input() isSelected = false;
  @Input() variant: keyof IButtonVariants = 'primary';
  @Input() iconBefore = false;
  @Input() iconAfter = false;
  @Input() iconClass: string | string[] = '';
  @Input() icon: IconId | undefined;
  @Input() customEventGA?: ICustomEventGA;

  buttonVariants: IButtonVariants = {
    green: 'button--green',
    blue: 'button--blue',
    lightGreen: 'button--lightGreen',
    lightBlue: 'button--lightBlue',
    primary: 'button--primary',
    ghost: 'button--ghost',
    ghostPrimary: 'button--ghostPrimary',
    ghostLight: 'button--ghostLight',
    ghostAlert: 'button--ghostAlert',
    secondary: 'button--secondary',
    secondary_0: 'button--secondary-0',
    alert: 'button--alert',
    link: 'button--link',
  };

  constructor(private readonly googleTagManager: GoogleTagManagerService) {}

  getVariant(): IButtonVariants[keyof IButtonVariants] {
    return this.buttonVariants[this.variant];
  }

  click(evt: any): void {
    this.sendEventGA();
    this.buttonClick.emit(evt);
  }

  sendEventGA(): void {
    if (this.customEventGA?.category && this.customEventGA?.action) {
      this.googleTagManager.sendCustomEvent(
        this.customEventGA.category,
        this.customEventGA.action,
        this.customEventGA.label
      );
    }
  }

  getIconClass(pos: 'before' | 'after'): string {
    if (typeof this.iconClass === 'string') return this.iconClass;
    if (pos === 'before') return this.iconClass[0];
    if (pos === 'after') return this.iconClass[1];
    return '';
  }
}
