import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'qsc-expandable-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpandableListComponent {
  @Input() title = '';
  @Input() isExpanded = false;
  @Output() expanded = new EventEmitter<boolean>();

  toggle() {
    this.isExpanded = !this.isExpanded;
    this.expanded.emit(this.isExpanded);
  }

  preventClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
