<div class="option-buttons">
  <button
    *ngFor="let option of options; let i = index"
    [disabled]="options.length === 1"
    class="option-buttons__button"
    [class.option-buttons__button--active]="i === activeOptionIndex"
    (click)="changeOption(i)"
  >
    <i *ngIf="option.iconClass" [class]="option.iconClass"></i
    ><span> {{ option.label | translate }}</span>
  </button>
</div>
