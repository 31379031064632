import { IUser } from './user.reducer';

const transformProtocol = (protocol: string): string => {
  if (protocol.length >= 8 && protocol.length <= 10) {
    return protocol.padStart(10, '0');
  }
  return protocol;
};

export const mapUserData = (user: Partial<IUser>): IUser => {
  return {
    ...user,
    protocol: user?.protocol && transformProtocol(user.protocol),
  };
};
