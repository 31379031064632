import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PictureComponent } from '../picture/picture.component';
import { TranslateModule } from '@ngx-translate/core';
import { IOptimizedImageData } from '@models/index';

@Component({
  selector: 'qsc-card-colored-border',
  standalone: true,
  imports: [CommonModule, PictureComponent, TranslateModule],
  templateUrl: './card-colored-border.component.html',
  styleUrls: ['./card-colored-border.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardColoredBorderComponent {
  @Input() title = '';
  @Input() image?: IOptimizedImageData;
}
