<div class="input autocomplete" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [htmlFor]="id" class="content-bold">{{
    required && outsideLabel
      ? (outsideLabel | translate) + " *"
      : (outsideLabel | translate)
  }}</label>
  <mat-form-field
    appearance="fill"
    [class.is-valid]="options.length || success"
    [class.is-disabled]="disabled"
  >
    <mat-label *ngIf="insideLabel">{{ insideLabel | translate }}</mat-label>
    <mat-icon *ngIf="prefixIcon && this.input?.value"
      ><i
        aria-hidden="true"
        [class]="prefixIcon"
        [ngClass]="getPrefixIconColor(this.input?.value ?? '', prefixIcon)"
        matTextPrefix
      ></i
    ></mat-icon>
    <input
      [attr.data-testid]="dataTestId"
      [id]="id"
      [name]="name"
      type="text"
      [placeholder]="placeholder | translate"
      [attr.aria-label]="outsideLabel | translate"
      [formControl]="control"
      [required]="required"
      [disabled]="disabled"
      [matAutocomplete]="auto"
      [errorStateMatcher]="errorMatcher"
      [class.ng-pending]="searching"
      (input)="onInput()"
      class="truncate"
      matInput
      #input
    />

    <button
      mat-icon-button
      *ngIf="control.value"
      matSuffix
      title="Limpar campo"
      aria-label="Limpar"
      class="clear-button"
      (click)="
        control.setValue(''); clearButtonEvent.emit(); $event.stopPropagation()
      "
    >
      <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete
      #auto="matAutocomplete"
      (opened)="onOpenedChange()"
      (closed)="onOpenedChange()"
    >
      <mat-option
        disabled
        *ngIf="noData"
        style="white-space: initial; line-height: initial; padding: 0.2rem 1rem"
      >
        <span [innerHTML]="'@NO-ADDRESS-FOUND' | translate"></span>
      </mat-option>

      @if (useVirtualScroll) {
      <cdk-virtual-scroll-viewport
        *ngIf="useVirtualScroll"
        [itemSize]="10"
        [minBufferPx]="100"
        [maxBufferPx]="200"
        [style.height.px]="getViewportHeight(filteredOptions | async)"
      >
        <mat-option
          style="
            white-space: initial;
            line-height: initial;
            padding: 0.2rem 1rem;
          "
          *cdkVirtualFor="let option of filteredOptions | async"
          [value]="option"
          (onSelectionChange)="onSelectionChange(option)"
        >
          <mat-icon *ngIf="prefixIcon && !this.input?.value"
            ><i
              aria-hidden="true"
              [class]="prefixIcon"
              [ngClass]="getPrefixIconColor(option ?? '', prefixIcon)"
              matTextPrefix
            ></i
          ></mat-icon>
          {{ option }}
        </mat-option>
      </cdk-virtual-scroll-viewport>
      } @else {
      <mat-option
        style="white-space: initial; line-height: initial; padding: 0.2rem 1rem"
        *ngFor="let option of filteredOptions | async"
        [value]="option"
        (onSelectionChange)="onSelectionChange(option)"
      >
        {{ option }}
      </mat-option>
      }
    </mat-autocomplete>

    <mat-icon
      *ngIf="
        name === 'address' &&
        (((control.dirty || control.touched) &&
          !control.hasError('required') &&
          (options.length || success)) ||
          control.valid)
      "
      matSuffix
    >
      <i aria-hidden="true" class="icon-check_bola_preenchido"></i>
    </mat-icon>
    <mat-icon
      *ngIf="
        name === 'address' &&
        (control.dirty || control.touched) &&
        control.hasError('required')
      "
      matSuffix
    >
      <i aria-hidden="true" class="icon-erro_fechar"></i>
    </mat-icon>
    <mat-icon *ngIf="searching" matSuffix>
      <img alt="" class="icon-loading" src="/assets/img/loading.gif" />
    </mat-icon>
    <mat-icon *ngIf="name === 'address'" matSuffix>
      <i aria-hidden="true" *ngIf="!success" class="icon-localizacao_gps"></i>
      <i
        aria-hidden="true"
        *ngIf="success"
        class="icon-localizacao_gps is-success"
      ></i>
    </mat-icon>

    <mat-icon
      *ngIf="name !== 'address'"
      [ngClass]="{ 'rotate-y-180': isOpened }"
      matSuffix
      ><i
        aria-hidden="true"
        class="icon-seta_baixo"
        [ngClass]="{ 'icon-seta_baixo--disabled': disabled }"
      ></i
    ></mat-icon>

    <mat-error *ngIf="control.hasError('required')">
      {{ "@ORTEC-CAMPO-REQUERIDO" | translate }}
    </mat-error>
  </mat-form-field>
</div>
