import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FloatingWrapperService {
  private showFloatingWrapper = new BehaviorSubject<boolean>(false);

  getFloatingWrapperStatus(): Observable<boolean> {
    return this.showFloatingWrapper.asObservable();
  }

  updateFloatingWrapperStatus(status: boolean): void {
    this.showFloatingWrapper.next(status);
  }

  resetFloatingWrapperStatus(): void {
    this.showFloatingWrapper.next(false);
  }
}
