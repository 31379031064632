export class AddressUtil {
  static build(address: any): string {
    if (!address.cep) return 'Nenhum endereço encontrado';

    const bairro =
      address.bairro!.length > 0
        ? `${address.bairro}, ${address.cidade ?? address.localidade}`
        : `${address.cidade ?? address.localidade}`;

    return `${this.capitalize(address.logradouro)}, ${this.capitalize(
      bairro
    )}, ${address.estado} - ${address.cep}`;
  }

  private static capitalize(value: string): string {
    if (!value) return '';

    const words = value.split(' ').filter((word) => word.length > 0);

    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(' ');
  }
}
