import { Inject, Injectable } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AnotherFlowModalComponent } from '@modules/address-validation/components/another-flow-modal/another-flow-modal.component';
import { CryptographyService } from '../cryptography/cryptography.service';
import { ICriptografiaInput } from '@models/cryptography/cryptography';
import { environment } from '@env';
import { DOCUMENT } from '@angular/common';
import { ChatModalService } from './chat-modal.service';
import { IApiError } from '@models/error/api-error';
import { fromUser } from '@core/index';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AnotherFlowsModalService {
  bsModalRefAnotherFlow: any;
  window: any;

  constructor(
    private readonly bsModalService: BsModalService,
    private readonly cryptographyService: CryptographyService,
    private readonly chatModalService: ChatModalService,
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.window = this.document.defaultView;
  }

  openAnotherFlowModal(urlData = ''): void {
    this.store
      .select(fromUser.selectUserData)
      .subscribe((data: fromUser.IUser | null) => {
        const modalOptions: ModalOptions = {
          initialState: {
            flowType: data?.flowTypeData?.conteudo.tipo,
          },
          class: 'another-flow-modal modal-dialog-centered',
        };

        this.bsModalRefAnotherFlow = this.bsModalService.show(
          AnotherFlowModalComponent,
          modalOptions
        );

        if (!data?.flowTypeData) {
          this.bsModalRefAnotherFlow.hide();
          return;
        }

        const object = { ...data.flowTypeData.conteudo };
        object.nomeCompleto = data?.name ?? '';

        this.bsModalRefAnotherFlow.onHide.subscribe(() => {
          this.redirectToOtherFlows({ texto: JSON.stringify(object) }, urlData);
        });
      });
  }

  redirectToOtherFlows(
    cryptographyInput: ICriptografiaInput,
    urlData = ''
  ): void {
    this.cryptographyService.encrypt(cryptographyInput).subscribe({
      next: (data: any) => {
        if (!data?.texto) {
          return;
        }
        const url = `${environment.endpoints.comgasVirtual}${
          !urlData || urlData.length <= 0
            ? `queroComgas?lead=${encodeURIComponent(data.texto)}`
            : urlData
        }`;

        this.window.open(url, '_self');
      },
      error: (err: IApiError) => {
        this.chatModalService.openChatModal({ apiError: err });
      },
    });
  }
}
