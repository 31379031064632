export const HomeAnalytics = {
  category: {
    tipoDeImovel: 'tipo de imóvel',
    faq: 'faq',
    homeDepoimentos: 'home depoimentos',
    acessoRapido: 'acesso rapido',
    ctaBanner: 'cta banner',
    modalConfirmacaoPiscinas: 'modal confirmacao piscinas',
    conhecaMais: 'conheça mais',
  },
  action: {
    residencias: 'residencias',
    negocios: 'negocios',
    predios: 'predios',
    industrias: 'industrias',
    veiculos: 'veiculos',
    faqOndePodeSerUtilizado: 'onde pode ser utilizado',
    faqQualADirefencaDoGas: 'qual a diferença do gás',
    faqQuaisVantagensFinanceiras: 'quais vantagens financeiras',
    faqComoFeitaCobranca: 'como é feita a cobrança',
    faqLinkDebitoAutomatico: 'débito automático',
    faqComoTornarMeusEquipamentosAptos: 'como tornar meus equipamentos aptos',
    visualizarMais: 'visualizar mais',
    bannerNovaCampanha: 'nova campanha',
    bannerPiscina: 'banner piscina',
    sim: 'sim',
    nao: 'nao',
    residenciaUnificada:'resid_uni'
  },
  label: {
    clique: 'clique',
    abrir: 'abrir',
    fechar: 'fechar',
    saberMais: 'saber mais',
    contratarAgora: 'contratar agora',
  },
} as const;

export const HOME_GTM_SEGMENTS = {
  1: HomeAnalytics.action.residenciaUnificada,
  2: HomeAnalytics.action.negocios,
  3: HomeAnalytics.action.industrias,
  4: HomeAnalytics.action.veiculos,
};

export type HomeGtmSegments = typeof HOME_GTM_SEGMENTS;
