import { fromUser } from '@core/index';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { isCpf } from '@shared/utils';
import { IEnviaLeadDadosPessoais } from '@models/index';

@Injectable({
  providedIn: 'root',
})
export class PersonalDataMapper {
  userData?: fromUser.IUser | null;

  constructor(private readonly store: Store) {
    this.store.select(fromUser.selectUserData).subscribe((data) => {
      this.userData = data;
    });
  }

  createPersonalData(): IEnviaLeadDadosPessoais {
    const document = this.userData?.document ?? '';

    return {
      cpf: isCpf(document) ? document : '',
      cnpj: !isCpf(document) ? document : '',
      email: this.userData?.email ?? '',
      nome: this.userData?.name ?? '',
      razaoSocial: this.userData?.company ?? '',
      telefone: this.userData?.phoneNumber ?? '',
    };
  }
}
