import { SegmentService } from '@shared/services/segment/segment.service';

export enum SegmentEnum {
  Residencial = 'RESIDENCIAL',
  Comercial = 'COMERCIAL',
  Industrial = 'INDUSTRIAL',
}

export enum SegmentIdsEnum {
  Residences = 1,
  Commerce,
  Industry,
}

export function getSegment(segmentService: SegmentService): SegmentEnum {
  if (segmentService.isResidences()) {
    return SegmentEnum.Residencial;
  }
  if (segmentService.isCommerce()) {
    return SegmentEnum.Comercial;
  }
  if (segmentService.isIndustry()) {
    return SegmentEnum.Industrial;
  }
  return SegmentEnum.Residencial;
}
