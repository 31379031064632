import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerService, UiService } from '@core/index';
import { HomeAnalytics } from '@models/analytics';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  SwiperOptions,
} from 'swiper';
import { PictureComponent } from '../picture/picture.component';
import { IOptimizedImageData } from '@models/index';

SwiperCore.use([Pagination, Navigation, Autoplay]);

export interface ITestimonialsTextCard {
  id: number;
  name: string;
  role: string;
  description: string;
  image: IOptimizedImageData;
}

@Component({
  selector: 'qsc-testimonials-text-cards',
  templateUrl: './testimonials-text-cards.component.html',
  styleUrls: ['./testimonials-text-cards.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SwiperModule, NgFor, PictureComponent, TranslateModule],
})
export class TestimonialsTextCardsComponent implements OnInit {
  isDesktop = false;
  currentBreakpoint = '';
  @Input() data: ITestimonialsTextCard[] = [];

  config: SwiperOptions = {
    navigation: true,
    pagination: true,
    slidesPerView: 1,
    spaceBetween: 16,
    breakpoints: {
      576: {
        slidesOffsetBefore: 36,
        slidesOffsetAfter: 36,
        slidesPerView: 1.5,
        spaceBetween: 16,
      },
      768: {
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
        slidesPerView: 1.9,
        spaceBetween: 16,
      },
      992: {
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
        slidesPerView: 2.5,
        spaceBetween: 16,
      },
      1200: {
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
        slidesPerView: 2.1,
        spaceBetween: 16,
        slidesPerGroup: 2,
      },
      1400: {
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        slidesPerView: 3,
        spaceBetween: 16,
        slidesPerGroup: 3,
        pagination: {
          clickable: true,
        },
      },
    },
  };

  constructor(
    private readonly uiService: UiService,
    private readonly googleTagManager: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.isDesktop = !this.uiService.isMobile;
    this.swiperConfigSetup();
  }

  swiperConfigSetup(): void {
    if (this.isDesktop) {
      this.config.autoplay = {
        delay: 15000,
        disableOnInteraction: false,
      };
    }
  }

  onSlideNextTransitionEnd() {
    this.sendEventGA();
  }

  onSlidePrevTransitionEnd() {
    this.sendEventGA();
  }

  sendEventGA() {
    this.googleTagManager.sendCustomEvent(
      HomeAnalytics.category.homeDepoimentos,
      HomeAnalytics.action.visualizarMais,
      HomeAnalytics.label.clique
    );
  }
}
