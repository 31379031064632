import { Injectable } from '@angular/core';
import { IMarkerData, LocalType } from '../marker/marker-data';
import { ElementService } from '@shared/services/html/element.service';
import { LinkService } from '@shared/services/html/link.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class InfoWindowService {
  private linkBase = 'https://www.google.com/maps';

  contentDiv: HTMLElement | null = null;
  linkDiv: HTMLElement | null = null;

  constructor(
    private readonly elementService: ElementService,
    private readonly linkService: LinkService,
    private readonly translateService: TranslateService
  ) {}

  createContent(data: IMarkerData, localType: LocalType): HTMLElement {
    this.contentDiv = this.elementService.createElement(
      'div',
      'info-window-content'
    );

    const nameAndAddressDiv = this.elementService.createElement('div');
    nameAndAddressDiv.append(
      this.createTitle(data.name),
      this.createAddress(data),
      this.createMapLink(data)
    );

    this.contentDiv.append(
      nameAndAddressDiv,
      this.createDirectionLink(data, localType)
    );

    return this.contentDiv;
  }

  private createTitle(title?: string): HTMLElement {
    const element = this.elementService.createElement(
      'div',
      'info-window-content__name'
    );
    element.appendChild(this.elementService.createTextNode(title));
    return element;
  }

  private createAddress(data: IMarkerData): HTMLElement {
    const addressDiv = this.elementService.createElement(
      'div',
      'info-window-content__address'
    );
    addressDiv.append(
      this.elementService.createTextNode(`${data.address} -`),
      this.elementService.createTextNode(
        `${data.area}, ${data.city} - ${data.state},`
      ),
      this.elementService.createTextNode(data.postalCode ?? '')
    );
    return addressDiv;
  }

  private createMapLink(data: IMarkerData): HTMLElement {
    this.linkDiv = this.elementService.createElement(
      'div',
      'info-window-content__link'
    );

    this.linkDiv.append(
      this.linkService.createLink(
        data.link ?? '',
        this.translateService.instant('@VEHICLES-CONTENT-MAPS-LABEL-1')
      )
    );

    return this.linkDiv;
  }

  private createDirectionLink(
    data: IMarkerData,
    localType?: LocalType
  ): HTMLElement {
    const url = `${this.linkBase}/dir/`;
    const link = this.linkService.createLink(
      `${url}${data.address},${data.area},${data.city},${data.localName},${localType}`,
      this.translateService.instant('@VEHICLES-CONTENT-MAPS-LABEL-2')
    );

    const iconDiv = this.elementService.createElement(
      'div',
      'info-window-content__navigate'
    );
    link.appendChild(iconDiv);

    return this.elementService.createElement('div').appendChild(link);
  }
}
