@if (displayedBreadcrumbs.length > 0) {
<nav aria-label="breadcrumb" class="breadcrumb-swiper pt-3">
  <swiper [config]="swiperConfig" #swiper>
    <ng-template swiperSlide>
      <div class="breadcrumb-swiper__item">
        <button
          type="button"
          class="btn-reset"
          (click)="navigate(homeBreadcrumbItem)"
        >
          <i class="icon-casa_home"></i>
        </button>
      </div>
    </ng-template>
    @for (breadcrumb of displayedBreadcrumbs; track $index) {
    <ng-template swiperSlide>
      <div class="breadcrumb-swiper__item">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6504 9.46186L6.5007 14.8008C6.02844 15.2904 5.25586 15.2904 4.78414 14.8008L4.35379 14.3552C3.88207 13.8667 3.88207 13.0651 4.35379 12.5761L8.28388 8.50158L4.35379 4.42652C3.88207 3.93747 3.88207 3.13594 4.35379 2.64745L4.78414 2.20184C5.25586 1.71223 6.02844 1.71223 6.5007 2.20184L11.6504 7.54186C11.904 7.80421 12.0168 8.15679 11.998 8.50158C12.0168 8.84637 11.904 9.19839 11.6504 9.46186Z"
            [attr.fill]="
              breadcrumb?.status === 'current'
                ? '#FFF'
                : breadcrumb?.status === 'next'
                ? '#005075'
                : '#9CCFE7'
            "
          />
        </svg>
        @if (breadcrumb?.status === 'previous') {
        <button
          type="button"
          class="btn-reset breadcrumb-previous"
          (click)="navigate(breadcrumb)"
        >
          {{ breadcrumb.label | translate }}
        </button>
        } @if (breadcrumb?.status === 'current') {
        <span class="breadcrumb-current">{{
          breadcrumb.label | translate
        }}</span>
        } @if (breadcrumb?.status === 'next') {
        <span class="breadcrumb-next">{{ breadcrumb.label | translate }}</span>
        }
      </div>
    </ng-template>
    }
  </swiper>
</nav>
}
