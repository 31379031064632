import { TranslateService } from '@ngx-translate/core';

export const extractAllHrefsRegex = (
  html: string,
  translate: TranslateService
): string[] => {
  const translated = translate.instant(html);
  const regex = /<a[^>]*href=['"]([^'"]+)['"][^>]*>/g;
  const hrefs: string[] = [];
  let match: RegExpExecArray | null;
  while ((match = regex.exec(translated)) !== null) {
    const href = match[1];
    if (href.startsWith('/')) {
      const host = window.location.origin;
      hrefs.push(`${host}${href}`);
    } else {
      hrefs.push(href);
    }
  }
  return hrefs;
};
