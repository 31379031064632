import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[copyToClipboard]',
  standalone: true,
})
export class CopyToClipboardDirective {
  @Input('copyToClipboard')
  public payload?: string;

  @Output()
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public async onClick(event: MouseEvent): Promise<void> {
    event.preventDefault();
    if (!this.payload) return;

    try {
      await navigator.clipboard.writeText(this.payload);
      this.copied.emit(this.payload);
    } catch (err) {
      console.error('Error in copying text: ', err);
    }
  }
}
