<div class="form-check form-switch theme-switcher">
  <label class="form-check-label" for="flexSwitchCheckDefault">{{
    "@THEME-CONTRAST" | translate
  }}</label>
  <input
    class="form-check-input"
    type="checkbox"
    role="switch"
    id="flexSwitchCheckDefault"
    [ariaChecked]="checked()"
    [checked]="checked()"
    (change)="toggleTheme()"
  />
</div>
