import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  NgIf,
  NgClass,
  NgFor,
  KeyValuePipe,
  NgTemplateOutlet,
} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IDevice } from '@models/device/device';
import { IOptimizedImageBreakpoints } from '@models/index';
import { OrderByPipe } from '../../pipes/order-by/order-by.pipe';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

type IPictureDevice = {
  [key in keyof IDevice]?: string;
};

@Component({
  selector: 'qsc-picture',
  templateUrl: './picture.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    KeyValuePipe,
    OrderByPipe,
    NgTemplateOutlet,
    FileHashPipe,
    TranslateModule,
  ],
})
export class PictureComponent {
  @Input() webp?: IPictureDevice;
  @Input() png?: IPictureDevice;
  @Input() alt?: string;
  @Input() title?: string;
  @Input() figCaption?: string;
  @Input() fullWidth?: boolean;
  @Input() fullHeight?: boolean;
  @Input() lazy = true;
  @Input() breakpoints?: IOptimizedImageBreakpoints;
  @Input() cover?: boolean;
}
