export const HeaderAnalytics = {
  category: {
    menuPrincipal: 'menu principal',
    busca: 'busca',
    acessibilidade: 'acessibilidade',
  },
  action: {
    emergencia: 'emergencia',
    jaSouCliente: 'ja sou cliente',
    pesquisa: 'pesquisa',
    contratarAgora: 'contratar agora',
    residencias: 'residencias',
    predios: 'predios',
    negocios: 'negocios',
    veiculos: 'veiculos',
    industrias: 'industrias',
    blog: 'blog',
    buscaValida: 'busca valida',
    buscaInvalida: 'busca invalida',
    modalBusca: 'modal busca',
    login: 'login',
    agendamentoVideochamada: 'agendamento videochamada',
    bannerHamburguer: 'banner hamburguer',
    contrasteAcessivel: 'contraste acessivel',
  },
  label: {
    clique: 'clique',
    abrir: 'abrir',
    residencias: 'residencias',
    predios: 'predios',
    negocios: 'negocios',
    industrias: 'industrias',
    veiculos: 'veiculos',
    fazerLogin: 'fazer login',
    contratarServicos: 'contratar servicos',
  },
} as const;

export const HEADER_GTM_SEGMENTS = {
  1: HeaderAnalytics.action.residencias,
  2: HeaderAnalytics.action.predios,
  3: HeaderAnalytics.action.negocios,
  4: HeaderAnalytics.action.veiculos,
  5: HeaderAnalytics.action.industrias,
  6: HeaderAnalytics.action.blog,
};

export type HeaderGtmSegments = typeof HEADER_GTM_SEGMENTS;
