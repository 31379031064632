import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { CopyToClipboardDirective } from '@shared/directives';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'qsc-copy-clipboard',
  templateUrl: './copy-clipboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CopyToClipboardDirective, NgIf, SvgIconComponent],
})
export class CopyClipboardComponent {
  @Input() width = '1.5rem';
  @Input() height = '1.5rem';
  @Input() valueToCopy?: string;
  @Input() successMessage?: string;
  @Input() variant: 'default' | 'light' = 'default';
  @Output() copyChange = new EventEmitter<boolean>(false);

  copied = false;

  constructor(private toastr: ToastrService) {}

  notify(): void {
    if (this.copied) return;
    this.copied = true;
    this.toastr.show(this.successMessage, undefined, {
      positionClass: 'toast-center-center',
    });

    this.copyChange.emit(true);
  }
}
