import { Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ElementService {
  constructor(private renderer: Renderer2) {}

  createElement(tag: string, className?: string): HTMLElement {
    const element = document.createElement(tag);
    if (className) {
      element.classList.add(className);
    }
    return element;
  }

  createElementWithClasses(tag: string, classesName: string[]): HTMLElement {
    const element = document.createElement(tag);
    classesName.forEach((className) => element.classList.add(className));
    return element;
  }

  createTextNode(text?: string): Text {
    return document.createTextNode(text ?? '');
  }

  createLabel(
    iconClass: string,
    iconColorClass: string,
    text: string
  ): HTMLElement {
    const labelContainer = this.renderer.createElement('div');
    this.renderer.addClass(labelContainer, 'd-flex');
    this.renderer.addClass(labelContainer, 'gap-1');
    this.renderer.addClass(labelContainer, 'align-items-center');

    const icon = this.renderer.createElement('i');
    this.renderer.setAttribute(icon, 'aria-hidden', 'true');
    this.renderer.addClass(icon, iconClass);
    this.renderer.addClass(icon, iconColorClass);
    this.renderer.appendChild(labelContainer, icon);

    const span = this.renderer.createElement('span');
    const textNode = this.renderer.createText(text);
    this.renderer.appendChild(span, textNode);
    this.renderer.appendChild(labelContainer, span);

    return labelContainer;
  }
}
