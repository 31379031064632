<div class="input autocomplete">
  <mat-form-field appearance="fill">
    <input
      [id]="id"
      [name]="name"
      type="text"
      [placeholder]="placeholder"
      matInput
      [formControl]="control"
      [matAutocomplete]="autoCompleteInput"
      class="truncate"
      #input
      maxlength="40"
    />
    <mat-icon matPrefix>
      <i aria-hidden="true" class="icon-buscar_lupa"></i>
    </mat-icon>
    <mat-autocomplete class="search" #autoCompleteInput="matAutocomplete">
      <mat-option
        disabled
        *ngIf="notFound"
        style="white-space: initial; line-height: initial; padding: 0.2rem 1rem"
      >
        <div class="not-found">
          <div
            class="not-found__title"
            [innerHTML]="
              '@HEADER-BUSCA-NAO-ENCONTRADA'
                | translate : { input: input.value }
            "
          ></div>
          <ul>
            <li
              [innerHTML]="'@HEADER-BUSCA-DIGITOU-CORRETAMENTE' | translate"
            ></li>
            <li [innerHTML]="'@HEADER-BUSCA-TENTAR-NOVAMENTE' | translate"></li>
            <li>
              <a
                (click)="onNavigate()"
                href="javascript:void(0)"
                [innerHTML]="'@HEADER-BUSCA-OUTRAS-PAGINAS' | translate"
              ></a
              ><span [innerHTML]="'@HEADER-BUSCA-COMGAS' | translate"></span>
            </li>
          </ul>
        </div>
      </mat-option>
      <mat-option
        style="white-space: initial; line-height: initial; padding: 0.2rem 1rem"
        *ngFor="let option of filteredOptions | async"
        [value]="option.names[0]"
        (onSelectionChange)="onSelectionChange(option)"
      >
        <span [innerHTML]="highlightMatchingText(option.names[0])"></span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
