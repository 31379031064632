<div class="base__loading-container text-center">
  <div
    *ngIf="!loadingStatus?.error && !loadingStatus?.success"
    class="base__loading-status--default spinner-border text-light"
    role="status"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
  <span
    *ngIf="loadingStatus?.success && !loadingStatus?.error"
    class="base__loading-status--unicode text-light m-0 p-0"
    role="status"
    >&#x2713;</span
  >
  <span
    *ngIf="!loadingStatus?.success && loadingStatus?.error"
    class="base__loading-status--unicode text-light m-0 p-0"
    role="status"
    F
    >&#x26A0;</span
  >
  <p class="content-default text-light m-0">
    {{ translatedText }}
  </p>
</div>
