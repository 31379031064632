import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DirectionsRendererService {
  private directionsRenderer: google.maps.DirectionsRenderer | null = null;

  async initializeRenderer(map: google.maps.Map) {
    const { DirectionsRenderer: DirectionsRenderer } =
      (await google.maps.importLibrary('routes')) as google.maps.RoutesLibrary;
    this.directionsRenderer = new DirectionsRenderer();
    this.directionsRenderer.setOptions({
      suppressInfoWindows: true,
      suppressMarkers: true,
    });

    this.directionsRenderer?.setMap(map);
  }

  async renderRoute(directionsResult: google.maps.DirectionsResult | null) {
    if (!directionsResult) return;
    this.directionsRenderer?.setDirections(directionsResult);
  }

  clearRoute(): void {
    if (this.directionsRenderer) {
      const emptyDirectionsResult: google.maps.DirectionsResult = {
        routes: [],
        request: {
          origin: '',
          destination: '',
          travelMode: google.maps.TravelMode.DRIVING,
        },
      };

      this.directionsRenderer.setDirections(emptyDirectionsResult);
    }
  }
}
