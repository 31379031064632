<div class="container">
  <div class="d-flex flex-column align-items-center">
    <div class="container__icon">
      <qsc-svg-icon icon="green-logo"></qsc-svg-icon>
    </div>

    <div>
      <p class="container__title" [innerHTML]="getTitle()"></p>
    </div>

    <div>
      <p
        class="container__subtitle"
        [innerHTML]="'@ANOTHER-FLOW-MODAL-SUBTITLE' | translate"
      ></p>
    </div>
  </div>
</div>
