import { SeoJson } from '@core/index';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private schemaScript: HTMLScriptElement | null = null;

  constructor(
    private readonly meta: Meta,
    private readonly title: Title,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  addMetaTags(metatags: any[]): void {
    metatags.forEach((item: any) => {
      this.translateService.get(item.content).subscribe((translated) => {
        item.content = translated;
        this.meta.updateTag(item);
      });
    });
  }

  updateMetaTags() {
    const route = this.getChild(this.activatedRoute);
    route.data.subscribe(({ meta }) => {
      if (meta?.description) {
        this.updateDescription(meta.description);
      }
    });
  }

  updateTitle(title: string) {
    this.translateService.get(title).subscribe((translatedTitle) => {
      this.title.setTitle(`${SeoJson.sitename} | ${translatedTitle}`);
    });
  }

  updateDescription(description: string) {
    this.translateService
      .get(description)
      .subscribe((translatedDescription) => {
        this.meta.updateTag({
          name: 'description',
          content: translatedDescription,
        });
      });
  }

  private getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  updateCanonicalUrl(url: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let element = this.document.querySelector(`link[rel='canonical']`) ?? null;
    if (element === null) {
      element = this.document.createElement('link');
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url.split('?')[0]);
  }

  addSchema(schema: any) {
    this.removeSchema();
    this.schemaScript = this.renderer.createElement('script');
    this.renderer.setAttribute(
      this.schemaScript,
      'type',
      'application/ld+json'
    );
    if (this.schemaScript) {
      this.schemaScript.textContent = JSON.stringify(schema);
      this.renderer.appendChild(this.document.head, this.schemaScript);
    }
  }

  removeSchema() {
    if (this.schemaScript) {
      this.renderer.removeChild(this.document.head, this.schemaScript);
      this.schemaScript = null;
    }
  }
}
