import { Injectable } from '@angular/core';
import { readingTime } from 'reading-time-estimator';

@Injectable({
  providedIn: 'root',
})
export class ReadingTimeService {
  getReadingTime(text: string): { time: number; unit: string } {
    const stats = readingTime(text, 200, 'pt-br');
    const minutes = Math.ceil(stats.minutes);
    return {
      time: minutes,
      unit: minutes > 1 ? 'minutos' : 'minuto',
    };
  }
}
