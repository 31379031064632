import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import {
  IDisponibilidadeRedeInput,
  IDisponibilidadeRedeOutput,
} from '@models/network-availability/network-availability';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { userActions } from '@core/store';
import { selectUserData } from '@core/store/user/user.reducer';

@Injectable({
  providedIn: 'root',
})
export class NetworkAvailabilityService {
  private readonly _backEndPoint = environment.endpoints.backEndPoint;
  private networkAvailabilityInput?: IDisponibilidadeRedeInput;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store
  ) {
    this.store.select(selectUserData).subscribe((data) => {
      this.networkAvailabilityInput = data?.networkAvailabilityInput;
    });
  }

  saveInput(input: IDisponibilidadeRedeInput): void {
    this.store.dispatch(
      userActions.updateUser({
        user: {
          networkAvailabilityInput: input,
        },
      })
    );
  }

  loadInput(): IDisponibilidadeRedeInput | undefined {
    return this.networkAvailabilityInput;
  }

  removeInput(): void {
    this.store.dispatch(
      userActions.updateUser({
        user: {
          networkAvailabilityInput: undefined,
        },
      })
    );
  }

  postNetworkAvailability(
    input: IDisponibilidadeRedeInput
  ): Observable<IDisponibilidadeRedeOutput> {
    const url = this._backEndPoint.concat('/disponibilidadeRede');
    return this.http.post<IDisponibilidadeRedeOutput>(url, input);
  }
}
