import { Pipe, PipeTransform } from '@angular/core';
import staticAssetsList from 'src/assets.json';

@Pipe({
  name: 'fileHash',
  standalone: true,
})
export class FileHashPipe implements PipeTransform {
  private staticAssets: { [key: string]: string };

  constructor() {
    this.staticAssets = staticAssetsList;
  }

  private addParamsToUrl(givenUrl: string, urlParameters: string): string {
    if (typeof urlParameters !== 'string' || urlParameters.length === 0) {
      return givenUrl;
    }

    const urlSplitByHash: string[] = givenUrl.split('#');
    const hash: string = urlSplitByHash[1] || '';
    const params: string[] = urlParameters.split('&');
    let url: string = urlSplitByHash[0];

    if (url.indexOf('?') === -1) {
      url += '?';
    } else {
      url += '&';
    }

    url += params
      .map((paramItem: string): string => {
        const p: string[] = paramItem.split('=');

        return `${p[0]}=${encodeURIComponent(p[1])}`;
      })
      .join('&');

    url = url.slice(0, -1); // remove last &

    return hash ? `${url}#${hash}` : url;
  }

  private getHashForStaticAsset(assetPath: string): string {
    const path: string = assetPath.split('#')[0];

    if (typeof this.staticAssets[path] === 'string') {
      return this.addParamsToUrl(assetPath, `c=${this.staticAssets[path]}`);
    }

    return '';
  }

  public transform(filePath?: string): string {
    if (!filePath) return '';
    const filePathWithCacheHash: string = this.getHashForStaticAsset(filePath);
    return filePathWithCacheHash;
  }
}
