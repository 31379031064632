import { BaseMarkerStrategy } from './base-marker-strategy';

export class CommonMarkerStrategy extends BaseMarkerStrategy {
  protected getSrc(): string {
    return '/assets/vehicles-content/img/common-station.png';
  }
  protected getWidth(): string {
    return '28px';
  }
  protected getHeight(): string {
    return '28px';
  }
}
