import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  IButtonVariants,
  SvgIconComponent,
} from '@shared/components';
import { NgFor, NgIf } from '@angular/common';

export interface OptionConfig {
  text: string;
  action: () => void;
  variant: keyof IButtonVariants;
}

@Component({
  selector: 'qsc-question-dialog-modal',
  templateUrl: './question-dialog-modal.component.html',
  styleUrls: ['./question-dialog-modal.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [SvgIconComponent, ButtonComponent, TranslateModule, NgIf, NgFor],
})
export class QuestionDialogModalComponent {
  @Input() iconClass = '';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() options: OptionConfig[] = [];
  @Input() backButtonClick?: any;

  constructor(private readonly bsModalRef: BsModalRef) {}

  onOptionClick(action: () => void): void {
    action();
    this.bsModalRef.hide();
  }

  onBackButtonClick(): void {
    if (this.backButtonClick) {
      this.backButtonClick();
    }

    this.bsModalRef.hide();
  }
}
