{
  "assets/blog/img/aquecimento-direto-indireto-mobile.png": "9zvTc1hnSk2r-b3AtvZX7ufJ0JV1FSGMHJNICrNcpPU",
  "assets/blog/img/aquecimento-direto-indireto-mobile.webp": "Kn8ue5DDCw11T9mo7fktubDJhxnjR7B8KeA-v7_jdwE",
  "assets/blog/img/aquecimento-direto-indireto.png": "mW4_ad9LiAtzlRTKpj6uX08wQ7gjDjwoYNN2zKYS5wU",
  "assets/blog/img/aquecimento-direto-indireto.webp": "l5rgtlRMs2R2DmCBm6lqMYphn-Q5h2fAN4X24YVsmdo",
  "assets/blog/img/chama-do-fogao-a-gas-mobile.png": "EdaL1sFWpM8DvgVQ-rlTuf_SiKdSpq3BYXPvFxZKTa0",
  "assets/blog/img/chama-do-fogao-a-gas-mobile.webp": "sNggSyjpyvMvbyHiE6G3ICA5m6PeP_V5g-Wkdc_l-7s",
  "assets/blog/img/chama-do-fogao-a-gas.png": "fontHFOkEM4xNW3R1qkmYPRGTnCI-011J-LI_Wt4GLw",
  "assets/blog/img/chama-do-fogao-a-gas.webp": "gFvLYMQeIFCQaTA1Y8H0hXaktkwnJPVgx0ip70N4XX8",
  "assets/blog/img/chuveiro-a-gas-natural-1200w.png": "7IswLvLvtj9DkHAAXj2F-FI6bWfBZkFVCAjUevVbtx4",
  "assets/blog/img/chuveiro-a-gas-natural-1200w.webp": "GrFSFYIXTXS7Gr4gQUEpOHwkSU-NqesHibk5Odw7xr4",
  "assets/blog/img/chuveiro-a-gas-natural-1400w.png": "u40kJMpn5VyYIpBDmupDagyJ7cKJQQ1e86Ef7lkIEIg",
  "assets/blog/img/chuveiro-a-gas-natural-1400w.webp": "KTL3iUu_1smNOfT2JcN2GbITAnGEg5B0mqe3KRZZO6k",
  "assets/blog/img/chuveiro-a-gas-natural-576w.png": "-l-d2ZjUMaKItYGiu0QuQlI69jYh4D0hrQZ43drdX9w",
  "assets/blog/img/chuveiro-a-gas-natural-576w.webp": "li7sVFStRBVOF-yE-EkZm-CgfR-6Dv5PulsSvaY8jfg",
  "assets/blog/img/chuveiro-a-gas-natural-768w.png": "tnOq8ru4pRH3Nz92mb2w8_8h935Legm7V20tfQRWHds",
  "assets/blog/img/chuveiro-a-gas-natural-768w.webp": "3-hnqLinJvOQMjpYEgZt000wRGmcKMRKvJho64CKoec",
  "assets/blog/img/chuveiro-a-gas-natural-992w.png": "LCHXV86TS1nbDQmxW0-WD1cMveakZrmzLID3vkGPbyQ",
  "assets/blog/img/chuveiro-a-gas-natural-992w.webp": "Ud-a7GoWti2Ko1Vv4prRkwtgj7q0xH_A9tcsn-agPZ8",
  "assets/blog/img/chuveiro-a-gas-natural-mobile.png": "YdQByWW-jWwfHlrJbfps0bNs0n5_YZdOj3m0CIWBB-k",
  "assets/blog/img/chuveiro-a-gas-natural-mobile.webp": "Ayszsb7PpuEuIHQGEmlDstpWg9Z-Po-kYXivhWXLzpE",
  "assets/blog/img/chuveiro-a-gas-natural.png": "qIbvwj6LciuBS-wIAeFDw7EFEhxu8McfsdfBimzLOZE",
  "assets/blog/img/chuveiro-a-gas-natural.webp": "i3bEeEVtlKSxauMkkepGfrdyt7m_ayhpZ0XR6juBxNs",
  "assets/blog/img/clube-com-piscina-aquecida-coberta-mobile.png": "_lxxue8ARNDpWjR7jtPnkegQndGeDQ_1-Sja-GRHvz0",
  "assets/blog/img/clube-com-piscina-aquecida-coberta-mobile.webp": "yH4hPI3pw2WDb-jvKpwXktSvyh4aTjZzSF2AoDrC0Jc",
  "assets/blog/img/clube-com-piscina-aquecida-coberta.png": "WrqnDIvcLBeKRIUzr48q7WloqUW8XKOJNHyh7ddMLUg",
  "assets/blog/img/clube-com-piscina-aquecida-coberta.webp": "tU5P3oD6iUY7Rn9p3dPrZxc70I1qDhMnMLh4KIrCibw",
  "assets/blog/img/crianca-mergulhando-em-piscina-de-agua-quente-mobile.png": "T4aOBHIy4ON9Wes-dnzKcATq1LLAX64tklMTpBRpUZQ",
  "assets/blog/img/crianca-mergulhando-em-piscina-de-agua-quente-mobile.webp": "ojM35AHaab_khI0DfccS6Qey3vLK_YRNXwUxKjrmNDc",
  "assets/blog/img/crianca-mergulhando-em-piscina-de-agua-quente.png": "3RDyn3KpSigiVpj6cTbaOIa-UInLttMvqhlD4pvYi_g",
  "assets/blog/img/crianca-mergulhando-em-piscina-de-agua-quente.webp": "EPIsijII8VKz95mTqTnTCWMkrepJNyBt-7ONLMADtE4",
  "assets/blog/img/mulher-calculando-contas-cozinha-mobile.png": "5lYVP4MGzu_o2P5sSx9fpfWFjIMgy64TRxYcdq30YlU",
  "assets/blog/img/mulher-calculando-contas-cozinha-mobile.webp": "2bR-Uc_Mx4NESsnSKPc-F6oT0dcfL7oW7MoFI3UXGNk",
  "assets/blog/img/mulher-calculando-contas-cozinha.png": "RdaQFOpkySRCLX7w-duoW_SGqb3o63Fsd_ObKSgmwKU",
  "assets/blog/img/mulher-calculando-contas-cozinha.webp": "3c4CVbyZuXPBXfIs30rmux46OLDXanJ4M9HiJeqBD9Q",
  "assets/blog/img/panela-pequena-em-boca-grande-fogao-gas-mobile.png": "4nkiHx_Ut6y-JUEtJa8jt5ngrxRFGizIFED2uI2siMo",
  "assets/blog/img/panela-pequena-em-boca-grande-fogao-gas-mobile.webp": "8_gEd55HMqYp6_q5w0e_OA21Kebgty7Suo_ZTbYRONQ",
  "assets/blog/img/panela-pequena-em-boca-grande-fogao-gas.png": "jVjYLiNjeoq5MvVWE81HAmwO2KRxCI5hQkOEZ65Z6c8",
  "assets/blog/img/panela-pequena-em-boca-grande-fogao-gas.webp": "PdFwARKJr1T00bcGDblpzUTiJISWHjG9kj3yetQup9I",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-1200w.png": "f2u8PFip4HE68E-5_6sisFI_c81Uf-AmUlHszI3XLwI",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-1200w.webp": "5Dp7HAfK8Qs7J85FJNOXlVytaXMh4ejRgDQJCLreO7g",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-1400w.png": "J8tprNSyR90Y7ieJILl5lrm7hTWlv5YC3AlvH8_39JE",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-1400w.webp": "1Ot7oud7azL9asWjL86IPAqjhPCAw-iYWK1CG7z6ySk",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-576w.png": "nXi2U6ivuEVqMC3GrCaafqTXHAIyOnK-AqZs4QOLTIs",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-576w.webp": "qGcqyTQb9WWXlwnWGvIrxeVeio9lhA7Tgsg1W55nFSo",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-768w.png": "4VmgUYKg9XX0KvwqaXA8MoDBWAuiYVzzhkiD1XhycSo",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-768w.webp": "ClKZxiUoTex9N5f4a0hOsrBJOKIA_MBUluCeSXyax9g",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-992w.png": "lJ1EuPY-YHAwPRYMIR581f2jSN95ylxfbi-ohp2S2Vg",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-992w.webp": "Cz0E-UwNftbCnHCJqR7DMBYrPKLOhL3eFMqP0p-eFAc",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas-mobile.png": "UzK9NCybdiZyUW659FvJ9uCCjTTyWlkgrlVT8E788cU",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas.png": "P069ySP1s4r7wdYc_h0ZIGRQIdnJDJxVMx8sSRLOBZA",
  "assets/blog/img/pesssoas-cozinhando-dicas-economizar-gas.webp": "KfEkvwLeHYnGsncaHNhESBNVCcSRorI_w8W5fIXdQ6w",
  "assets/blog/img/piscina-aquecida-a-gas-com-cobertura-mobile.png": "UjJGUmw_goZ3xzx1ZEcN1hvLiGENjmnbcQFlv-jIPgo",
  "assets/blog/img/piscina-aquecida-a-gas-com-cobertura-mobile.webp": "_PgD7yDWIPfDdsT3EmyDyNbeMnju5KJK89MD2gzQOZY",
  "assets/blog/img/piscina-aquecida-a-gas-com-cobertura.png": "8LVp9hDTNXBzrMwBaQD9C5VYuWXHuB9b7jwr-vp3rL0",
  "assets/blog/img/piscina-aquecida-a-gas-com-cobertura.webp": "CLPxptptekaauCQycNuVnHgCh9uKJG1j_WQABJJO5o4",
  "assets/blog/img/piscina-aquecida-a-gas-natural-1200w.png": "81gGdlcCtEOYuhiQ6rFdEOTOVlBTkhTTFX4sV4JGNzs",
  "assets/blog/img/piscina-aquecida-a-gas-natural-1200w.webp": "MVI_rSWUbGQDaCujpiwblq3IdByakNzoZlk6sTNVbN8",
  "assets/blog/img/piscina-aquecida-a-gas-natural-1400w.png": "AK-dtBIMh06M-aBn8R2AYD_GQQkqIo8nDmIjBiZGX2M",
  "assets/blog/img/piscina-aquecida-a-gas-natural-1400w.webp": "kNQYaXwGIsSObLhStEYQuJc-x0Dr_xNvgiDLsaiv7DU",
  "assets/blog/img/piscina-aquecida-a-gas-natural-576w.png": "igZCKo_3yKJ6NByMobsSfbnrsTsyMK5KYozzAvzkttQ",
  "assets/blog/img/piscina-aquecida-a-gas-natural-576w.webp": "Kqwde5hgO_Tw0fU7PD4cUzoSld0RON9q7rLVUSOoCw8",
  "assets/blog/img/piscina-aquecida-a-gas-natural-768w.png": "IFZCHR_J0LPO1VttWxnuQW_LCCU113QSjVCAmdNtzew",
  "assets/blog/img/piscina-aquecida-a-gas-natural-768w.webp": "VGxvCq2i03kL1FwgQwZY8zryrVHPXQqUuf6hl5Dmywc",
  "assets/blog/img/piscina-aquecida-a-gas-natural-992w.png": "-KaUp91VR5upmcONpBFAtcA0GutlJvxKGfnPIJla6XQ",
  "assets/blog/img/piscina-aquecida-a-gas-natural-992w.webp": "vH6Igb3CTwwwaclvrCwvgyS-vPTt6Pq4WhaQLNe_9HQ",
  "assets/blog/img/piscina-aquecida-a-gas-natural-mobile.png": "Bep37fNa8mxC6A8rp6OCMFv6maSbZQ0HUSN-TJCcY8k",
  "assets/blog/img/piscina-aquecida-a-gas-natural-mobile.webp": "V8NRBaJs3WyZ5kiEW_-PDhLprltVo4nwKzKW2uREsrA",
  "assets/blog/img/piscina-aquecida-a-gas-natural.png": "1e4mvki6kax_AOIHQuKJibhprfiF5dzMkAemyBup7uE",
  "assets/blog/img/piscina-aquecida-a-gas-natural.webp": "H2wXiL_MXT6Kxh9ZtIGKskKwkVofTG2inxS_sfu32to",
  "assets/blog/img/piscina-de-agua-quente-em-condominio-mobile.png": "DlmLCnXm6CeAYzrVeWTa2_7MIaSiX2JhEQ_4LGyxUnU",
  "assets/blog/img/piscina-de-agua-quente-em-condominio-mobile.webp": "qrNpqDRlHEqyjpwoC4PT26IluzLVVAbRQC2UQkugaGg",
  "assets/blog/img/piscina-de-agua-quente-em-condominio.png": "U1-DjzTRQXbrOUTmxcPtMqRSmwwkFap-CufsF6MGwGA",
  "assets/blog/img/piscina-de-agua-quente-em-condominio.webp": "UipUQ0Wym7ABA0SUtYoJ3-kg7AJAbUFWKTxuKUXE0YQ",
  "assets/buildings-content/img/cenário-com-piscina-aquecida-em-condomínio-de-prédios.png": "2_if6z0LHfD3b9HWeZ4_nWuTR44NN5-pJvAzkAHvc0s",
  "assets/buildings-content/img/cenário-com-piscina-aquecida-em-condomínio-de-prédios.webp": "ilFdFEd-2wTf3HuSE4rHJp1eeq7fUAdlkaA0cWORaAQ",
  "assets/buildings-content/img/equipamento-gerador-de-energia-a-gás-natural.png": "Ot23fTRhEoeCbsHu6GgDgtBqAbv9bXdlvF4nPaQGO0U",
  "assets/buildings-content/img/equipamento-gerador-de-energia-a-gás-natural.webp": "j6HcAFd02obqNBDqEKz3wXcMRj5y9rTSnULTmGAMFvU",
  "assets/buildings-content/img/família-feliz-reunida-em-volta-de-churrasqueira-a-gás.png": "QWtKISKlUpb-PUgrDsJuASbex6JFQHYHr85ULqGHQP4",
  "assets/buildings-content/img/família-feliz-reunida-em-volta-de-churrasqueira-a-gás.webp": "Owg9eZ3GPTejHDSI2-Gn06OQLu-o3JF-VT-SZK15f_k",
  "assets/buildings-content/img/lareira-com-aquecimento-a-gás-natural.png": "Ty3FFVxMuNuIYtQgUckaH1Ua7qjDSoHJsRVAvmECQoM",
  "assets/buildings-content/img/lareira-com-aquecimento-a-gás-natural.webp": "K-IioKXNaGhkjsrwcwgBi-H551U3w_bzi5eVasn1UuE",
  "assets/commerce-content/img/academias.png": "4dsScLBbBQHi35dcgKXMhOU5Cgqi_5bkBTs5Xx08Veg",
  "assets/commerce-content/img/academias.webp": "aCJkC9jS7GspDqzgjn_K0agB20oHEKC4AR05RjfkVLM",
  "assets/commerce-content/img/chefe-profissional-cozinhando-em-fogao-gas-encanado.png": "PPolnmtfGqqtRXhMj1jeZSdEv45QbU04yTAe_wuowqw",
  "assets/commerce-content/img/chefe-profissional-cozinhando-em-fogao-gas-encanado.webp": "OSfInEwSS5Nrn7cBQt0AhKQNcgTaoKkqLVpcV2iF4HI",
  "assets/commerce-content/img/cliente-albert-einstein-perdizes.png": "ZhrCUyP0_Ujm9HtRDQDguReEKlCXujrw16RhbzeHQCk",
  "assets/commerce-content/img/cliente-albert-einstein-perdizes.webp": "8SiqvOoSCaj20VvzZY04c2yQ_1-kj4OELf6cxzfmqYg",
  "assets/commerce-content/img/cliente-coco-bambu-santo-andre.png": "b4E8rZwrJfEbcYedpLVC0SWrJlhwzH_mIDve3bgL4Z8",
  "assets/commerce-content/img/cliente-coco-bambu-santo-andre.webp": "8bCnGYdmZTy1_3PrZ-FaTMRZZkgeycVWlViB3OBR3Vw",
  "assets/commerce-content/img/cliente-dengo-chocolates-faria-lima.png": "sa6Lc4_batwCa4LDkAyaV85Wnq3Yr7v4QyrB1xCuryI",
  "assets/commerce-content/img/cliente-dengo-chocolates-faria-lima.webp": "Ok16EtdAproW_Unt2fVgIBSa7dIzYdt38qd2c2qpmI8",
  "assets/commerce-content/img/cliente-fleury.png": "xT0zYqVooixYz-vfUF-8V_jabah8fAco1P8GgCyHZ7c",
  "assets/commerce-content/img/cliente-fleury.webp": "Z2ZP80Q34KL0goURDZSZVE_y2trE-Fw6Gt2GBO3ks9g",
  "assets/commerce-content/img/fogao-e-forno.png": "YXNU2rLlKMqs2dAow22qCNu05FC7vI3eFTkJXotXQlk",
  "assets/commerce-content/img/fogao-e-forno.webp": "5aYmei42ySOkFKJij-7hwTydJZbsL5OXP031c9gZEJM",
  "assets/commerce-content/img/fritadeiras.png": "_ZdA7V9u6F4p34SQlKEt7c9N1kc7d6_iLC8WqxLmVrE",
  "assets/commerce-content/img/fritadeiras.webp": "WYGuxEqKXblan_HNcmE6zZG77I_ng7yxbcMFWED2PhI",
  "assets/commerce-content/img/gas-natural-para-seu-clube-academia-piscina-aquecida-sempre.png": "L9GJwIGeiXMQX7l2mSD0iHx23zXMtIodIMKOuzg-Rac",
  "assets/commerce-content/img/gas-natural-para-seu-clube-academia-piscina-aquecida-sempre.webp": "dEQJWytfVLCl2BDpOH-DEmqU3uTKlPeKicniGb4RHic",
  "assets/commerce-content/img/mulher-empreendedora-tranquila-com-a-instalação-do-gás-natural-em-seu-negócio.png": "AR6SaBnqbcL6m10deXP8tpsGUH4T9gkGOmY3vdzeg4k",
  "assets/commerce-content/img/mulher-empreendedora-tranquila-com-a-instalação-do-gás-natural-em-seu-negócio.webp": "65QIWykEuKajT3rAy392o-6bUnVB3jIO2qedgO9Anq8",
  "assets/commerce-content/img/pessoa-manuseando-fritadeiras-a-gas-encanado.png": "RW_OYZJb0nBHu2X1Uo9qO2AGKZ2mCsjVPMOjmotxtXM",
  "assets/commerce-content/img/pessoa-manuseando-fritadeiras-a-gas-encanado.webp": "FRlvQ86NKn8-XE9gbdu7RZD4x6uHdupAVp4LC0YjEV4",
  "assets/commerce-content/img/piscinas.png": "s5Hwl7wj1rSYUFBvehltPMS4SdMiY6yRYOF0_pccNtA",
  "assets/commerce-content/img/piscinas.webp": "x3iKfXBlwHxt3qUJfU_HYENrX0BgAWE8VntqvZ_U8Bs",
  "assets/faq/img/atendente-de-relacionamento-sac-quero-comgas-desktop.png": "wKpB-XVhjZsg90KUBxkrMnLwvnI2veuM-bNLbsJoqRI",
  "assets/faq/img/atendente-de-relacionamento-sac-quero-comgas-desktop.webp": "LWU7TrcbGb-KeXLrL2Uhi3rVzqqk6fQdjbb-S3v65Ao",
  "assets/faq/img/atendente-de-relacionamento-sac-quero-comgas.png": "Auvqb9C0Oub_m5lrenWmBYkTM1ixLForNUUCHcQSIK8",
  "assets/faq/img/atendente-de-relacionamento-sac-quero-comgas.webp": "wKyq0fj2HKnKVI2T-EsVY6SVl6mHNISW9ChyCWgUicw",
  "assets/home/img/advantages/piscina-aquecida-a-gás-1366.png": "dYkIKmPiA9AAwqtanrXGGOM-daC8596ckJtOcSG7uxE",
  "assets/home/img/advantages/piscina-aquecida-a-gás-1366.webp": "X3xPLWo9slushf0HG6CEelS_I1ZLzSorrFRky_Q2k5U",
  "assets/home/img/advantages/piscina-aquecida-a-gás-1920.png": "uOHgKVwCVy2_0DnZPmvxwj77mYHEmOaM18VeNzMQmws",
  "assets/home/img/advantages/piscina-aquecida-a-gás-1920.webp": "8EFdTOYVpF82SGiMBbxr7g71nXEybjueBYP3md6m9q0",
  "assets/home/img/advantages/piscina-aquecida-a-gás.png": "le4j4rVeyy7iIek45PTqynFh8kjL7qq1TvvtmXxkWs8",
  "assets/home/img/advantages/piscina-aquecida-a-gás.webp": "mNFn2xncmDtBFytWIJkYuiwe3kdaPen56oWy0rH4csw",
  "assets/home/img/banners/campaign-banner/botijao-375.png": "wnqcGgAq_i8q4vCFuQg4G1o9yntRRe1Vxt-SKGP0QTk",
  "assets/home/img/banners/campaign-banner/botijao-375.webp": "V9LHozlo7yJqE5Tzn66hbIYrqwqqg2_HbSBl2HjOb6c",
  "assets/home/img/banners/campaign-banner/botijao-768.png": "UKcDXbODfURQKaxT__yaQzKaNcXbPF6qBEoc9d3nWew",
  "assets/home/img/banners/campaign-banner/botijao-768.webp": "2nt6YlvzvGeoWZx5PSp0kz7Uea-8ucKe5jfZDs9owNc",
  "assets/home/img/banners/campaign-banner/botijao-992.png": "wrg6BvFYksHMoe-0tHSMWtOxceDRtDJwHqCntbbXsXg",
  "assets/home/img/banners/campaign-banner/botijao-992.webp": "HZn5TTyoy2NuqT8QAuzjdfUAVWXdaCF-iKJZW3Fc1Qc",
  "assets/home/img/banners/campaign-banner/botijao.png": "MZbXV8UCEQuX6MWvWG64LjM8dnnEBMeXec5LHVc9uDA",
  "assets/home/img/banners/campaign-banner/botijao.webp": "zvhevGXZ_k8J9eW_Oi8pVXyMbB12lF5l8lbr-TybSXU",
  "assets/home/img/segments/commerce/gás-encanado-para-comercio.png": "Um4DEakIpCwnQwFONZD0P3Hiuo1F5ZAPeftSCDSKExY",
  "assets/home/img/segments/commerce/gás-encanado-para-comercio.webp": "iawURFaUS1RHjKlO9odgxO91s4vopOxp97vkZIyfDfo",
  "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal-mobile.png": "dFM7qQKVqmAPwR-hFxcyCaQ9Er7dbBTUM4nOBLpmuQw",
  "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal-mobile.webp": "CLLPZqIBC-_kmYRA3XDVwd8HgpwR4EsUaDQ8CvArh18",
  "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal.png": "bF-9r1jHVZlp8Cr8i071lmHRooNaCZCPMo9PSJfAvz4",
  "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal.webp": "uijff3MZWC5bw_2DIFBDIl7jBkYBHGb6VB-PcjhrHz8",
  "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas-mobile.png": "h1oYkoVvdMJ_GnmFPEGmhoAKB8P8pfHvpYuLX11XKoM",
  "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas-mobile.webp": "Qn-nmliuvG2EKmEdr_hCwEazEqymw1o-qF5pz0tUaGQ",
  "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas.png": "lWqF-m6S8M9rufkcE_jEKlYb9nwSnhuvYxopHVUBKQw",
  "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas.webp": "lWqF-m6S8M9rufkcE_jEKlYb9nwSnhuvYxopHVUBKQw",
  "assets/home/img/segments/industries/gás-natural-para-industrias.png": "fUfgVi1yCbx5qewvDV4iK3Lj5G0rad1XiI1J5pa2PqM",
  "assets/home/img/segments/industries/gás-natural-para-industrias.webp": "VB6AByp_wg5HLIbxotoA5et3TEyQqwZxDzZ1WHyU8QM",
  "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas-mobile.png": "DH0nFZpH8F2kXObKEvUUOdmKpAhgiyN_lHktTOkMqjA",
  "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas-mobile.webp": "EAoJiqmGT_DyXzjMWWvD0BB4hM0NRiDTfnHoScF94As",
  "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas.png": "MvcrD6I79I1mDJAQUcEqMw0-U0O-r_D4cea-hwVaB3U",
  "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas.webp": "MvcrD6I79I1mDJAQUcEqMw0-U0O-r_D4cea-hwVaB3U",
  "assets/home/img/segments/residences/fogão-a-gás-encanado-na-residencia.png": "tb3WE6jvXmaJoQks4Mq6YC0x-L8jcK78I7che8xLYo4",
  "assets/home/img/segments/residences/fogão-a-gás-encanado-na-residencia.webp": "PABzfHv_1xzpPRbyrfkfarDmT7h0YXZliGzHBetLyxo",
  "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal-mobile.png": "ADYGwu54kCfdWE4BVfRWF19onG6sY2N2tIMJuqG5Zn0",
  "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal-mobile.webp": "4my6R-dmR_98MajbDqNYT49vt-oHFYtBcotACtpuTa8",
  "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal.png": "9wktZVFCB5PaFaJ7f-97wdOUddm1ZJ0ZoXLb4UzfvZo",
  "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal.webp": "WSufGIbI5BI09pJRTjxqsXY5abO-ziEqcIgUy7JMGcM",
  "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas-mobile.png": "2fsggWK2KKEfdj_iA68kkAM7M-elfI_BXk8H-7UkUkI",
  "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas-mobile.webp": "zB065rTChQN3CDBbGOq9QUCt_tHSclk5663qu76H2-g",
  "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas.png": "F3jdxf2zlRwlRK3u4O_-KPMlsbH25rdtaTKQe371dDU",
  "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas.webp": "F3jdxf2zlRwlRK3u4O_-KPMlsbH25rdtaTKQe371dDU",
  "assets/home/img/segments/vehicles/gnv-veiculos-comgas.png": "reeGzxoxkaHDfykIrn4HE3vOvebmy1PrrkbaedjxdPs",
  "assets/home/img/segments/vehicles/gnv-veiculos-comgas.webp": "qKjAac0xUK5Hex8MNjVG0pv42ZpiU55Oc57oOa3JS08",
  "assets/home/img/svg/about/passo-a-passo-desktop.svg": "xMfyy95ZcXk_J0jQMkOr8cNDzkAv5EPRpIvpTSfSxqc",
  "assets/home/img/svg/about/passo-a-passo-mobile.svg": "u86IvBA3z2VZVmJqYxY2adeVsYAFxjgPUTQMlPjLXTI",
  "assets/home/img/svg/icon-banner-slider-next-btn-disabled.svg": "X8har43vDFbI159HIef0hcYWnSTea-zSrTWUNpdYefk",
  "assets/home/img/svg/icon-banner-slider-next-btn.svg": "-TghaEUDzLIPlIef_L3_gyKFc79ES9NW_gS-C0IxvtM",
  "assets/home/img/svg/icon-banner-slider-prev-btn-disabled.svg": "Iz92EHklhuel0TtAXzH8YjZ8XY5wmzS-I0g0CS3UebQ",
  "assets/home/img/svg/icon-banner-slider-prev-btn.svg": "iK3QGVC65cwFZgA7CTojygDm7bw45ZBJ94vkKY0AQoI",
  "assets/home/img/svg/icon-depoimentos-balao.svg": "W2MpvrQXPWqbDrxNdUvYtTM2kBBSfKRCWCAoCcjbaO0",
  "assets/home/img/svg/icon-faq-link-mais-perguntas.svg": "EbtXzcjIUHxvCcRa4lxEJsLl_qf8RaBEQF5pyE9rct4",
  "assets/home/img/svg/icon-perfil-login-desktop.svg": "OYQDKRaRxdnzRHNbm29_odhY-zeB2Lqx4cbHqaO2JmI",
  "assets/home/img/svg/icon-perfil-login-mobile.svg": "M7ciWfdoh9C8_YAMHdnNVyvMBPlLrtYGEf8UFLgnGR8",
  "assets/home/img/svg/icon-segmentos-seta-cta.svg": "5MRjPVWjDDk-IhVslBRNNIAUJ198yFOy8wwwj8t2dws",
  "assets/home/img/svg/icon-slider-next-button-disabled.svg": "2CdXyh4aCjYGLw0VgrbL5T85PrGT1_cds5VbnpBjOS0",
  "assets/home/img/svg/icon-slider-next-button.svg": "VDGwr8sh13GJg8ji2npBtjpEz_rldmGonlhLK0_QUHI",
  "assets/home/img/svg/icon-slider-prev-button-disabled.svg": "5qd09ySjghOpGoX684bRqnLWb4RipWBHn_wD1RwqwC4",
  "assets/home/img/svg/icon-slider-prev-button.svg": "7LJbJJf_YpqXbCh_GPYBpLhjvN8NyHP0zmRDcqZmFpw",
  "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-desktop.png": "KkBKZZE6O-2902_j_XOOcMUkwUW0GVt6S8BpucXsKi4",
  "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-desktop.webp": "cpO9DKmVjO3P25Jo5hhJlAj_hUMu4ivQbPR-zQH5VPM",
  "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-mobile.png": "V4eXN3D3mhWDILVLe-j5y0-OxL2uDgvVRz-_QkcMkUM",
  "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-mobile.webp": "99ohqFucdVYte4XWQnDhrOm3F_VOKeQFJNGTuOJDQVA",
  "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-desktop.png": "AWD694WWBFWrqWfbDKrbDj3dYrMZIS3Nag-UoCe9TDU",
  "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-desktop.webp": "JD-n3j-ceYUF3klsHrUYcaDV3GR7jtYcce17t83IZc0",
  "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-mobile.png": "mkJfSoKVzsjB3KIICtxZqCsyUMmPxev13z7eS_gWR1w",
  "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-mobile.webp": "eeQWuc9FHUrDSAkBHUV5ZZz4a8MTZ9IVsfvHG8NkyTc",
  "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-desktop.png": "kzf_MQ7x6PFxk72Zfbnzuxhru-LjePfbqu9JLg-LApk",
  "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-desktop.webp": "UT5BuNO37O0RpPPhhR_HkQE4hkH4Rp4E6U5aqMwzsG4",
  "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-mobile.png": "VKLqJzFcec9vHyT7CIIgFlMLyqGrAWBgLE5yxxZ5Bn8",
  "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-mobile.webp": "IXnJzqIL6SU3rBDfkQXGMrly3s3-VIBJx6GQp8ANpN4",
  "assets/img/comgas-metatag.png": "CGO6vybqvR7FWxPbO154D9Nwhs6291be85Ql9za2Sjw",
  "assets/img/hamburger-menu/atendimento-online-comgas-contraste.png": "q4JCMwY4ToGsyr_y-T7VZXqk5OcF2_ps09phOsZgZ4Q",
  "assets/img/hamburger-menu/atendimento-online-comgas-contraste.webp": "ET8WYj7eWCzRYuOHX3PZtCSp8e3tZEvrIRlJZOLXrAA",
  "assets/img/hamburger-menu/atendimento-online-comgas.png": "ocfEl3NFP-NhjMhbKpXCtQfd8cxN1XK3dCj8gFxQ9Ps",
  "assets/img/hamburger-menu/atendimento-online-comgas.webp": "ocfEl3NFP-NhjMhbKpXCtQfd8cxN1XK3dCj8gFxQ9Ps",
  "assets/img/hamburger-menu/svg/logo-icon.svg": "xfjU6Ae5_nrWGWhEotfu57UvjJ-f5u7ebJh-U12IeQM",
  "assets/img/hamburger-menu/svg/sem-a-visita-da-comgas-na-sua-casa.svg": "4HaqvZ4Hr1AN1gKlw0f11Yh0oWeSEU2CQdmBSqOyvNs",
  "assets/img/svg/benefits-fold-bg-right.svg": "NWWmPs1fPtnUabKnnJtQpeChKDvhLnDtax526VCfwJg",
  "assets/img/svg/cris-mobile.svg": "7-G0dNMq1MEreXpada6-zeAqNEnuA3rgEovtJobJSGQ",
  "assets/img/svg/cris.svg": "T12vl3oVUB8G6_3NhrRHvZt8KeizlzddmUFi-FyEeJY",
  "assets/img/svg/footer-arrow.svg": "DRM1RjqJPlLYDi3xVtLeaqYE5YuemvZ5DRQk24MIPls",
  "assets/img/svg/icon_check_bola_preenchido.svg": "GlX6rw2_Vc4I7n1hZSivQkJ-VFrht1w9WW04uevGny4",
  "assets/img/svg/icon_erro_fechar.svg": "N0vgJWEs1hJ0uIuOAoJcqONanf80TTjW4PYmX45LTyk",
  "assets/img/svg/icon-attention-sign.svg": "LAHzJJApBu-EBB_rDAv_ZHqZDsGhjPL4ChNcyUSUr-8",
  "assets/img/svg/icon-blue-slider-next-btn-disabled.svg": "kX_Zg8pEkuZn7YSQRGhsekXcAgkYeTGo2yDFRFFad1M",
  "assets/img/svg/icon-blue-slider-next-btn.svg": "1JxBNX0sjAEHaH8GEiW20fiyorU48z82KFq0qA8rYMA",
  "assets/img/svg/icon-blue-slider-prev-btn-disabled.svg": "NcJgnNDy7_ThJeYX-3TGbQlKWQRoQ6ssFpFkic9dRms",
  "assets/img/svg/icon-blue-slider-prev-btn.svg": "JkCaKgtFUZMUC7SIxa4Un5mDTXUo4PxWRUPTVX4lgeE",
  "assets/img/svg/icon-caminhao-frete.svg": "ICFmPe7a7zBet_exRVD7Ga_BOk6PyDgPnwsfdPax2_4",
  "assets/img/svg/icon-carro-direita.svg": "WAXXQpWo2AvsvcJSGIupV8zcQqVaGvWvFA-lU1KSDsY",
  "assets/img/svg/icon-cg-address-validation.svg": "_8J3OF4rJfERPr4R2CBLRMZDobHC_TxOLsw1IgZFnVI",
  "assets/img/svg/icon-cg-alerta.svg": "it6ardQB3jSCyMstQ74wohjE0zIu2gB9RwHD03K03a4",
  "assets/img/svg/icon-cg-atencao-branco.svg": "Kb_JZDqiBPuK9KhhQcYx4GZdt91LD1EwHbkSHlrHkTs",
  "assets/img/svg/icon-cg-check-circle.svg": "5Zfhcq08kQfrWJ8CjFKHJjlSWYj2vdPzR-3-_P0BlcQ",
  "assets/img/svg/icon-cg-facebook-wireframe.svg": "9BfrmL3T8qDg2HCDgGkh8QMeeGJu6IdStyknXur5Yuw",
  "assets/img/svg/icon-cg-facebook.svg": "Vs7_qwhNO6LHl3EPlvYh_baNvn88j6-qCrlx41-jI-Y",
  "assets/img/svg/icon-cg-instagram.svg": "oRz2FCX4TFNyFzHnQh0eeCfzltPxzNK2-ECEx9SDUpY",
  "assets/img/svg/icon-cg-linkedin-wireframe.svg": "mxsCn7zmvtov6_yr82RCi6X_y4UcwvEYwBaz5w0AnL0",
  "assets/img/svg/icon-cg-linkedin.svg": "Ue63ibFnrQ3Z6ChiHWtSP11c0k2SjdpyzoKqK_KFi9c",
  "assets/img/svg/icon-cg-localizacao-gps.svg": "7jPGB5HOG9TG4352yDCxVfWB8F3BnEvlcHiAvyXexTw",
  "assets/img/svg/icon-cg-mao-aperto.svg": "IH93SE03p8tzIe73Sk6Qe24e99X0Uc9g1kR0TTIqfW4",
  "assets/img/svg/icon-cg-perfil.svg": "O_KaIGZGPXQvaj5TlzX8x69BCHZ1p_PUaBw4YK-TCsg",
  "assets/img/svg/icon-cg-twitter-x-wireframe.svg": "P99_COBOxIEMtu9910AhM6AgJVGIZQp2NzYApYFEOEI",
  "assets/img/svg/icon-cg-twitter.svg": "-A6DbjXjDWCh_Kqn-TPoLyhvSdY7CkY9iU4aEHWsjOU",
  "assets/img/svg/icon-cg-whatsapp-wireframe.svg": "VKEAgWDUiz65dbX8qoZkFy0CgvpevaWItYDX7Ek9gu8",
  "assets/img/svg/icon-cg-youtube.svg": "N6VF0m7BRMd5yrPZkfOCwn1xJWJsxYcSmQ2b30G_vXE",
  "assets/img/svg/icon-change-circle-outline.svg": "1_76LOBM3TxxClqDxEZ2dgfMuas5rtgJbleewm7ole4",
  "assets/img/svg/icon-change-segment-feedback.svg": "P7xNGswT8QwY_l--RmKB02ar2sNXwKsH5Ia6vjR9xck",
  "assets/img/svg/icon-dinheiro-salvar-gradiente.svg": "zl5Zwft2qfGhcee7eL-7wPQeyGRIJ0_rpYZB54ql6BM",
  "assets/img/svg/icon-feedback-positive-gradient.svg": "R01D53yr6DnRj_CnB9a45An5i4HYRGDRYBKnXv7SNhI",
  "assets/img/svg/icon-pause.svg": "d0t1BvordcGYzItxff8OCGsMO3HRkLs-1MC-YEi7hOc",
  "assets/img/svg/icon-play.svg": "IMQPIAiXt3V6-i2VCbt0JD16E2VpTXxddLy63MbRl8A",
  "assets/img/svg/icon-seta-direita.svg": "e3yTboWUHb0Ib0COmFlrjx_G4IhCdEp99vXRDifG3HY",
  "assets/img/svg/icon-seta-esquerda.svg": "7jn1wKdyuk6Qno1Y_6Bx7MwhLs0vgKwAhTot9g8BER0",
  "assets/img/svg/icon-stop.svg": "BAKEe06_U3UPF4C5ojAX1MuZOfrCyNUqdFgEJcZlXek",
  "assets/img/svg/loading-animation-white.svg": "_6fxkjzkbFPaZsAbQXoWtWV7IV_KY0Be_Bmx6HPcwxg",
  "assets/img/svg/logo/footer-logo-transparent.svg": "HnWEINed7M6vcSVgnYr8h-9Sj0DvheG8e8sqT6Pf9zQ",
  "assets/img/svg/logo/header-logo-sliced-desktop.svg": "qW_ig-uomOF_G441Z1PVq52U-8C3LGWspCODE38obaM",
  "assets/img/svg/logo/header-logo-sliced-mobile.svg": "4SeFjDCN-TXBLbjOWp_Ic6Iw2znqOtTlVDo2JiS7Xk4",
  "assets/img/svg/logo/header-logo-transparent-desktop.svg": "HnWEINed7M6vcSVgnYr8h-9Sj0DvheG8e8sqT6Pf9zQ",
  "assets/img/svg/logo/header-logo-transparent-mobile.svg": "fJmOaLYBS2JR3MW-GP6-IpCcQtgX3Lvci89yiwezGqU",
  "assets/img/svg/logo/logo-colorful.svg": "P_FO38USNnXz7xHlYKjI6-nS3Fu_U1VMkJLjl1LBbLc",
  "assets/img/svg/logo/logo-link-desktop.svg": "hUbeBQEwBCSi13ocE8SYW4LQbqBM3ihmLhOxrYOatcY",
  "assets/img/svg/logo/logo-link-mobile.svg": "NOTApGY7ukYOBOrePhCXmgYmsKHRMdHkciOZYfxuSDo",
  "assets/img/svg/logo/logo-white.svg": "RxjvvyuCYCPff9sWoji7_sIp4Ds_x85-RR1fUWS2wuY",
  "assets/img/svg/sprite.svg": "TPOh9COKl8j6dCU8acUDpzY-ivRlWmvDgIiqVyRkA-4",
  "assets/img/svg/whatsapp.svg": "D9EnL1-qaTKmjV_I2VzPXyi7dZbpkabomAAaNQ4FPvw",
  "assets/industries-content/img/aquecedores.png": "w8NFh8m5IrcCPBbH7J5YLD7shw3MB5am5OkHPcGVSpY",
  "assets/industries-content/img/aquecedores.webp": "VC8Gj40wVGZvb_YMGlTcSseieu1X0r-AkrViZ7naHuk",
  "assets/industries-content/img/banner-icms-comgas.png": "FEeE01uuNI3N1erytIUPiQyCBSAJvM3RMwdD8zps9_0",
  "assets/industries-content/img/banner-icms-comgas.webp": "qCG_X_9H8cM5nNFKrj_YJAuU107cUYMs8xQ7lFg7jrc",
  "assets/industries-content/img/caldeiras.png": "raq55nSMSJNto3f7De9Q2xfxPhR8pkHobMQ-ONFZDY4",
  "assets/industries-content/img/caldeiras.webp": "6I0munbfirY0uH0HBRQBK63gnGC-RhTRS7qYM2QZMks",
  "assets/industries-content/img/empilhadeiras.png": "n9_eMOoG67ywOiA0e0Zq2A1WFs6D5euWsLwSFZJ_scU",
  "assets/industries-content/img/empilhadeiras.webp": "OLyqI9tWmOx5NFXnne3M6WpwbVINnnD838bJlUn-Zwk",
  "assets/industries-content/img/engenheiro-com-api-seguranca-industrial-com-gás-encanado-mobile.png": "yttaoqSHB1zhJWPx6fcCGPAvWt8MB2tNPvfkIFfjWNs",
  "assets/industries-content/img/engenheiro-com-api-seguranca-industrial-com-gás-encanado-mobile.webp": "G4cC85n-TQBULNG9pJu2c56OW74VXntlm4yDZFOajTU",
  "assets/industries-content/img/engenheiro-com-api-seguranca-industrial-com-gás-encanado.png": "yttaoqSHB1zhJWPx6fcCGPAvWt8MB2tNPvfkIFfjWNs",
  "assets/industries-content/img/engenheiro-com-api-seguranca-industrial-com-gás-encanado.webp": "G4cC85n-TQBULNG9pJu2c56OW74VXntlm4yDZFOajTU",
  "assets/industries-content/img/estufas.png": "fURJ_h72AVtGGj2y8PRncppqe56EZHKwE14OoAb8pf0",
  "assets/industries-content/img/estufas.webp": "OYCLN8i3f-Qg_-5c9gnJ4hB_ZIVIM7I9LaIw4ezrPI0",
  "assets/industries-content/img/fornos.png": "hh9TOzFPcvY-LVPJl7I1izVHGG85DcXrFBqmuhawbRE",
  "assets/industries-content/img/fornos.webp": "cWvigXErroO-9ZNmTw5xIsqT2AqwcXdjBoYxQzr5cbQ",
  "assets/industries-content/img/fundicao.png": "WHSvW7ahtRCFrg6aKLdczSujIBLm5Rtzh9RRH-AtQXg",
  "assets/industries-content/img/fundicao.webp": "P-nODY4eTWe4kjKwLpHQt3iOlrvF4wgRp3-96Eeeq8o",
  "assets/industries-content/img/gás-natural-e-sustentabilidade-industrial-mobile.png": "WpQZ74GpeDTziqW1tUFfrg6P13ZT9dI0ydJ9guLM3yE",
  "assets/industries-content/img/gás-natural-e-sustentabilidade-industrial-mobile.webp": "pQuAieZjvXwkpiVDefBeUe5vCc9Pq9h8egQnebLZEr4",
  "assets/industries-content/img/gás-natural-e-sustentabilidade-industrial.png": "WpQZ74GpeDTziqW1tUFfrg6P13ZT9dI0ydJ9guLM3yE",
  "assets/industries-content/img/gás-natural-e-sustentabilidade-industrial.webp": "pQuAieZjvXwkpiVDefBeUe5vCc9Pq9h8egQnebLZEr4",
  "assets/industries-content/img/geracao-e-cogeracao.png": "RIrjHLqE55MTmpo-zcEBzjutDKtZyO2YtyITpJFLKYU",
  "assets/industries-content/img/geracao-e-cogeracao.webp": "Aw5nUffFwpogvjorLKiFo-U_PHVaRsOCnKb1RqNN7D8",
  "assets/industries-content/img/maquinários-industriais-com-abastecimento-a-gás-mobile.png": "UWdaD-LbVCmlf3cNuNlU7XDR4lepXs0EcnMcW0CZu8Y",
  "assets/industries-content/img/maquinários-industriais-com-abastecimento-a-gás-mobile.webp": "U3heEPwskcnxEe91_Al6QF4PHa9P4j9ETemsFRsvtQE",
  "assets/industries-content/img/maquinários-industriais-com-abastecimento-a-gás.png": "UWdaD-LbVCmlf3cNuNlU7XDR4lepXs0EcnMcW0CZu8Y",
  "assets/industries-content/img/maquinários-industriais-com-abastecimento-a-gás.webp": "U3heEPwskcnxEe91_Al6QF4PHa9P4j9ETemsFRsvtQE",
  "assets/industries-content/img/svg/gerador.svg": "3WfCTqj5WnghPnH1vn4RW84qCeOSIGvstWDGHddKm5o",
  "assets/protocol/img/agendamento-casas.png": "okEkwME97BauM5_l8au_9VZIJOnmCjrLl3lnFDQbifw",
  "assets/protocol/img/agendamento-comercio.png": "vLYcKIdVvhDBTxnGYYPAhOL7WbB_QrEYYRgpfEoDgIk",
  "assets/protocol/img/casas.png": "vfDEH_vDCwShGfkX0uizm_DxM6CJASrmuWII-2v_Dac",
  "assets/protocol/img/central-predio.png": "LJVOgHmomQDDzrkofugSyl3fdhN4pZQuOxMmeUJc9Ns",
  "assets/protocol/img/comercio.png": "xOIQdMGn3kQPnJW4bT59bYotqZu4pW-GALKGrOSaTsg",
  "assets/protocol/img/individual-predio.png": "dWgGQ5R4gQ27TbS_aUucoykff58iAuqJzBPMYC-abkQ",
  "assets/protocol/img/industria.png": "D_0XKvfy8hfZR-un2eD-GtmtdSyVaSWSgQfb7U4C4J0",
  "assets/protocol/img/new-home.png": "cRmzrxhKMJ9bUkDVHqi3EYGJ7XBANWRoDoAACYVC2B4",
  "assets/protocol/img/new-housing.png": "woFZmzv--uZsIeDXHtJX78qf36zlTJhe4owrwUUOVHA",
  "assets/residential-content/img/bairro-planejado-casas-gas-encanado.png": "2Pq3U_blMdrWXCWpEjEJpXqIJ6jgJthKnJqmSmQUeT4",
  "assets/residential-content/img/bairro-planejado-casas-gas-encanado.webp": "q2APVWmVBBuPyMwFOZXgRQ5WMwH9VEaxDP0pcUk5cfQ",
  "assets/residential-content/img/casa-recem-construída-com-piscina-aquecida.png": "-QitmqXTX7SiCxPRw_lKqmrLnF_b9zrVOAGyxgqitgs",
  "assets/residential-content/img/casa-recem-construída-com-piscina-aquecida.webp": "kfE_A6726AfHu8bUkQRiL_COlZ5c6a1ynfsC5Sh8Ka4",
  "assets/residential-content/img/casal-feliz-preparando-churrasco-em-churrasqueira-a-gas-encanado.png": "OVHP0tbbYVy8Kc-03zjT20T1ioSO9QlusakEQfFRjAQ",
  "assets/residential-content/img/casal-feliz-preparando-churrasco-em-churrasqueira-a-gas-encanado.webp": "eIxct4wD2XskGc4g8WqcIOx9-pmQ61ICMyYjrhh9KrM",
  "assets/residential-content/img/casas-de-rua-construidas-com-gas-natural-encanado.png": "wg_IdejsX5g82zWq7dIpPcGqbXG2PhVZEZ_Y3zxDWtk",
  "assets/residential-content/img/casas-de-rua-construidas-com-gas-natural-encanado.webp": "wE91GOHNHFvQ02g6ittHmi8BT0qNP0TZp8JocoD6lE8",
  "assets/residential-content/img/chuveiro-com-instalação-de-gás-encanado.png": "Jv6fKRi4iBBQ7tPIGzbg-2oeDnmaTIpBKed_mA2LpsM",
  "assets/residential-content/img/chuveiro-com-instalação-de-gás-encanado.webp": "EWAC8_ziOBdHx08Q2dCklGCnJGzdTq48IW7tifZP3t4",
  "assets/residential-content/img/condominio-de-casas-novas-abastecidas-com-gas-natural.png": "5BQrVwQE1S9cxKM6k89i3eABckZbHakLTOP2ONzPjrs",
  "assets/residential-content/img/condominio-de-casas-novas-abastecidas-com-gas-natural.webp": "wx3igP8u13p43DiYHRxUnjGLJsNCif1riI2Y4kez13Q",
  "assets/residential-content/img/condominio-padrao-gas-natural-encanado.png": "k__8N-0QM7staBm_z-LbofkXqpLGUJ1UWBEYTrfs4zs",
  "assets/residential-content/img/condominio-padrao-gas-natural-encanado.webp": "AXrKDjaEsu9bEs_8aLmYAaskwmpONhZjUeLXll2l8Is",
  "assets/residential-content/img/familia-feliz-brincando-em-piscina-aquecida-a-gas-natural.png": "FjrYAxUQEIpR6H4x3HXroAslKqAvGn9ckx-nax9tWTg",
  "assets/residential-content/img/familia-feliz-brincando-em-piscina-aquecida-a-gas-natural.webp": "7rw997WMNeD--E97JCm5crw21IKCGFQF296bF0jLEFw",
  "assets/residential-content/img/homem-prepara-refeicao-em-fogao-a-gas-encanado.png": "8XVIdTbGXn50nzP3dNU2B7aB-Y7pAiEI4VQlkXgE3ps",
  "assets/residential-content/img/homem-prepara-refeicao-em-fogao-a-gas-encanado.webp": "kdMzMKZBOJsMAGFp5cO4X8JlYwB-6tltHMPQEW8WaRY",
  "assets/residential-content/img/instalação-de-sistema-a-gas-para-residencias.png": "dIMb0HYPqHAtWhflYiDPpad80NoCzOBJutqNJw-LqZc",
  "assets/residential-content/img/instalação-de-sistema-a-gas-para-residencias.webp": "LpDZj7eJQ3Yk8ZGfsMExWpUeCu7-41wNMlYiMAYzeEI",
  "assets/residential-content/img/mãe-e-filha-em-casa-com-aquecimento-a-gás.png": "Acm4PeR6iNuuy-V795hlcPSKfl65QW-mnz-WnSQTitM",
  "assets/residential-content/img/mãe-e-filha-em-casa-com-aquecimento-a-gás.webp": "DEx1eGB3bzUke8eTMRUGExX_E6bEUq2PAkkjqlDAGWc",
  "assets/residential-content/img/piscina-residencial-climatizada-aquecimento-gas.png": "qZi-NUUW8kUmkmk1gM8ctmtiTOjSiFqMtBROBa1BGJ4",
  "assets/residential-content/img/piscina-residencial-climatizada-aquecimento-gas.webp": "m4Q9TO16gF_2KiAv6o_ovAi4nqq_qPA8lwYEpW7pnIY",
  "assets/residential-content/img/residencia-com-piscina-aquecida-gas-natural.png": "W4mg6ndNXO_Q0Tzzn9yDbxHVTZYbI6801v8M5mqjhRk",
  "assets/residential-content/img/residencia-com-piscina-aquecida-gas-natural.webp": "jA3aCJIiar5hgAZdig0g50OFncZa7xdkdJlcSygvROA",
  "assets/residential-content/img/sistema-gas-encanado-em-residencias.png": "2Xnr-gnv7IvsEXB6Y-3MEfA9wLgGk7XAWeHOf5Hfc3c",
  "assets/residential-content/img/sistema-gas-encanado-em-residencias.webp": "4bG8FNmyXDfNyY5Nh7apGGepjD4gttNiLQrR6FQZK3U",
  "assets/residential-content/img/trabalhadores-analisando-casa-residencial-recem-construida-com-gas-natural.png": "g74rEeB6y4lBl_JUD12cRfl0TFJtIbTPC6F3mY1Kjcc",
  "assets/residential-content/img/trabalhadores-analisando-casa-residencial-recem-construida-com-gas-natural.webp": "_5yqF9orQiV_vI1VlaUAm4SqKNIHAJSkSAHSKfnvIhU",
  "assets/residential-content/img/trabalhadores-em-obra-de-apartamento-com-sistema-de-aquecimento.png": "chNYDgMETadj8oZP1Rb6ab89oWV6CD5nk8KarRzSqgs",
  "assets/residential-content/img/trabalhadores-em-obra-de-apartamento-com-sistema-de-aquecimento.webp": "Srdc_6TnWefhb46qDHa_eX_BA4PnykUkPoIFtE0ZdMc",
  "assets/scheduling/img/passo-1.png": "V1eVKd_gWEzW7uWndD7VhIKM4W_JL9QigPI3eDzGHyw",
  "assets/scheduling/img/passo-1.webp": "N9lfag2AME-ibxPliw71KHtxfmh5R-U4fLYjNU3SW5E",
  "assets/scheduling/img/passo-2.png": "9m2jmqlkz7usavfzUbYKGWw9CI-l446uYb0G9P_-qvY",
  "assets/scheduling/img/passo-2.webp": "sTn7vOWYc0fZYHtH4158zAYMrMYHccberHWp57p0JCc",
  "assets/scheduling/img/passo-3.png": "dFHoi2ZC57Q_hpsJmkkZ3nz3YamydI-qdFQimequrbI",
  "assets/scheduling/img/passo-3.webp": "UQIqfR-3vqFnV4YxpdpCo3a1cY2ThD6wen9kYHoZQ7Q",
  "assets/swimming-pool-content/img/banner-piscina-1200w.png": "tqPqIT1gHBmCOsC2VvGtCmKpg5EELt5CmYRwDt2uAFQ",
  "assets/swimming-pool-content/img/banner-piscina-1200w.webp": "olSHcx6KCHeWSAz9xyDMQB1RUbxVfgX6zVY-_X5jt2k",
  "assets/swimming-pool-content/img/banner-piscina-1400w.png": "FeYtoZ1zEuTw4Emp1aZ8ofc9BMEdnumSQgtnkNvbhKs",
  "assets/swimming-pool-content/img/banner-piscina-1400w.webp": "BUIjSuIgBYQTdB1FSpgoi1nPIJIAo8xsxXGHCBECJH0",
  "assets/swimming-pool-content/img/banner-piscina-320w.png": "0HPEl82RVZyIwa0Zs8pwmB9x-a-ffEo3Eq3-n7iBQBM",
  "assets/swimming-pool-content/img/banner-piscina-320w.webp": "EQmM9hmsSSTqMSLtdJi7u07qKgD0QhZQBYBoqrfqjbQ",
  "assets/swimming-pool-content/img/banner-piscina-576w.png": "Gkxevd07X9nID41Y3zM-zAz0wR28YgUr2HLe-SbTBp8",
  "assets/swimming-pool-content/img/banner-piscina-576w.webp": "-GSttsCglv0zk1k_x2D4UCNgz5NchJryBlj1er4Mitk",
  "assets/swimming-pool-content/img/banner-piscina-768w.png": "fO85AUycX3lHdU25nJsI-_hyg0ZpNxb9bz3D7zjMeEU",
  "assets/swimming-pool-content/img/banner-piscina-768w.webp": "qgRiD50VjYHEWNWRS0wZUtLngkZBNPEPjfjFXaxEeyQ",
  "assets/swimming-pool-content/img/banner-piscina-992w.png": "sXWluuZuf5vs3UnJa8OWVGPs3jqS29dXipv6nwrS9_I",
  "assets/swimming-pool-content/img/banner-piscina-992w.webp": "PjcdbT27MU6fZ6VHbOAEZlu18gLcxNnuCow5SNIfl9k",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-1400w.png": "DS-_2JcsigFErpkE_-5C-iSzJZMUI1vNMEznHD9taZc",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-1400w.webp": "e9vP7sWt1rJPnnSfTVa_iJbV5zVl6-bq_xhM7Qf9iBY",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-576w.png": "JPaThtWDmOfX3HGyNW6iHwr_K3D998_py-GpZt9j66w",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-576w.webp": "Ft15nP_pUXjWiThU9Pt7dUL8bYE57g06YwsOF56puiM",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-768w.png": "9IPGAQXjK3SfuCh7CRiH56DZqYk8U2dgbkQivkE6kLw",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-768w.webp": "8oy4UgTqs1I-0ILFI4WPglXJdL6j_dk2Nczykni-URw",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-mobile.png": "7LQPPDLgH2-pOUC4iif-ttwoscayKwG4aKlXMvZCJSs",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-mobile.webp": "k-8WpPRtW0b4kZ3VGtSb7sx6jbOij-lnA2fVkkwMD10",
  "assets/swimming-pool-content/img/família-em-momento-de-lazer-na-piscina-aquecida-a-gás.png": "QbXd5lGPNeso9bZXPMoP4IvCZpjcZ_OF9-xd2T86Y6g",
  "assets/swimming-pool-content/img/família-em-momento-de-lazer-na-piscina-aquecida-a-gás.webp": "fqBFOfRJmKLFqQnTRS1AwIBJ0YhfKCSIfSsKWdaJ77E",
  "assets/swimming-pool-content/img/hall-de-hotel-com-aquecimento-a-gás-natural.png": "R2SRjmLu6pJmdGWp1WfZyJa67ZIIBtFjlx4JNS9p0Qk",
  "assets/swimming-pool-content/img/hall-de-hotel-com-aquecimento-a-gás-natural.webp": "zkA4GKPmVL8CkJc98DRlvi45zDf25JZGoiEEIpQLL-8",
  "assets/swimming-pool-content/img/sistema-de-aquecimento-de-piscina-em-clubes-e-academias.png": "PaPfMv1HflCwzc_qZb-0CzdKU-dsLqDrweSxzxvn3vo",
  "assets/swimming-pool-content/img/sistema-de-aquecimento-de-piscina-em-clubes-e-academias.webp": "dEHz_I0lHo25x3IG7iTM2z-6qJOyZrgjgrMeeG3Ja64",
  "assets/swimming-pool-content/img/vista-de-pôr-do-sol-em-fazenda-com-piscina-aquecida.png": "yJTppQsLK_pYedEJUNQECRcGvwtlH241bbBYbPC0JAw",
  "assets/swimming-pool-content/img/vista-de-pôr-do-sol-em-fazenda-com-piscina-aquecida.webp": "6K-9G5vZkJ4CH1dTscmljTIwO4KrJrS9EmdjWrUPogk",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-desktop.png": "7mNONEMN9T44oQwQ24H5-iZnNoZEnT9V82SSSNvFS_U",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-desktop.webp": "1L_GkWWHCMUI4i5UUzR52mH7ISZn8qo-3xsPLzPE1uo",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol.png": "seTfpjWrF0eUfXIZacOq3CGk-slx5SwbTJYpedXL2pg",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol.webp": "wyY6w05L8Yej1R6M7DuX6dq-r4Ff_SPpPpo1CxKk46k",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-desktop.png": "4CO1MPx9tM3Uv_gBk6Wwr3EsxtYEpD5y_0SlyZvcGNo",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-desktop.webp": "0WqGZVmVxng4djD4hfxgMD6rmNUuuWNcoL3LdgAxSbI",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv.png": "4U2_gX3QffXJKiWIu8dKivXW4Nh1prHJNXQuq9GXfp4",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv.webp": "whonCReox9Bo74TCvIvXx2SxsiJGTlbWOYq4e-bDlyU",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-contraste-desktop.png": "2lnYF59P-fWZ79_XEXc4svZKz1j3sTtNk8GZk4Hblm0",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-contraste-desktop.webp": "xuWYMe5AbFggwFOq5v9IMlUQ3W-gvnfdGr3L74Ojazk",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-contraste-tablet.png": "wcm6-4FsildOzM5LZNH3bo-3u56tEC6VjjXK1hrg-Pk",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-contraste-tablet.webp": "7YgfKn99NtPKdm5AEFmzT_YntwyeZ_1JcrvZET6bwNY",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-contraste.png": "upzzr0YAgsr1agd74AMpHpKdl8DQWx9GwFHRLyLC7Mo",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-contraste.webp": "KE3kdkPbDvdv51E3UCPlSB0EDEMVHJia9g-3VsATckI",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-desktop.png": "ZS4v09H0b1Xf56DkStIjSyyQV_7prBZVW_6sQhpnHug",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-desktop.webp": "KTTwL0ViayAWovFtrd80xqB8UtGmt61WNX4owOSP3g8",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-tablet.png": "AgP_bZFWdNeotMmN2fu9qO11s7IVg2SnskY4NnIvL_k",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-tablet.webp": "Z0E4YIkmwQvcDGgb4KrALsBU-X7NbCyIHuCW5v7ztEE",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv.png": "-SfNNUVkfkw7mbAYGXdoUNhqaNJliitl9JFC3QY_qbQ",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv.webp": "yGLcFR07OyuFvASyujcPmmQuzotohX5TQFmgT5OourQ",
  "assets/vehicles-content/img/common-station.png": "5-rAj8FWfgnb2vTjGb3hplh0tLyb1CH9MZnZ1BZV26s",
  "assets/vehicles-content/img/default-marker.png": "kOtnvMNQ8HAQLylLK52dNKLKP2po6Bksybuo7x3FkVU",
  "assets/vehicles-content/img/google-maps.png": "GJ4O8O3dE1tNVZsFocgdsvvWS12IXGfS04AblcOqzBw",
  "assets/vehicles-content/img/high-flow-station.png": "iI8Nr92DzonlpdacwLc6_6izo2u6Jf6pflGcFl1ME_Y"
}