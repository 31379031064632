import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qsc-animated-number',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './animated-number.component.html',
  styleUrls: ['./animated-number.component.scss'],
})
export class AnimatedNumberComponent implements OnInit {
  @Input() startNumber = '0';
  @Input() endNumber = '10';
  @Input() duration = 2500;
  @Input() isPercentage = false;

  currentNumber = 0;
  displayNumber = '';
  startTime = 0;
  isAnimated = false;
  lastScrollPosition = 0;
  scrolledDownPast = false;
  window: any

  constructor(
    private readonly elementRef: ElementRef,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.window = this.document.defaultView;
  }

  ngOnInit() {
    this.currentNumber = parseFloat(this.startNumber.replace(',', '.'));
    this.updateDisplayNumber();
    this.lastScrollPosition = this.window.scrollY;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    const currentScrollPosition = this.window.scrollY;
    if (
      rect.top <= this.window.innerHeight &&
      currentScrollPosition > this.lastScrollPosition &&
      !this.scrolledDownPast
    ) {
      this.scrolledDownPast = true;
      this.startTime = performance.now();
      requestAnimationFrame(this.animate.bind(this));
    } else if (
      rect.top > this.window.innerHeight &&
      currentScrollPosition < this.lastScrollPosition
    ) {
      this.scrolledDownPast = false;
    }
    this.lastScrollPosition = currentScrollPosition;
  }

  animate(currentTime: number) {
    const elapsedTime = currentTime - this.startTime;
    const progress = Math.min(elapsedTime / this.duration, 1);
    this.currentNumber =
      parseFloat(this.startNumber.replace(',', '.')) +
      (parseFloat(this.endNumber.replace(',', '.')) -
        parseFloat(this.startNumber.replace(',', '.'))) *
        progress;

    this.updateDisplayNumber();

    if (progress < 1) {
      requestAnimationFrame(this.animate.bind(this));
    } else {
      this.isAnimated = false;
    }
  }

  updateDisplayNumber() {
    if (this.isPercentage) {
      this.displayNumber = `${Math.round(this.currentNumber)}%`;
    } else {
      const isDecimal = this.endNumber.includes(',');
      this.displayNumber = isDecimal
        ? this.currentNumber.toFixed(1).replace('.', ',')
        : Math.round(this.currentNumber).toString();
    }
  }
}
