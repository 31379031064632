<div class="flow-title d-flex flex-column gap-3">
  <div class="flow-title__container">
    <div
      class="flow-title__icon"
      [ngClass]="{
        'flow-title__icon--red': iconColor === 'red',
        'flow-title__icon--green': iconColor === 'green',
        'flow-title__icon--blue': iconColor === 'blue',
        'flow-title__icon--fire-bg': fireBackground
      }"
    >
      <i aria-hidden="true" *ngIf="iconClass" [class]="iconClass"></i>
      <img *ngIf="iconImg" [src]="iconImg | fileHash" alt="" />
    </div>
    <h1 class="flow-title__title">{{ title }}</h1>
  </div>
  <p *ngIf="subtitle" class="flow-title__subtitle" [innerHTML]="subtitle"></p>
</div>
