<div class="calendar-event">
  <add-to-calendar-button
    [attr.name]="'@PROTOCOL-CALENDAR-EVENT-TITLE' | translate"
    [attr.description]="
      '@PROTOCOL-CALENDAR-EVENT-DESCRIPTION'
        | translate
          : {
              protocol: protocol,
              videoLink: data.videoLink,
              address: data.address
            }
    "
    [attr.startDate]="data.startDate"
    [attr.startTime]="data.startTime"
    [attr.endTime]="data.endTime"
    [attr.timeZone]="'@PROTOCOL-CALENDAR-EVENT-TIMEZONE' | translate"
    [attr.options]="'@PROTOCOL-CALENDAR-EVENT-OPTIONS' | translate"
    [attr.organizer]="'@PROTOCOL-CALENDAR-EVENT-ORGANIZER' | translate"
    trigger="click"
    buttonsList
    hideCheckmark
    [attr.size]="uiService.isMobile ? '1' : '3'"
    language="pt"
    class="calendar-event__button"
  ></add-to-calendar-button>
</div>
