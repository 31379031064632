import { Injectable, Renderer2 } from '@angular/core';
import { UiService } from '@core/index';
import { BackToTopService } from '@shared/services/back-to-top/back-to-top.service';
import { BootstrapService } from '@core/ui/bootstrap/bootstrap.service';
import { PageScrollerService } from '@shared/services/page-scroller/page-scroller.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class UiAppFacade {
  constructor(
    public readonly uiService: UiService,
    public readonly windowRef: WindowRefService,
    public readonly pageScrollerService: PageScrollerService,
    public readonly backToTopService: BackToTopService,
    public readonly renderer: Renderer2,
    public readonly bootstrap: BootstrapService
  ) {}
}
