<div class="container">
  <div class="d-flex flex-column align-items-lg-center">
    <div class="container__icon">
      <qsc-svg-icon [icon]="icon ?? 'warning-sign'"></qsc-svg-icon>
    </div>
    <div>
      <p
        class="container__title"
        [innerHTML]="title ?? '@ERROR-DEFAULT-TITLE' | translate"
      ></p>
    </div>
    <div>
      <p
        class="container__subtitle"
        [innerHTML]="subtitle ?? '@ERROR-DEFAULT-SUBTITLE' | translate"
      ></p>
    </div>
    <div class="container__button w-100">
      <qsc-button
        class="w-100"
        variant="secondary_0"
        [buttonText]="'@CHAT-MODAL-BUTTON' | translate"
        icon="balloon-chat"
        [iconBefore]="true"
        (buttonClick)="onOpenChat()"
      ></qsc-button>
    </div>
  </div>
</div>
