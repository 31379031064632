import { Injectable } from '@angular/core';
import { EventFlowGtmByProfileId } from '@models/gtm/flows-gtm';
import { Store } from '@ngrx/store';
import { fromUser } from '@core/store';
import { ProfileIdsEnum } from '@models/enums';
import { GoogleTagManagerService } from '@core/index';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  private userData?: fromUser.IUser | null;

  constructor(
    private readonly store: Store,
    private readonly gtm: GoogleTagManagerService
  ) {
    this.store.select(fromUser.selectUserData).subscribe((data) => {
      this.userData = data;
    });
  }

  getGtmFlow(): EventFlowGtmByProfileId | undefined {
    switch (this.userData?.profileId) {
      case ProfileIdsEnum.Residences:
        return 'residencias';
      case ProfileIdsEnum.Buildings:
        return 'predios';
      case ProfileIdsEnum.Commerce:
        return 'negocios';
      case ProfileIdsEnum.Industry:
        return 'industrias';
      default:
        return;
    }
  }

  sendGtmEventWithFlow(
    category: string,
    action: string,
    label: string | unknown[],
    flow?: string
  ): void {
    this.gtm.sendCustomEvent(
      category,
      action,
      label,
      flow ?? this.getGtmFlow() ?? ''
    );
  }
}
