import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  createLink(href: string, text: string): HTMLAnchorElement {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = href;
    link.textContent = text;
    return link;
  }
}
