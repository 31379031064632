<div
  class="helpful-answer"
  [ngClass]="{ 'helpful-answer--border-top': borderTop }"
>
  <ng-container *ngIf="!selectedButton">
    <p
      class="helpful-answer__title"
      [innerHTML]="'@FAQ-HELPFUL-ANSWER' | translate"
    ></p>
    <div class="helpful-answer__buttons">
      <button
        (click)="onClickYes()"
        class="helpful-answer__yes"
        [innerHTML]="'@FAQ-HELPFUL-ANSWER-YES' | translate"
      ></button>
      <button
        (click)="onClickNo()"
        class="helpful-answer__no"
        [innerHTML]="'@FAQ-HELPFUL-ANSWER-NO' | translate"
      ></button>
    </div>
  </ng-container>
  <p
    *ngIf="selectedButton"
    class="helpful-answer__message"
    [innerHTML]="'@FAQ-HELPFUL-ANSWER-THANK-YOU' | translate"
  ></p>
</div>
