import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

@Component({
  selector: 'qsc-benefit-card',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, FileHashPipe],
  templateUrl: './benefit-card.component.html',
  styleUrls: ['./benefit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitCardComponent {
  @Input() variant: 'default' | 'box' = 'default';
  @Input() title = '';
  @Input() description = '';
  @Input() iconClass?: string;
  @Input() iconSvg?: string;
}
