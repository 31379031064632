import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlowTypeEnum } from '@modules/address-validation/models/address-validation';
import { SvgIconComponent } from '@shared/components/svg-icon/svg-icon.component';

@Component({
  selector: 'qsc-another-flow-modal',
  templateUrl: './another-flow-modal.component.html',
  styleUrls: ['./another-flow-modal.component.scss'],
  standalone: true,
  imports: [SvgIconComponent, TranslateModule],
})
export class AnotherFlowModalComponent implements OnInit {
  flowType?: FlowTypeEnum;
  title?: string;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateService
      .get('@ANOTHER-FLOW-MODAL-TITLE')
      .subscribe((translated: string) => {
        this.title = translated;
      });

    setTimeout(() => {
      this.bsModalRef.hide();
    }, 4000);
  }

  getTitle(): string {
    let translated: string;
    switch (this.flowType) {
      case FlowTypeEnum.LigaReliga:
        translated = this.translateService.instant(
          '@ANOTHER-FLOW-MODAL-FLOW-1'
        );
        break;

      case FlowTypeEnum.MutiraoHabitado:
        translated = this.translateService.instant(
          '@ANOTHER-FLOW-MODAL-FLOW-2'
        );
        break;

      case FlowTypeEnum.IndividualizacaoCliente:
        translated = this.translateService.instant(
          '@ANOTHER-FLOW-MODAL-FLOW-3'
        );
        break;
    }
    return this.title?.concat(translated!) ?? '';
  }
}
