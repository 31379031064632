import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

@Component({
  selector: 'qsc-warning-banner',
  standalone: true,
  imports: [CommonModule, FileHashPipe],
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.scss'],
})
export class WarningBannerComponent {
  @Input() message = '';
}
