import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'qsc-text-area',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './text-area.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaComponent {
  @Input() control: FormControl = new FormControl();
  @Input() format = '';
  @Input() pattern = '';
  @Input() outsideLabel = '';
  @Input() insideLabel = '';
  @Input() id = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() maxLength = 255;
  @Input() required = false;
  @Input() disabled = false;
}
