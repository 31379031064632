import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { ContentAnalytics } from '@models/analytics';
import { ButtonComponent } from '../button/button.component';
import { ContentPageService } from '@shared/services';

type HelpfulAnswerButton = 'yes' | 'no' | null;

@Component({
  selector: 'qsc-helpful-answer',
  standalone: true,
  imports: [CommonModule, ButtonComponent, TranslateModule],
  templateUrl: './helpful-answer.component.html',
  styleUrls: ['./helpful-answer.component.scss'],
})
export class HelpfulAnswerComponent {
  @Input() question = '';
  @Input() borderTop = true;
  selectedButton: HelpfulAnswerButton = null;

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly pageService: ContentPageService
  ) {}

  onClickYes = () => this.onClickHandler('yes', ContentAnalytics.label.sim);

  onClickNo = () => this.onClickHandler('no', ContentAnalytics.label.nao);

  onClickHandler(selectedButton: HelpfulAnswerButton, label: string): void {
    if (this.selectedButton !== selectedButton) {
      this.sendGtmEvent(label);
    }
    this.selectedButton = selectedButton;
  }

  sendGtmEvent(label: string): void {
    this.googleTagManager.sendCustomEvent(
      this.pageService.isPage('faq')
        ? ContentAnalytics.category.faq
        : ContentAnalytics.category.cardConteudo,
      this.question,
      label
    );
  }
}
