import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlowsAnalytics } from '@models/analytics';
import { ICustomEventGA, ButtonComponent } from '@shared/components';
import { FlowService } from '@shared/services';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

enum InstalledGasStep {
  Initial = 1,
  MoreEquipments,
  ChangeOwnership,
}

@Component({
  selector: 'qsc-installed-gas-modal',
  templateUrl: './installed-gas-modal.component.html',
  styleUrls: ['./installed-gas-modal.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonComponent, FileHashPipe, TranslateModule],
})
export class InstalledGasModalComponent implements OnInit {
  servicesStore = false;
  changeOwnership = false;
  home = false;
  openChat = false;
  step: InstalledGasStep = InstalledGasStep.Initial;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly flowService: FlowService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.sendGtmEventOnButtonClick();
  }

  onMoreEquipments(): void {
    this.step = InstalledGasStep.MoreEquipments;
  }

  onChangeOwnership(): void {
    this.step = InstalledGasStep.ChangeOwnership;
  }

  onNeverUsedGas(): void {
    this.openChat = true;
    this.bsModalRef.hide();
  }

  onGoToServicesStore(): void {
    this.servicesStore = true;
    this.bsModalRef.hide();
  }

  onGoToHome(): void {
    this.home = true;
    this.bsModalRef.hide();
  }

  onGoToChangeOwnership(): void {
    this.changeOwnership = true;
    this.bsModalRef.hide();
  }

  getTitle(): string {
    switch (this.step) {
      case InstalledGasStep.Initial:
        return '@INSTALLED-GAS-MODAL-TITLE-1';
      case InstalledGasStep.MoreEquipments:
      case InstalledGasStep.ChangeOwnership:
        return '@INSTALLED-GAS-MODAL-TITLE-2';
    }
  }

  getStep = (): InstalledGasStep => this.step;

  sendGtmEventOnButtonClick(): void {
    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.erroFrontend,
      FlowsAnalytics.action.enderecoLigado,
      FlowsAnalytics.label.visualizacao
    );
  }

  getCustomEventGA(label: string): ICustomEventGA {
    return {
      category: FlowsAnalytics.category.erroFrontend,
      action: FlowsAnalytics.action.enderecoLigado,
      label: this.translateService.instant(label),
    };
  }
}
