import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  AdvancedRouterLinkDirective,
  EventTrackerDirective,
} from '@shared/directives';
import { HomeAnalytics } from '@models/analytics';
import { SwiperModule } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { environment } from '@env';

export interface QuickLink {
  name: string;
  url: string;
  channel: string;
  iconClass: string;
  actionEvent?: string;
}

@Component({
  selector: 'qsc-quick-access',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AdvancedRouterLinkDirective,
    EventTrackerDirective,
    SwiperModule,
    TranslateModule,
  ],
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class QuickAccessComponent {
  @Input() quickLinks: QuickLink[] = [];

  get homeAnalytics() {
    return HomeAnalytics;
  }

  getChannelUrl(route: string, channel: string): string {
    switch (channel) {
      case 'Comgás Virtual':
        return `${environment.endpoints.comgasVirtual}${route}`;
      case 'Quero Comgás':
        return route;
      default:
        return '';
    }
  }

  config: SwiperOptions = {
    slidesPerView: 'auto',
    slidesOffsetBefore: 16,
    slidesOffsetAfter: 16,
    spaceBetween: 16,
    breakpoints: {
      992: {
        slidesPerView: 4,
        spaceBetween: 16,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 16,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
    },
  };
}
