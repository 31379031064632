import { IMarkerStrategy } from './marker-strategy';

export abstract class BaseMarkerStrategy implements IMarkerStrategy {
  protected abstract getSrc(): string;
  protected abstract getWidth(): string;
  protected abstract getHeight(): string;

  async createPinElement(): Promise<HTMLImageElement> {
    const pinElement = document.createElement('img');
    pinElement.src = this.getSrc();
    pinElement.style.width = this.getWidth();
    pinElement.style.height = this.getHeight();
    pinElement.style.backgroundColor = 'transparent';

    return pinElement;
  }
}
