import { Inject, Injectable } from '@angular/core';
import { GoogleTagManagerService } from '@core/index';
import { TranslateService } from '@ngx-translate/core';
import { extractAllHrefsRegex } from '@shared/utils';
import {
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
} from 'rxjs';
import { PlatformCheckerService } from '../platform-checker/platform-checker.service';
import { DOCUMENT } from '@angular/common';

interface IAnswerLinkForGtm {
  id: number;
  question: string;
  hrefs: string[];
  gtmCategory: string;
}

export interface IContentData {
  id: number;
  question: string;
  answer: string;
}

@Injectable({
  providedIn: 'root',
})
export class TrackContentClicks {
  private answerLinkForGtmEvent: IAnswerLinkForGtm | null = null;
  private clickSubscription: Subscription | null = null;

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly plataformChecker: PlatformCheckerService,
    private readonly translateService: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  startTrackingClickOnLinks(): void {
    this.answerLinkForGtmEvent = null;
    this.stopTrackingClickOnLinks();

    if (!this.plataformChecker.isBrowser() || !this.document.defaultView) {
      return;
    }

    this.clickSubscription = fromEvent(this.document.defaultView, 'click')
      .pipe(
        debounceTime(200),
        filter(
          (e: Event) =>
            e.target instanceof HTMLAnchorElement && Boolean(e.target.href)
        ),
        distinctUntilChanged()
      )
      .subscribe((e: Event) => {
        const el = e.target as HTMLAnchorElement;
        const found = this.answerLinkForGtmEvent?.hrefs.find(
          (href: string) => el.href === href
        );
        if (this.answerLinkForGtmEvent && found) {
          this.sendGtmEventOnAnswerLink(
            this.answerLinkForGtmEvent?.gtmCategory,
            this.answerLinkForGtmEvent?.question,
            found.replace(/(https?:\/\/|\/$)/gi, '')
          );
        }
      });
  }

  stopTrackingClickOnLinks(): void {
    this.answerLinkForGtmEvent = null;
    this.clickSubscription?.unsubscribe();
  }

  updateAnswerLinkForGtmEvent(
    content: IContentData,
    gtmCategory: string
  ): void {
    if (!content.answer) return;
    const hrefs = extractAllHrefsRegex(content.answer, this.translateService);
    if (!hrefs.length) return;

    if (this.answerLinkForGtmEvent?.question !== content.question) {
      this.answerLinkForGtmEvent = {
        id: content.id,
        question: content.question,
        hrefs,
        gtmCategory,
      };
    }
  }

  private sendGtmEventOnAnswerLink(
    category: string,
    action: string,
    label: string
  ): void {
    this.googleTagManager.sendCustomEvent(
      category,
      this.translateService.instant(action),
      label
    );
  }
}
