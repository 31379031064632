import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[qscAppLink]',
    standalone: true
})
export class AppLinkDirective {
  @Input() qscAppLink: any;

  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): boolean | void {
    let url = this.qscAppLink.url;
    const iosPlatforms = ['ios'];

    const _window = window as any;
    const _cordovaUtils = _window.cordovaUtilsProxy;

    if (_cordovaUtils.isCordova()) {
      if (
        _cordovaUtils.getPlatformId() == 'android' &&
        this.qscAppLink.urlAndroid
      ) {
        url = this.qscAppLink.urlAndroid;
      } else if (
        iosPlatforms.indexOf(_cordovaUtils.getPlatformId()) != -1 &&
        this.qscAppLink.urlIos
      ) {
        url = this.qscAppLink.urlIos;
      }

      _cordovaUtils.canLaunch(
        { uri: url },
        () => {
          _cordovaUtils.launch(
            { uri: url },
            function () {
              console.log('Aplicativo aberto com sucesso');
            },
            () => {
              _window.open(this.el.nativeElement.href, '_system');
            }
          );
        },
        () => {
          _window.open(this.el.nativeElement.href, '_system');
        }
      );
      event.preventDefault();
      return false;
    }
  }
}
