import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from '@models/content-pages/data';

@Injectable({
  providedIn: 'root',
})
export class ContentPageService {
  private contentPageSubject: BehaviorSubject<Page> = new BehaviorSubject<Page>(
    'home'
  );
  contentPage$ = this.contentPageSubject.asObservable();

  getContentPage(): Observable<Page> {
    return this.contentPage$;
  }

  setContentPage(contentPage: Page): void {
    this.contentPageSubject.next(contentPage);
  }

  isPage(pageName: Page): boolean {
    return this.contentPageSubject.getValue() === pageName;
  }
}
