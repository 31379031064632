export enum FlowIdsEnum {
  Common,
  SwimmingPool,
}

export enum FlowEnum {
  Common = '',
  SwimmingPool = 'Piscina',
}

export function getFlow(flowId: FlowIdsEnum): FlowEnum {
  if (flowId === FlowIdsEnum.SwimmingPool) {
    return FlowEnum.SwimmingPool;
  }
  return FlowEnum.Common;
}
