import { Inject, Injectable } from '@angular/core';
import { PlatformCheckerService } from '../../../shared/services/platform-checker/platform-checker.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { DOCUMENT } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

const bootstrapBreakpoints = {
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1400px)',
};

type BootstrapBreakpoints = keyof typeof bootstrapBreakpoints;

@Injectable({
  providedIn: 'root',
})
export class BootstrapService {
  window: any;

  constructor(
    private platformChecker: PlatformCheckerService,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  configure(): void {
    if (this.platformChecker.isBrowser()) {
      setTheme('bs5');
    }
  }

  enableBootstrapTooltips(): void {
    if (this.platformChecker.isBrowser()) {
      const tooltipTriggerList = this.document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      tooltipTriggerList.forEach(
        (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
      );
    }
  }

  isMatched(breakpoint: BootstrapBreakpoints): boolean {
    return this.breakpointObserver.isMatched(bootstrapBreakpoints[breakpoint]);
  }

  observeBreakpoint(
    breakpoint: BootstrapBreakpoints
  ): Observable<BreakpointState> {
    return this.breakpointObserver.observe([bootstrapBreakpoints[breakpoint]]);
  }
}
