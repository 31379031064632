export const SECTION_MAPPING: { [key: string]: string } = {
  simulador: 'simulator',
  beneficios: 'advantages-fold',
  programas: 'programs-fold',
  sustentabilidade: 'sustainability',
  depoimentos: 'testimonials',
  'encontrar-postos': 'find-gas-station',
  'comparativo-gas-natural': 'comparatives-fold',
  'clientes-comerciais': 'portfolio-fold',
  'duvidas-frequentes': 'short-faq',
  'acesso-rapido': 'quick-access',
  'canais-atendimento': 'footer',
  'contato-gnv': 'gnv-contact',
};
