import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { HumanSupportModalComponent } from '@modules/address-validation/components/human-support-modal/human-support-modal.component';
import { IChatMetadata } from '@models/chat/chat-metadata';
import { HumanSupportServiceEnum } from '@models/enums/human-support.enum';

@Injectable({
  providedIn: 'root',
})
export class HumanSupportModalService {
  bsModalRefHumanSupport: any;

  constructor(private readonly bsModalService: BsModalService) {}

  openHumanSupportModal(): BsModalRef {
    const modalOptions: ModalOptions = {
      class: 'human-support-modal modal-dialog-centered',
    };

    this.bsModalRefHumanSupport = this.bsModalService.show(
      HumanSupportModalComponent,
      modalOptions
    );

    return this.bsModalRefHumanSupport;
  }

  createChatMetadata(name: string, payload: any, code: number): IChatMetadata {
    return {
      servico: this.getHumanSupportService(code),
      api: 'buscaEnderecoUnificada',
      nome: name ?? '',
      payload,
      status: code,
    };
  }

  getHumanSupportService(code: number): HumanSupportServiceEnum {
    switch (code) {
      case 421:
      case 259:
      case 420:
      case 251:
        return HumanSupportServiceEnum.QueroSerCliente;
      case 450:
      case 423:
      case 424:
      case 430:
      case 500:
        return HumanSupportServiceEnum.LigaReliga;
      default:
        return HumanSupportServiceEnum.QueroSerCliente;
    }
  }
}
