import { Injectable } from '@angular/core';
import { FlowsAnalytics } from '@models/analytics/flows-analytics';
import { TranslateService } from '@ngx-translate/core';
import { IApiError } from '@models/error/api-error';
import { GoogleTagManagerService } from '@core/index';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly gtm: GoogleTagManagerService
  ) {}

  sendGtmEventOnDefaultError(err: IApiError): void {
    this.gtm.sendCustomEvent(
      FlowsAnalytics.category.erroBackend,

      err.error?.codigo?.toString() ??
        err.status?.toString() ??
        this.translateService.instant('@ERROR-UNDEFINED-CODE'),

      err.error?.mensagem ??
        err.statusText ??
        this.translateService.instant('@ERROR-UNDEFINED-MESSAGE')
    );
  }
}
