import { Injectable } from '@angular/core';
import { fromUser, userActions } from '@core/store';
import { IDisponibilidadeRedeEquipamento, ProfileIdsEnum } from '@models/index';
import {
  equipments,
  IEquipmentData,
  ISelectedEquipment,
  ProfileOfEquipment,
} from '@modules/equipment-selection/models/equipment';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  private selectedEquipments?: ISelectedEquipment[] = [];

  constructor(private readonly store: Store) {
    this.store.select(fromUser.selectEquipmentsData).subscribe((data) => {
      this.selectedEquipments = data;
    });
  }

  loadAndFormatEquipmentsToNetworkAvailability():
    | IDisponibilidadeRedeEquipamento[]
    | undefined {
    const storedEquipments = this.loadEquipmentsData();
    if (!storedEquipments?.length) return;
    return this.formatEquipmentsToNetworkAvailability(storedEquipments);
  }

  formatEquipments(type: ProfileIdsEnum): IEquipmentData[] {
    let profileType: ProfileOfEquipment = '';

    switch (type) {
      case ProfileIdsEnum.Residences:
        profileType = 'residencia';
        break;
      case ProfileIdsEnum.Buildings:
        profileType = 'condominio';
        break;
      case ProfileIdsEnum.Commerce:
        profileType = 'comercial';
        break;
      case ProfileIdsEnum.Industry:
        profileType = 'industrial';
        break;
    }

    const result: IEquipmentData[] = [];
    const equipmentList = this.getEquipments();
    equipmentList.forEach((val) => {
      if (val.profile.indexOf(profileType) >= 0) {
        result.push(val);
      }
    });
    return result;
  }

  private getEquipments(): IEquipmentData[] {
    return [
      equipments.FOGAO,
      equipments.AQUECEDORPARACHUVEIRO,
      equipments.CHURRASQUEIRA,
      equipments.AQUECEDORPARAPISCINA,
      equipments.LAREIRA,
      equipments.TOALHEIRO,
      equipments.GERADOR,
      equipments.PISOAQUECIDO,
      equipments.FOGAOINDUSTRIAL,
      equipments.FORNOCOMBINADO,
      equipments.CHAPA,
      equipments.FORNODEPIZZA,
      equipments.CHARBROILER,
      equipments.FRITADEIRA,
      equipments.PISCINAAQUECIDA,
    ];
  }

  formatEquipmentsToNetworkAvailability(
    data: ISelectedEquipment[]
  ): IDisponibilidadeRedeEquipamento[] {
    return data
      .filter(({ qtd }) => qtd > 0)
      .map<IDisponibilidadeRedeEquipamento>(({ qtd, value }) => ({
        aparelho: value,
        quantidade: qtd,
      }));
  }

  formatEquipmentsAnalytics(selectedEquipments: ISelectedEquipment[]): string {
    let result = '';
    selectedEquipments.forEach((item) => {
      if (item.qtd > 0) {
        result += item.sigla + item.qtd + ' ';
      }
    });
    return result.trimEnd();
  }

  saveEquipmentsData(selectedEquipments: ISelectedEquipment[]): void {
    this.store.dispatch(
      userActions.updateUser({
        user: {
          equipments: selectedEquipments,
        },
      })
    );
  }

  loadEquipmentsData(): ISelectedEquipment[] | undefined {
    return this.selectedEquipments;
  }

  removeData(): void {
    this.store.dispatch(
      userActions.updateUser({
        user: { equipments: undefined },
      })
    );
  }
}
