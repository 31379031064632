import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { DOCUMENT, NgFor, NgIf, NgClass } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { environment } from '@env';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import {
  FlowsAnalytics,
  HeaderAnalytics,
  HeaderGtmSegments,
  HEADER_GTM_SEGMENTS,
} from '@models/analytics';
import { FlowService } from '@shared/services';
import * as headerDataJson from 'src/assets/home/data/header.json';
import * as searchServicesJson from 'src/assets/data/search-services.json';
import { HamburgerMenuComponent } from '../hamburger-menu/hamburger-menu.component';
import { SearchComponent } from '../search/search.component';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import {
  ISearchService,
  SearchService,
} from '@shared/services/search/search.service';
import { EventTrackerDirective } from '@shared/directives';
import { ThemeSwitcherComponent } from '../theme-switcher/theme-switcher.component';
import { filter } from 'rxjs';
import { SwimmingPoolService } from '@modules/swimming-pool-content/services/swimming-pool.service';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

export interface ILink {
  id: number;
  label: string;
  url: string;
}

@Component({
  selector: 'qsc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    RouterLink,
    FileHashPipe,
    EventTrackerDirective,
    HamburgerMenuComponent,
    SearchComponent,
    ThemeSwitcherComponent,
    TranslateModule,
  ],
})
export class HeaderComponent implements OnInit {
  @ViewChild('searchInput')
  searchInput?: AutocompleteComponent;
  window: any;
  headerData = headerDataJson;
  searchServicesData = searchServicesJson;
  isFaqPage = false;
  isSwimmingPoolPage = false;
  private readonly breakpointObserver = inject(BreakpointObserver);

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly router: Router,
    private readonly flowService: FlowService,
    private readonly searchService: SearchService,
    private readonly swimmingPoolService: SwimmingPoolService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isFaqPage = event.urlAfterRedirects === '/duvidas-frequentes';
        this.isSwimmingPoolPage =
          event.urlAfterRedirects === '/piscina-aquecida';
      });
  }

  isMatched = (value: string) => this.breakpointObserver.isMatched(value);

  get url(): string {
    return environment.endpoints.comgasVirtual;
  }

  get headerAnalytics() {
    return HeaderAnalytics;
  }

  onSegmentClick(id: number): void {
    const segmentAction = HEADER_GTM_SEGMENTS[id as keyof HeaderGtmSegments];
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.menuPrincipal,
      segmentAction,
      HeaderAnalytics.label.clique
    );
  }

  checkCurrentPageByUrl(url: string): boolean {
    return this.router.url.startsWith(url);
  }

  openEmergencies(): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.menuPrincipal,
      HeaderAnalytics.action.emergencia,
      HeaderAnalytics.label.clique
    );

    const url = environment.endpoints.comgasVirtual + '/emergencias';
    this.window.open(url, '_self');
  }

  contractNow(): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.menuPrincipal,
      HeaderAnalytics.action.contratarAgora,
      HeaderAnalytics.label.clique
    );
    if (this.isSwimmingPoolPage) {
      this.swimmingPoolService.contractNow();
      return;
    }
    this.router.navigateByUrl('/selecionar-segmento');
  }

  onClickLogo(): void {
    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.menuPrincipal,
      FlowsAnalytics.action.logoComgas,
      FlowsAnalytics.label.clique
    );
  }

  linkTrackBy(_: number, link: ILink) {
    return link.id;
  }

  onSelectedService(service: ISearchService): void {
    this.searchInput?.control.setValue(null);
    this.searchService.navigate(service);
  }
}
