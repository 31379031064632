<div class="container">
  <button (click)="bsModalRef.hide()" class="container__close-button">
    <i aria-hidden="true" class="icon-sinal_multiplicar_x_fechar"></i>
  </button>
  <div class="d-flex flex-column align-items-center">
    <div class="container__icon">
      <i
        aria-hidden="true"
        class="icon-chama_comgas"
        [ngClass]="isAddAction() ? 'blue' : 'orange'"
      ></i>
    </div>
    <div class="d-flex flex-column w-100">
      <p class="container__title" [innerHTML]="getTitle() | translate"></p>
      <p class="container__subtitle" [innerHTML]="gasStation | translate"></p>
      <div>
        <div class="container__button">
          <qsc-button
            class="w-100"
            [variant]="isAddAction() ? 'secondary_0' : 'alert'"
            [buttonText]="getButtonLabel() | translate"
            (buttonClick)="onButtonClick()"
          ></qsc-button>
        </div>
      </div>
    </div>
  </div>
</div>
