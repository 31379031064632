<button
  class="expandable-list-button"
  [ngClass]="{ 'is-expanded pe-auto': isExpanded }"
  (click)="toggle()"
  [attr.aria-expanded]="isExpanded"
  [attr.aria-controls]="'expandableContent'"
>
  {{ title }}
  <i
    aria-hidden="true"
    [ngClass]="{ 'icon-seta_cima': isExpanded, 'icon-seta_baixo': !isExpanded }"
  ></i>
</button>
<div
  id="expandableContent"
  class="expandable-list"
  *ngIf="isExpanded"
  (click)="preventClick($event)"
  [attr.aria-hidden]="true"
>
  <ng-content></ng-content>
</div>
