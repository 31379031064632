<div class="container">
  <div class="d-flex flex-column align-items-lg-center">
    <div class="container__icon">
      <qsc-svg-icon icon="warning-sign"></qsc-svg-icon>
    </div>
    <div>
      <p
        class="container__title"
        [innerHTML]="'@HUMAN-SUPPORT-MODAL-TITLE' | translate"
      ></p>
    </div>
    <div>
      <p
        class="container__subtitle"
        [innerHTML]="'@HUMAN-SUPPORT-MODAL-SUBTITLE' | translate"
      ></p>
    </div>
    <div class="container__button w-100">
      <qsc-button
        class="w-100"
        variant="green"
        [buttonText]="'@HUMAN-SUPPORT-MODAL-BUTTON' | translate"
        icon="balloon-chat"
        [iconBefore]="true"
        (buttonClick)="onClose()"
      ></qsc-button>
    </div>
  </div>
</div>
