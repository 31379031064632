import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';

export interface IWebToCaseField {
  name: string;
  value: string | null | undefined;
}

export interface IWebToCaseConfig {
  url: string;
  fields: IWebToCaseField[];
}

@Injectable({
  providedIn: 'root',
})
export class WebToCaseService {
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  createAndSubmitForm(config: IWebToCaseConfig): void {
    const form = this.createForm(config.url);
    this.addFieldsToForm(form, config.fields);
    this.appendFormToBody(form);
    this.submitForm(form);
    this.removeFormFromBody(form);
  }

  private createForm(actionUrl: string): HTMLFormElement {
    const form = this.renderer.createElement('form');
    this.renderer.setAttribute(form, 'method', 'POST');
    this.renderer.setAttribute(form, 'action', actionUrl);
    this.renderer.setAttribute(form, 'target', '_self');
    this.renderer.setStyle(form, 'display', 'none');
    return form;
  }

  private addFieldsToForm(
    form: HTMLFormElement,
    fields: IWebToCaseField[]
  ): void {
    fields.forEach((field) => {
      const input = this.createInput(field.name, field.value || '');
      this.renderer.appendChild(form, input);
    });
  }

  private createInput(name: string, value: string): HTMLInputElement {
    const input = this.renderer.createElement('input');
    this.renderer.setAttribute(input, 'type', 'hidden');
    this.renderer.setAttribute(input, 'name', name);
    this.renderer.setAttribute(input, 'value', value);
    return input;
  }

  private appendFormToBody(form: HTMLFormElement): void {
    this.renderer.appendChild(this.document.body, form);
  }

  private submitForm(form: HTMLFormElement): void {
    form.submit();
  }

  private removeFormFromBody(form: HTMLFormElement): void {
    this.renderer.removeChild(this.document.body, form);
  }
}
