import { Injectable, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElementService, LegendBuilder } from '@shared/services';

export enum LegendType {
  CoverageArea,
  Route,
}

@Injectable({
  providedIn: 'root',
})
export class LegendService {
  constructor(
    private readonly elementService: ElementService,
    private readonly renderer: Renderer2,
    private readonly translateService: TranslateService
  ) {}

  private createBuilder(): LegendBuilder {
    return new LegendBuilder(
      this.elementService,
      this.renderer,
      this.translateService
    );
  }

  removeLegends(): void {
    const legends = document.getElementsByClassName('find-gas-station__legend');

    if (legends) {
      Array.from(legends).forEach((legend) => legend.remove());
    }
  }

  createLegendForCovarageArea(): HTMLElement {
    this.removeLegends();

    const legend = this.createBuilder()
      .addHighFlowGasStationLabel()
      .addCommonGasStationLabel()
      .build();

    return legend;
  }

  createLegendForRoute(): HTMLElement {
    this.removeLegends();

    const legend = this.createBuilder()
      .addHighFlowGasStationLabel()
      .addCommonGasStationLabel()
      .addOriginAndDestinationLabel()
      .build();

    return legend;
  }
}
