import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesService {
  private autocompleteService?: google.maps.places.AutocompleteService;
  private placesService?: google.maps.places.PlacesService;

  constructor(private readonly ngZone: NgZone) {}

  async initializeService() {
    const {
      AutocompleteService: AutocompleteService,
      PlacesService: PlacesService,
    } = (await google.maps.importLibrary(
      'places'
    )) as google.maps.PlacesLibrary;

    if (!this.autocompleteService) {
      this.autocompleteService = new AutocompleteService();
    }

    if (!this.placesService) {
      const map = new google.maps.Map(document.createElement('div'));
      this.placesService = new PlacesService(map);
    }
  }

  getPlacePredictions(
    input: string
  ): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable((observer) => {
      if (!input.trim()) {
        observer.next([]);
        observer.complete();
        return;
      }

      this.autocompleteService!.getPlacePredictions(
        { input },
        (predictions, status) => {
          this.ngZone.run(() => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              observer.next(predictions);
            } else {
              observer.next([]);
            }
            observer.complete();
          });
        }
      );
    });
  }
}
