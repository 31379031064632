import { fromUser } from '@core/index';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  CategoryIdsEnum,
  ICriarAgendamentoInput,
  IObterAgendamentoInput,
  IObterDatasDisponiveisInput,
  IObterHorariosDisponiveisInput,
  IVerificarCepAgendamentoInput,
} from '@models/index';
import {
  combineDateAndTime,
  formatDateToISO,
  getLastDayOfFutureMonth,
} from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class SchedullingMapper {
  userData?: fromUser.IUser | null;

  constructor(private readonly store: Store) {
    this.store.select(fromUser.selectUserData).subscribe((data) => {
      this.userData = data;
    });
  }

  createGetSchedulingInput(): IObterAgendamentoInput {
    const unifiedAddress = this.userData?.unifiedAddress
      ? this.userData?.unifiedAddress
      : this.userData?.address;

    return {
      bairro: unifiedAddress?.bairro ?? '',
      cep: unifiedAddress?.cep ?? '',
      cidade: unifiedAddress?.cidade ?? '',
      estado: unifiedAddress?.estado ?? '',
      logradouro: unifiedAddress?.logradouro ?? '',
      numero: this.userData?.number ?? '',
      complemento:
        this.userData?.complement?.complemento ??
        this.userData?.optionalComplement ??
        '',
      apartamento: this.userData?.apartment ?? '',
      protocolo: this.userData?.protocol ?? '',
      nome: this.userData?.name ?? '',
      telefone: this.userData?.phoneNumber ?? '',
    };
  }

  createSchedulingInput(): ICriarAgendamentoInput {
    const unifiedAddress = this.userData?.unifiedAddress
      ? this.userData?.unifiedAddress
      : this.userData?.address;

    return {
      dadosPessoais: {
        telefone: this.userData?.phoneNumber as string,
        nome: this.userData?.name as string,
        email: this.userData?.email as string,
      },
      dataAgendamento: combineDateAndTime(
        this.userData?.scheduling?.selectedDate as string,
        this.userData?.scheduling?.selectedTime as string
      ),
      endereco: {
        bairro: unifiedAddress?.bairro as string,
        cep: unifiedAddress?.cep as string,
        cidade: unifiedAddress?.cidade as string,
        logradouro: unifiedAddress?.logradouro as string,
        numero: this.userData?.number as string,
        estado: unifiedAddress?.estado as string,
        apartamento: this.userData?.apartment,
        complemento:
          this.userData?.complement?.complemento ??
          this.userData?.optionalComplement ??
          '',
      },
      protocolo: this.userData?.protocol as string,
      perfil: this.getSchedulingCategory(),
    };
  }

  createVerifyCepSchedulingInput(): IVerificarCepAgendamentoInput {
    const unifiedAddress = this.userData?.unifiedAddress
      ? this.userData?.unifiedAddress
      : this.userData?.address;

    return {
      cep: unifiedAddress?.cep as string,
      perfil: this.getSchedulingCategory(),
      nome: this.userData?.name as string,
      telefone: this.userData?.phoneNumber as string,
    };
  }

  getSchedulingCategory(): string {
    const schedulingProfiles = {
      [CategoryIdsEnum.HouseBuilt]: 'construida',
      [CategoryIdsEnum.NewHome]: 'obra',
      [CategoryIdsEnum.Commercial]: 'comercio',
    };
    if (!this.userData?.categoryId) return '';
    return schedulingProfiles[this.userData?.categoryId] ?? '';
  }

  createGetDatesInput(): IObterDatasDisponiveisInput {
    return {
      dataInicial: formatDateToISO(new Date()),
      dataFinal: getLastDayOfFutureMonth(1),
      perfil: this.getSchedulingCategory(),
      nome: this.userData?.name ?? '',
      telefone: this.userData?.phoneNumber ?? '',
    };
  }

  createGetTimesInput(date: string | null): IObterHorariosDisponiveisInput {
    return {
      data: String(date),
      perfil: this.getSchedulingCategory(),
      nome: this.userData?.name ?? '',
      telefone: this.userData?.phoneNumber ?? '',
    };
  }
}
