import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapSettings {
  defaultOptions(): google.maps.MapOptions {
    return {
      mapId: 'map',
      scrollwheel: true,
      keyboardShortcuts: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      center: { lat: -14.235, lng: -51.9253 },
      maxZoom: 15,
      zoom: 4,
    };
  }
}
