<button
  class="back-to-top"
  [ngClass]="(showBackToTop$ | async) ? 'd-flex' : 'd-none'"
  (click)="scrollToTop()"
  [qscEventTrackerCategory]="footerAnalytics.category.rodape"
  [qscEventTrackerAction]="footerAnalytics.action.voltarAoTopo"
  [qscEventTrackerLabel]="footerAnalytics.label.clique"
  aria-label="Voltar ao topo"
>
  <i aria-hidden="true" class="icon-seta_cima"></i>
  <span
    class="d-none d-xl-inline-block"
    [innerHTML]="'@BACK-TO-TOP' | translate"
  ></span>
</button>
