import { NgClass, NgFor } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { IJsonLd, JsonLdComponent } from '@core/seo';
import { TrackContentClicks } from '@shared/services/track-content-clicks/track-content-clicks.service';
import { ContentAnalytics } from '@models/analytics';

interface IShortFaqItem {
  id: number;
  question: string;
  answer: {
    html: string;
  };
}

export interface IShortFaq {
  items: IShortFaqItem[];
  schema?: IJsonLd;
}

@Component({
  selector: 'qsc-short-faq',
  templateUrl: './short-faq.component.html',
  styleUrls: ['./short-faq.component.scss'],
  animations: [
    trigger('contentExpansion', [
      state(
        'expanded',
        style({ height: '*', opacity: 1, visibility: 'visible' })
      ),
      state(
        'collapsed',
        style({ height: '0px', opacity: 0, visibility: 'hidden' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('300ms cubic-bezier(.37,1.04,.68,.98)')
      ),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CdkAccordionModule,
    RouterLink,
    NgFor,
    NgClass,
    JsonLdComponent,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortFaqComponent implements AfterViewInit, OnDestroy {
  @Input() data?: IShortFaq;

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly trackContentClicks: TrackContentClicks,
    private readonly translateService: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.trackContentClicks.startTrackingClickOnLinks();
  }

  ngOnDestroy(): void {
    this.trackContentClicks.stopTrackingClickOnLinks();
  }

  openQuestion(item: IShortFaqItem) {
    this.googleTagManager.sendCustomEvent(
      ContentAnalytics.category.faq,
      this.translateService.instant(item.question),
      ContentAnalytics.label.abrir
    );
    this.trackContentClicks.updateAnswerLinkForGtmEvent(
      {
        id: item.id,
        question: item.question,
        answer: item.answer.html,
      },
      ContentAnalytics.category.faq
    );
  }

  closeQuestion(item: IShortFaqItem) {
    this.googleTagManager.sendCustomEvent(
      ContentAnalytics.category.faq,
      this.translateService.instant(item.question),
      ContentAnalytics.label.fechar
    );
  }

  sendEventGA(action?: string) {
    this.googleTagManager.sendCustomEvent(
      ContentAnalytics.category.faq,
      action ?? ContentAnalytics.action.faqConsultarTodasAsPerguntas,
      ContentAnalytics.label.clique
    );
  }

  faqItemTrackBy(_: number, item: IShortFaqItem) {
    return item.id;
  }
}
