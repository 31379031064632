export const BlogAnalytics = {
  category: {
    blog: 'blog',
  },
  action: {
    redeSocial: 'rede social',
    saibaMais: 'saiba mais',
    continuarLendo: 'continuar lendo',
    acessibilidade: 'acessibilidade',
    ctaHiperlink: 'cta hiperlink',
    compartilhar: 'compartilhar',
    navSecoes: 'nav secoes',
    categorias: 'categorias',
    acessarArtigo: 'acessar artigo',
    ctaHomeBlog: 'cta home blog',
    saberMaisSegmentos: 'saber mais segmentos',
    ctaSegmentos: 'cta segmentos',
    verTodosArtigos: "ver todos artigos"
  },
  label: {
    linkedin: 'linkedin',
    facebook: 'facebook',
    twitter: 'twitter',
    whatsapp: 'whatsapp',
    audioDinamico: 'audio dinamico',
    clique: 'clique',
    getById: (id: number) =>
      ({
        1: 'resid_uni',
        2: 'negocios',
        3: 'industrias',
        4: 'veiculos',
      }[id]),
  },
} as const;
