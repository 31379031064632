import { BaseMarkerStrategy } from './base-marker-strategy';

export class HighFlowMarkerStrategy extends BaseMarkerStrategy {
  protected getSrc(): string {
    return '/assets/vehicles-content/img/high-flow-station.png';
  }
  protected getWidth(): string {
    return '28px';
  }
  protected getHeight(): string {
    return '28px';
  }
}
