import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IContentData } from '@models/index';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

@Component({
  selector: 'qsc-solution-card',
  standalone: true,
  imports: [CommonModule, FileHashPipe, TranslateModule],
  templateUrl: './solution-card.component.html',
  styleUrls: ['./solution-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionCardComponent {
  @Input() data?: IContentData;
}
