import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DirectionsService {
  private directionsService: google.maps.DirectionsService | null = null;

  async initializeService() {
    const { DirectionsService: DirectionsService } =
      (await google.maps.importLibrary('routes')) as google.maps.RoutesLibrary;
    this.directionsService = new DirectionsService();
  }

  async createRoute(
    origin: string,
    destination: string,
    waypoints: google.maps.DirectionsWaypoint[] = [],
    travelMode: google.maps.TravelMode = google.maps.TravelMode.DRIVING
  ): Promise<google.maps.DirectionsResult | null> {
    const request: google.maps.DirectionsRequest = {
      origin,
      destination,
      waypoints,
      travelMode,
      optimizeWaypoints: true,
      language: 'pt-BR',
      region: 'BR',
    };

    return new Promise((resolve, reject) => {
      this.directionsService?.route(request, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          resolve(result);
        } else {
          reject(null);
        }
      });
    });
  }
}
