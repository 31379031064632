export type ProfileOfEquipment =
  | 'residencia'
  | 'condominio'
  | 'comercial'
  | 'industrial'
  | '';

export type EquipmentName =
  | 'FOGAO'
  | 'LAREIRA'
  | 'CHURRASQUEIRA'
  | 'TOALHEIRO'
  | 'GERADOR'
  | 'PISOAQUECIDO'
  | 'AQUECEDORPARAPISCINA'
  | 'AQUECEDORPARACHUVEIRO'
  | 'FOGAOINDUSTRIAL'
  | 'FORNOCOMBINADO'
  | 'CHAPA'
  | 'FORNODEPIZZA'
  | 'CHARBROILER'
  | 'FRITADEIRA'
  | 'PISCINAAQUECIDA';

export interface IEquipmentData {
  translate: string;
  value: string;
  profile: ProfileOfEquipment[];
  sigla: string;
}

export type IEquipments = {
  [key in EquipmentName]: IEquipmentData;
};

export interface ISelectedEquipment {
  value: IEquipmentData['value'];
  qtd: number;
  sigla: IEquipmentData['sigla'];
}

export const equipments: IEquipments = {
  FOGAO: {
    translate: '@EQUIPMENT-TYPE-FOGAO',
    value: 'fogao',
    profile: ['residencia', 'condominio'],
    sigla: 'FOG',
  },
  LAREIRA: {
    translate: '@EQUIPMENT-TYPE-LAREIRA',
    value: 'lareira',
    profile: ['residencia', 'condominio'],
    sigla: 'LAR',
  },
  CHURRASQUEIRA: {
    translate: '@EQUIPMENT-TYPE-CHURRASQUEIRA',
    value: 'Churrasqueira',
    profile: ['residencia', 'condominio'],
    sigla: 'CHU',
  },
  TOALHEIRO: {
    translate: '@EQUIPMENT-TYPE-TOALHEIRO',
    value: 'toalheiro',
    profile: ['residencia', 'condominio'],
    sigla: 'TOA',
  },
  GERADOR: {
    translate: '@EQUIPMENT-TYPE-GERADOR',
    value: 'gerador',
    profile: ['residencia', 'condominio', 'comercial', 'industrial'],
    sigla: 'GER',
  },
  PISOAQUECIDO: {
    translate: '@EQUIPMENT-TYPE-PISOAQUECIDO',
    value: 'piso aquecido',
    profile: ['residencia', 'condominio'],
    sigla: 'PSA',
  },
  AQUECEDORPARAPISCINA: {
    translate: '@EQUIPMENT-TYPE-AQUECEDORPARAPISCINA',
    value: 'aquecedor para piscina',
    profile: ['residencia', 'condominio'],
    sigla: 'AQP',
  },
  AQUECEDORPARACHUVEIRO: {
    translate: '@EQUIPMENT-TYPE-AQUECEDORPARACHUVEIRO',
    value: 'aquecedor para chuveiro',
    profile: ['residencia', 'condominio', 'comercial'],
    sigla: 'AQC',
  },
  FOGAOINDUSTRIAL: {
    translate: '@EQUIPMENT-TYPE-FOGAOINDUSTRIAL',
    value: 'fogao industrial',
    profile: ['comercial', 'industrial'],
    sigla: 'FGI',
  },
  FORNOCOMBINADO: {
    translate: '@EQUIPMENT-TYPE-FORNOCOMBINADO',
    value: 'forno combinado',
    profile: ['comercial', 'industrial'],
    sigla: 'FNC',
  },
  CHAPA: {
    translate: '@EQUIPMENT-TYPE-CHAPA',
    value: 'chapa',
    profile: ['comercial', 'industrial'],
    sigla: 'CHP',
  },
  FORNODEPIZZA: {
    translate: '@EQUIPMENT-TYPE-FORNODEPIZZA',
    value: 'forno de pizza',
    profile: ['comercial', 'industrial'],
    sigla: 'FNP',
  },
  CHARBROILER: {
    translate: '@EQUIPMENT-TYPE-CHARBROILER',
    value: 'char broiler',
    profile: ['comercial', 'industrial'],
    sigla: 'CHB',
  },
  FRITADEIRA: {
    translate: '@EQUIPMENT-TYPE-FRITADEIRA',
    value: 'fritadeira',
    profile: ['comercial', 'industrial'],
    sigla: 'FRT',
  },
  PISCINAAQUECIDA: {
    translate: '@EQUIPMENT-TYPE-PISCINAAQUECIDA',
    value: 'piscina aquecida',
    profile: ['comercial', 'industrial'],
    sigla: 'PCA',
  },
};
