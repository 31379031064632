<div class="container">
  <div class="d-flex flex-column align-items-lg-center">
    <div class="container__icon">
      <qsc-svg-icon icon="location-gps"></qsc-svg-icon>
    </div>

    <div>
      <p
        class="container__title"
        [innerHTML]="'@ADDRESS-CONFIRMATION-MODAL-TITLE' | translate"
      ></p>
    </div>

    <div>
      <p
        class="container__subtitle"
        [innerHTML]="'@ADDRESS-CONFIRMATION-MODAL-SUBTITLE' | translate"
      ></p>
    </div>

    <div class="container__items">
      <ul>
        <li *ngIf="street">
          <div>
            <span
              >{{ street | titlecase }}, {{ neighborhood | titlecase }}, </span
            ><span
              [ngClass]="
                addressConfirmationForm.controls.address.value
                  ? 'valid'
                  : 'invalid'
              "
              >{{ number }}</span
            >
          </div>
          <mat-checkbox
            [formControl]="addressConfirmationForm.controls.address"
          ></mat-checkbox>
        </li>
        <li *ngIf="complement">
          <div>
            <span
              [innerHTML]="'@ADDRESS-CONFIRMATION-MODAL-LABEL-2' | translate"
            ></span
            ><span
              [ngClass]="
                addressConfirmationForm.controls.complement.value
                  ? 'valid'
                  : 'invalid'
              "
              >{{ complement }}</span
            >
          </div>
          <mat-checkbox
            [formControl]="addressConfirmationForm.controls.complement"
          ></mat-checkbox>
        </li>
        <li *ngIf="apartment">
          <div>
            <span
              [innerHTML]="'@ADDRESS-CONFIRMATION-MODAL-LABEL-1' | translate"
            ></span
            ><span
              [ngClass]="
                addressConfirmationForm.controls.apartment.value
                  ? 'valid'
                  : 'invalid'
              "
              >{{ apartment }}</span
            >
          </div>
          <mat-checkbox
            [formControl]="addressConfirmationForm.controls.apartment"
          ></mat-checkbox>
        </li>
      </ul>
    </div>

    <div class="container__button w-100">
      <qsc-button
        [disabled]="addressConfirmationForm.invalid"
        class="w-100"
        variant="green"
        [buttonText]="'@ADDRESS-CONFIRMATION-MODAL-BUTTON-1' | translate"
        (buttonClick)="onConfirmAddress()"
      ></qsc-button>
      <qsc-button
        class="w-100"
        variant="secondary"
        [buttonText]="'@ADDRESS-CONFIRMATION-MODAL-BUTTON-2' | translate"
        (buttonClick)="onChangeAddress()"
      ></qsc-button>
    </div>
  </div>
</div>
