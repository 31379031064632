export const FooterAnalytics = {
  category: {
    rodape: 'rodape',
  },
  action: {
    conhecaComgas: 'conheca a comgas',
    comgasVirtual: 'comgas virtual',
    logoComgas: 'logo comgas',
    clienteComgas: 'cliente comgas',
    administradoras: 'administradoras',
    clienteConcessionaria: 'concessionarias e construtoras',
    politicaDePrivacidade: 'politica de privacidade',
    politicaDeCookies: 'politica de cookies',
    linkedin: 'linkedin',
    instagram: 'instagram',
    facebook: 'facebook',
    twitter: 'twitter',
    youtube: 'youtube',
    redesSociaos: 'redes sociais',
    chatbot: 'chatbot',
    whatsAppCris: 'whatsapp',
    voltarAoTopo: 'voltar ao topo',
    duvidasFrequentes: 'duvidas frequentes',
    definicoesDeCookies: 'definicoes de cookies',
    canaisAtendimento: 'canais de atendimento',
  },
  label: {
    clique: 'clique',
  },
} as const;
