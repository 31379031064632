import { Injectable } from '@angular/core';
import { MarkerStrategyFactory } from './marker-strategy-factory';
import { IMarkerData, LocalType } from './marker-data';
import { MarkerTypeEnum } from './marker-type.enum';
import { InfoWindowService } from '../info-window/info-window.service';

@Injectable({
  providedIn: 'root',
})
export class MarkerService {
  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  private currentInfoWindow: google.maps.InfoWindow | null = null;

  constructor(
    private readonly markerStrategyFactory: MarkerStrategyFactory,
    private readonly infoWindowService: InfoWindowService
  ) {}

  async createMarkerAsync(
    markerType: MarkerTypeEnum,
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const marker = await this.createAndConfigureMarker(markerType, data, map);
    this.addClickListenerToMarker(marker, data);
    return marker;
  }

  getMarkers(): google.maps.marker.AdvancedMarkerElement[] {
    return this.markers;
  }

  async addMarker(
    type: MarkerTypeEnum,
    latLng: google.maps.LatLng,
    name: string,
    map: google.maps.Map
  ): Promise<void> {
    this.markers.push(
      await this.createMarkerAsync(
        type,
        {
          latitude: latLng.lat(),
          longitude: latLng.lng(),
          name,
        },
        map
      )
    );
  }

  setMarkers(markers: google.maps.marker.AdvancedMarkerElement[]): void {
    this.markers = markers;
  }

  clearMarkers(): void {
    this.markers.forEach((marker) => (marker.map = null));
    this.markers = [];
  }

  private addClickListenerToMarker(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ): void {
    marker.addListener('click', () => this.handleMarkerClick(marker, data));
  }

  private handleMarkerClick(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ): void {
    this.closeCurrentInfoWindow();
    this.openNewInfoWindow(marker, data);
  }

  private closeCurrentInfoWindow(): void {
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }
  }

  private openNewInfoWindow(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ): void {
    this.currentInfoWindow = new google.maps.InfoWindow({
      content: this.infoWindowService.createContent(data, LocalType.GasStation),
    });
    this.currentInfoWindow.open(marker.map, marker);
  }

  private async createAndConfigureMarker(
    markerType: MarkerTypeEnum,
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const markerStrategy =
      this.markerStrategyFactory.createMarkerStrategy(markerType);
    const pinElement = await markerStrategy.createPinElement();
    return this.createAdvancedMarkerElement(pinElement, data, map);
  }

  private async createAdvancedMarkerElement(
    pinElement: HTMLImageElement,
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    return new AdvancedMarkerElement({
      position: new google.maps.LatLng(
        Number(data.latitude),
        Number(data.longitude)
      ),
      title: data.name,
      map: map,
      content: pinElement,
    });
  }
}
