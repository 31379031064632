import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { HeaderAnalytics } from '@models/analytics';
import { ButtonComponent } from '@shared/components';

@Component({
  selector: 'qsc-content-pages-modal',
  templateUrl: './content-pages-modal.component.html',
  styleUrls: ['./content-pages-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FileHashPipe, TranslateModule, ButtonComponent],
})
export class ContentPagesModalComponent {
  residences = false;
  buildings = false;
  commerce = false;
  industry = false;
  virtual = false;
  solutions = false;
  gnv = false;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly googleTagManager: GoogleTagManagerService
  ) {}

  goToResidences(): void {
    this.sendGtmEvent(HeaderAnalytics.label.residencias);
    this.residences = true;
    this.bsModalRef.hide();
  }

  goToBuildings(): void {
    this.sendGtmEvent(HeaderAnalytics.label.predios);
    this.buildings = true;
    this.bsModalRef.hide();
  }

  goToCommerce(): void {
    this.sendGtmEvent(HeaderAnalytics.label.negocios);
    this.commerce = true;
    this.bsModalRef.hide();
  }

  goToIndustry(): void {
    this.sendGtmEvent(HeaderAnalytics.label.industrias);
    this.industry = true;
    this.bsModalRef.hide();
  }

  goToGnv(): void {
    this.sendGtmEvent(HeaderAnalytics.label.veiculos);
    this.gnv = true;
    this.bsModalRef.hide();
  }

  goToVirtual(): void {
    this.sendGtmEvent(HeaderAnalytics.label.fazerLogin);
    this.virtual = true;
    this.bsModalRef.hide();
  }

  goToSolutions(): void {
    this.sendGtmEvent(HeaderAnalytics.label.contratarServicos);
    this.solutions = true;
    this.bsModalRef.hide();
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  sendGtmEvent(action: string): void {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.busca,
      HeaderAnalytics.action.modalBusca,
      action
    );
  }
}
