<div
  class="select-segment"
  [ngClass]="{ 'select-segment--swimming-pool': isSwimmingPoolFlow }"
>
  <div class="d-md-flex justify-content-md-between align-items-md-center">
    <h3 class="select-segment__title" [innerHTML]="title | translate"></h3>
    <button class="select-segment__btn-close" (click)="backToHome()">
      <i aria-hidden="true" class="icon-sinal_multiplicar_x_fechar"></i>
    </button>
  </div>
  <div class="select-segment__content">
    @for (item of filteredItems; track $index) {
    <button class="select-segment__item" (click)="handle(item.id)">
      <qsc-segment-card
        [ngClass]="{ 'segment-card--swimming-pool': isSwimmingPoolFlow }"
        [data]="item"
        [withCta]="false"
        [index]="$index"
        [isSwimmingPoolFlow]="isSwimmingPoolFlow"
      >
      </qsc-segment-card>
    </button>
    }
  </div>
</div>
