import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface IOptionButtons {
  index: number;
  option: {
    iconClass?: string;
    label: string;
  };
}

@Component({
  selector: 'qsc-option-buttons',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './option-buttons.component.html',
  styleUrls: ['./option-buttons.component.scss'],
})
export class OptionButtonsComponent implements OnChanges {
  @Input() options: IOptionButtons['option'][] = [];
  @Input() activeOptionIndex = 0;
  @Output() optionChange: EventEmitter<IOptionButtons> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      if (this.options.length === 1) {
        this.changeOption(0);
      }
    }
  }

  changeOption(index: number) {
    this.activeOptionIndex = index;

    this.optionChange.emit({
      index: this.activeOptionIndex,
      option: this.options[this.activeOptionIndex],
    });
  }
}
