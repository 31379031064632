import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterEventsService } from '@shared/services/router-events/router-events.service';
import { UrlService } from '@shared/services/url/url.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingAppFacade {
  constructor(
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly routerEvents: RouterEventsService,
    public readonly urlService: UrlService
  ) {}
}
