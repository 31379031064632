<button
  class="border-0 bg-transparent"
  [copyToClipboard]="valueToCopy"
  (copied)="notify()"
>
  <qsc-svg-icon
    *ngIf="!copied"
    [width]="width"
    [height]="height"
    [icon]="variant === 'light' ? 'copy-light' : 'copy'"
  ></qsc-svg-icon>
  <qsc-svg-icon
    *ngIf="copied"
    [width]="width"
    [height]="height"
    [icon]="variant === 'light' ? 'copy-filled-light' : 'copy-filled'"
  ></qsc-svg-icon>
</button>
