<div class="content-accordion h-100">
  @if (titleFold) {
  <h2
    class="heading-bold text-light mb-0"
    [innerHTML]="titleFold | translate"
  ></h2>
  } @if (subtitleFold) {
  <h3
    class="text-h4-mobile-semibold text-light mb-0"
    [innerHTML]="subtitleFold | translate"
  ></h3>
  } @if (items.length) {
  <mat-accordion class="content-accordion__mat-accordion">
    @for (item of items; track $index) {
    <mat-expansion-panel
      [expanded]="accordionId === item.id"
      (opened)="onAccordionOpened(item)"
      (closed)="onAccordionClosed(item)"
      [hideToggle]="true"
    >
      <mat-expansion-panel-header collapsedHeight="100%" expandedHeight="100%">
        <mat-panel-title>
          <h3
            *ngIf="!subtitleFold"
            class="text-h4-mobile-bold text-color-secondary mb-0"
            [innerHTML]="item.title | translate"
          ></h3>
          <h4
            *ngIf="subtitleFold"
            class="text-h4-mobile-bold text-color-secondary mb-0"
            [innerHTML]="item.title | translate"
          ></h4>
          <i
            aria-hidden="true"
            [class]="
              accordionId === item.id && isAccordionOpened
                ? 'icon-sinal_menos'
                : 'icon-sinal_somar'
            "
          ></i
        ></mat-panel-title>
      </mat-expansion-panel-header>
      <div [innerHTML]="item?.description ?? '' | translate"></div>
      <qsc-helpful-answer
        [question]="item.title | translate"
      ></qsc-helpful-answer>
    </mat-expansion-panel>
    }
  </mat-accordion>
  }
</div>
