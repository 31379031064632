import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class StoredFlowFacade {
  constructor(
    public readonly session: SessionStorageService,
    public readonly store: Store
  ) {}
}
