export const formatWhatsAppMessage = (message: string): string => {
  return encodeURIComponent(message);
};

export const getWhatsAppMessage = (): string => {
  return `Olá,

Gostaria de falar com você sobre uma recomendação importante relacionada ao gás encanado para o nosso prédio. Para facilitar o processo, basta seguir estes simples passos:

✅Acesse https://www.querocomgas.com.br
✅Selecione o card de prédios e clique em "Contratar Gás Encanado Agora"
✅Escolha a opção de Síndico/Administrador
✅Preencha as informações solicitadas

Aguarde sua solicitação e um consultor especializado entrará em contato com você para apresentar todas as soluções da Comgás. Não perca mais tempo e faça sua solicitação agora mesmo! `;
};
