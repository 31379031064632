import { Injectable } from '@angular/core';
import { CategoryService } from '@shared/services/category/category.service';
import { FlowService } from '@shared/services/flow/flow.service';
import { LeadService } from '@shared/services/lead/lead.service';
import { ProfileService } from '@shared/services/profile/profile.service';
import { SegmentService } from '@shared/services/segment/segment.service';

@Injectable({
  providedIn: 'root',
})
export class CommonFlowFacade {
  constructor(
    public readonly flowService: FlowService,
    public readonly profileService: ProfileService,
    public readonly segmentService: SegmentService,
    public readonly categoryService: CategoryService,
    public readonly leadService: LeadService
  ) {}
}
