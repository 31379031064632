import { Injectable } from '@angular/core';
import { CookiesService } from '@shared/services/cookies/cookies.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class StorageAppFacade {
  constructor(
    public readonly cookiesService: CookiesService,
    public readonly sessionService: SessionStorageService
  ) {}
}
