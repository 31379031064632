import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IOptimizedImageData, Page } from '@models/index';
import { ContentPageService } from '@shared/services';
import * as searchServicesJson from 'src/assets/data/search-services.json';
import { PictureComponent } from '../picture/picture.component';
import { SearchComponent } from '../search/search.component';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import {
  ISearchService,
  SearchService,
} from '@shared/services/search/search.service';

interface IHeadlineVariants {
  horizontal: string;
  vertical: string;
}

@Component({
  selector: 'qsc-headline-banner',
  templateUrl: './headline-banner.component.html',
  styleUrls: ['./headline-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    PictureComponent,
    TranslateModule,
    forwardRef(() => SearchComponent),
  ],
})
export class HeadlineBannerComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() image?: IOptimizedImageData;
  @Input() variant: keyof IHeadlineVariants = 'horizontal';
  @Input() gradientClass?: string;
  @Input() showSearch = false;
  searchServicesData = searchServicesJson;
  @ViewChild('searchInput')
  searchInput?: AutocompleteComponent;
  window: (Window & typeof globalThis) | null;

  constructor(
    private readonly contentPageService: ContentPageService,
    private readonly searchService: SearchService,
    private readonly router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  headlineVariants: IHeadlineVariants = {
    horizontal: 'gradient-headline--horizontal',
    vertical: 'gradient-headline--vertical',
  };

  get chosenVariant(): string {
    return this.gradientClass ?? this.headlineVariants[this.variant];
  }

  onSelectedService(service: ISearchService): void {
    this.searchInput?.control.setValue(null);
    this.searchService.navigate(service);
  }

  isPage(page: Page): boolean {
    return this.contentPageService.isPage(page);
  }
}
