import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'qsc-back-link',
  templateUrl: './back-link.component.html',
  styleUrls: ['./back-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class BackLinkComponent {
  @Input() text = '';
  @Input() color?: string;
  @Input() action?: 'back';
  @Output() backClick = new EventEmitter<any>();

  constructor(private readonly location: Location) {}

  handleBackClick(evt: Event): void {
    if (this.action === 'back') this.location.back();
    this.backClick.emit(evt);
  }

  handleBackKeyup(evt: KeyboardEvent): void {
    if (evt.key === 'Enter') {
      this.handleBackClick(evt);
    }
  }
}
