import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from '@shared/services/chat/chat.service';
import { CryptographyService } from '@shared/services/cryptography/cryptography.service';
import { LoadingService } from '@shared/services/loading/loading.service';
import { PlatformCheckerService } from '@shared/services/platform-checker/platform-checker.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesAppFacade {
  constructor(
    public readonly chatService: ChatService,
    public readonly cryptographService: CryptographyService,
    public readonly platformChecker: PlatformCheckerService,
    public readonly loadingService: LoadingService,
    public readonly translateService: TranslateService
  ) {}
}
