import { Injectable } from '@angular/core';
import { SegmentIdsEnum } from '@models/enums/segment.enum';
import { EquipmentService } from '@modules/equipment-selection/services/equipment/equipment.service';
import { Store } from '@ngrx/store';
import { fromUser, userActions } from '@core/store';
import { FlowsAnalytics } from '@models/index';
import { Router } from '@angular/router';
import { FlowService } from '../flow/flow.service';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  private segmentId?: SegmentIdsEnum;

  constructor(
    private readonly store: Store,
    private readonly equipmentService: EquipmentService,
    private readonly flowService: FlowService,
    private readonly router: Router
  ) {
    this.selectSegmentId();
  }

  select(segmentId: SegmentIdsEnum): void {
    this.store.dispatch(
      userActions.updateUser({
        user: {
          segmentId,
        },
      })
    );
    this.equipmentService.removeData();
    this.selectSegmentId();
  }

  private selectSegmentId() {
    this.store.select(fromUser.selectSegmentId).subscribe((segmentId) => {
      this.segmentId = segmentId;
    });
  }

  isResidences(): boolean {
    return this.segmentId === SegmentIdsEnum.Residences;
  }

  isCommerce(): boolean {
    return this.segmentId === SegmentIdsEnum.Commerce;
  }

  isIndustry(): boolean {
    return this.segmentId === SegmentIdsEnum.Industry;
  }

  get flowsAnalytics() {
    return FlowsAnalytics;
  }

  getGtmCategory(): string {
    return this.flowsAnalytics.category.alterarSegmento;
  }

  getGtmAction(): string {
    switch (this.router.url) {
      case '/validar-endereco':
        return this.flowsAnalytics.action.validarEndereco;
      case '/agendamento/agendar':
        return this.flowsAnalytics.action.agendamento;
      default:
        return '';
    }
  }

  getGtmLabel(segmentId?: SegmentIdsEnum): string {
    const segmentIdToCompare = segmentId ?? this.segmentId;

    if (segmentIdToCompare === SegmentIdsEnum.Residences) {
      return this.flowsAnalytics.label.residenciaUnificada;
    }
    if (segmentIdToCompare === SegmentIdsEnum.Commerce) {
      return this.flowsAnalytics.label.negocios;
    }
    if (segmentIdToCompare === SegmentIdsEnum.Industry) {
      return this.flowsAnalytics.label.industrias;
    }
    return '';
  }

  getGtmFlow(): string | undefined {
    return this.flowService.getGtmFlow();
  }
}
