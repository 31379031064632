import { afterNextRender, Injectable } from '@angular/core';
import { PlatformCheckerService } from '../platform-checker/platform-checker.service';

@Injectable({
  providedIn: 'root',
})
export class TextToSpeechService {
  private synthesis!: SpeechSynthesis;
  private utterance!: SpeechSynthesisUtterance;

  constructor(private readonly plataformChecker: PlatformCheckerService) {
    afterNextRender(() => {
      this.synthesis = window.speechSynthesis;
      this.utterance = new SpeechSynthesisUtterance();
      this.configureLanguage();
      this.configureVoice();
    });
  }

  private configureLanguage() {
    this.utterance.lang = 'pt-BR';
  }

  private configureVoice() {
    this.utterance.voice =
      this.synthesis.getVoices().find((voice) => voice.lang === 'pt-BR') ||
      null;
  }

  speak(text: string): void {
    if (this.plataformChecker.isBrowser()) {
      this.utterance.text = text;
      this.synthesis.speak(this.utterance);
    }
  }

  stop(): void {
    if (this.plataformChecker.isBrowser()) {
      if (this.synthesis.speaking) {
        this.synthesis.cancel();
      }
    }
  }
}
