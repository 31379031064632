import { HumanSupportServiceEnum } from '@models/enums/human-support.enum';

export const errorCodesWithHumanSupport = [259, 421, 423, 424, 450, 251];

export interface IChatMetadata {
  servico: HumanSupportServiceEnum;
  api: string;
  nome: string;
  payload: any;
  status: number;
}
