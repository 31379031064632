import { Injectable } from '@angular/core';
import { IMarkerData } from '@shared/services';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WaypointService {
  waypointsSubject = new BehaviorSubject<google.maps.DirectionsWaypoint[]>([]);
  waypoints$: Observable<google.maps.DirectionsWaypoint[]> =
    this.waypointsSubject.asObservable();

  waypoints: google.maps.DirectionsWaypoint[] = [];
  optimizedWaypoints: google.maps.DirectionsWaypoint[] = [];

  getWaypoints(): google.maps.DirectionsWaypoint[] {
    return this.waypoints;
  }

  setWaypoints(waypoints: google.maps.DirectionsWaypoint[]): void {
    this.waypoints = waypoints;
  }

  clearWaypoints(): void {
    this.waypoints = [];
  }

  addWaypoint(data: IMarkerData): void {
    this.waypoints.push({
      location: {
        lat: Number(data.latitude),
        lng: Number(data.longitude),
      },
    });
  }

  hasWaypoints(): boolean {
    return this.waypoints.length > 0;
  }

  isInWaypoints(data: IMarkerData): boolean {
    return this.getWaypointIndex(data) !== -1;
  }

  getWaypointIndex(data: IMarkerData): number {
    return this.waypoints.findIndex((wp) => {
      const wpLatLng = wp.location as google.maps.LatLng;
      return (
        wpLatLng.lat.toString() === data.latitude?.toString() &&
        wpLatLng.lng.toString() === data.longitude?.toString()
      );
    });
  }

  removeWaypoint(data: IMarkerData) {
    this.waypoints.splice(this.getWaypointIndex(data), 1);
  }

  getOptimizedWaypoints(): google.maps.DirectionsWaypoint[] {
    return this.optimizedWaypoints;
  }

  getOptimizedWaypointsByOrder(
    waypointOrder: number[]
  ): google.maps.DirectionsWaypoint[] {
    return waypointOrder.map((index) => this.waypoints[index]);
  }

  setOptimizedWaypoints(waypoints: google.maps.DirectionsWaypoint[]): void {
    this.optimizedWaypoints = waypoints;
  }
}
