import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

export type IconId =
  | 'arrow-dropper'
  | 'btn-confirmation'
  | 'btn-steps'
  | 'btn-redirect'
  | 'btn-close'
  | 'btn-close-modal'
  | 'feedback-alert'
  | 'feedback-negative'
  | 'feedback-positive'
  | 'feedback-gradient-positive'
  | 'input-confirmed'
  | 'input-error'
  | 'input-email'
  | 'input-phone'
  | 'input-location'
  | 'input-location-success'
  | 'location-negative'
  | 'location-positive'
  | 'location-gps'
  | 'copy'
  | 'copy-filled'
  | 'copy-light'
  | 'copy-filled-light'
  | 'installation-tool'
  | 'technical-visit'
  | 'loading'
  | 'telephone-chat'
  | 'smartphone-attendant'
  | 'attention-sign'
  | 'right-arrow'
  | 'bottom-arrow'
  | 'worker'
  | 'handshake'
  | 'worker-list-check'
  | 'tool-home'
  | 'fire-flame'
  | 'feedback-positive-gradient'
  | 'pure-minus'
  | 'pure-plus'
  | 'show-less'
  | 'show-more'
  | 'free-annotation'
  | 'calendar-check'
  | 'location-unavailable'
  | 'blue-fire'
  | 'blue-logo'
  | 'emoji-sad'
  | 'whatsapp'
  | 'green-logo'
  | 'warning-sign'
  | 'balloon-chat'
  | 'balloon-chat-blue'
  | 'arrow-down'
  | 'change-circle-outline'
  | 'change-segment-feedback'
  | 'pipe'
  | 'ceramica'
  | 'caldera'
  | 'empilhadeira'
  | 'gerador'
  | 'radio-button-off'
  | 'radio-button-on'
  | 'forno-pizza'
  | 'fritadeira'
  | 'piscina';

@Component({
  selector: 'qsc-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  imports: [FileHashPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SvgIconComponent implements OnInit {
  @Input() icon?: IconId | string;
  @Input() title = '';
  @Input() size: number | string = '';
  @Input() width?: string;
  @Input() height?: string;
  @Input() class?: string;
  svgSpritePath = '';

  ngOnInit(): void {
    this.getSpritePath();
  }

  getSpritePath(): void {
    if (this.icon) {
      this.svgSpritePath = `assets/img/svg/sprite.svg#${this.icon}`;
    }
  }
}
