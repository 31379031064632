import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  getUrl(loginType: string): string {
    return `${environment.endpoints.comgasVirtual}?login=${loginType}`;
  }

  openModalLogin(loginType: string, params = ''): void {
    window.open(
      `${environment.endpoints.comgasVirtual}?login=${loginType}&${params}`,
      '_self'
    );
  }
}
