declare let cordovaUtils: any;

export function loadCordovaUtils(): void {
  (window as any).cordovaUtilsProxy = {
    initialize(): void {
      if (typeof cordovaUtils !== 'undefined') {
        cordovaUtils.initialize();
      }
    },
    canLaunch(uri: any, successCallback: any, errorCallback: any): void {
      if (typeof cordovaUtils !== 'undefined') {
        cordovaUtils.canLaunch(uri, successCallback, errorCallback);
      }
    },
    launch(uri: any, successCallback: any, errorCallback: any): void {
      if (typeof cordovaUtils !== 'undefined') {
        cordovaUtils.launch(uri, successCallback, errorCallback);
      }
    },
    getPlatformId(): any {
      return typeof cordovaUtils !== 'undefined'
        ? cordovaUtils.getPlatformId()
        : false;
    },
    isCordova(): boolean {
      return typeof cordovaUtils !== 'undefined'
        ? cordovaUtils.isCordova
        : false;
    },
  };
}
