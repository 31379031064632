import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { UiService } from '@core/ui/ui.service';
import { UrlService } from '@shared/services/url/url.service';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { analytics } from './analytics';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private page = '';
  private savedPage = false;

  constructor(
    private urlService: UrlService,
    private googleTagManagerService: GoogleTagManagerService,
    private uiService: UiService
  ) {}

  saveStep(): void {
    this.savedPage = true;
  }

  setStep(o: any, view = null): void {
    const currentUrl = this.urlService.getCurrentUrlPath() ?? '';
    const urlSplitList = currentUrl.split('/');

    let page = urlSplitList[urlSplitList.length - 1];
    const loginModalEl = document.querySelector('#loginModal');
    if (!loginModalEl) return;
    if (loginModalEl || view === 'login') {
      this.saveStep();
      page = 'login';
    }

    let newUrl = this.uiService.analyticsKeys[page]
      ? this.uiService.analyticsKeys[page][o - 1]
      : '';

    if (newUrl === '') {
      return;
    }

    if (newUrl === '/') {
      newUrl = '';
    } else {
      newUrl = '/' + newUrl;
    }

    let newUrlSegment = '';
    if (o) {
      newUrlSegment = newUrl;
    }

    if (page === 'login') {
      this.page = o ? newUrl : '';
    } else {
      this.page = this.urlService.getCurrentUrlPath() + newUrlSegment;
    }

    this.googleTagManagerService.sendPageView(this.page);
  }

  startCapturing(): void {
    this.urlService.currentUrlPath$.subscribe((currentUrlPath) => {
      if (this.uiService.skipGA || !currentUrlPath) {
        return;
      }

      this.setStep(1, null);
    });
  }

  clearStep(): void {
    this.savedPage = false;
  }

  executeSavedStep(): void {
    if (this.savedPage) {
      timer(1000).subscribe(() => {
        this.setStep(1, null);
        this.clearStep();
      });
    }
  }

  getAnalytics() {
    return analytics;
  }

  sendException(text: string) {
    console.debug(
      'GoogleAnalitycs send error: |text:' + text + '|page:' + this.page + '|'
    );
  }

  sendEvent(e: any) {
    console.debug(
      'GoogleAnalitycs send event: |category:' +
        e.category +
        '|action:' +
        e.action +
        '|label:' +
        e.label +
        '|'
    );
  }
}
