{
  "links": [
    {
      "id": 1,
      "label": "@HEADER-RESIDENCIAS",
      "url": "/residencias"
    },
    {
      "id": 2,
      "label": "@HEADER-PREDIOS",
      "url": "/predios"
    },
    {
      "id": 3,
      "label": "@HEADER-NEGOCIOS",
      "url": "/negocios"
    },
    {
      "id": 4,
      "label": "@HEADER-VEICULOS",
      "url": "/veiculos"
    },
    {
      "id": 5,
      "label": "@HEADER-INDUSTRIAS",
      "url": "/industrias"
    },
    {
      "id": 6,
      "label": "Blog",
      "url": "/blog"
    }
  ]
}
