import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  private previousUrlPath: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public previousUrlPath$: Observable<string | null> =
    this.previousUrlPath.asObservable();

  private currentUrlPath: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  public currentUrlPath$: Observable<string> =
    this.currentUrlPath.asObservable();

  setCurrentUrlPath(currentUrl: string) {
    this.previousUrlPath.next(this.currentUrlPath.getValue());
    const formattedUrl = this.formatUrl(currentUrl);
    if (!formattedUrl) return;
    this.currentUrlPath.next(formattedUrl);
  }

  getCurrentUrlPath() {
    return this.currentUrlPath.getValue();
  }
  getPreviousUrlPath() {
    return this.previousUrlPath.getValue();
  }

  formatUrl(urlUnformatted: string): string | null {
    if (urlUnformatted == null) return null;
    return urlUnformatted.split('?')[0]?.split('#')[0] ?? null;
  }
}
