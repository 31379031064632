export function paserQueryString<T>(queryString: string): T {
  const paramPairs = queryString.split('&');
  const queryParamsObject = {} as T;

  for (const paramPair of paramPairs) {
    const [key, value] = paramPair.split('=');
    queryParamsObject[key] = value;
  }

  return queryParamsObject;
}
