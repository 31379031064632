<button [class]="'card-more-about ' + modifierClass" (click)="goToPage()">
  <i class="card-more-about__icon" [class]="data.iconClass"></i>
  <h3 class="card-more-about__title" [innerHTML]="data.title | translate"></h3>
  <p
    class="card-more-about__description mb-0"
    [innerHTML]="data.description | translate"
  ></p>
  <a
    [routerLink]="data.toUrl"
    class="card-more-about__link text-decoration-underline"
    [innerHTML]="('@KNOW-MORE' | translate) + '.'"
  ></a>
</button>
