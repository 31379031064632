export const formatDateToISO = (date: Date) => {
  return date.toISOString().split('T')[0];
};

export const getLastDayOfFutureMonth = (monthsAhead: number) => {
  const today = new Date();
  let futureMonth = today.getMonth() + monthsAhead + 1;
  let year = today.getFullYear();

  while (futureMonth > 12) {
    futureMonth -= 12;
    year++;
  }

  const lastDay = new Date(year, futureMonth, 0);
  return formatDateToISO(lastDay);
};

export const combineDateAndTime = (date: string, time: string) => {
  if (!date || !time) return undefined;
  return `${date}T${time}:00`;
};

export const addHoursToStringTime = (time: string, hoursToAdd: number) => {
  const [hours, minutes] = time.split(':').map(Number);
  const newTime = new Date();
  newTime.setHours(hours);
  newTime.setMinutes(minutes);
  newTime.setHours(newTime.getHours() + hoursToAdd);
  return (
    newTime.getHours().toString().padStart(2, '0') +
    ':' +
    newTime.getMinutes().toString().padEnd(2, '0')
  );
};
