<button
  #tooltip
  type="button"
  class="btn-reset"
  data-bs-toggle="tooltip"
  data-bs-placement="bottom"
  [attr.data-bs-title]="content | translate"
  (click)="click()"
  (mouseover)="hover()"
  (mouseleave)="leave()"
  (focus)="focus()"
  (blur)="blur()"
>
  <svg
    [class.d-none]="isActiveByHover || isActiveByClick"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
      [attr.fill]="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 9.4C12.9389 9.4 13.7 8.63888 13.7 7.7C13.7 6.76112 12.9389 6 12 6C11.0611 6 10.3 6.76112 10.3 7.7C10.3 8.63888 11.0611 9.4 12 9.4ZM10 11.2V11.875C10 11.9582 10.0514 12.0326 10.1292 12.0621L10.65 12.2593C10.8444 12.3329 10.973 12.5191 10.973 12.7269V16.517C10.973 16.7405 10.8246 16.9369 10.6096 16.9979L10.1454 17.1298C10.0593 17.1542 10 17.2327 10 17.3222V18H14V17.3222C14 17.2327 13.9407 17.1542 13.8546 17.1298L13.3904 16.9979C13.1754 16.9369 13.027 16.7405 13.027 16.517V11H10.2C10.0895 11 10 11.0895 10 11.2Z"
      [attr.fill]="fillColor"
    />
  </svg>
  <svg
    [class.d-none]="!isActiveByHover && !isActiveByClick"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13.7 7.7C13.7 8.63888 12.9389 9.4 12 9.4C11.0611 9.4 10.3 8.63888 10.3 7.7C10.3 6.76112 11.0611 6 12 6C12.9389 6 13.7 6.76112 13.7 7.7ZM10 11.875V11.2C10 11.0895 10.0895 11 10.2 11H13.027V16.517C13.027 16.7405 13.1754 16.9369 13.3904 16.9979L13.8546 17.1298C13.9407 17.1542 14 17.2327 14 17.3222V18H10V17.3222C10 17.2327 10.0593 17.1542 10.1454 17.1298L10.6096 16.9979C10.8246 16.9369 10.973 16.7405 10.973 16.517V12.7269C10.973 12.5191 10.8444 12.3329 10.65 12.2593L10.1292 12.0621C10.0514 12.0326 10 11.9582 10 11.875Z"
      [attr.fill]="fillColor"
    />
  </svg>
</button>
