import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeSubject = new BehaviorSubject<string>(this.getTheme());
  theme$ = this.themeSubject.asObservable();

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.loadTheme();
  }

  setTheme(theme: string) {
    const root = this.document.documentElement;
    this.renderer.setAttribute(root, 'data-theme', theme);
    this.localStorageService.saveData('theme', theme);
    this.themeSubject.next(theme);
  }

  getTheme(): string {
    const theme = this.getThemeFromLocalStorage();
    if (theme) return theme;
    return this.getThemeFromDocument();
  }

  private loadTheme() {
    this.setTheme(this.getTheme());
  }

  private getThemeFromLocalStorage() {
    return this.localStorageService.getData<string>('theme');
  }

  private getThemeFromDocument(): string {
    return (
      this.document.documentElement.getAttribute('data-theme') ?? 'default'
    );
  }
}
