import { Inject, Injectable } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { environment } from '@env';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs';
import { ContentPagesModalComponent } from '@shared/modal/content-pages-modal/content-pages-modal.component';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root',
})
export class ContentPagesModalService {
  bsModalContentPages: any;
  window: any;

  constructor(
    private readonly bsModalService: BsModalService,
    private readonly router: Router,
    private readonly loginService: LoginService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.window = this.document.defaultView;
  }

  openContentPagesModal(): void {
    const modalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: 'content-pages-modal modal-dialog-centered',
    };

    this.bsModalContentPages = this.bsModalService.show(
      ContentPagesModalComponent,
      modalOptions
    );

    this.bsModalContentPages.onHide.pipe(take(1)).subscribe(() => {
      if (this.bsModalContentPages.content.residences) {
        this.goToResidences();
        return;
      }
      if (this.bsModalContentPages.content.buildings) {
        this.goToBuildings();
        return;
      }
      if (this.bsModalContentPages.content.commerce) {
        this.goToCommerce();
        return;
      }
      if (this.bsModalContentPages.content.industry) {
        this.goToIndustry();
        return;
      }
      if (this.bsModalContentPages.content.gnv) {
        this.goToGnv();
        return;
      }
      if (this.bsModalContentPages.content.virtual) {
        this.goToLogin();
        return;
      }
      if (this.bsModalContentPages.content.solutions) {
        this.goToSolutions();
      }
    });
  }

  goToResidences(): void {
    this.router.navigate(['/residencias']);
  }

  goToBuildings(): void {
    this.router.navigate(['/predios']);
  }

  goToCommerce(): void {
    this.router.navigate(['/negocios']);
  }

  goToIndustry(): void {
    this.router.navigate(['/industrias']);
  }

  goToGnv(): void {
    this.router.navigate(['/veiculos']);
  }

  goToLogin(): void {
    this.loginService.openModalLogin('clienteComgas');
  }

  goToSolutions(): void {
    this.window.open(environment.endpoints.solucoes, '_self');
  }
}
