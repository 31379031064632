import { noop } from 'rxjs';

export const safeSetInterval = (
  action: () => void,
  interval: number
): (() => void) => {
  if (typeof action !== 'function' || interval <= 0) return noop;

  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const repeat = () => {
    action();
    if (timeoutId !== null) {
      timeoutId = setTimeout(repeat, interval);
    }
  };

  timeoutId = setTimeout(repeat, interval);

  return () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
};
