import { CategoryService } from '@shared/services/category/category.service';

export enum CategoryEnum {
  Construida = 'RESIDENCIAL',
  Obra = 'NEWHOME',
  TMEO = 'TMEO',
  Construcao = 'CONSTRUCAO',
  Habitado = 'HABITADO',
  Morador = 'MORADOR',
  Comercial = 'COMERCIAL',
  Industrial = 'INDUSTRIAL',
}

export enum CategoryIdsEnum {
  HouseBuilt = 1,
  NewHome,
  Resident,
  NewHousing,
  Inhabited,
  Commercial,
  Industrial,
  Tmeo,
}

export function getCategory(categoryService: CategoryService): CategoryEnum {
  if (categoryService.isBuilt()) {
    return CategoryEnum.Construida;
  }
  if (categoryService.isNewHome()) {
    return CategoryEnum.Obra;
  }
  if (categoryService.isNewHousing()) {
    return CategoryEnum.Construcao;
  }
  if (categoryService.isInhabited()) {
    return CategoryEnum.Habitado;
  }
  if (categoryService.isResident()) {
    return CategoryEnum.Morador;
  }
  if (categoryService.isCommercial()) {
    return CategoryEnum.Comercial;
  }
  if (categoryService.isIndustrial()) {
    return CategoryEnum.Industrial;
  }
  return CategoryEnum.Construida;
}

export function checkCategoryIdForScheduling(
  categoryId?: CategoryIdsEnum
): boolean {
  return categoryId
    ? [
        CategoryIdsEnum.HouseBuilt,
        CategoryIdsEnum.NewHome,
        CategoryIdsEnum.Commercial,
      ].includes(categoryId)
    : false;
}
