import { Component, Input } from '@angular/core';

@Component({
  selector: 'qsc-text-divider',
  standalone: true,
  templateUrl: './text-divider.component.html',
  styleUrls: ['./text-divider.component.scss'],
})
export class TextDividerComponent {
  @Input() text = '';
}
