<div class="container">
  <button (click)="bsModalRef.hide()" class="container__close-button">
    <i aria-hidden="true" class="icon-sinal_multiplicar_x_fechar"></i>
  </button>
  <div class="d-flex flex-column align-items-center">
    <div class="container__icon">
      <i
        aria-hidden="true"
        class="icon-chama_comgas"
        [ngClass]="isRouteError() ? 'warning' : 'success'"
      ></i>
    </div>

    <div>
      <p class="container__title" [innerHTML]="getTitle() | translate"></p>
    </div>

    <div class="container__icon">
      <i
        aria-hidden="true"
        [class]="
          isRouteError() ? 'icon-erro_fechar' : 'icon-check_bola_preenchido'
        "
      ></i>
    </div>
  </div>
</div>
