{
  "services": [
    {
      "names": [
        "2º via de conta",
        "Segunda via de conta",
        "2 via",
        "2 via conta de gas",
        "2a via boleto",
        "2a via da conta",
        "conta atrasada",
        "fatura atrasada",
        "quitar",
        "consultar débitos",
        "conta do mês",
        "fatura por cpf",
        "contas a pagar",
        "1 via",
        "a fatura desse mes",
        "a expedicao de uma",
        "anexar debitos pagos",
        "boleto",
        "boleto 2 via",
        "boleto fatura",
        "boletos",
        "cadastrar conta",
        "cobranca",
        "codigo de barras",
        "codigo de barras de minha conta",
        "codigo de barras para pagar",
        "como pagar a conta",
        "como pagar a conta congas sem boleto",
        "como saber se sua conta esta em dia",
        "como faco pra ver se estou em debito",
        "contas pendentes",
        "confirmar boletos",
        "confirmar boletos quitados",
        "consulta pendencias financeiras",
        "conta atrasada",
        "conta congas",
        "conta de gas",
        "conta do mes",
        "conta em atraso",
        "conta que vence agora em",
        "contas abertas",
        "contas de gas",
        "contas em atraso",
        "contas pendentes",
        "contas vencidas",
        "debito em atraso",
        "estou querendo fazer um parcelamento da minha divida",
        "fatura para pagar",
        "meu acordo",
        "meus boletos",
        "pagamento",
        "data da minha conta",
        "ver contas",
        "fatura digital",
        "alterar a data da minha conta",
        "contas anteriores de gás",
        "débito",
        "parcelas",
        "segunda guia",
        "mandou conta",
        "mandou minha conta",
        "mandou a conta",
        "mandou o boleto",
        "mandou boleto",
        "mandou meu boleto",
        "diferença",
        "valores"
      ],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Abastecer carro"],
      "url": "/veiculos",
      "type": "Quero Comgás"
    },
    {
      "names": [
        "Agendamento com consultor de vendas",
        "consultar agendamento",
        "acompanhar agendamento"
      ],
      "url": "/agendamento/consultar",
      "type": "Quero Comgás"
    },
    {
      "names": ["Alterar endereço"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Ambiente aquecido"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Aquecedor de piso", "aquecedor"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Aquecedor de água", "aquecedor"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Aquecedor piscina", "aquecedor"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Bomba de calor"],
      "url": "/predios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Caldeiras"],
      "url": "/industrias",
      "type": "Quero Comgás"
    },
    {
      "names": [
        "Canais de atendimento",
        "contato",
        "telefone",
        "celular",
        "numero",
        "falar",
        "alguem",
        "conversar",
        "atendente",
        "atendimento",
        "ouvidoria",
        "email",
        "enviar",
        "pgto",
        "solicitacao",
        "servico",
        "sac",
        "reclamacao",
        "reclamar",
        "revisao",
        "consumo",
        "whatsapp",
        "zap",
        "fale conosco",
        "0800",
        "urgente",
        "duvida",
        "urgencia",
        "emergencia",
        "cobranca",
        "cancelamento",
        "desligamento",
        "falar com atendente",
        "telefone da comgás",
        "área de atendimento",
        "chat",
        "central de atendimento",
        "como consigo atendimento",
        "como falar no whatsapp",
        "whatsapp",
        "contato online",
        "atendente",
        "falar com atendente",
        "falar com departamento",
        "fale conosco",
        "fone para contato",
        "ouvidoria"
      ],
      "url": "?secao=canais-atendimento",
      "type": "Quero Comgás"
    },
    {
      "names": ["Casa em construção"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Casas de condomínio"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Cheiro de gás"],
      "url": "emergencias",
      "type": "Virtual"
    },
    {
      "names": ["Churrasqueira a gás"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Chuveiro a gás"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Danos na tubulação"],
      "url": "emergencias",
      "type": "Virtual"
    },
    {
      "names": ["Débito automático"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": [
        "Dúvidas Frequentes",
        "tirar duvida",
        "tenho uma duvida",
        "como",
        "?"
      ],
      "url": "/duvidas-frequentes",
      "type": "Quero Comgás"
    },
    {
      "names": ["Economia para veículos"],
      "url": "/veiculos",
      "type": "Quero Comgás"
    },
    {
      "names": ["Energia para laboratórios"],
      "url": "/industrias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Energia sustentável"],
      "url": "/negocios",
      "type": "Quero Comgás"
    },
    {
      "names": [
        "Envio de Documentos",
        "anexar",
        "enviar comprovante",
        "comprovante de locação",
        "comprovante de pagamento",
        "enviar contrato",
        "anexa",
        "anexa documentos",
        "documentos"
      ],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Estufas"],
      "url": "/industrias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Falta de gás"],
      "url": "emergencias",
      "type": "Virtual"
    },
    {
      "names": ["Fatura por e-mail", "boleto"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Forno industrial a gás"],
      "url": "/negocios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Futuro sustentável"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Gás em academia"],
      "url": "/negocios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Gás encanado para lareiras"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Gás para apartamento", "para o meu prédio"],
      "url": "/predios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Gás para carro"],
      "url": "/veiculos",
      "type": "Quero Comgás"
    },
    {
      "names": ["Gás para condomínios", "para o meu prédio"],
      "url": "/predios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Gás para lavanderias"],
      "url": "/negocios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Individualização"],
      "url": "/predios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Instalação em casa"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Ligar o gás", "ligação", "ligamento", "ativar", "ativacao"],
      "url": "liga-religa/triagem",
      "type": "Virtual"
    },
    {
      "names": ["Locais para abastecimento"],
      "url": "/veiculos",
      "type": "Quero Comgás"
    },
    {
      "names": ["Manutenção de veículos"],
      "url": "/veiculos",
      "type": "Quero Comgás"
    },
    {
      "names": [
        "Minha conta",
        "login",
        "debitos",
        "boleto",
        "pagamento",
        "comprovante",
        "codigo de barras",
        "conta atrasada",
        "contas pagamento",
        "fatura aberta",
        "valor da conta",
        "vencimento da fatura",
        "fatura",
        "minhas faturas",
        "contas em atraso",
        "conta para pagamento",
        "conta para ser paga",
        "contas pendentes",
        "pagar contas",
        "pagar boleto",
        "pagar fatura",
        "revisao",
        "pendencia",
        "negociacao",
        "quitar",
        "boleto fatura",
        "faturas",
        "valor da minha conta",
        "valor da minha conta deste mês",
        "cadastro",
        "consultar débitos",
        "conta do mês",
        "fatura por cpf",
        "acessar",
        "pagamento com cartão de crédito",
        "consulta de débitos",
        "fazer login outro cadastro",
        "corrigir meu e mail de contato",
        "ver contas",
        "enviar leitura",
        "o codigo do consumidor",
        "número do cliente",
        "meu consumo",
        "pendências",
        "reclamar",
        "histórico de pagamento",
        "leitura do mês",
        "meu cadastro",
        "retirada de medidor",
        "informar leitura",
        "mandou conta",
        "mandou minha conta",
        "mandou a conta",
        "diferença",
        "comgas virtual",
        "declaracao",
        "quitacao",
        "corte",
        "atrasado",
        "recupera",
        "senha",
        "termo",
        "valores"
      ],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Painel solar"],
      "url": "/negocios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Parcelamento da fatura"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Piscina aquecida"],
      "url": "/residencias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Prédios em construção"],
      "url": "/predios",
      "type": "Quero Comgás"
    },
    {
      "names": ["Recuperar protocolo", "esqueci meu protocolo"],
      "url": "/protocolo/recuperar",
      "type": "Quero Comgás"
    },
    {
      "names": ["Rede de abastecimento"],
      "url": "/veiculos",
      "type": "Quero Comgás"
    },
    {
      "names": ["Rede individual"],
      "url": "/predios",
      "type": "Quero Comgás"
    },
    {
      "names": [
        "Religar o gás",
        "religação",
        "religamento",
        "reativar",
        "reativacao",
        "nova ligação",
        "morador novo",
        "como pedir religação",
        "como pedir reativação",
        "reativar",
        "ligação do gás"
      ],
      "url": "liga-religa/triagem",
      "type": "Virtual"
    },
    {
      "names": ["Risco de explosão", "urgente", "urgencia", "emergencia"],
      "url": "emergencias",
      "type": "Virtual"
    },
    {
      "names": [
        "Serviços da Comgás",
        "servicos",
        "servico tecnico",
        "servicos virtuais",
        "servicos de estancamento",
        "fogao",
        "fogao comum",
        "fogao convecional",
        "fogao de 4 bocas",
        "conversao",
        "converter",
        "converter fogao",
        "conversao de fogao",
        "converter fogao para gas de botijao",
        "converter fogao para gas natural",
        "converter fogao para gas de rua",
        "desconverter fogao",
        "instalar duto de ventilacao",
        "instalar gas em um novo fogao",
        "contratar instalacao de aquecedor chuveiro",
        "aquecedor",
        "assistencia",
        "adaptacao do fogao",
        "adequacao de ponto",
        "instalar cano para passagem de gas da area externa",
        "mangueira de cobre",
        "mangueira flexivel",
        "troca de mangueira",
        "trocar de fogao",
        "trocar fogao por cooktop",
        "mangueira",
        "medidor de gas",
        "mudanca do ponto de gas",
        "aquecedor a gas",
        "aquecedor para piscina",
        "aquecedores",
        "convencao gas",
        "cooktop",
        "aparelho",
        "forno e fogao podem dividir a mesma mangueira",
        "instalacao de fogao",
        "instalacao de gas",
        "ligar fogao",
        "ligar forno",
        "ligar o gas  e adaptar fogao para encanado",
        "manutencao de sistema de gas",
        "conversor",
        "manutencao de gas",
        "manutencao preventiva",
        "manutencao de mangueira",
        "manutencao residencial",
        "colocar no apartamento",
        "verificar vazamento de gas",
        "avaliacao de vazamento de gas",
        "procurando o servico de um profissional",
        "visita de um tecnico",
        "reverter o gas",
        "agendar servico para troca",
        "acompanhar servico",
        "regulador",
        "estanqueidade",
        "prestador",
        "custo de instalação",
        "aquecedor",
        "regular",
        "manutencao",
        "aquecedores",
        "renovação",
        "equipamento",
        "cozinha",
        "detectar",
        "diminuir",
        "encanamento",
        "medida",
        "abrigo",
        "remocao",
        "canalizacao"
      ],
      "url": "",
      "type": "Solucoes"
    },
    {
      "names": ["Suporte para concessionárias"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Suporte para construtoras"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": [
        "Trabalhe conosco",
        "linkedin",
        "curriculo",
        "quero trabalhar",
        "vagas de emprego"
      ],
      "url": "https://br.linkedin.com/company/comgas?trk=public_post_feed-actor-name",
      "type": "Redes sociais"
    },
    {
      "names": [
        "Troca de titularidade",
        "Alterar titular da conta",
        "trocar titularidade",
        "trocar titular",
        "trocar nome da conta",
        "transferencia de titularidade",
        "transferencia de nome",
        "transferencia de proprietario",
        "transferencia de titular",
        "transferencia de usuario",
        "transferir titularidade",
        "transferir",
        "transferir nome",
        "mudar titular",
        "mudanca de titularidade",
        "mudar titularidade",
        "mudar nome",
        "mudar para meu nome",
        "mudar o nome do cliente",
        "mudar o titular",
        "mudar o titular da conta",
        "mudar o nome do usuario",
        "quero mudar",
        "trocar de nome",
        "antigo morador",
        "antiga moradora",
        "gostaria de passar a conta",
        "reoca de assinante",
        "acompanhar solicitação de mudança de titularidade",
        "conta no meu nome"
      ],
      "url": "trocaTitularidade",
      "type": "Virtual"
    },
    {
      "names": [
        "Vazamento de gás",
        "emergencia",
        "urgente",
        "urgencia",
        "detectar",
        "tecnico"
      ],
      "url": "emergencias",
      "type": "Virtual"
    },
    {
      "names": [
        "Solicitar gás encanado",
        "cadastro",
        "fazer cadastro",
        "me cadastrar",
        "botijão",
        "botijão vazio",
        "quero",
        "gás na",
        "gás em",
        "botijão de gás",
        "gás de rua",
        "gás encanado",
        "comgás em casa",
        "instalação de gás",
        "instalação em apartamento",
        "gás residencial",
        "cadastramento",
        "glp",
        "gnv",
        "Rua",
        "Av",
        "Avenida",
        "Bairro",
        "Cidade",
        "Estado",
        "R.",
        "passa gás",
        "nossa rede",
        "forno de pizzaria",
        "tenho gás no meu endereço",
        "restaurante",
        "tem gas",
        "região",
        "contratar",
        "cozinha",
        "ligar",
        "ultragas"
      ],
      "url": "/selecionar-segmento",
      "type": "Quero Comgás"
    },
    {
      "names": [
        "Gerador",
        "gerador a gás",
        "gerador de energia",
        "gerador de eletricidade"
      ],
      "url": "/industrias",
      "type": "Quero Comgás"
    },
    {
      "names": ["Conversão", "conversão de gás", "tecnico"],
      "url": "",
      "type": "Solucoes"
    },
    {
      "names": ["Comgás em casa"],
      "url": "/selecionar-segmento",
      "type": "Quero Comgás"
    },
    {
      "names": ["Tem gás encanado?"],
      "url": "/selecionar-segmento",
      "type": "Quero Comgás"
    },
    {
      "names": [
        "Simular valor da conta",
        "preço",
        "valor",
        "valor da instalação",
        "valores"
      ],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Regulador"],
      "url": "",
      "type": "Solucoes"
    },
    {
      "names": [
        "Mudança de endereço",
        "trocar de residência",
        "novo endereço",
        "troca de endereço",
        "vou trocar de"
      ],
      "url": "/selecionar-segmento",
      "type": "Quero Comgás"
    },
    {
      "names": ["Desligamento", "desligar", "desligado meu gas", "cancelar"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Informar pagamento"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Tarifas"],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": [
        "Acompanhar instalação",
        "acompanhamento",
        "instalação",
        "pedido de ligação",
        "acompanhar meu pedido",
        "acompanhar pedido",
        "acompanhamento de ligação",
        "acompanhamento de religação",
        "alterar data de instalação"
      ],
      "url": "",
      "type": "Virtual"
    },
    {
      "names": ["Gás para casas", "gerador a gas residencial"],
      "url": "/residencias",
      "type": "Quero Comgás"
    }
  ]
}
