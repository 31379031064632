import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  IMediaParameters,
  IMediaParametersWithoutEvent,
  MEDIA_PARAMETERS_SESSION_STORAGE_KEY,
  DATALAYER_TRAFFIC_ORIGIN_KEY,
} from '@models/gtm/media-parameters';
import { isInvalidString } from '@shared/utils/string';
import { CookiesService } from '@shared/services/cookies/cookies.service';

export interface ICustomEvent {
  event: 'SendCustomEvent';
  eventCategory: string;
  eventAction: string;
  eventLabel: string | unknown[];
  eventFlow?: string;
}

interface IServiceWithProtocolEvent {
  event: 'servicoSucessoComProtocolo';
  servico: string;
  numeroProtocolo: string;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  window: any;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly cookiesService: CookiesService
  ) {
    this.window = this.document.defaultView;
  }

  public getMediaParameters(): IMediaParametersWithoutEvent {
    let sessionMediaParameters: IMediaParameters =
      this.getMediaParametersFromSession();
    if (this.isInvalidMediaParameters(sessionMediaParameters)) {
      sessionMediaParameters = this.getMediaParametersFromDataLayer()!;
    }
    if (this.isInvalidMediaParameters(sessionMediaParameters)) {
      sessionMediaParameters = this.getDefaultMediaParameters();
    }

    sessionMediaParameters.fbc = this.cookiesService.getCookie('_fbc');
    sessionMediaParameters.fbp = this.cookiesService.getCookie('_fbp');

    return this.validateMediaParameters({
      campaign: sessionMediaParameters?.campaign,
      fbclid: sessionMediaParameters?.fbclid,
      gclid: sessionMediaParameters?.gclid,
      medium: sessionMediaParameters?.medium,
      source: sessionMediaParameters?.source,
      term: sessionMediaParameters?.term,
      fbc: sessionMediaParameters?.fbc,
      fbp: sessionMediaParameters?.fbp,
    });
  }

  public saveMediaParamaters(): void {
    const parametersFromSession = this.getMediaParametersFromSession();
    if (!parametersFromSession) {
      const parametersFromDataLayer = this.getMediaParametersFromDataLayer();
      if (!parametersFromDataLayer) return;
      this.window.sessionStorage.setItem(
        MEDIA_PARAMETERS_SESSION_STORAGE_KEY,
        JSON.stringify(parametersFromDataLayer)
      );
    }
  }

  private validateMediaParameters(
    mediaParameters: IMediaParametersWithoutEvent
  ): IMediaParametersWithoutEvent {
    const resultado = {} as IMediaParametersWithoutEvent;
    for (const key of Object.keys(mediaParameters)) {
      resultado[key] = mediaParameters[key] ? mediaParameters[key] : '';
    }
    return resultado;
  }

  private getMediaParametersFromDataLayer(): IMediaParameters | undefined {
    return (this.window.dataLayer as any[])?.find(
      (f: IMediaParameters) => f.event === DATALAYER_TRAFFIC_ORIGIN_KEY
    );
  }

  private getMediaParametersFromSession(): IMediaParameters {
    return isPlatformBrowser(this.platformId)
      ? (JSON.parse(
          this.window.sessionStorage.getItem(
            MEDIA_PARAMETERS_SESSION_STORAGE_KEY
          )
        ) as IMediaParameters)
      : ({} as IMediaParameters);
  }

  public sendCustomEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel?: string | unknown[],
    eventFlow?: string
  ): void {
    const customEvent: ICustomEvent = {
      event: 'SendCustomEvent',
      eventCategory,
      eventAction,
      eventLabel: eventLabel ?? '',
      eventFlow: eventFlow ?? '',
    };
    this.window?.dataLayer?.push(customEvent);
  }

  public serviceSuccessWithProtocol(service: string, protocol: string): void {
    const successProtocolEvent: IServiceWithProtocolEvent = {
      event: 'servicoSucessoComProtocolo',
      servico: service,
      numeroProtocolo: protocol,
    };

    this.window?.dataLayer?.push(successProtocolEvent);
  }

  public autoLogin(service: string): void {
    this.window?.dataLayer?.push({
      event: 'loginAutomatico',
      servico: service,
    });
  }

  public addVariable(name: string, value: string): void {
    this.window?.dataLayer?.push({
      [name]: value,
    });
  }

  public sendFullServiceEvent(category: string, label = ''): void {
    this.sendCustomEvent(category, 'Serviço completo', label);
  }

  public sendErrorServiceEvent(category: string, label = ''): void {
    this.sendCustomEvent(category, 'Serviço com erro', label);
  }

  public sendPageView(page: string): void {
    this.window?.dataLayer?.push({
      event: 'newPage',
      url: page,
    });
  }

  isInvalidMediaParameters(mediaParameters?: IMediaParameters): boolean {
    if (!mediaParameters) return true;
    return (
      isInvalidString(mediaParameters.campaign) &&
      isInvalidString(mediaParameters.fbclid) &&
      isInvalidString(mediaParameters.gclid) &&
      isInvalidString(mediaParameters.medium) &&
      isInvalidString(mediaParameters.source) &&
      isInvalidString(mediaParameters.term)
    );
  }

  getDefaultMediaParameters(): IMediaParameters {
    return {
      event: 'origem-trafego',
      source: 'direct',
      medium: 'none',
      term: '',
      campaign: '',
      gclid: '',
      fbclid: '',
    };
  }
}
