import { Component, OnInit } from '@angular/core';
import { FlowsAnalytics } from '@models/analytics';
import { FlowService } from '@shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, SvgIconComponent } from '@shared/components';

@Component({
  selector: 'qsc-opened-request-modal',
  templateUrl: './opened-request-modal.component.html',
  styleUrls: ['./opened-request-modal.component.scss'],
  standalone: true,
  imports: [SvgIconComponent, ButtonComponent, TranslateModule],
})
export class OpenedRequestModalComponent implements OnInit {
  trackRequest = false;
  openChat = false;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly flowService: FlowService
  ) {}

  ngOnInit(): void {
    this.sendGtmEventOnOpenModal();
  }

  onTrackRequest(): void {
    this.trackRequest = true;
    this.sendGtmEventOnTrackRequest();
    this.bsModalRef.hide();
  }

  onOpenChat(): void {
    this.openChat = true;
    this.sendGtmEventOnOpenChat();
    this.bsModalRef.hide();
  }

  sendGtmEventOnOpenModal(): void {
    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.erroFrontend,
      FlowsAnalytics.action.solicitacaoExistente,
      FlowsAnalytics.label.visualizacao
    );
  }

  sendGtmEventOnTrackRequest(): void {
    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.erroFrontend,
      FlowsAnalytics.action.solicitacaoExistente,
      FlowsAnalytics.label.acompanhar
    );
  }

  sendGtmEventOnOpenChat(): void {
    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.erroFrontend,
      FlowsAnalytics.action.solicitacaoExistente,
      FlowsAnalytics.label.iniciarChat
    );
  }
}
