import { Injectable } from '@angular/core';
import { IMarkerStrategy } from './marker-strategy';
import { HighFlowMarkerStrategy } from './highflow-marker-strategy';
import { CommonMarkerStrategy } from './common-marker-strategy';
import { MarkerTypeEnum } from './marker-type.enum';
import { DefaultMarkerStrategy } from './default-marker-strategy';

@Injectable({
  providedIn: 'root',
})
export class MarkerStrategyFactory {
  createMarkerStrategy(markerType: MarkerTypeEnum): IMarkerStrategy {
    switch (markerType) {
      case MarkerTypeEnum.HighFlow:
        return new HighFlowMarkerStrategy();
      case MarkerTypeEnum.Common:
        return new CommonMarkerStrategy();
      case MarkerTypeEnum.Default:
        return new DefaultMarkerStrategy();
    }
  }
}
