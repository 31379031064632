import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ChatModalComponent } from '@shared/components/chat-modal/chat-modal.component';
import { IconId } from '@shared/components';
import { LoadingService } from '../loading/loading.service';
import { GtmService } from '../gtm/gtm.service';
import { IApiError } from '@models/error/api-error';

export interface IChatModalParameters {
  title?: string;
  subtitle?: string;
  icon?: IconId;
  apiError?: IApiError;
}

@Injectable({
  providedIn: 'root',
})
export class ChatModalService {
  bsModalRef: any;

  constructor(
    private readonly modalService: BsModalService,
    private readonly loadingService: LoadingService,
    private readonly gtmService: GtmService
  ) {}

  openChatModal(
    input?: IChatModalParameters,
    sendEvent = true
  ): BsModalRef {
    const modalOptions: ModalOptions = {
      initialState: {
        title: input?.title,
        subtitle: input?.subtitle,
        icon: input?.icon,
        sendEvent: sendEvent,
      },
      class: 'chat-modal modal-dialog-centered',
    };
    this.loadingService.hideLoading();
    this.bsModalRef = this.modalService.show(ChatModalComponent, modalOptions);
    if (!input?.apiError || !sendEvent) return this.bsModalRef;
    this.gtmService.sendGtmEventOnDefaultError(input.apiError);
    return this.bsModalRef;
  }
}
