import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as UserActions from './user.actions';
import {
  IBuscaComplemento,
  IBuscaEnderecoUnificado,
  IBuscaTipoFluxoOutput,
  ICep,
} from '@modules/address-validation/models/address-validation';
import { SegmentIdsEnum } from '@models/enums/segment.enum';
import { ISelectedEquipment } from '@modules/equipment-selection/models/equipment';
import { ScheduleFeedbackStatusEnum } from '@modules/scheduling/models/schedule-feedback';
import { ManageScheduleStatusEnum } from '@modules/scheduling/models/manage-schedule';
import { ScheduleFlowEnum } from '@modules/scheduling/models/schedule';
import { ScheduleContactConfirmStatusEnum } from '@modules/scheduling/models/schedule-contact-confirm';
import { FeedbackStatusEnum } from '@modules/region-feedback/containers/region-feedback.component';
import { IEnviaLeadDadosRede, IEnviaLeadInput } from '@models/lead/lead';
import { IDisponibilidadeRedeInput } from '@models/network-availability/network-availability';
import { mapUserData } from './user.mapper';
import { CategoryIdsEnum, ProfileIdsEnum } from '@models/enums';

export interface IUser {
  name?: string | null;
  address?: ICep;
  addressLabel?: string;
  number?: string;
  complements?: IBuscaComplemento[];
  complement?: IBuscaComplemento;
  complementsLabel?: string[];
  complementLabel?: string;
  unifiedAddress?: IBuscaEnderecoUnificado;
  flowTypeData?: IBuscaTipoFluxoOutput;
  optionalComplement?: string | null;
  useOtherComplement?: boolean;
  apartments?: string[];
  apartment?: string;
  numberOfUnits?: string;
  moreThanOneApartment?: string;
  hasOptionalComplement?: string;
  initialQuestionAnswered?: {
    hasComgas?: boolean;
    hasSchedulingWithComgas?: boolean;
  };
  email?: string;
  phoneNumber?: string;
  document?: string;
  company?: string;
  network?: boolean;
  concession?: boolean;
  extension?: boolean;
  extensionConfirmation?: string;
  latitude?: number;
  longitude?: number;
  segmentId?: SegmentIdsEnum;
  categoryId?: CategoryIdsEnum;
  profileId?: ProfileIdsEnum;
  feedbackError?: FeedbackStatusEnum;
  tmeo?: boolean;
  leadInput?: IEnviaLeadInput;
  networkAvailabilityInput?: IDisponibilidadeRedeInput;
  networkData?: IEnviaLeadDadosRede;
  equipments?: ISelectedEquipment[];
  protocol?: string;
  protocolRecoverySuccess?: {
    status?: boolean;
    email?: string;
  };
  scheduling?: {
    dates?: string[];
    times?: string[];
    selectedDate?: string | null;
    selectedTime?: string | null;
    formattedDate?: string | null;
    formattedTime?: string | null;
    videoLink?: string | null;
    consultor?: string | null;
    isInScheduleFlow?: boolean;
    skipScheduling?: boolean;
    reuseProtocol?: boolean;
    hasInPerson?: boolean;
    hasOnline?: boolean;
    isAttendance?: boolean;
    canReschedule?: boolean;
    canScheduleContactConfirm?: {
      status: ScheduleContactConfirmStatusEnum;
      error?: any;
    };
    canSchedule?: {
      status: ScheduleFlowEnum;
      error?: any;
    };
    canManageSchedule?: {
      status: ManageScheduleStatusEnum;
      error?: any;
    };
    canScheduleFeedback?: {
      status: ScheduleFeedbackStatusEnum;
      error?: any;
    };
  };
  lead?: {
    categoryId?: CategoryIdsEnum | null;
  };
}

export interface IUserState {
  data: IUser | null;
  error: any;
}

export const initialState: IUserState = {
  data: null,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.addUser, (state, { user }) => ({
    ...state,
    data: user,
  })),
  on(UserActions.updateUser, (state, { user }) => ({
    ...state,
    data: mapUserData({ ...state.data, ...user }),
  })),
  on(UserActions.deleteUser, (state) => ({
    ...state,
    data: null,
  })),
  on(UserActions.userError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UserActions.setProtocolRecoverySuccess, (state, { status, email }) => ({
    ...state,
    data: {
      ...state.data,
      protocolRecoverySuccess: { status, email },
    },
  })),
  on(UserActions.updateScheduling, (state, { scheduling }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: { ...state.data?.scheduling, ...scheduling },
    },
  })),
  on(UserActions.resetScheduling, (state) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: undefined,
    },
  })),
  on(UserActions.setSchedulingDateOrTime, (state, { key, value }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: {
        ...state.data?.scheduling,
        [key]: value,
      },
    },
  })),
  on(UserActions.setSchedulingFlowStatus, (state, { isInScheduleFlow }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: { ...state.data?.scheduling, isInScheduleFlow },
    },
  })),
  on(UserActions.setInPersonScheduleStatus, (state, { hasInPerson }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: { ...state.data?.scheduling, hasInPerson },
    },
  })),
  on(UserActions.setOnlineScheduleStatus, (state, { hasOnline }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: { ...state.data?.scheduling, hasOnline },
    },
  })),
  on(UserActions.setSkipScheduling, (state, { skipScheduling }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: { ...state.data?.scheduling, skipScheduling },
    },
  })),
  on(UserActions.setReuseProtocol, (state, { reuseProtocol }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: { ...state.data?.scheduling, reuseProtocol },
    },
  })),
  on(UserActions.setCanScheduleFlowStatus, (state, { canSchedule }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: { ...state.data?.scheduling, canSchedule },
    },
  })),
  on(
    UserActions.setCanScheduleContactConfirm,
    (state, { canScheduleContactConfirm }) => ({
      ...state,
      data: {
        ...state.data,
        scheduling: { ...state.data?.scheduling, canScheduleContactConfirm },
      },
    })
  ),
  on(
    UserActions.setCanManageScheduleStatus,
    (state, { canManageSchedule }) => ({
      ...state,
      data: {
        ...state.data,
        scheduling: { ...state.data?.scheduling, canManageSchedule },
      },
    })
  ),
  on(UserActions.setCanRescheduleStatus, (state, { canReschedule }) => ({
    ...state,
    data: {
      ...state.data,
      scheduling: { ...state.data?.scheduling, canReschedule },
    },
  })),
  on(
    UserActions.setCanScheduleFeedbackStatus,
    (state, { canScheduleFeedback }) => ({
      ...state,
      data: {
        ...state.data,
        scheduling: { ...state.data?.scheduling, canScheduleFeedback },
      },
    })
  )
);

export const selectUserState = createFeatureSelector<IUserState>('user');

export const selectUserData = createSelector(
  selectUserState,
  (state: IUserState) => state.data
);

export const selectUserError = createSelector(
  selectUserState,
  (state: IUserState) => state.error
);

export const selectSegmentId = createSelector(
  selectUserState,
  (state: IUserState) => state.data?.segmentId
);

export const selectCategoryId = createSelector(
  selectUserState,
  (state: IUserState) => state.data?.categoryId
);

export const selectProfileId = createSelector(
  selectUserState,
  (state: IUserState) => state.data?.profileId
);

export const selectTmeo = createSelector(
  selectUserState,
  (state: IUserState) => state.data?.tmeo
);

export const selectEquipmentsData = createSelector(
  selectUserState,
  (state: IUserState) => state.data?.equipments
);

export const selectSchedulingData = createSelector(
  selectUserState,
  (state: IUserState) => state.data?.scheduling
);

export const selectSchedulingDate = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.selectedDate
);

export const selectSchedulingTime = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.selectedTime
);

export const selectIsInScheduleFlow = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.isInScheduleFlow
);

export const selectHasInPerson = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.hasInPerson
);

export const selectHasOnline = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.hasOnline
);

export const selectSkipScheduling = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.skipScheduling
);

export const selectReuseProtocol = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.reuseProtocol
);

export const selectCanSchedule = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.canSchedule
);

export const selectCanManageSchedule = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.canManageSchedule
);

export const selectCanScheduleContactConfirm = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.canScheduleContactConfirm
);

export const selectCanReschedule = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.canReschedule
);

export const selectCanScheduleFeedback = createSelector(
  selectSchedulingData,
  (scheduling) => scheduling?.canScheduleFeedback
);
