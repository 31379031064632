import {  isPlatformBrowser, isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformCheckerService {
  private readonly platform = inject(PLATFORM_ID);

  isBrowser(): boolean {
    return isPlatformBrowser(this.platform);
  }

  isServer(): boolean {
    return isPlatformServer(this.platform);
  }
}
