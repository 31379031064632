import { Injectable } from '@angular/core';
import { QuestionDialogModalComponent } from '@shared/modal/question-dialog-modal/question-dialog-modal.component';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

export interface QuestionConfig {
  title: string;
  subtitle?: string;
  iconClass?: string;
  options: QuestionOptionConfig[];
}

export interface QuestionOptionConfig {
  text: string;
  action?: () => void;
  variant: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionModalService {
  bsModalSegmentQuestion: any;

  constructor(private readonly bsModalService: BsModalService) {}

  open(
    config: QuestionConfig,
    callbacks: (() => void)[],
    backButtonClick?: any
  ): BsModalService {
    const options: QuestionOptionConfig[] = [];

    for (let i = 0; i < config.options.length; i++) {
      const option = config.options[i];
      options.push({
        text: option.text,
        action: callbacks[i],
        variant: option.variant,
      });
    }

    const modalOptions: ModalOptions = {
      initialState: {
        iconClass: config.iconClass,
        title: config.title,
        subtitle: config.subtitle,
        options,
        backButtonClick,
      },
      backdrop: 'static',
      keyboard: false,
      class: 'question-dialog-modal modal-dialog-centered',
    };

    this.bsModalSegmentQuestion = this.bsModalService.show(
      QuestionDialogModalComponent,
      modalOptions
    );
    return this.bsModalSegmentQuestion;
  }
}
