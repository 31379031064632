import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SECTION_MAPPING } from '@models/section-mapping/section-mapping';
import { filter, interval, takeWhile } from 'rxjs';

interface ScrollToAnchorOptions {
  topElementSelector?: string;
  offsetTop?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PageScrollerService {
  private readonly sectionMapping = SECTION_MAPPING;

  constructor(
    private readonly router: Router,
    private readonly viewportScroller: ViewportScroller,
    @Inject(DOCUMENT) private document: Document
  ) {}

  handle(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const state = this.router.routerState.snapshot;
        const value = state.root.queryParams['secao'];
        const elementId = this.sectionMapping[value] || value;
        if (elementId) {
          this.scrollToAnchor(elementId);
        }
      });
  }

  scrollToAnchor(elementId: string, options?: ScrollToAnchorOptions): void {
    const maxAttempts = 10;
    let attempts = 0;

    interval(500)
      .pipe(takeWhile(() => attempts < maxAttempts))
      .subscribe(() => {
        if (options?.offsetTop) {
          this.viewportScroller.scrollToPosition([0, options?.offsetTop]);
          attempts = maxAttempts;
          return;
        }
        
        let topElement: HTMLElement | null = null;

        if (options?.topElementSelector) {
          topElement = this.document.querySelector(options?.topElementSelector);
        } else {
          topElement = this.document.querySelector('.header');
        }

        const targetElement = this.document.getElementById(elementId);

        if (topElement && targetElement) {
          const rect = targetElement.getBoundingClientRect();
          const scrollY = this.document.defaultView?.scrollY as number;
          const height = topElement.clientHeight;
          const top = rect.top + scrollY - height;
          this.viewportScroller.scrollToPosition([0, top]);
          attempts = maxAttempts;
          return;
        }
        attempts++;
      });
  }
}
