import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { InstalledGasModalComponent } from '@modules/address-validation/components/installed-gas-modal/installed-gas-modal.component';

@Injectable({
  providedIn: 'root',
})
export class InstalledGasModalService {
  bsModalInstalledGas: any;

  constructor(private readonly bsModalService: BsModalService) {}

  openInstalledGasModal(): BsModalRef {
    const modalOptions: ModalOptions = {
      class: 'installed-gas-modal modal-dialog-centered',
    };

    this.bsModalInstalledGas = this.bsModalService.show(
      InstalledGasModalComponent,
      modalOptions
    );
    return this.bsModalInstalledGas;
  }
}
