import { Injectable } from '@angular/core';
import { fromUser, userActions } from '@core/store';
import { NavigateService } from '../navigate/navigate.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class TmeoService {
  userData?: fromUser.IUser | null;

  constructor(
    private readonly store: Store,
    private readonly navigateService: NavigateService
  ) {
    this.store.select(fromUser.selectUserData).subscribe((data) => {
      this.userData = data;
    });
  }

  isTmeo(): boolean {
    return (
      (this.userData?.concession &&
        !this.userData.network &&
        !this.userData.address?.altopadrao) ??
      false
    );
  }

  handleTmeo(cb: () => void): void {
    this.removeNumberOfUnits();
    cb();
  }

  removeNumberOfUnits(): void {
    this.store.dispatch(
      userActions.updateUser({
        user: { numberOfUnits: undefined },
      })
    );
  }

  updateUserTmeoStatus(isTmeo: boolean = this.isTmeo()): void {
    this.store.dispatch(
      userActions.updateUser({
        user: { tmeo: isTmeo },
      })
    );
  }
}
