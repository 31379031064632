<button
  class="hamburger-menu__btn"
  type="button"
  [attr.aria-expanded]="isActive"
  aria-label="Menu"
  aria-controls="navigation"
  (click)="showMenu()"
>
  <i aria-hidden="true" class="icon-menu_hamburguer"></i>
</button>

<div class="sidenav">
  <div
    class="sidenav__overlay"
    [attr.aria-hidden]="true"
    (click)="closeMenu()"
    (keyup)="closeMenuOnEsc($event)"
    [ngClass]="{ 'sidenav__overlay--active': isActive }"
  ></div>

  <nav class="sidenav__menu" [ngClass]="{ 'sidenav__menu--active': isActive }">
    <div
      class="sidenav__menu-content"
      [ngClass]="{
        'sidenav__menu-content--active': isActive
      }"
    >
      <div class="sidenav__header">
        <img
          class="logo"
          [src]="'assets/img/svg/logo/logo-white.svg' | fileHash"
          alt=""
        />
        <div class="d-flex justify-content-center align-items-center gap-3">
          <button
            class="emergency-btn d-xl-none"
            (click)="openEmergencies()"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Emergencia"
          >
            <img
              [src]="'assets/img/svg/icon-cg-alerta.svg' | fileHash"
              alt="Emergencia"
              loading="lazy"
            />
          </button>
          <button aria-label="Fechar" class="login-btn" (click)="openLogin()">
            <i aria-hidden="true" class="icon-perfil"></i>
          </button>
          <button aria-label="Fechar" class="close-btn" (click)="closeMenu()">
            <i aria-hidden="true" class="icon-sinal_multiplicar_x_fechar"></i>
          </button>
        </div>
      </div>

      <div class="sidenav__inner-content">
        <div class="d-flex flex-column gap-3 overflow-y-auto max-h-container">
          <div class="menu-items">
            @for (item of menuItems; track $index) {
            <div class="menu-item">
              <qsc-expandable-list
                [title]="item.label | translate"
                [isExpanded]="item.id === currentMenuId"
                (expanded)="showSubMenu(item)"
              >
                <ul>
                  @for (subItem of currentSubMenu; track $index) {
                  <li>
                    @if (subItem.url.startsWith('/')) {
                    <a
                      href="#"
                      aria-label="Link"
                      (click)="onSegmentClick(subItem.id)"
                      [class.active]="checkCurrentPageByUrl(subItem.url)"
                      [routerLink]="subItem.url"
                      [innerHTML]="subItem.label | translate"
                      rel="noopener"
                    ></a>
                    } @else {
                    <a
                      href="#"
                      aria-label="Link"
                      (click)="onSegmentClick(subItem.id)"
                      [href]="getExternalLink(subItem.url)"
                      target="_blank"
                      [innerHTML]="subItem.label | translate"
                      rel="noopener"
                    ></a>
                    }
                  </li>
                  }
                </ul>
              </qsc-expandable-list>
            </div>
            }
          </div>

          <div
            class="consult-schedule"
            routerLink="agendamento/consultar"
            [qscEventTrackerCategory]="headerAnalytics.category.menuPrincipal"
            [qscEventTrackerAction]="
              headerAnalytics.action.agendamentoVideochamada
            "
            [qscEventTrackerLabel]="headerAnalytics.label.clique"
          >
            <i aria-hidden="true" class="icon-calendario_check-1"></i>
            <button [innerHTML]="'@QUICK-ACCESS-LABEL-4' | translate"></button>
          </div>
        </div>
        <div class="banner">
          <p
            class="banner__title"
            [innerHTML]="menuBanner.title | translate"
          ></p>
          <button
            (click)="clickBanner(menuBanner.title)"
            class="btn-reset banner__image"
          >
            <qsc-picture
              [webp]="{
                mobile: imageBanner.mobile.webp,
              }"
              [png]="{
                mobile: imageBanner.mobile.png,
              }"
              [alt]="imageBanner.alt"
            ></qsc-picture>

            <img
              class="svg-text"
              [src]="menuBanner.svgs[0] | fileHash"
              alt=""
            />
            <img
              class="svg-logo"
              [src]="menuBanner.svgs[1] | fileHash"
              alt=""
            />
          </button>

          <caption
            class="banner__disclaimer"
            [innerHTML]="menuBanner.disclaimer | translate"
          ></caption>
        </div>
      </div>
    </div>
  </nav>
</div>
