import { Injectable } from '@angular/core';
import { LoadingComponent } from '@shared/components';
import { sleep } from '@shared/utils/sleep';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingModalRef?: BsModalRef;
  private refs: BsModalRef[] = [];

  constructor(private readonly bsModalService: BsModalService) {}

  showLoading(
    loadingText: string,
    loadingStatus?: { success?: boolean; error?: boolean }
  ) {
    const modalOptions: ModalOptions = {
      initialState: { loadingText, loadingStatus },
      backdrop: 'static',
      keyboard: false,
      class: 'loading-modal modal-dialog-centered',
    };
    this.loadingModalRef = this.bsModalService.show(
      LoadingComponent,
      modalOptions
    );
    this.refs.push(this.loadingModalRef);
  }

  hideLoading(loadingText?: string) {
    if (this.loadingModalRef) {
      this.refs.forEach((ref) => {
        if (this.loadingModalRef) {
          if (loadingText?.length) {
            this.loadingModalRef.content = loadingText;
            sleep(600).then(() => ref.hide());
          } else {
            ref.hide();
          }
          this.removeRefs(ref);
        }
      });
    }
  }

  private removeRefs(refParam: BsModalRef): void {
    const index = this.refs.findIndex((ref) => ref.id === refParam.id);
    this.refs.splice(index, 1);
  }
}
